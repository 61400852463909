"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ControlLabel = (theme) => {
    return {
        MuiFormControlLabel: {
            styleOverrides: {
                label: Object.assign({}, theme.typography.body2),
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginTop: theme.spacing(1),
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: theme.palette.text.disabled,
                },
            },
        },
    };
};
exports.default = ControlLabel;
