"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.roofAreaQuerySchema = exports.roofAreaUpdateSchema = exports.roofAreaWriteSchema = exports.roofAreaReadSchema = exports.roofAreaSchema = exports.ROOF_PITCHES = exports.sizeOptions = exports.RoofPitches = exports.MOUNTING_TYPES = exports.azimuthLookup = exports.ROOF_DIRECTIONS = exports.roofDirections = exports.MountTypes = void 0;
var typebox_1 = require("@feathersjs/typebox");
var general_types_1 = require("./general-types");
exports.MountTypes = [
    {
        id: 0,
        name: 'tile',
        label: 'Tile Roof',
        iconPath: '/svgs/roofTypes/tile-roof.svg',
    },
    {
        id: 1,
        name: 'ibr',
        label: 'IBR',
        iconPath: '/svgs/roofTypes/IBR.svg',
    },
    {
        id: 2,
        name: 'clip-lock',
        label: 'Clip Lock',
        iconPath: '/svgs/roofTypes/ClipLock.svg',
    },
    {
        id: 3,
        name: 'slate',
        label: 'Slate Roof',
        iconPath: '/svgs/roofTypes/SlateRoof.svg',
    },
    {
        id: 4,
        name: 'flat-concrete',
        label: 'Concrete Roof',
        iconPath: '/svgs/roofTypes/FlatConcrete.svg',
    },
    {
        id: 5,
        name: 'carport',
        label: 'Carport',
        iconPath: '/svgs/roofTypes/Carport.svg',
    },
    {
        id: 6,
        name: 'ground',
        label: 'On Ground',
        iconPath: '/svgs/roofTypes/OnGround.svg',
    },
];
exports.roofDirections = [
    { id: '1', direction: 'north' },
    { id: '2', direction: 'north east' },
    { id: '3', direction: 'east' },
    { id: '4', direction: 'south east' },
    { id: '5', direction: 'south' },
    { id: '6', direction: 'south west' },
    { id: '7', direction: 'west' },
    { id: '8', direction: 'north west' },
];
exports.ROOF_DIRECTIONS = [
    'north',
    'north east',
    'east',
    'south east',
    'south',
    'south west',
    'west',
    'north west',
];
exports.azimuthLookup = {
    north: 0,
    'north east': 45,
    east: 90,
    'south east': 135,
    south: 180,
    'south west': 225,
    west: 270,
    'north west': 315,
};
// Mounting types here affect the consumables subgroups with regards to mounting.
exports.MOUNTING_TYPES = [
    'tile',
    'carport',
    'ground',
    'ibr',
    'flat-concrete',
    'slate',
    'clip-lock',
    'corrugated-sheet-metal',
];
exports.RoofPitches = [
    {
        id: 0,
        number: 0,
        label: 'Flat Pitch',
        iconPath: '/svgs/roofPitches/FlatRoof.svg',
    },
    {
        id: 1,
        number: 22.5,
        label: 'Gentle Pitch',
        iconPath: '/svgs/roofPitches/GentlePitch.svg',
    },
    {
        id: 2,
        number: 45,
        label: 'Average Pitch',
        iconPath: '/svgs/roofPitches/AveragePitch.svg',
    },
    {
        id: 3,
        number: 75,
        label: 'Steep Pitch',
        iconPath: '/svgs/roofPitches/SteepPitch.svg',
    },
];
exports.sizeOptions = {
    small: {
        area: [
            [-26.052768788113116, 28.060910784337203],
            [-26.052768788113116, 28.060941629740874],
            [-26.052796499309892, 28.060941629740874],
            [-26.052800113813323, 28.06090273771016],
        ],
        areaSize: 11.377788025693997,
        pitch: 22.5,
        mountType: 'tile',
        id: '156eaac5-e06d-46e5-8e07-a3d09d45a913',
        direction: 'north',
    },
    medium: {
        area: [
            [-26.052778426790997, 28.06083970579831],
            [-26.05276637844352, 28.060914807650725],
            [-26.052815776660303, 28.060918830964248],
            [-26.05282180083165, 28.060830318066756],
        ],
        areaSize: 41.976385022631305,
        pitch: 22.5,
        mountType: 'tile',
        id: '156eaac5-e06d-46e5-8e07-a3d09d45a913',
        direction: 'north',
    },
    large: {
        area: [
            [-26.05274830591998, 28.060837023589293],
            [-26.05273625756942, 28.060926877591292],
            [-26.052844692679972, 28.0609282186958],
            [-26.05284589751397, 28.060842388007323],
        ],
        areaSize: 100.98796992021522,
        pitch: 22.5,
        mountType: 'tile',
        id: '156eaac5-e06d-46e5-8e07-a3d09d45a913',
        direction: 'north',
    },
    veryLarge: {
        area: [
            [-26.052754330094803, 28.060778014990966],
            [-26.052735052734292, 28.06094431194989],
            [-26.052843487845962, 28.060945653054397],
            [-26.052855536185515, 28.060767286154906],
        ],
        areaSize: 200.28500359656246,
        pitch: 22.5,
        mountType: 'tile',
        id: '156eaac5-e06d-46e5-8e07-a3d09d45a913',
        direction: 'north',
    },
};
exports.ROOF_PITCHES = [0, 22.5, 45, 75];
exports.roofAreaSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    area: typebox_1.Type.Array(general_types_1.coordinateSchema),
    areaSize: typebox_1.Type.Number(),
    pitch: typebox_1.Type.Number(),
    azimuth: typebox_1.Type.Optional(typebox_1.Type.Number()),
    direction: (0, typebox_1.StringEnum)(__spreadArray([], exports.ROOF_DIRECTIONS, true)),
    mountType: (0, typebox_1.StringEnum)(__spreadArray([], exports.MOUNTING_TYPES, true)),
    organisationId: typebox_1.Type.Optional(typebox_1.Type.String()),
    propertyId: typebox_1.Type.String(),
    ownerId: typebox_1.Type.String(),
    isDummy: typebox_1.Type.Optional(typebox_1.Type.Boolean()),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.roofAreaReadSchema = typebox_1.Type.Omit(exports.roofAreaSchema, []);
exports.roofAreaWriteSchema = typebox_1.Type.Omit(exports.roofAreaReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.roofAreaUpdateSchema = typebox_1.Type.Partial(exports.roofAreaSchema, {
    $id: 'RoofAreaUpdate',
});
var roofAreaQueryProperties = typebox_1.Type.Partial(exports.roofAreaSchema, {
    $id: 'RoofAreaQuery',
});
exports.roofAreaQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(roofAreaQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
