"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var bmwfinancialservices = {
    theme: 'bmwfinancialservices',
    brand: {
        name: 'BMW Financial Services',
        legalName: 'BMW Financial Services',
        logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/BMW/Group%20logo%20_BMW_MINI%20(1).png',
        icon: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/BMW/Group%20logo%20_BMW_MINI%20(1).png',
    },
    financePartners: [],
    pages: {
        signup: {
            sections: {
                titleSection: {
                    title: "From start to solar, we've got your back.",
                },
            },
        },
    },
    partner: {
        name: 'Hohm Energy',
        legalName: 'Hohm Services (Pty) Ltd.',
        logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/general/HohmEnergyLogoOrange.png',
    },
    poweredBy: {
        name: 'Hohm Energy',
        legalName: 'Hohm Services (Pty) Ltd.',
        logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/general/HohmEnergyLogoOrange.png',
    },
    modules: {
        draftProposal: {
            enableRentalBanner: false,
        },
    },
    packages: {
        hohmPdf: {
            customBookACall: true,
            hideBrandLogo: true,
        },
    },
    financeOptions: [
        {
            label: 'Solar Finance',
            value: 'bmw-financial-services',
            default: true,
        },
    ],
    hideSubscribeBuyCTA: true,
};
exports.default = bmwfinancialservices;
