"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.appliedProductKitSchema = exports.inclusiveProductKitSchema = exports.productKitQuerySchema = exports.productKitUpdateSchema = exports.productKitWriteSchema = exports.productKitReadSchema = exports.productKitSchema = exports.configSchema = exports.PRODUCT_KIT_TAGS = void 0;
var typebox_1 = require("@feathersjs/typebox");
var battery_types_1 = require("./battery.types");
var inverter_types_1 = require("./inverter.types");
var kit_consumable_types_1 = require("./kit-consumable.types");
var kit_panel_types_1 = require("./kit-panel.types");
var organisation_types_1 = require("./organisation.types");
var panel_types_1 = require("./panel.types");
var variables_1 = require("../constants/variables");
var loadEagerSchema = (0, typebox_1.StringEnum)([
    'inverter',
    'battery',
    'secondaryBattery',
    'kitConsumables',
    'installers',
    'kitPanels',
    'owner',
]);
exports.PRODUCT_KIT_TAGS = __spreadArray(['PREMIUM', 'VALUE', 'GLINT'], variables_1.PACKAGE_TAGS, true);
exports.configSchema = typebox_1.Type.Object({
    limits: typebox_1.Type.Object({
        battery: typebox_1.Type.Object({
            min: typebox_1.Type.Number(),
            max: typebox_1.Type.Number(),
        }),
        kwp: typebox_1.Type.Object({
            min: typebox_1.Type.Number(),
            max: typebox_1.Type.Number(),
        }),
        secondaryBattery: typebox_1.Type.Optional(typebox_1.Type.Object({
            min: typebox_1.Type.Number(),
            max: typebox_1.Type.Number(),
            maxNoPerStack: typebox_1.Type.Number(),
        })),
        numOfInverters: typebox_1.Type.Number(),
    }),
    kwCapacity: typebox_1.Type.Optional(typebox_1.Type.Number()),
});
var kitPanelConfigSchema = typebox_1.Type.Object({
    orderNo: typebox_1.Type.Number(),
    id: typebox_1.Type.String(),
});
exports.productKitSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    name: typebox_1.Type.String(),
    active: typebox_1.Type.Boolean(),
    config: exports.configSchema,
    orderNo: typebox_1.Type.Optional(typebox_1.Type.Number()),
    markup: typebox_1.Type.Optional(typebox_1.Type.Number()),
    subscriptionCost: typebox_1.Type.Optional(typebox_1.Type.Number()),
    // Relationships
    inverterId: typebox_1.Type.String(),
    batteryId: typebox_1.Type.Optional(typebox_1.Type.String()),
    ownerId: typebox_1.Type.Optional(typebox_1.Type.String()),
    kitPanelConfigs: typebox_1.Type.Array(kitPanelConfigSchema),
    tags: typebox_1.Type.Optional(typebox_1.Type.Array((0, typebox_1.StringEnum)(__spreadArray([], exports.PRODUCT_KIT_TAGS, true)))),
    kitInstallerIds: typebox_1.Type.Array(typebox_1.Type.Number()),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.productKitReadSchema = typebox_1.Type.Intersect([
    exports.productKitSchema,
    typebox_1.Type.Partial(typebox_1.Type.Object({
        inverter: inverter_types_1.inverterReadSchema,
        battery: typebox_1.Type.Optional(battery_types_1.batteryReadSchema),
        secondaryBattery: typebox_1.Type.Optional(battery_types_1.batteryReadSchema),
        kitConsumables: typebox_1.Type.Array(kit_consumable_types_1.kitConsumableReadSchema),
        kitPanels: typebox_1.Type.Array(kit_panel_types_1.kitPanelSchema),
        installers: typebox_1.Type.Array(organisation_types_1.organisationReadSchema),
    })),
]);
exports.productKitWriteSchema = typebox_1.Type.Omit(exports.productKitSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.productKitUpdateSchema = typebox_1.Type.Partial(exports.productKitSchema, {
    $id: 'ProductKitUpdate',
});
var productKitQueryProperties = typebox_1.Type.Partial(exports.productKitSchema, {
    $id: 'ProductKitQuery',
});
exports.productKitQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(productKitQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({
        $loadEager: typebox_1.Type.Optional(typebox_1.Type.Array(loadEagerSchema)),
        skipHandleReorder: typebox_1.Type.Optional(typebox_1.Type.Boolean()),
    }, { additionalProperties: true }),
], { additionalProperties: false });
exports.inclusiveProductKitSchema = typebox_1.Type.Intersect([
    exports.productKitSchema,
    // add the other schemas here
    typebox_1.Type.Object({
        inverter: inverter_types_1.inverterReadSchema,
        battery: typebox_1.Type.Optional(battery_types_1.batteryReadSchema),
        secondaryBattery: typebox_1.Type.Optional(battery_types_1.batteryReadSchema),
        kitConsumables: typebox_1.Type.Array(kit_consumable_types_1.kitConsumableReadSchema),
        kitPanels: typebox_1.Type.Array(kit_panel_types_1.kitPanelSchema),
        installers: typebox_1.Type.Array(organisation_types_1.organisationReadSchema),
    }),
]);
exports.appliedProductKitSchema = typebox_1.Type.Intersect([
    exports.inclusiveProductKitSchema,
    typebox_1.Type.Object({
        panel: typebox_1.Type.Optional(panel_types_1.panelSchema),
        quantity: typebox_1.Type.Object({
            battery: typebox_1.Type.Number(),
            secondaryBattery: typebox_1.Type.Number(),
            panel: typebox_1.Type.Number(),
            inverter: typebox_1.Type.Number(),
        }),
    }),
]);
