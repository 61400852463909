import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { Analytics } from 'hohm-utilities';

export const TrackNextPageViews = () => {
	const router = useRouter();

	useEffect(() => {
		Analytics.trackPageView();
	}, [router]);

	return null;
};
