"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var translation = {
    theme: 'default',
    brand: {
        name: 'Hohm Energy',
        legalName: 'Hohm Services (Pty) Ltd.',
        logo: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/general/HohmEnergyLogoOrange.png',
        icon: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/general/HohmEnergyLogoOrange.png',
    },
    pages: {
        signup: {
            heading: 'Claim your solar offer now.',
            content: 'Generate your customised proposal in 4 minutes.',
            promoImage: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/signUp/iPhone%20%281%29.png',
            callUsNumber: '010 510 4449',
            callUsText: '',
            offerings: [
                {
                    id: 0,
                    heading: 'Say goodbye to load shedding',
                    content: "Hohm Energy will give you and your family peace of mind that you don't have to restructure your life around the inconvenience of load shedding.",
                },
                {
                    id: 1,
                    heading: 'Get the best price',
                    content: 'Hohm Energy and Investec have negotiated the best prices from product suppliers and solar installers to ensure the best value for you.',
                },
                {
                    id: 2,
                    heading: 'Your own energy advisor',
                    content: 'Hohm Energy is with you every step of the way to answer any questions, manage the process and ensure your solar journey is as seamless as possible.',
                },
            ],
            hideOffers: true,
            sections: {
                titleSection: {
                    title: "From start to solar, we've got your back.",
                },
            },
        },
    },
    modules: {
        draftProposal: {
            enableAdditionalFields: false,
            enableRentalBanner: false,
            showRewardsAlert: false,
            defaultOM: false,
            monthlyBill: {
                usageMap: {
                    low: 470,
                    medium: 824,
                    high: 2100,
                },
            },
        },
    },
    financePartners: [],
    poweredBy: {
        name: '',
        legalName: '',
        logo: '',
    },
    financeOptions: [
        {
            label: 'Solar Black Friday',
            value: 'solar-black-friday',
            default: true,
        },
    ],
    subscriptionOnly: true,
};
exports.default = translation;
