'use client';

import { useState, useEffect } from 'react';
import Link from 'next/link';
// @mui
import {
	List,
	Theme,
	styled,
	Divider,
	Collapse,
	ListItemText,
	ListSubheader,
	ListItemButton,
} from '@mui/material';
// @icon
import { ChevronRight } from '@mui/icons-material';
// @local
import { TMenuItem, TMenuWithSubmenuItems } from './menu.types';

const StyledListItemButton = styled(ListItemButton)(() => ({
	color: 'rgba(255,255,255,0.7)',
	'&.Mui-selected': {
		color: 'rgba(255,255,255,1) !important',
		background: 'rgba(0, 0, 0, 0.1) !important',
		boxShadow: '0 0 6px #000 inset',
	},
}));

const StyledChevronRight = styled(ChevronRight)(
	({ theme, isOpened }: { theme?: Theme; isOpened: boolean }) => ({
		color: theme?.palette.common.white,
		minWidth: '35px',
		transition: 'all 300ms',
		...(isOpened && { transform: 'rotate(90deg)' }),
	})
);

const StyledDivider = styled(Divider)(() => ({
	'&:before': {
		borderColor: 'rgba(255,255,255,0.4)',
	},
	'&:after': {
		borderColor: 'rgba(255,255,255,0.4)',
	},
}));

const StyledListSubheader = styled(ListSubheader)(() => ({
	color: 'rgba(255,255,255,0.4)',
	background: 'transparent',
}));

const StyledListItemText = styled(ListItemText)(
	({
		theme,
		isNested,
		isSelected,
	}: {
		theme?: Theme;
		isNested: boolean;
		isSelected: boolean;
	}) => ({
		...(isNested && {
			color: `rgba(255,255,255,${isSelected ? '1' : '0.7'})`,
			paddingLeft: theme?.spacing(2),
		}),
	})
);

const StyledList = styled(List)(() => ({ minWidth: '212px' }));

const VerticalMenuItem = ({
	text,
	href = '',
	onClick,
	pathname,
	selected,
	isOpened,
	isNested = false,
}: TMenuItem & {
	pathname: string;
	selected?: boolean;
	isOpened?: boolean;
	isNested?: boolean;
}) => {
	const linkProps = {
		href,
		onClick,
	};
	const isMenuItemHead = typeof isOpened === 'boolean';
	const isSelected = selected || (!isMenuItemHead && pathname === href);

	return (
		<Link href={href}>
			<StyledListItemButton
				selected={!!href && isSelected}
				{...linkProps}
			>
				<StyledListItemText
					primary={text}
					isNested={isNested}
					isSelected={isSelected}
				/>
				{isMenuItemHead && <StyledChevronRight isOpened={isOpened} />}
			</StyledListItemButton>
		</Link>
	);
};

export type TSidebarProps = {
	menu: TMenuWithSubmenuItems[];
	pathname: string;
};
export const Sidebar = ({ menu, pathname }: TSidebarProps) => {
	const [openItems, setOpenItems] = useState<string[]>([]);

	const toggleCollapse = (menuItem: string) => () => {
		setOpenItems(openItems.includes(menuItem) ? [] : [menuItem]);
	};

	useEffect(() => {
		menu.find(
			({ submenuItems, text }) =>
				!openItems.includes(text) &&
				submenuItems?.find(({ href }) => pathname === href) &&
				toggleCollapse(text)()
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	return (
		<StyledList dense>
			{menu.map(({ href, text, onClick, submenuItems }) => {
				if (href || onClick) {
					return (
						<VerticalMenuItem
							key={text}
							href={href}
							text={text}
							onClick={onClick}
							pathname={pathname}
						/>
					);
				}
				if (submenuItems) {
					return (
						<div key={text}>
							<VerticalMenuItem
								text={text}
								onClick={toggleCollapse(text)}
								isOpened={openItems.includes(text)}
								pathname={pathname}
							/>
							<Collapse
								in={openItems.includes(text)}
								timeout="auto"
								unmountOnExit
							>
								<List dense component="div" disablePadding>
									{submenuItems.map((submenuItem) => (
										<VerticalMenuItem
											key={`collapse_${submenuItem.text}`}
											isNested
											pathname={pathname}
											{...submenuItem}
										/>
									))}
								</List>
							</Collapse>
						</div>
					);
				}

				return (
					<StyledDivider key={`divider_${text}`}>
						<StyledListSubheader>{text}</StyledListSubheader>
					</StyledDivider>
				);
			})}
		</StyledList>
	);
};
