"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadProfileQuerySchema = exports.loadProfileUpdateSchema = exports.loadProfileWriteSchema = exports.loadProfileReadSchema = exports.loadProfileSchema = exports.HEMISPHERES = exports.SEASONS = exports.returnLoadProfileCategory = exports.HOME_TYPE_AND_PROPERTY_LOAD_PROFILES = exports.PROPERTY_LOAD_PROFILES = exports.HOME_TYPE_LOAD_PROFILES = void 0;
var typebox_1 = require("@feathersjs/typebox");
exports.HOME_TYPE_LOAD_PROFILES = [
    'complex',
    'cluster',
    'free hold',
];
exports.PROPERTY_LOAD_PROFILES = [
    'agriculture',
    'commercial-office',
    'industrial',
    'retail-park',
    'shopping-center',
    'apartment',
    'farm',
    'home',
];
exports.HOME_TYPE_AND_PROPERTY_LOAD_PROFILES = __spreadArray(__spreadArray([], exports.HOME_TYPE_LOAD_PROFILES, true), exports.PROPERTY_LOAD_PROFILES, true);
var returnLoadProfileCategory = function (propertyType) {
    return (exports.HOME_TYPE_LOAD_PROFILES.includes(propertyType)
        ? 'home'
        : propertyType);
};
exports.returnLoadProfileCategory = returnLoadProfileCategory;
exports.SEASONS = ['autumn', 'spring', 'summer', 'winter'];
exports.HEMISPHERES = ['north', 'south'];
exports.loadProfileSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    propertyType: (0, typebox_1.StringEnum)(__spreadArray([], exports.HOME_TYPE_AND_PROPERTY_LOAD_PROFILES, true)),
    hemisphere: (0, typebox_1.StringEnum)(__spreadArray([], exports.HEMISPHERES, true)),
    season: (0, typebox_1.StringEnum)(__spreadArray([], exports.SEASONS, true)),
    profile: typebox_1.Type.Array(typebox_1.Type.Object({
        time: typebox_1.Type.String(),
        kw: typebox_1.Type.Number(),
        ratio: typebox_1.Type.Number(),
    })),
    totalMonthlyUsage: typebox_1.Type.Number(),
    propertyId: typebox_1.Type.String(),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.loadProfileReadSchema = typebox_1.Type.Omit(exports.loadProfileSchema, []);
exports.loadProfileWriteSchema = typebox_1.Type.Omit(exports.loadProfileReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.loadProfileUpdateSchema = typebox_1.Type.Partial(exports.loadProfileSchema, {
    $id: 'LoadProfileUpdate',
});
var loadProfileQueryProperties = typebox_1.Type.Partial(exports.loadProfileSchema, {
    $id: 'LoadProfileQuery',
});
exports.loadProfileQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(loadProfileQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
