import {
	type AccordionSummaryProps as TAccordionSummaryProps,
	styled,
	AccordionSummary as MuiAccordionSummary,
} from '@mui/material';
import { ArrowForwardIosSharp } from '@mui/icons-material';

export { TAccordionSummaryProps };
export const AccordionSummary = styled((props: TAccordionSummaryProps) => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor:
		theme.palette.mode === 'dark'
			? 'rgba(255, 255, 255, .05)'
			: 'rgba(0, 0, 0, .03)',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1),
	},
}));
