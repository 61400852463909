"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Tooltip = (theme) => {
    const isLight = theme.palette.mode === 'light';
    return {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: theme.palette.grey[isLight ? 800 : 700],
                },
                arrow: {
                    color: theme.palette.grey[isLight ? 800 : 700],
                },
            },
        },
    };
};
exports.default = Tooltip;
