"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.compressedImage = void 0;
var imageKitEndpoint = 'https://ik.imagekit.io/15fiecozb/tr:f-jpg,bg-ffffff';
var compressedImage = function (param) {
    if (!param)
        return null;
    var urlParam = new URL(param);
    if (!urlParam)
        return null;
    var pathname = urlParam.pathname;
    return "".concat(imageKitEndpoint).concat(pathname);
};
exports.compressedImage = compressedImage;
