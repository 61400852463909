export type TPrivacyPolicyProps = Record<string, never>;
export const PrivacyPolicy = () => (
	<a
		rel="noreferrer"
		href="https://www.hohmenergy.co.za/terms-and-conditions"
		style={{
			textDecoration: 'underline',
		}}
		target="_blank"
	>
		Privacy Policy
	</a>
);
