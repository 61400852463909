"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var clearscore = {
    theme: 'clearscore',
    brand: {
        name: 'ClearScore',
        legalName: 'ClearScore',
        logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/clearscore/clearscore-dark.png',
        icon: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/clearscore/clearscore-dark.png',
    },
    pages: {
        signup: {
            sections: {
                titleSection: {
                    title: "From start to solar, we've got your back.",
                },
            },
        },
    },
    poweredBy: {
        name: 'Hohm Energy',
        legalName: 'Hohm Services (Pty) Ltd.',
        logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/general/HohmEnergyLogoOrange.png',
    },
    partner: {
        name: 'Hohm Energy',
        legalName: 'Hohm Services (Pty) Ltd.',
        logo: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/general/HohmEnergyLogoOrange.png',
    },
    packages: {
        hohmPdf: {
            customBookACall: false,
            hideBrandLogo: false,
            hideFinancePages: false,
            hideInvoicePage: false,
        },
    },
    financePartners: [
        {
            name: 'ClearScore',
            logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/clearscore/clearscore-dark.png',
        },
        {
            name: 'FNB',
            logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/fnb/88a93794-c079-490f-9ee8-d6d19a4c797c.png',
        },
        {
            name: 'Glint',
            logo: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/glint/glint-logo.png',
        },
    ],
    financeOptions: [
        {
            label: 'Solar or battery subscription',
            value: 'glint',
            default: true,
        },
        {
            label: 'Cash',
            value: 'cash',
        },
        {
            label: 'FNB Solar Finance',
            value: 'fnb-solar-finance',
        },
    ],
};
exports.default = clearscore;
