"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var oldmutual = {
    pages: {
        signup: {
            sections: {
                titleSection: {
                    title: "From start to solar, we've got your back.",
                },
            },
        },
    },
    partner: {
        name: 'Old Mutual',
        legalName: 'Old Mutual',
        logo: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/oldmutual-images/oldmutual-logo.png',
    },
    financeOptions: [
        {
            label: 'Cash',
            value: 'cash',
        },
        {
            label: 'Solar Finance',
            value: 'nedbank-mfc',
            default: true,
        },
        {
            label: 'Home Loan',
            value: 'home-loan',
        },
    ],
};
exports.default = oldmutual;
