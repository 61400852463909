"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.taxQuerySchema = exports.taxUpdateSchema = exports.taxWriteSchema = exports.taxReadSchema = exports.taxSchema = exports.appliedTaxSchema = void 0;
var typebox_1 = require("@feathersjs/typebox");
var country_codes_1 = require("../constants/country-codes");
exports.appliedTaxSchema = typebox_1.Type.Object({
    name: typebox_1.Type.String(),
    value: typebox_1.Type.Number(),
});
exports.taxSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    name: typebox_1.Type.String(),
    description: typebox_1.Type.Optional(typebox_1.Type.String()),
    countryCode: (0, typebox_1.StringEnum)(__spreadArray([], country_codes_1.COUNTRY_SUPPORTED_CODES, true)),
    percentage: typebox_1.Type.Number(),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.taxReadSchema = typebox_1.Type.Omit(exports.taxSchema, []);
exports.taxWriteSchema = typebox_1.Type.Omit(exports.taxReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.taxUpdateSchema = typebox_1.Type.Partial(exports.taxSchema, {
    $id: 'TaxUpdate',
});
var taxQueryProperties = typebox_1.Type.Partial(exports.taxSchema, {
    $id: 'TaxQuery',
});
exports.taxQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(taxQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
