import React from 'react';
// @mui
import { GridOverlay } from '@mui/x-data-grid';
import { LinearProgress } from '@mui/material';

function LoadingOverlay() {
	return (
		<GridOverlay>
			<div style={{ position: 'absolute', top: 0, width: '100%' }}>
				<LinearProgress />
			</div>
		</GridOverlay>
	);
}

export default LoadingOverlay;
