"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.cablingQuerySchema = exports.cablingUpdateSchema = exports.cablingWriteSchema = exports.cablingReadSchema = exports.cablingSchema = exports.PVCableThicknessMap = exports.ACCableThicknessMap = exports.BatteryCableThicknessMap = exports.PV_STRING_MAP = exports.AC_CABLE_THICKNESSES = exports.PV_CABLE_THICKNESSES = exports.BATTERY_CABLE_THICKNESSES = exports.THREE_PHASE_AC_CABLE_RUNS = exports.SINGLE_PHASE_AC_CABLE_RUNS = exports.STANDARD_BATTERY_RUN_METERS = exports.STANDARD_PANEL_RUN_METERS = exports.STANDARD_AC_RUN_METERS = void 0;
var typebox_1 = require("@feathersjs/typebox");
var currency_types_1 = require("./currency.types");
exports.STANDARD_AC_RUN_METERS = 25;
exports.STANDARD_PANEL_RUN_METERS = 50;
exports.STANDARD_BATTERY_RUN_METERS = 3;
exports.SINGLE_PHASE_AC_CABLE_RUNS = 1;
exports.THREE_PHASE_AC_CABLE_RUNS = 2;
exports.BATTERY_CABLE_THICKNESSES = [25, 35, 50, 70];
exports.PV_CABLE_THICKNESSES = [6];
exports.AC_CABLE_THICKNESSES = [6, 10];
exports.PV_STRING_MAP = {
    3: 2,
    5: 2,
    8: 4,
    12: 3,
};
exports.BatteryCableThicknessMap = {
    3: 25,
    5: 35,
    8: 50,
    12: 70,
};
exports.ACCableThicknessMap = {
    3: 6,
    5: 6,
    8: 10,
    12: 6,
};
exports.PVCableThicknessMap = {
    3: 6,
    5: 6,
    8: 6,
    12: 6,
};
var inverterSizeMatchSchema = typebox_1.Type.Object({
    inverterSize: typebox_1.Type.Number(),
    value: typebox_1.Type.Number(),
});
var cablingAccessoriesSchema = typebox_1.Type.Object({
    singlePhase: typebox_1.Type.Array(inverterSizeMatchSchema),
    threePhase: typebox_1.Type.Array(inverterSizeMatchSchema),
});
var acCablingPricingSchema = typebox_1.Type.Object({
    twoCore: typebox_1.Type.Number(),
    fourCore: typebox_1.Type.Number(),
    earth: typebox_1.Type.Number(),
});
var pvCablingPricingSchema = typebox_1.Type.Object({
    thickness: typebox_1.Type.Number(),
    red: typebox_1.Type.Number(),
    black: typebox_1.Type.Number(),
    earth: typebox_1.Type.Number(),
});
var batteryCablingPricingSchema = typebox_1.Type.Object({
    thickness: typebox_1.Type.Number(),
    red: typebox_1.Type.Number(),
    black: typebox_1.Type.Number(),
});
var dcCablingPricesSchema = typebox_1.Type.Object({
    pv: typebox_1.Type.Array(pvCablingPricingSchema),
    battery: typebox_1.Type.Array(batteryCablingPricingSchema),
});
exports.cablingSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Number(),
    currency: (0, typebox_1.StringEnum)(__spreadArray([], currency_types_1.SUPPORTED_CURRENCY, true)),
    accessories: cablingAccessoriesSchema,
    acCabling: acCablingPricingSchema,
    dcCabling: dcCablingPricesSchema,
    organisationId: typebox_1.Type.Number(),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.cablingReadSchema = typebox_1.Type.Omit(exports.cablingSchema, ['organisationId']);
exports.cablingWriteSchema = typebox_1.Type.Omit(exports.cablingReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.cablingUpdateSchema = typebox_1.Type.Partial(exports.cablingSchema, {
    $id: 'CablingUpdate',
});
var cablingQueryProperties = typebox_1.Type.Partial(exports.cablingSchema, {
    $id: 'CablingQuery',
});
exports.cablingQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(cablingQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
