"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkIfPeriodInMinutesHasElapsed = exports.setTimeInDate = void 0;
var date_fns_1 = require("date-fns");
var setTimeInDate = function (date, time) {
    var _a = time
        .trim()
        .split(':')
        .map(function (x) { return Number(x); }), hours = _a[0], minutes = _a[1];
    return (0, date_fns_1.setMinutes)((0, date_fns_1.setHours)(date, hours), minutes);
};
exports.setTimeInDate = setTimeInDate;
// check if wait period has elapsed in minutes since the previous time
var checkIfPeriodInMinutesHasElapsed = function (previousTime, waitPeriodInMinutes) {
    var currentTime = new Date();
    var timeDifference = currentTime.getTime() - previousTime.getTime();
    var minutesDifference = timeDifference / (1000 * 60);
    return minutesDifference >= waitPeriodInMinutes;
};
exports.checkIfPeriodInMinutesHasElapsed = checkIfPeriodInMinutesHasElapsed;
