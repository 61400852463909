// @mui
import {
	type AccordionDetailsProps as TAccordionDetailsProps,
	AccordionDetails as MuiAccordionDetails,
} from '@mui/material';

export { TAccordionDetailsProps };
export const AccordionDetails = (props: TAccordionDetailsProps) => (
	<MuiAccordionDetails {...props} />
);
