"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.indicativeProposalQuerySchema = exports.indicativeProposalUpdateSchema = exports.indicativeProposalWriteSchema = exports.indicativeProposalReadSchema = exports.indicativeProposalSchema = exports.generateIPInputDataSchema = exports.systemSpecSchema = exports.proposalDraftSchema = exports.proposalBaseSchema = exports.panelLimitsSchema = exports.roofAreaDraftSchema = exports.propertyDraftSchema = exports.monthlyBillDraftSchema = exports.productionKwhSchema = exports.savingsAnalysisSchema = exports.onFinanceSchema = exports.projectionSchema = exports.financeProjectionSchema = exports.proposalFinanceSchema = exports.financeTermsSchema = exports.GLINT_APPLICATION_STAGES = exports.ADDITIONAL_FIELD_TYPES = exports.legalAgreementSchema = exports.documentSchema = exports.FINANCING_TYPES = exports.DIFFICULTY = void 0;
var typebox_1 = require("@feathersjs/typebox");
var consumable_types_1 = require("./consumable.types");
var currency_types_1 = require("./currency.types");
var document_types_1 = require("./document.types");
var organisation_types_1 = require("./organisation.types");
var product_kit_types_1 = require("./product-kit.types");
var project_fixed_cost_types_1 = require("./project-fixed-cost.types");
var service_plan_types_1 = require("./service-plan.types");
var user_types_1 = require("./user.types");
var date_types_1 = require("../utility-types/date.types");
var roof_area_types_1 = require("./roof-area.types");
var property_types_1 = require("./property.types");
var inverter_types_1 = require("./inverter.types");
var tax_types_1 = require("./tax.types");
var general_types_1 = require("./general-types");
var travel_expenses_types_1 = require("./travel-expenses.types");
var custom_labor_line_item_types_1 = require("./custom-labor-line-item.types");
exports.DIFFICULTY = ['low', 'medium', 'hard'];
exports.FINANCING_TYPES = [
    'glint',
    'nedbank-mfc',
    'home-loan',
    'cash',
    'uncategorised',
    'bmw-financial-services',
    'investec-finance',
    'solar-black-friday',
    'fnb-solar-finance',
];
exports.documentSchema = typebox_1.Type.Object({
    type: (0, typebox_1.StringEnum)(__spreadArray([], document_types_1.DOCUMENT_TYPES, true)),
    name: typebox_1.Type.String(),
    url: typebox_1.Type.String(),
});
exports.legalAgreementSchema = typebox_1.Type.Object({
    fullTimestamp: typebox_1.Type.Date(),
    deviceVendor: typebox_1.Type.String(),
    deviceModel: typebox_1.Type.String(),
    ipAddress: typebox_1.Type.String(),
});
exports.ADDITIONAL_FIELD_TYPES = [
    'paymentReference',
    'membershipNumber',
];
exports.GLINT_APPLICATION_STAGES = [
    'None',
    'ID entered',
    'Credit failed',
    'Credit passed',
];
var additionalFieldsSchema = typebox_1.Type.Partial(typebox_1.Type.Record(typebox_1.Type.Union(exports.ADDITIONAL_FIELD_TYPES.map(function (n) { return typebox_1.Type.Literal(n); })), typebox_1.Type.String()));
exports.financeTermsSchema = typebox_1.Type.Object({
    term: typebox_1.Type.Number(),
    deposit: typebox_1.Type.Number(),
    interestRate: typebox_1.Type.Number(),
    monthlyInsuranceCost: typebox_1.Type.Number(),
    financeType: (0, typebox_1.StringEnum)(__spreadArray([], exports.FINANCING_TYPES, true)),
    userBank: typebox_1.Type.String(),
    glintApplicationStage: (0, typebox_1.StringEnum)(__spreadArray([], exports.GLINT_APPLICATION_STAGES, true)),
});
exports.proposalFinanceSchema = typebox_1.Type.Object({
    monthlyPayments: typebox_1.Type.Number(),
    financeTerms: exports.financeTermsSchema,
});
exports.financeProjectionSchema = typebox_1.Type.Object({
    deposit: typebox_1.Type.Number(),
    pvSavings: typebox_1.Type.Number(),
    payback: typebox_1.Type.Number(),
    annualFinancePayments: typebox_1.Type.Number(),
    interestRate: typebox_1.Type.Number(),
    financeTermMonths: typebox_1.Type.Number(),
    financeAnnualSavings: typebox_1.Type.Number(),
    annualInsuranceCost: typebox_1.Type.Number(),
    desiredDeposit: typebox_1.Type.Optional(typebox_1.Type.Number()),
});
exports.projectionSchema = typebox_1.Type.Object({
    year: typebox_1.Type.Number(),
    solar: typebox_1.Type.Number(),
    effectiveKwhFromPv: typebox_1.Type.Number(),
    tariffEscalation: typebox_1.Type.Number(),
    kwhTariff: typebox_1.Type.Number(),
    annualOnGridBill: typebox_1.Type.Number(),
    annualOnGridKwh: typebox_1.Type.Number(),
    annualSavings: typebox_1.Type.Number(),
    pvSavings: typebox_1.Type.Number(),
    oAndMCost: typebox_1.Type.Number(),
    taxBenefit: typebox_1.Type.Number(),
    monitoring: typebox_1.Type.Number(),
    payback: typebox_1.Type.Number(),
    vat: typebox_1.Type.Number(),
    finance: exports.financeProjectionSchema,
});
exports.onFinanceSchema = typebox_1.Type.Object({
    totalSavings: typebox_1.Type.Number(),
    paybackPeriod: typebox_1.Type.Number(),
    levelisedCostOfSolar: typebox_1.Type.Number(),
    savingsExceedFinance: typebox_1.Type.Number(),
});
exports.savingsAnalysisSchema = typebox_1.Type.Object({
    totalSavings: typebox_1.Type.Number(),
    levelisedCostOfSolar: typebox_1.Type.Number(),
    paybackPeriod: typebox_1.Type.Number(),
    onFinance: exports.onFinanceSchema,
    projections: typebox_1.Type.Array(exports.projectionSchema),
});
exports.productionKwhSchema = typebox_1.Type.Object({
    annualkWhEnergy: typebox_1.Type.Number(),
    monthlyEnergy: typebox_1.Type.Array(typebox_1.Type.Object({
        ghi: typebox_1.Type.Number(),
        month: typebox_1.Type.String(),
        kWhGeneration: typebox_1.Type.Number(),
        averageHourlyWattHourGenerationProfile: typebox_1.Type.Array(typebox_1.Type.Number()),
    })),
    seasonalDailyAverages: typebox_1.Type.Object({
        summer: typebox_1.Type.Array(typebox_1.Type.Number()),
        autumn: typebox_1.Type.Array(typebox_1.Type.Number()),
        winter: typebox_1.Type.Array(typebox_1.Type.Number()),
        spring: typebox_1.Type.Array(typebox_1.Type.Number()),
    }),
    energyConfig: typebox_1.Type.Object({
        panelArea: typebox_1.Type.Number(),
        eff: typebox_1.Type.Number(),
        irrad: typebox_1.Type.Number(),
        ratio: typebox_1.Type.Number(),
        area: typebox_1.Type.String(),
    }),
});
exports.monthlyBillDraftSchema = typebox_1.Type.Object({
    cost: typebox_1.Type.Number(),
    currency: (0, typebox_1.StringEnum)(__spreadArray([], currency_types_1.SUPPORTED_CURRENCY, true)),
    usageStartDate: typebox_1.Type.Date(),
    usageEndDate: typebox_1.Type.Date(),
    usage: typebox_1.Type.Number(),
    monthCaptured: (0, typebox_1.StringEnum)(__spreadArray([], date_types_1.MONTHS, true)),
});
exports.propertyDraftSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Optional(typebox_1.Type.String()),
    type: typebox_1.Type.Optional((0, typebox_1.StringEnum)(__spreadArray([], property_types_1.PROPERTY_TYPES, true))),
    stories: typebox_1.Type.Optional(typebox_1.Type.Number()),
    propertyType: (0, typebox_1.StringEnum)(__spreadArray([], property_types_1.PROPERTY_TYPES, true)),
    propertyStories: typebox_1.Type.Number(),
    coordinates: general_types_1.coordinateSchema,
    phase: (0, typebox_1.StringEnum)(__spreadArray([], inverter_types_1.PHASES, true)),
    location: property_types_1.locationSchema,
    monthlyBill: exports.monthlyBillDraftSchema,
    distributionBoards: typebox_1.Type.Optional(typebox_1.Type.Number()),
});
exports.roofAreaDraftSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Optional(typebox_1.Type.String()),
    area: typebox_1.Type.Array(general_types_1.coordinateSchema),
    areaSize: typebox_1.Type.Number(),
    pitch: typebox_1.Type.Number(),
    azimuth: typebox_1.Type.Optional(typebox_1.Type.Number()),
    direction: (0, typebox_1.StringEnum)(__spreadArray([], roof_area_types_1.ROOF_DIRECTIONS, true)),
    mountType: (0, typebox_1.StringEnum)(__spreadArray([], roof_area_types_1.MOUNTING_TYPES, true)),
});
exports.panelLimitsSchema = typebox_1.Type.Object({
    maxPanelsForArea: typebox_1.Type.Number(),
});
var INSTALLATION_TYPES = ['default', 'custom'];
var DEAL_SEGMENTS = ['Premium', 'Value'];
exports.proposalBaseSchema = typebox_1.Type.Object({
    systemType: (0, typebox_1.StringEnum)(__spreadArray([], general_types_1.SYSTEM_TYPES, true)),
    additional: typebox_1.Type.Optional(additionalFieldsSchema),
    documents: typebox_1.Type.Array(exports.documentSchema),
    finance: typebox_1.Type.Optional(exports.proposalFinanceSchema),
    recommendedKwpArraySize: typebox_1.Type.Number(),
    legalAgreements: typebox_1.Type.Optional(exports.legalAgreementSchema),
    desiredStorageInKwh: typebox_1.Type.Number(),
    desiredBackupHours: typebox_1.Type.Number(),
    kwhStorage: typebox_1.Type.Number(),
    kwpSize: typebox_1.Type.Number(),
    hoursOfBackup: typebox_1.Type.Number(),
    components: typebox_1.Type.Object({
        productKit: typebox_1.Type.Object({
            total: typebox_1.Type.Number(),
            breakdown: product_kit_types_1.appliedProductKitSchema,
        }),
        mounting: typebox_1.Type.Object({
            total: typebox_1.Type.Number(),
            breakdown: typebox_1.Type.Array(consumable_types_1.appliedConsumableSchema),
        }),
        installation: typebox_1.Type.Object({
            total: typebox_1.Type.Number(),
            installationType: (0, typebox_1.StringEnum)(__spreadArray([], INSTALLATION_TYPES, true)),
            breakdown: typebox_1.Type.Optional(typebox_1.Type.Object({
                installSize: typebox_1.Type.Object({
                    inverterInstallSize: typebox_1.Type.Number(),
                    baseNoOfDays: typebox_1.Type.Number(),
                }),
                property: typebox_1.Type.Object({
                    propertyStories: typebox_1.Type.Optional(typebox_1.Type.Number()),
                    propertyStoriesNoOfDays: typebox_1.Type.Number(),
                }),
                panels: typebox_1.Type.Object({
                    noOfPanels: typebox_1.Type.Optional(typebox_1.Type.Number()),
                    additionalPanels: typebox_1.Type.Number(),
                    additionalPanelsNoOfDays: typebox_1.Type.Number(),
                }),
                roof: typebox_1.Type.Object({
                    pitchOfRoof: typebox_1.Type.Optional(typebox_1.Type.Number()),
                    roofAngleNoOfDays: typebox_1.Type.Number(),
                }),
                installerId: typebox_1.Type.Optional(typebox_1.Type.String()),
                extraLaborItemsNoOfDays: typebox_1.Type.Number(),
                extraLaborItemsNo: typebox_1.Type.Number(),
                totalNoOfDays: typebox_1.Type.Number(),
                installationDayRate: typebox_1.Type.Number(),
            })),
            customBreakdown: typebox_1.Type.Optional(typebox_1.Type.Array(custom_labor_line_item_types_1.customLaborLineItemReadSchema)),
        }),
        complianceCosts: typebox_1.Type.Object({
            total: typebox_1.Type.Number(),
            name: typebox_1.Type.String(),
            sseg: typebox_1.Type.Number(),
            hohmRanger: typebox_1.Type.Number(),
        }),
        travelExpenses: typebox_1.Type.Object({
            total: typebox_1.Type.Number(),
            breakdown: typebox_1.Type.Union([travel_expenses_types_1.travelCostsSchema, typebox_1.Type.Null()]),
        }),
        servicePlan: typebox_1.Type.Object({
            total: typebox_1.Type.Number(),
            plan: typebox_1.Type.Union([service_plan_types_1.servicePlanReadSchema, typebox_1.Type.Null()]),
        }),
        pricingLineItems: typebox_1.Type.Object({
            total: typebox_1.Type.Number(),
            breakdown: typebox_1.Type.Array(project_fixed_cost_types_1.appliedProjectFixedCostSchema),
        }),
        deposit: typebox_1.Type.Object({
            total: typebox_1.Type.Number(),
            percent: typebox_1.Type.Number(),
        }),
        margin: typebox_1.Type.Object({
            total: typebox_1.Type.Number(),
            percent: typebox_1.Type.Number(),
        }),
        consumablesMarkup: typebox_1.Type.Object({
            percent: typebox_1.Type.Number(),
        }),
        mountingConsumablesMarkup: typebox_1.Type.Object({
            percent: typebox_1.Type.Number(),
        }),
        contingency: typebox_1.Type.Object({
            total: typebox_1.Type.Number(),
            percent: typebox_1.Type.Number(),
        }),
        taxes: typebox_1.Type.Object({
            total: typebox_1.Type.Number(),
            breakdown: typebox_1.Type.Array(tax_types_1.appliedTaxSchema),
        }),
    }),
    consumption: typebox_1.Type.Object({
        averageHourlyKwhUsage: typebox_1.Type.Number(),
    }),
    production: exports.productionKwhSchema,
    savingsAnalysis: typebox_1.Type.Union([exports.savingsAnalysisSchema, typebox_1.Type.Null()]),
    currency: (0, typebox_1.StringEnum)(__spreadArray([], currency_types_1.SUPPORTED_CURRENCY, true)),
    totalCost: typebox_1.Type.Object({
        final: typebox_1.Type.Number(),
    }),
    selectedKit: typebox_1.Type.Optional(typebox_1.Type.Object({})),
    dealSegment: typebox_1.Type.Optional((0, typebox_1.StringEnum)(__spreadArray([], DEAL_SEGMENTS, true))),
    essentialLoadsKWh: typebox_1.Type.Optional(typebox_1.Type.Number()),
});
exports.proposalDraftSchema = typebox_1.Type.Intersect([
    exports.proposalBaseSchema,
    typebox_1.Type.Object({
        property: exports.propertyDraftSchema,
        roofArea: exports.roofAreaDraftSchema,
        installer: organisation_types_1.organisationReadSchema,
        pricingOrganisation: organisation_types_1.organisationReadSchema,
        propertyOwner: typebox_1.Type.Optional(user_types_1.userReadSchema),
    }),
]);
exports.systemSpecSchema = typebox_1.Type.Object({
    backupHours: typebox_1.Type.Number(),
    systemType: (0, typebox_1.StringEnum)(__spreadArray([], general_types_1.SYSTEM_TYPES, true)),
    desiredKwp: typebox_1.Type.Optional(typebox_1.Type.Number()),
});
exports.generateIPInputDataSchema = typebox_1.Type.Object({
    propertyOwnerId: typebox_1.Type.String(),
    targetCurrency: (0, typebox_1.StringEnum)(__spreadArray([], currency_types_1.SUPPORTED_CURRENCY, true)),
    roofArea: exports.roofAreaDraftSchema,
    property: exports.propertyDraftSchema,
    desiredBackupHours: typebox_1.Type.Number(),
    systemType: (0, typebox_1.StringEnum)(__spreadArray([], general_types_1.SYSTEM_TYPES, true)),
    installerId: typebox_1.Type.Optional(typebox_1.Type.String()),
    budget: typebox_1.Type.Optional(typebox_1.Type.Number()),
    desiredKwp: typebox_1.Type.Optional(typebox_1.Type.Number()),
    servicePlanId: typebox_1.Type.Optional(typebox_1.Type.String()),
    financeTerms: typebox_1.Type.Optional(exports.financeTermsSchema),
    campaignId: typebox_1.Type.Optional(typebox_1.Type.String()),
    producktKitId: typebox_1.Type.Optional(typebox_1.Type.String()),
});
exports.indicativeProposalSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    migrationVersion: typebox_1.Type.Number(),
    config: exports.proposalBaseSchema,
    roofAreaId: typebox_1.Type.String(),
    proposalOwnerId: typebox_1.Type.String(),
    pricingOrganisationId: typebox_1.Type.String(),
    installerId: typebox_1.Type.String(),
    propertyId: typebox_1.Type.String(),
    projectId: typebox_1.Type.Optional(typebox_1.Type.String()),
    leadScoreId: typebox_1.Type.Optional(typebox_1.Type.Number()),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.indicativeProposalReadSchema = typebox_1.Type.Omit(exports.indicativeProposalSchema, []);
exports.indicativeProposalWriteSchema = typebox_1.Type.Omit(exports.indicativeProposalReadSchema, ['id', 'createdAt', 'updatedAt']);
exports.indicativeProposalUpdateSchema = typebox_1.Type.Partial(exports.indicativeProposalSchema, {
    $id: 'IndicativeProposalUpdate',
});
var indicativeProposalQueryProperties = typebox_1.Type.Partial(exports.indicativeProposalSchema, {
    $id: 'IndicativeProposalQuery',
});
exports.indicativeProposalQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(indicativeProposalQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
