'use client';

import { useState, useEffect } from 'react';
// @mui
import {
	DialogTitle,
	DialogContentText,
	DialogContent,
	DialogActions,
	Dialog,
	Button,
	TextField,
	IconButton,
	DialogProps,
	Stack,
} from '@mui/material';
import { Close } from '@mui/icons-material';
// @local
import { DialogTransition } from '../utils/DialogTransition';
import { Autocomplete } from './Autocomplete';
import { CircularProgress } from '../feedback/CircularProgress';

interface IOption {
	label: string;
	id: string;
}
interface IProps {
	open: boolean;
	title?: string;
	content?: string;
	loading?: boolean;
	options: IOption[];
	selected?: IOption;
	loadingText?: string;
	textFieldLabel?: string;
	confirmButtonText?: string;
	handleClose: (selected?: IOption) => void;
}

export type TSelectDialogProps = IProps & DialogProps;
export const SelectDialog = ({
	open,
	title = '',
	content = '',
	loading = false,
	options,
	selected,
	loadingText = 'Loading',
	textFieldLabel = 'Make your selection',
	confirmButtonText = 'Submit',
	handleClose,
	...rest
}: TSelectDialogProps) => {
	const [selectedValue, setSelectedValue] = useState<IOption | undefined>(
		selected
	);

	const closeDialog = () => {
		handleClose(selectedValue);
		setSelectedValue(undefined);
	};

	const cancelDialog = () => {
		handleClose();
		setSelectedValue(undefined);
	};

	useEffect(() => {
		if (selected) setSelectedValue(selected);
	}, [selected]);

	return (
		<Dialog
			{...rest}
			open={open}
			keepMounted
			TransitionComponent={DialogTransition}
		>
			{loading ? (
				<DialogContent>
					<DialogContentText>
						<Stack
							spacing={2}
							direction="row"
							justifyContent="center"
							alignItems="center"
						>
							<CircularProgress />
							<span>{loadingText}</span>
						</Stack>
					</DialogContentText>
				</DialogContent>
			) : (
				<>
					<DialogTitle>
						<Stack direction="row" spacing={2} alignItems="center">
							{title}
							<span style={{ flex: 1 }} />
							<IconButton onClick={cancelDialog}>
								<Close />
							</IconButton>
						</Stack>
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<Stack spacing={2}>
								<span>{content}</span>
								<Autocomplete
									sx={{ mt: 2 }}
									value={selectedValue}
									isOptionEqualToValue={(option, value) =>
										option?.id === value?.id
									}
									options={options}
									onChange={(e, value) =>
										value && setSelectedValue(value)
									}
									renderInput={(params) => (
										<TextField
											{...params}
											label={textFieldLabel}
										/>
									)}
								/>
							</Stack>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button variant="contained" onClick={closeDialog}>
							{confirmButtonText}
						</Button>
					</DialogActions>
				</>
			)}
		</Dialog>
	);
};
