"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.solarRadiationQuerySchema = exports.solarRadiationUpdateSchema = exports.solarRadiationWriteSchema = exports.solarRadiationReadSchema = exports.solarRadiationSchema = void 0;
var typebox_1 = require("@feathersjs/typebox");
exports.solarRadiationSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    coordinates: typebox_1.Type.Array(typebox_1.Type.Number()),
    area: typebox_1.Type.String(),
    irradiation: typebox_1.Type.Number(),
    diffusedIrradiation: typebox_1.Type.Number(),
    temperature: typebox_1.Type.Number(),
    windVelocity: typebox_1.Type.Number(),
    performanceRatio: typebox_1.Type.Number(),
    tiltedIrradiation: typebox_1.Type.Number(),
    tilt: typebox_1.Type.Number(),
    azimuth: typebox_1.Type.Number(),
    monthyFigures: typebox_1.Type.Object({
        ghi: typebox_1.Type.Array(typebox_1.Type.Number()),
        gti: typebox_1.Type.Array(typebox_1.Type.Number()),
        dhi: typebox_1.Type.Optional(typebox_1.Type.Array(typebox_1.Type.Number())),
        wind_speed_10m: typebox_1.Type.Optional(typebox_1.Type.Array(typebox_1.Type.Number())),
        air_temp: typebox_1.Type.Optional(typebox_1.Type.Array(typebox_1.Type.Number())),
        month: typebox_1.Type.Array(typebox_1.Type.String()),
        profile: typebox_1.Type.Optional(typebox_1.Type.Array(typebox_1.Type.Object({
            gtiProfile: typebox_1.Type.Array(typebox_1.Type.Number()),
            ghiProfile: typebox_1.Type.Array(typebox_1.Type.Number()),
        }))),
    }),
    monthlyProfile: typebox_1.Type.Array(typebox_1.Type.Object({
        gtiProfile: typebox_1.Type.Array(typebox_1.Type.Number()),
        ghiProfile: typebox_1.Type.Array(typebox_1.Type.Number()),
    })),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.solarRadiationReadSchema = typebox_1.Type.Omit(exports.solarRadiationSchema, []);
exports.solarRadiationWriteSchema = typebox_1.Type.Omit(exports.solarRadiationReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.solarRadiationUpdateSchema = typebox_1.Type.Partial(exports.solarRadiationSchema, {
    $id: 'SolarRadiationUpdate',
});
var solarRadiationQueryProperties = typebox_1.Type.Partial(exports.solarRadiationSchema, {
    $id: 'SolarRadiationQuery',
});
exports.solarRadiationQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(solarRadiationQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
