import { toast } from 'react-toastify';
import { Application } from '@feathersjs/feathers/lib';
import { Api } from 'hohm-queries';

const useGetProposalPDF = (client: Application<any, any>) => {
	const api = new Api.default(client);

	const getProposalPDF = async (proposalId: string) => {
		if (proposalId != null) {
			try {
				await api.get('get-indicative-proposal', proposalId);

				toast.loading('IP PDF is being generated.', {
					toastId: 'preparing-pdf',
				});
			} catch (err: any) {
				throw new Error(`ERROR DOWNLOADING IP PDF ${err.message}`);
			}
		}
	};

	return {
		getProposalPDF,
	};
};

export default useGetProposalPDF;
