"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ICoreStatus = exports.IRoofSections = exports.IBudget = exports.IAttendance = exports.IAssignedPersona = exports.IDiscountApplied = exports.IBuyingIntent = exports.IEventType = void 0;
// Events
var IEventType;
(function (IEventType) {
    IEventType["ClientCreated"] = "CLIENT_CREATED";
    IEventType["DealCreated"] = "DEAL_CREATED";
    IEventType["PSIInProgress"] = "PSI_IN_PROGRESS";
    IEventType["DealUpdated"] = "DEAL_UPDATED";
    IEventType["DealOwnerAssigned"] = "DEAL_OWNER_ASSIGNED";
    IEventType["FileUploaded"] = "FILE_UPLOADED";
    IEventType["NoteCreated"] = "NOTE_CREATED";
    IEventType["DealAssociated"] = "DEAL_ASSOCIATED";
    IEventType["DealToAirtable"] = "DEAL_TO_AIRTABLE";
    IEventType["ContactCreated"] = "CONTACT_CREATED";
    IEventType["ContactUpdated"] = "CONTACT_UPDATED";
    IEventType["BulkContactUpdate"] = "BULK_CONTACT_UPDATE";
    IEventType["DealsSearch"] = "DEALS_SEARCH";
    IEventType["SearchComplete"] = "SEARCH_COMPLETE";
    IEventType["DealsArchive"] = "DEALS_ARCHIVE";
    IEventType["ArchiveComplete"] = "ARCHIVE_COMPLETE";
    IEventType["UpdateOwnerMeetingLink"] = "UPDATE_OWNER_MEETING_LINK";
    IEventType["GetHubspotOwner"] = "GET_HUBSPOT_OWNER";
    IEventType["SearchContactsByEmail"] = "SEARCH_CONTACTS_BY_EMAIL";
    IEventType["DealStageChange"] = "DEAL_STAGE_CHANGE";
    IEventType["ContactsSearch"] = "CONTACTS_SEARCH";
    IEventType["SearchObjectAssociations"] = "SEARCH_OBJECT_ASSOCIATIONS";
    IEventType["ContactsArchive"] = "CONTACTS_ARCHIVE";
    IEventType["GetDealStageByDealId"] = "GET_DEAL_STAGE_BY_DEAL_ID";
    IEventType["GetDealById"] = "GET_DEAL_BY_ID";
    IEventType["DealVersionUpdated"] = "DEAL_VERSION_UPDATED";
    IEventType["DeleteObjectAssociation"] = "DELETE_OBJECT_ASSOCIATION";
    IEventType["DeleteDealFile"] = "DELETE_DEAL_FILE";
    IEventType["DeleteDealNote"] = "DELETE_DEAL_NOTE";
})(IEventType || (exports.IEventType = IEventType = {}));
var HUBSPOT_OBJECTS = [
    'deals',
    'contacts',
    'companies',
    'tickets',
    'products',
];
// Contact properties
// Radios
var IBuyingIntent;
(function (IBuyingIntent) {
    IBuyingIntent["Now"] = "Now";
    IBuyingIntent["Months"] = "2-3 months";
    IBuyingIntent["Researching"] = "Researching";
    IBuyingIntent["SolarAsService"] = "Solar as service";
})(IBuyingIntent || (exports.IBuyingIntent = IBuyingIntent = {}));
// Deal properties
// Checkboxes
var IDiscountApplied;
(function (IDiscountApplied) {
    IDiscountApplied["PercentageOfTotalSystem"] = "Percentage of total system";
    IDiscountApplied["FreeServicePlanIncluded"] = "Free service plan included";
    IDiscountApplied["PercentageOfFacilitationFee"] = "Percentage of facilitation fee";
    IDiscountApplied["ReducedDepositAmount"] = "Reduced deposit amount";
})(IDiscountApplied || (exports.IDiscountApplied = IDiscountApplied = {}));
// Radios
var IAssignedPersona;
(function (IAssignedPersona) {
    IAssignedPersona["Researcher"] = "Researcher";
    IAssignedPersona["Engineer"] = "Engineer";
    IAssignedPersona["MomPop"] = "Mom & Pop";
})(IAssignedPersona || (exports.IAssignedPersona = IAssignedPersona = {}));
var IAttendance;
(function (IAttendance) {
    IAttendance["Yes"] = "Yes";
    IAttendance["No"] = "No";
    IAttendance["Reschedule"] = "Reschedule";
})(IAttendance || (exports.IAttendance = IAttendance = {}));
var IBudget;
(function (IBudget) {
    IBudget["High"] = "High";
    IBudget["Medium"] = "Medium";
    IBudget["Low"] = "Low";
})(IBudget || (exports.IBudget = IBudget = {}));
var IRoofSections;
(function (IRoofSections) {
    IRoofSections["Small"] = "Small";
    IRoofSections["Medium"] = "Medium";
    IRoofSections["Large"] = "Large";
    IRoofSections["VeryLarge"] = "Very large";
})(IRoofSections || (exports.IRoofSections = IRoofSections = {}));
var ICoreStatus;
(function (ICoreStatus) {
    ICoreStatus["YES"] = "Yes";
    ICoreStatus["NO"] = "No";
})(ICoreStatus || (exports.ICoreStatus = ICoreStatus = {}));
