"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Radio = (theme) => {
    return {
        MuiRadio: {
            styleOverrides: {
                root: {
                    padding: theme.spacing(1),
                    svg: {
                        fontSize: 24,
                        '&[font-size=small]': {
                            fontSize: 20,
                        },
                    },
                },
            },
        },
    };
};
exports.default = Radio;
