"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGenerateClientIPStepUrl = exports.getGenerateClientIPUrl = exports.getProjectProposalsUrl = exports.getProposalRoute = exports.getClientProposalsUrl = exports.getAddEngineeringProposalUrl = exports.getEditProposalUrl = exports.getProposalUrl = exports.epcProposalsUrl = exports.proposalsUrl = exports.getPropertyUrl = exports.propertiesUrl = exports.getEPCClientUrl = exports.getClientUrl = exports.epcClientsUrl = exports.superClientsUrl = exports.clientsUrl = exports.getClientProjectsUrl = exports.getProjectUrl = exports.epcProjectsUrl = exports.projectsUrl = exports.ADMIN_BASE_URL = exports.EPC_BASE_URL = exports.ADVISOR_BASE_URL = void 0;
exports.ADVISOR_BASE_URL = '/advisor';
exports.EPC_BASE_URL = '/installer';
exports.ADMIN_BASE_URL = '/admin';
// Projects
exports.projectsUrl = "".concat(exports.ADVISOR_BASE_URL, "/projects");
exports.epcProjectsUrl = "".concat(exports.EPC_BASE_URL, "/projects");
var getProjectUrl = function (projectId, loggedInRole) {
    return "".concat(loggedInRole === 'epcAdmin' ? exports.epcProjectsUrl : exports.projectsUrl, "/").concat(projectId);
};
exports.getProjectUrl = getProjectUrl;
var getClientProjectsUrl = function (clientId, loggedInRole) {
    return "".concat(loggedInRole === 'epcAdmin' ? exports.epcProjectsUrl : exports.projectsUrl, "?clientId=").concat(clientId);
};
exports.getClientProjectsUrl = getClientProjectsUrl;
// Clients
exports.clientsUrl = "".concat(exports.ADVISOR_BASE_URL, "/clients");
exports.superClientsUrl = "".concat(exports.ADMIN_BASE_URL, "/clients");
exports.epcClientsUrl = "".concat(exports.EPC_BASE_URL, "/clients");
var getClientUrl = function (clientId) { return "".concat(exports.clientsUrl, "/").concat(clientId); };
exports.getClientUrl = getClientUrl;
var getEPCClientUrl = function (clientId) {
    return "".concat(exports.epcClientsUrl, "/").concat(clientId);
};
exports.getEPCClientUrl = getEPCClientUrl;
// Properties
exports.propertiesUrl = "".concat(exports.ADVISOR_BASE_URL, "/properties");
var getPropertyUrl = function (propertyId) {
    return "".concat(exports.propertiesUrl, "/").concat(propertyId);
};
exports.getPropertyUrl = getPropertyUrl;
// Proposals
exports.proposalsUrl = "".concat(exports.ADVISOR_BASE_URL, "/proposals");
exports.epcProposalsUrl = "".concat(exports.EPC_BASE_URL, "/proposals");
var getProposalUrl = function (proposalId) {
    return "".concat(exports.proposalsUrl, "/").concat(proposalId);
};
exports.getProposalUrl = getProposalUrl;
var getEditProposalUrl = function (proposalId) {
    return "".concat(exports.ADVISOR_BASE_URL, "/edit-engineering-solar-proposal/").concat(proposalId);
};
exports.getEditProposalUrl = getEditProposalUrl;
var getAddEngineeringProposalUrl = function (projectId, loggedInRole, organisationId) {
    return loggedInRole === 'epcAdmin'
        ? "".concat(exports.EPC_BASE_URL, "/engineering-proposal/").concat(projectId, "&installerId=").concat(organisationId)
        : "".concat(exports.ADVISOR_BASE_URL, "/engineering-proposal/").concat(projectId);
};
exports.getAddEngineeringProposalUrl = getAddEngineeringProposalUrl;
var getClientProposalsUrl = function (clientId, loggedInRole) {
    return "".concat(loggedInRole === 'epcAdmin' ? exports.epcProposalsUrl : exports.proposalsUrl, "?clientId=").concat(clientId);
};
exports.getClientProposalsUrl = getClientProposalsUrl;
var getProposalRoute = function (clientId, loggedInRole) {
    return "".concat(loggedInRole === 'epcAdmin' ? exports.epcProposalsUrl : exports.proposalsUrl, "?clientId=").concat(clientId);
};
exports.getProposalRoute = getProposalRoute;
var getProjectProposalsUrl = function (projectId) {
    return "".concat(exports.proposalsUrl, "?projectId=").concat(projectId);
};
exports.getProjectProposalsUrl = getProjectProposalsUrl;
var getGenerateClientIPUrl = function (clientId, userRole, organisationId) {
    return "".concat(userRole === 'client'
        ? "/main/proposal/{1}"
        : userRole === 'epcAdmin'
            ? "/generate-ip?role=epcAdmin&clientId=".concat(clientId, "&installerId=").concat(organisationId, "&startStep={1}")
            : userRole === 'advisor'
                ? "/generate-ip?role=advisor&clientId=".concat(clientId, "&startStep={1}")
                : "/get-my-proposal/{1}");
};
exports.getGenerateClientIPUrl = getGenerateClientIPUrl;
var getGenerateClientIPStepUrl = function (clientId, userRole, step, organisationId) {
    var useStep = step || '';
    if (userRole === 'client' || userRole === 'non_auth_user') {
        return "/main/proposal/".concat(useStep);
    }
    if (userRole === 'epcAdmin') {
        return "/generate-ip?role=epcAdmin&clientId=".concat(clientId, "&installerId=").concat(organisationId, "&startStep=").concat(step);
    }
    if (userRole === 'advisor') {
        return "/generate-ip?role=advisor&clientId=".concat(clientId, "&startStep=").concat(useStep);
    }
    return "/get-my-proposal/".concat(useStep);
};
exports.getGenerateClientIPStepUrl = getGenerateClientIPStepUrl;
