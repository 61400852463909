"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.tariffEscalationQuerySchema = exports.tariffEscalationUpdateSchema = exports.tariffEscalationWriteSchema = exports.tariffEscalationReadSchema = exports.tariffEscalationSchema = exports.SUPPORTED_COUNTRIES = void 0;
var typebox_1 = require("@feathersjs/typebox");
exports.SUPPORTED_COUNTRIES = ['south africa'];
exports.tariffEscalationSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    yearFrom: typebox_1.Type.Number(),
    yearTo: typebox_1.Type.Number(),
    escalation: typebox_1.Type.Number(),
    country: (0, typebox_1.StringEnum)(__spreadArray([], exports.SUPPORTED_COUNTRIES, true)),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.tariffEscalationReadSchema = typebox_1.Type.Omit(exports.tariffEscalationSchema, []);
exports.tariffEscalationWriteSchema = typebox_1.Type.Omit(exports.tariffEscalationReadSchema, ['id', 'createdAt', 'updatedAt']);
exports.tariffEscalationUpdateSchema = typebox_1.Type.Partial(exports.tariffEscalationSchema, {
    $id: 'TariffEscalationUpdate',
});
var tariffEscalationQueryProperties = typebox_1.Type.Partial(exports.tariffEscalationSchema, {
    $id: 'TariffEscalationQuery',
});
exports.tariffEscalationQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(tariffEscalationQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
