// @mui
import { Theme, SxProps, TextFieldProps } from '@mui/material';
// @local
import { GoogleLoader } from '../Loader';
import Places from './Places';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface PlacesResult extends google.maps.places.PlaceResult {
	city: string | undefined;
	region: string | undefined;
	country: string | undefined;
	shortName: string | undefined;
	coordinates: [number, number];
}

export type TGooglePlacesProps = {
	gridSx?: SxProps<Theme>;
	onChange?: () => void;
	showLabel?: boolean;
	countryCode?: string;
	showAddress?: boolean;
	showNorthIcon?: boolean;
	currentAddress?: string;
	containerClass?: string;
	textfieldClass?: string;
	textfieldProps?: TextFieldProps;
	getSelectedLocation?: (location: PlacesResult) => void;
};

export const GooglePlaces = ({ ...rest }: TGooglePlacesProps) => {
	return (
		<GoogleLoader>
			<Places {...rest} />
		</GoogleLoader>
	);
};
