import {
	CircularProgress as MUICircularProgress,
	CircularProgressProps as MUICircularProgressProps,
} from '@mui/material';

export type TCircularProgressProps = MUICircularProgressProps;

export const CircularProgress = (props: TCircularProgressProps) => (
	<MUICircularProgress {...props} />
);
