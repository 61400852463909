// @mui
import { ListItem, ListItemAvatar, Avatar, ListItemText } from '@mui/material';
// @icons
import { SvgIconComponent } from '@mui/icons-material';

export type TDataItemProps = {
	label: string;
	value: string | React.ReactNode;
	Icon?: SvgIconComponent;
};

export const DataItem = ({ label, value, Icon }: TDataItemProps) => (
	<ListItem>
		<ListItemAvatar>
			<Avatar sx={{ bgcolor: 'secondary.main' }}>
				{Icon && <Icon htmlColor="white" />}
			</Avatar>
		</ListItemAvatar>
		<ListItemText
			primary={label}
			secondary={
				typeof value === 'string' ? (
					<b style={{ textTransform: 'capitalize' }}>{value}</b>
				) : (
					value
				)
			}
		/>
	</ListItem>
);
