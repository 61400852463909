"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.normalizeWords = exports.capitalizeFirstLetter = exports.getFullname = void 0;
var getFullname = function (user) {
    return user ? "".concat(user.firstName, " ").concat(user.lastName) : undefined;
};
exports.getFullname = getFullname;
var capitalizeFirstLetter = function (str) {
    if (str === void 0) { str = ''; }
    return str[0].toUpperCase() + str.slice(1);
};
exports.capitalizeFirstLetter = capitalizeFirstLetter;
var normalizeWords = function (str) {
    if (str === void 0) { str = ''; }
    return str.split(/-|\ /).map(exports.capitalizeFirstLetter).join(' ');
};
exports.normalizeWords = normalizeWords;
