import { ReactNode, ElementType } from 'react';
// @mui
import { ButtonProps } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export type TButtonProps = ButtonProps & {
	isLoading?: boolean;
	text?: string;
	icon?: ReactNode;
	className?: string;
	type?: 'button' | 'reset' | 'submit';
	autoFocus?: boolean;
	disabled?: boolean;
	component?: ElementType<any>;
	to?: string;
};
export const Button = ({
	isLoading,
	disabled,
	text,
	children,
	...rest
}: TButtonProps) => {
	return (
		<LoadingButton
			disabled={disabled}
			loading={isLoading}
			{...(rest as unknown as any)}
		>
			{text || children}
		</LoadingButton>
	);
};
