'use client';

import React from 'react';
import { pathToRegexp } from 'path-to-regexp';
// @mui
import { Box, Backdrop, Typography } from '@mui/material';
// @next
import { useRouter } from 'next/router';
// @app
import { useStore } from '@app/store';
// ui
import { CircularProgress } from 'hohm-ui';
import { useShallow } from 'zustand/react/shallow';
import { useAutomaticDisconnection } from 'hohm-store';

const safeRoutes = [
	'/',
	'/login/:nextRoute*',
	'/glint-subscription-application',
	'/forgot-password',
	'/update-password/:token',
	'/password-reset/:token',
	'/verify-account/:token',
	'/not-found/:nextRoute*',
	'/signup', // original signup page
	'/register', // alt signup page
	'/change-request',
	'/generate-proposal',
	'/forgot-password',
	'client-approve-ep',
	'client-reject-ep',
	'/unsubscribe/:token',
	'/main/terms-and-conditions',
	'/terms-and-conditions',
	'/get-my-proposal/:startStep?',
	// Regular Signup
	'/e7e4bb7f-a6d1-4d48-aaa4-623150ff4832',
	// nedbank private signup
	'/d9ab20ed-531e-4a5e-8216-3817c63942a6',
	// nedbank public signup
	'/2aaf7f66-10b4-4bb1-8a31-793ffc5a0d4d',
	// nedbank mfc
	'/mfc',
	// Anon Generation
	'/545ea866-e9af-44c3-b69c-adf8cf0b29cc',
	'/main/client-approve-ep',
	'/main/client-reject-ep',
	'/book-call',
	'/book-a-call/:clientId',
	'/select-kit/:step?',
	'/glint-preapproval/:email?',
	'/engineered-proposal-actions/accept-proposal/:proposalId/:state',
	'/engineered-proposal-actions/reject-proposal/:proposalId/:clientId',
	'/engineered-proposal-actions/accept-terms/:proposalId',
].map((path) => pathToRegexp(path));

const protectedRoutes = [
	'/main',
	'/main/add-proposal/:history?',
	'/main/calendar/:projectId?',
	'/main/solar-education',
	'/main/properties/:propertyId?',
	'/main/solar-proposals/:projectId?',
	'/main/storage-proposals/:projectId?',
	'/main/profile',
	'/main/terms-and-conditions',
	'/main/client-approve-ep',
	'/main/client-reject-ep',
	'/main/proposal/:startStep?',
	'/openai-test',
].map((path) => pathToRegexp(path));

const AuthCheck = ({ children }: { children: React.ReactElement }) => {
	const router = useRouter();
	const currentRoute = router.pathname;
	const isBrowser = typeof window !== 'undefined';

	const { accessToken, userId, permissions, clearAccessToken } = useStore(
		useShallow((state) => ({
			accessToken: state.accessToken,
			userId: state.userId,
			permissions: state.permissions,
			clearAccessToken: state.clearAccessToken,
		}))
	);

	const hasAccess = () => {
		if (safeRoutes.some((checkRoute) => checkRoute.test(currentRoute))) {
			return true;
		}

		if (
			userId &&
			accessToken &&
			protectedRoutes.some((checkRoute) => checkRoute.test(currentRoute))
		) {
			if ((permissions || []).includes('isClient')) {
				return true;
			}
		}

		return false;
	};

	const canAccess = hasAccess();

	useAutomaticDisconnection(accessToken, clearAccessToken);

	if (isBrowser && canAccess === false) router.push('/login');

	// a loading component that prevents the page from rendering
	if (canAccess === false)
		return (
			<Backdrop open>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'column',
					}}
				>
					<CircularProgress sx={{ mb: 2 }} />
					<Typography
						variant="overline"
						color="white"
						sx={{ color: '#fff' }}
					>
						Authenticating...
					</Typography>
				</Box>
			</Backdrop>
		);

	return children;
};

export default AuthCheck;
