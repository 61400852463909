import React, { useState, useEffect, useCallback } from 'react';
import { Application } from '@feathersjs/feathers';
import { toast } from 'react-toastify';
import { WebsocketTypes } from 'hohm-types';
import { useLifecycles } from 'react-use';
import PdfErrorNotify from './PdfErrorNotify';
import PdfSuccessNotify from './PdfSuccessNotify';

type TEventType = 'ep' | 'invoice' | 'purchaseOrder' | 'ip' | 'error';
type TEventName =
	| 'quote-ready'
	| 'invoice-ready'
	| 'quote-generation-started'
	| 'ip-pdf-ready'
	| 'purchase-order-ready'
	| 'error';

interface IPdfEvent {
	eventName: TEventName;
	eventType: TEventType;
}

interface IWithManagePdfNotifierProps {
	client: Application<any, any>;
	service: string;
	events: IPdfEvent[];
}

function useManagePdfNotify(
	client: Application<any, any>,
	service: string,
	events: IPdfEvent[]
) {
	const [pdfSuccessOpen, setPdfSuccessOpen] = useState(false);
	const [pdfErrorOpen, setPdfErrorOpen] = useState(false);
	const [pdfErrorMessage, setPdfErrorMessage] = useState('');
	const [pdfUrl, setPdfUrl] = useState('');
	const [pdfType, setPdfType] = useState<TEventType>('ep');

	const eventHandlerSuccess = useCallback(
		(eventType: TEventType) => (data: WebsocketTypes.ISuccessPayload) => {
			setPdfUrl(data.url);
			setPdfSuccessOpen(true);
			setPdfType(eventType);
		},
		[]
	);

	const eventHandlerProgress = useCallback(() => {
		toast.info('Preparing PDF...', { toastId: 'preparing-pdf' });
	}, []);

	const eventHandlerError = useCallback(
		(data: WebsocketTypes.IErrorPayload) => {
			setPdfErrorOpen(true);
			setPdfErrorMessage(data.message);
		},
		[]
	);

	useLifecycles(() => {
		const epListener = client.service(service);

		// Attaching event listeners
		events.forEach(({ eventName, eventType }) => {
			if (eventName === 'error') {
				epListener.on(eventName, eventHandlerError);
			} else if (eventName === 'quote-generation-started') {
				epListener.on(eventName, eventHandlerProgress);
			} else {
				epListener.on(eventName, eventHandlerSuccess(eventType));
			}
		});

		// Cleanup function to remove event listeners
		return () => {
			events.forEach(({ eventName, eventType }) => {
				if (eventName === 'error') {
					epListener.off(eventName, eventHandlerError);
				} else if (eventName === 'quote-generation-started') {
					epListener.off(eventName, eventHandlerProgress);
				} else {
					epListener.off(eventName, eventHandlerSuccess(eventType));
				}
			});
		};
	});

	// Clear toasts
	useEffect(() => {
		if (pdfSuccessOpen || pdfErrorOpen || pdfUrl) {
			toast.dismiss('preparing-pdf');
		}
	}, [pdfSuccessOpen, pdfErrorOpen, pdfUrl]);

	return {
		pdfSuccessOpen,
		setPdfSuccessOpen,
		pdfErrorOpen,
		setPdfErrorOpen,
		pdfErrorMessage,
		pdfUrl,
		pdfType,
	};
}

function withManagePdfNotify({
	client,
	service,
	events,
}: IWithManagePdfNotifierProps) {
	return function WrappedComponent() {
		const {
			pdfSuccessOpen,
			setPdfSuccessOpen,
			pdfErrorOpen,
			setPdfErrorOpen,
			pdfErrorMessage,
			pdfUrl,
			pdfType,
		} = useManagePdfNotify(client, service, events);

		return (
			<>
				<PdfSuccessNotify
					pdfSuccessOpen={pdfSuccessOpen}
					setPdfSuccessOpen={setPdfSuccessOpen}
					pdfURL={pdfUrl}
					pdfType={pdfType}
				/>
				<PdfErrorNotify
					pdfErrorOpen={pdfErrorOpen}
					setPdfErrorOpen={setPdfErrorOpen}
					message={pdfErrorMessage}
				/>
			</>
		);
	};
}

export default withManagePdfNotify;
