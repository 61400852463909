"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.engineeredProposalVersionsQuerySchema = exports.engineeredProposalVersionsUpdateSchema = exports.engineeredProposalVersionsWriteSchema = exports.engineeredProposalVersionsReadSchema = exports.engineeredProposalVersionsSchema = exports.rejectionReasons = exports.versionConfigSchema = void 0;
var typebox_1 = require("@feathersjs/typebox");
var consumable_types_1 = require("./consumable.types");
var currency_types_1 = require("./currency.types");
var document_types_1 = require("./document.types");
var engineered_proposal_types_1 = require("./engineered-proposal.types");
var indicative_proposal_types_1 = require("./indicative-proposal.types");
var general_types_1 = require("./general-types");
var monthly_bill_types_1 = require("./monthly-bill.types");
var organisation_types_1 = require("./organisation.types");
var product_kit_types_1 = require("./product-kit.types");
var project_fixed_cost_types_1 = require("./project-fixed-cost.types");
var property_types_1 = require("./property.types");
var roof_area_types_1 = require("./roof-area.types");
var service_plan_types_1 = require("./service-plan.types");
var system_design_types_1 = require("./system-design.types");
var user_types_1 = require("./user.types");
var tax_types_1 = require("./tax.types");
var engineered_proposal_version_constants_types_1 = require("./engineered-proposal-version-constants.types");
var travel_expenses_types_1 = require("./travel-expenses.types");
var custom_labor_line_item_types_1 = require("./custom-labor-line-item.types");
exports.versionConfigSchema = typebox_1.Type.Object({
    advisor: user_types_1.userReadSchema,
    systemType: (0, typebox_1.StringEnum)(__spreadArray([], general_types_1.SYSTEM_TYPES, true)),
    finance: typebox_1.Type.Optional(indicative_proposal_types_1.proposalFinanceSchema),
    documents: typebox_1.Type.Array(document_types_1.jsonDocumentSchema),
    desiredStorageInKwh: typebox_1.Type.Number(),
    desiredKwp: typebox_1.Type.Number(),
    desiredBackupHours: typebox_1.Type.Number(),
    property: property_types_1.propertyReadSchema,
    monthlyBill: monthly_bill_types_1.monthlyBillReadSchema,
    roofArea: roof_area_types_1.roofAreaReadSchema,
    components: typebox_1.Type.Object({
        productKit: typebox_1.Type.Object({
            total: typebox_1.Type.Number(),
            breakdown: product_kit_types_1.appliedProductKitSchema,
        }),
        mounting: typebox_1.Type.Object({
            total: typebox_1.Type.Number(),
            breakdown: typebox_1.Type.Array(consumable_types_1.appliedConsumableSchema),
        }),
        installation: typebox_1.Type.Object({
            total: typebox_1.Type.Number(),
            installationType: (0, typebox_1.StringEnum)(__spreadArray([], engineered_proposal_types_1.INSTALLATION_TYPES, true)),
            breakdown: typebox_1.Type.Optional(typebox_1.Type.Object({
                installSize: typebox_1.Type.Object({
                    inverterInstallSize: typebox_1.Type.Number(),
                    baseNoOfDays: typebox_1.Type.Number(),
                }),
                property: typebox_1.Type.Object({
                    propertyStories: typebox_1.Type.Optional(typebox_1.Type.Number()),
                    propertyStoriesNoOfDays: typebox_1.Type.Number(),
                }),
                panels: typebox_1.Type.Object({
                    noOfPanels: typebox_1.Type.Optional(typebox_1.Type.Number()),
                    additionalPanels: typebox_1.Type.Number(),
                    additionalPanelsNoOfDays: typebox_1.Type.Number(),
                }),
                roof: typebox_1.Type.Object({
                    pitchOfRoof: typebox_1.Type.Optional(typebox_1.Type.Number()),
                    roofAngleNoOfDays: typebox_1.Type.Number(),
                }),
                installerId: typebox_1.Type.Optional(typebox_1.Type.String()),
                extraLaborItemsNoOfDays: typebox_1.Type.Number(),
                extraLaborItemsNo: typebox_1.Type.Number(),
                totalNoOfDays: typebox_1.Type.Number(),
                installationDayRate: typebox_1.Type.Number(),
            })),
            customBreakdown: typebox_1.Type.Optional(typebox_1.Type.Array(custom_labor_line_item_types_1.customLaborLineItemReadSchema)),
        }),
        complianceCosts: typebox_1.Type.Object({
            total: typebox_1.Type.Number(),
            name: typebox_1.Type.String(),
            sseg: typebox_1.Type.Number(),
            hohmRanger: typebox_1.Type.Number(),
        }),
        travelExpenses: typebox_1.Type.Object({
            total: typebox_1.Type.Number(),
            breakdown: typebox_1.Type.Union([travel_expenses_types_1.travelCostsSchema, typebox_1.Type.Null()]),
        }),
        servicePlan: typebox_1.Type.Object({
            total: typebox_1.Type.Number(),
            plan: typebox_1.Type.Union([service_plan_types_1.servicePlanReadSchema, typebox_1.Type.Null()]),
        }),
        pricingLineItems: typebox_1.Type.Object({
            total: typebox_1.Type.Number(),
            breakdown: typebox_1.Type.Array(project_fixed_cost_types_1.appliedProjectFixedCostSchema),
        }),
        deposit: typebox_1.Type.Object({
            total: typebox_1.Type.Number(),
            percent: typebox_1.Type.Number(),
        }),
        discount: typebox_1.Type.Object({
            total: typebox_1.Type.Number(),
            percent: typebox_1.Type.Number(),
        }),
        margin: typebox_1.Type.Object({
            total: typebox_1.Type.Number(),
            percent: typebox_1.Type.Number(),
        }),
        consumablesMarkup: typebox_1.Type.Object({
            percent: typebox_1.Type.Number(),
        }),
        mountingConsumablesMarkup: typebox_1.Type.Object({
            percent: typebox_1.Type.Number(),
        }),
        contingency: typebox_1.Type.Object({
            total: typebox_1.Type.Number(),
            percent: typebox_1.Type.Number(),
        }),
        taxes: typebox_1.Type.Object({
            total: typebox_1.Type.Number(),
            breakdown: typebox_1.Type.Array(tax_types_1.appliedTaxSchema),
        }),
    }),
    consumption: typebox_1.Type.Object({
        averageHourlyKwhUsage: typebox_1.Type.Number(),
    }),
    production: indicative_proposal_types_1.productionKwhSchema,
    systemDesign: typebox_1.Type.Object({
        backupLoads: typebox_1.Type.Array(typebox_1.Type.String()),
        activeLoads: typebox_1.Type.Array(typebox_1.Type.String()),
        panelRowSetup: typebox_1.Type.Array(system_design_types_1.panelRowSchema),
        panelMappingSetup: typebox_1.Type.Union([system_design_types_1.panelMappingSchema, typebox_1.Type.Null()]),
        cabling: engineered_proposal_types_1.cablingSchema,
        cableRun: typebox_1.Type.Array(engineered_proposal_types_1.cableRunSchema),
    }),
    savingsAnalysis: typebox_1.Type.Union([indicative_proposal_types_1.savingsAnalysisSchema, typebox_1.Type.Null()]),
    currency: (0, typebox_1.StringEnum)(__spreadArray([], currency_types_1.SUPPORTED_CURRENCY, true)),
    totalCost: typebox_1.Type.Object({
        final: typebox_1.Type.Number(),
    }),
    installer: organisation_types_1.organisationReadSchema,
    pricingOrganisation: organisation_types_1.organisationReadSchema,
    propertyOwner: user_types_1.userReadSchema,
    indicativeProposalId: typebox_1.Type.Optional(typebox_1.Type.String()),
});
exports.rejectionReasons = [
    'Component and other system costs',
    'The affordability of finance solutions',
    'Technical performance of the system',
    'Ongoing support processes',
    'Other',
];
exports.engineeredProposalVersionsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    migrationVersion: typebox_1.Type.Number(),
    epIteration: typebox_1.Type.Number(),
    expiryDate: typebox_1.Type.Date(),
    config: exports.versionConfigSchema,
    engineeredProposalId: typebox_1.Type.String(),
    approved: typebox_1.Type.Optional(typebox_1.Type.Boolean()),
    state: (0, typebox_1.StringEnum)(__spreadArray([], engineered_proposal_version_constants_types_1.EPV_STATES, true)),
    tags: typebox_1.Type.Optional(typebox_1.Type.Array((0, typebox_1.StringEnum)(__spreadArray([], engineered_proposal_version_constants_types_1.EPV_TAGS, true)))),
    rejectionReason: typebox_1.Type.Optional(typebox_1.Type.String()),
    tcsViewed: typebox_1.Type.Boolean(),
    tcsAccepted: typebox_1.Type.Boolean(),
    legalAgreements: typebox_1.Type.Optional(typebox_1.Type.Object({
        fullTimestamp: typebox_1.Type.Date(),
        location: typebox_1.Type.String(),
    })),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.engineeredProposalVersionsReadSchema = typebox_1.Type.Omit(exports.engineeredProposalVersionsSchema, []);
exports.engineeredProposalVersionsWriteSchema = typebox_1.Type.Omit(exports.engineeredProposalVersionsReadSchema, ['id', 'createdAt', 'updatedAt']);
exports.engineeredProposalVersionsUpdateSchema = typebox_1.Type.Partial(exports.engineeredProposalVersionsSchema, {
    $id: 'EngineeredProposalVersionsUpdate',
});
var engineeredProposalVersionsQueryProperties = typebox_1.Type.Partial(exports.engineeredProposalVersionsSchema, {
    $id: 'EngineeredProposalVersionsQuery',
});
exports.engineeredProposalVersionsQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(engineeredProposalVersionsQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
