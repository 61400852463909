import React from 'react';
import { styled } from '@mui/material';
// @app
import { CountryCodes } from 'hohm-types';
// @components
import { useDeepCompareEffect } from 'react-use';
import { PlacesResult } from '../Places';

export interface IOverlay {
	area: [number, number][];
	areaSize: number;
	id: string;
}

type TGoogleMap = 'roadmap' | 'satellite';
interface IMapConfig {
	zoom?: number;
	type?: TGoogleMap;
}
export interface IPosition {
	lat: number;
	lng: number;
}

type TIcon = 'default' | 'png' | 'svg';
interface ILocation extends IPosition {
	name: string;
	address: string;
	iconType?: TIcon;
	draggable?: boolean;
}

interface IMarkerData {
	position: IPosition;
}

interface IMarker extends google.maps.Marker {
	formatted_address?: string;
}

const StyledMapDiv = styled('div')(() => ({
	height: 'calc(100%)',
}));

export type TGoogleMarkerMapProps = {
	center: IPosition;
	locations: ILocation[];
	overlaySettings?: {
		strokeColor: string;
		fillColor: string;
	};
	mapConfig?: IMapConfig;
	getSelectedLocation?: (location: PlacesResult, context?: string) => void;
	render?: boolean;
	countryCode?: string;
};

export const GoogleMarkerMap = ({
	center,
	locations,
	mapConfig,
	getSelectedLocation,
	render,
	countryCode,
	...rest
}: TGoogleMarkerMapProps) => {
	const ref = React.useRef<HTMLDivElement>(null);

	const [, setGoogleMap] = React.useState<null | google.maps.Map>(null);

	const geocoder = new google.maps.Geocoder();

	const addMarkers = async (
		mapInstance: google.maps.Map,
		mapLocations: ILocation[]
	) => {
		mapLocations.map((location) => {
			const marker: IMarker = new google.maps.Marker({
				position: { lat: location.lat, lng: location.lng },
				draggable: location?.draggable,
			} as IMarkerData);

			function geocodePosition(pos: google.maps.LatLng) {
				const latLng: Record<string, google.maps.LatLng> = {
					latLng: pos,
				};

				geocoder.geocode(latLng, (responses) => {
					if (responses && responses.length > 0) {
						marker.formatted_address =
							responses[0].formatted_address;

						const addressComponents =
							responses[0].address_components;

						const selectedPlace = marker.getPosition();

						const coordinates = [
							selectedPlace && selectedPlace.lat(),
							selectedPlace && selectedPlace.lng(),
						] as [number, number];

						const city = addressComponents.find((item) =>
							item.types.includes('locality')
						)?.long_name;

						const region = addressComponents.find((item) =>
							item.types.includes('administrative_area_level_1')
						)?.long_name;

						const country = addressComponents.find((item) =>
							item.types.includes('country')
						)?.long_name;

						const shortName = addressComponents.find((item) =>
							item.types.includes('country')
						)?.short_name as CountryCodes.TCountryCode;

						coordinates &&
							city &&
							country &&
							region &&
							shortName &&
							getSelectedLocation &&
							getSelectedLocation(
								{
									coordinates,
									city,
									country,
									region,
									shortName,
									name: '',
									formatted_address: marker.formatted_address,
								},
								'fromDrag'
							);
					} else {
						marker.formatted_address =
							'Cannot determine address at this location.';
					}
				});
			}

			marker.addListener('dragend', function markerDragEnd() {
				const getPosition = marker.getPosition();

				getPosition && geocodePosition(getPosition);
			});

			marker.setMap(mapInstance);
		});
	};

	useDeepCompareEffect(() => {
		const runGoogle = async () => {
			const mapOptions = {
				center,
				title: 0,
				zoom: mapConfig?.zoom || 6,
				tilt: 0,
				mapTypeControl: false,
				mapTypeId: mapConfig?.type || 'hybrid',
				scrollwheel: false,
				zoomControlOptions: {
					position: google.maps.ControlPosition.RIGHT_BOTTOM,
				},
				streetViewControl: false,
				fullscreenControl: true,
				rotateControl: false,
				styles: [
					{
						featureType: 'poi',
						stylers: [{ visibility: 'off' }],
					},
					{
						featureType: 'transit',
						stylers: [{ visibility: 'off' }],
					},
				],
			} as google.maps.MapOptions;

			const mapInstance = new window.google.maps.Map(
				ref.current as HTMLDivElement,
				mapOptions
			);

			if (mapInstance != null) {
				setGoogleMap(mapInstance);

				if (center.lat && center.lng) {
					addMarkers(mapInstance, locations);
				} else {
					geocoder.geocode(
						{ componentRestrictions: { country: countryCode } },
						function geocoderResults(results, status) {
							if (
								results &&
								status === google.maps.GeocoderStatus.OK
							) {
								addMarkers(mapInstance, [
									{
										lat: results[0].geometry.location.lat(),
										lng: results[0].geometry.location.lng(),
										name: '',
										address: '',
										iconType: 'default',
										draggable: true,
									},
								]);
								mapInstance.setCenter(
									results[0].geometry.location
								);
							}
						}
					);
				}
			}
		};

		if (Boolean(ref.current) === true && render) {
			runGoogle();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mapConfig, center, Boolean(ref.current)]);

	return <StyledMapDiv ref={ref} id="polygon-map" {...rest} />;
};

export default GoogleMarkerMap;
