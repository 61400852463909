"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const themeOptions = {
    palette: {
        primary: {
            lighter: '#009999',
            light: '#009999',
            dark: '#009999',
            darker: '#009999',
            main: '#009999',
            contrastText: '#FFFFFF',
        },
        secondary: {
            lighter: '#3E4F6E',
            light: '#3E4F6E',
            main: '#0E244A',
            dark: '#091933',
            darker: '#091933',
            contrastText: '#FFFFFF',
        },
    },
};
exports.default = themeOptions;
