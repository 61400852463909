"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.consumableQuerySchema = exports.consumableUpdateSchema = exports.consumableWriteSchema = exports.consumableReadSchema = exports.appliedConsumableSchema = exports.consumableSchema = exports.consumableDependentSchema = exports.CONSUMABLE_DEPENDENT = exports.ConsumableMap = exports.consumableTypesSchema = exports.CONSUMABLE_TYPES = exports.consumableSubGroupSchema = exports.CONSUMABLE_SUB_GROUPS = exports.consumableGroupSchema = exports.CONSUMABLE_GROUPS = void 0;
var typebox_1 = require("@feathersjs/typebox");
var currency_types_1 = require("./currency.types");
var roof_area_types_1 = require("./roof-area.types");
exports.CONSUMABLE_GROUPS = [
    'cabling',
    'consumable materials',
    'mounting',
];
exports.consumableGroupSchema = (0, typebox_1.StringEnum)(__spreadArray([], exports.CONSUMABLE_GROUPS, true));
exports.CONSUMABLE_SUB_GROUPS = __spreadArray([
    'pv cabling',
    'battery cabling',
    'ac cabling',
    'pv protection',
    'ac protection box',
    'battery protection',
    'ac essential db',
    'non-essential db',
    'sundries'
], roof_area_types_1.MOUNTING_TYPES, true);
exports.consumableSubGroupSchema = (0, typebox_1.StringEnum)(__spreadArray([], exports.CONSUMABLE_SUB_GROUPS, true));
// TODO cleanup this for types
exports.CONSUMABLE_TYPES = [
    'red',
    'black',
    'earth',
    'general',
    'flush',
    'tilted',
];
exports.consumableTypesSchema = (0, typebox_1.StringEnum)(__spreadArray([], exports.CONSUMABLE_TYPES, true));
exports.ConsumableMap = {
    cabling: {
        'ac cabling': ['general', 'earth'],
        'battery cabling': ['red', 'black'],
        'pv cabling': ['red', 'black', 'earth'],
    },
    // Mounting depends on roofArea.mountTypes adding stuff there has an impact here too
    mounting: {
        carport: ['flush', 'tilted'],
        'clip-lock': ['flush', 'tilted'],
        'corrugated-sheet-metal': ['flush', 'tilted'],
        'flat-concrete': ['flush', 'tilted'],
        ground: ['flush', 'tilted'],
        ibr: ['flush', 'tilted'],
        slate: ['flush', 'tilted'],
        tile: ['flush', 'tilted'],
    },
    'consumable materials': {
        'ac essential db': ['general'],
        'pv protection': ['general'],
        'ac protection box': ['general'],
        'battery protection': ['general'],
        'non-essential db': ['general'],
        sundries: ['general'],
    },
};
exports.CONSUMABLE_DEPENDENT = ['panel', 'battery'];
exports.consumableDependentSchema = (0, typebox_1.StringEnum)(__spreadArray([], exports.CONSUMABLE_DEPENDENT, true));
// type TLoadEager = 'consumables';
var loadEagerSchema = (0, typebox_1.StringEnum)(['consumables']);
exports.consumableSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Number(),
    name: typebox_1.Type.String(),
    description: typebox_1.Type.String(),
    price: typebox_1.Type.Number(),
    currency: (0, typebox_1.StringEnum)(__spreadArray([], currency_types_1.SUPPORTED_CURRENCY, true)),
    dimensions: typebox_1.Type.Optional(typebox_1.Type.Object({
        lengthInMM: typebox_1.Type.Number(),
        breadthInMM: typebox_1.Type.Number(),
    })),
    dependsOn: typebox_1.Type.Optional((0, typebox_1.StringEnum)(__spreadArray([], exports.CONSUMABLE_DEPENDENT, true))),
    group: (0, typebox_1.StringEnum)(__spreadArray([], exports.CONSUMABLE_GROUPS, true)),
    subgroup: (0, typebox_1.StringEnum)(__spreadArray([], exports.CONSUMABLE_SUB_GROUPS, true)),
    type: (0, typebox_1.StringEnum)(__spreadArray([], exports.CONSUMABLE_TYPES, true)),
    redundancyFactor: typebox_1.Type.Number(),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.appliedConsumableSchema = typebox_1.Type.Intersect([
    exports.consumableSchema,
    typebox_1.Type.Object({
        quantity: typebox_1.Type.Number(),
    }),
]);
exports.consumableReadSchema = typebox_1.Type.Omit(exports.consumableSchema, []);
exports.consumableWriteSchema = typebox_1.Type.Omit(exports.consumableReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.consumableUpdateSchema = typebox_1.Type.Partial(exports.consumableSchema, {
    $id: 'ConsumableUpdate',
});
var consumableQueryProperties = typebox_1.Type.Partial(exports.consumableSchema, {
    $id: 'ConsumableQuery',
});
exports.consumableQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(consumableQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({
        $loadEager: typebox_1.Type.Optional(typebox_1.Type.Array(loadEagerSchema)),
    }, { additionalProperties: false }),
], { additionalProperties: false });
