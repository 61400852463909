// @mui
import { InputAdornment } from '@mui/material';
// types
import { CurrencyTypes } from 'hohm-types';
// utilities
import { HandleCurrency } from 'hohm-utilities';
// @local
import { Input } from './Input';
import { type TCurrencyInputProps } from './utils/types';

export type { TCurrencyInputProps };
export const CurrencyInput = ({
	currency,
	InputProps,
	...props
}: TCurrencyInputProps) => {
	const currencySymbol = HandleCurrency.getCurrencySymbol(
		currency as CurrencyTypes.TCurrency
	);

	return (
		<Input
			isNumeric
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						{currencySymbol}
					</InputAdornment>
				),
				...InputProps,
			}}
			{...props}
		/>
	);
};
