"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.subscriberQuerySchema = exports.subscriberUpdateSchema = exports.subscriberWriteSchema = exports.subscriberReadSchema = exports.subscriberSchema = void 0;
var typebox_1 = require("@feathersjs/typebox");
exports.subscriberSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    email: typebox_1.Type.String(),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.subscriberReadSchema = typebox_1.Type.Omit(exports.subscriberSchema, []);
exports.subscriberWriteSchema = typebox_1.Type.Omit(exports.subscriberReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.subscriberUpdateSchema = typebox_1.Type.Partial(exports.subscriberSchema, {
    $id: 'SubscriberUpdate',
});
var subscriberQueryProperties = typebox_1.Type.Partial(exports.subscriberSchema, {
    $id: 'SubscriberQuery',
});
exports.subscriberQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(subscriberQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
