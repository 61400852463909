"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.kitConsumableQuerySchema = exports.kitConsumableUpdateSchema = exports.kitConsumableWriteSchema = exports.kitConsumableReadSchema = exports.kitConsumableSchema = exports.MATCH_RULES = exports.matchComponentSchema = exports.MATCH_COMPONENTS = void 0;
var typebox_1 = require("@feathersjs/typebox");
var consumable_types_1 = require("./consumable.types");
exports.MATCH_COMPONENTS = [
    'battery',
    'panel',
    'inverter',
    'mppt strings',
];
exports.matchComponentSchema = (0, typebox_1.StringEnum)(__spreadArray([], exports.MATCH_COMPONENTS, true));
exports.MATCH_RULES = {
    'Per X': 'per X',
    'Per given ratio': 'per given ratio',
    'Given Quantity': 'given quantity',
    'More than X': 'more than X',
};
// type TLoadEager = 'consumables';
var loadEagerSchema = (0, typebox_1.StringEnum)(['consumables']);
exports.kitConsumableSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Number(),
    consumableId: typebox_1.Type.String(),
    productKitId: typebox_1.Type.String(),
    matchedRule: (0, typebox_1.StringEnum)(__spreadArray([], Object.values(exports.MATCH_RULES), true)),
    matchedComponent: typebox_1.Type.Union([
        (0, typebox_1.StringEnum)(__spreadArray([], exports.MATCH_COMPONENTS, true)),
        typebox_1.Type.Null(),
        typebox_1.Type.Undefined(),
    ]),
    quantity: typebox_1.Type.Number(),
    minMatchedComponentQuantity: typebox_1.Type.Number(),
    perMatchedComponentQuantity: typebox_1.Type.Number(),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
    consumable: consumable_types_1.consumableSchema,
});
exports.kitConsumableReadSchema = typebox_1.Type.Omit(exports.kitConsumableSchema, []);
exports.kitConsumableWriteSchema = typebox_1.Type.Omit(exports.kitConsumableReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.kitConsumableUpdateSchema = typebox_1.Type.Partial(exports.kitConsumableSchema, {
    $id: 'KitConsumableUpdate',
});
var kitConsumableQueryProperties = typebox_1.Type.Partial(exports.kitConsumableSchema, {
    $id: 'KitConsumableQuery',
});
exports.kitConsumableQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(kitConsumableQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({
        $loadEager: typebox_1.Type.Optional(typebox_1.Type.Array(loadEagerSchema)),
    }, { additionalProperties: false }),
], { additionalProperties: false });
