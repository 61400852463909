"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.installationPricingQuerySchema = exports.installationPricingUpdateSchema = exports.installationPricingWriteSchema = exports.installationPricingReadSchema = exports.installationPricingSchema = exports.PRICING_MODES = void 0;
var typebox_1 = require("@feathersjs/typebox");
var general_types_1 = require("./general-types");
// basic - base number of panels to determine number of days
// additionalPanels - additional panels to determine number of days - exceeding no of panels within kwp range
// labor extra line items - other factors if present increase installation days
// for example if installation is on a storey building > 1 storey - additional 0.5 days
exports.PRICING_MODES = [
    'basic',
    'additionalPanels',
    'laborExtraLineItems',
];
exports.installationPricingSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    startkwSize: typebox_1.Type.Number(),
    endkwSize: typebox_1.Type.Number(),
    minNoOfPanels: typebox_1.Type.Number(),
    maxNoOfPanels: typebox_1.Type.Number(),
    noOfDays: typebox_1.Type.Number(),
    mode: (0, typebox_1.StringEnum)(__spreadArray([], exports.PRICING_MODES, true)),
    consumerType: (0, typebox_1.StringEnum)(__spreadArray([], general_types_1.CONSUMER_TYPES, true)),
    systemType: (0, typebox_1.StringEnum)(__spreadArray([], general_types_1.SYSTEM_TYPES, true)),
    // for extra line items
    itemName: typebox_1.Type.Optional(typebox_1.Type.String()),
    required: typebox_1.Type.Optional(typebox_1.Type.Boolean()),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.installationPricingReadSchema = typebox_1.Type.Omit(exports.installationPricingSchema, []);
exports.installationPricingWriteSchema = typebox_1.Type.Omit(exports.installationPricingReadSchema, ['id', 'createdAt', 'updatedAt']);
exports.installationPricingUpdateSchema = typebox_1.Type.Partial(exports.installationPricingSchema, {
    $id: 'InstallationPricingUpdate',
});
var installationPricingQueryProperties = typebox_1.Type.Partial(exports.installationPricingSchema, {
    $id: 'InstallationPricingQuery',
});
exports.installationPricingQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(installationPricingQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
