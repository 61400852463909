"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// @local
// import { StarIcon } from '../../../icons';
const ICON_SMALL = { width: 20, height: 20 };
const ICON_LARGE = { width: 28, height: 28 };
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Rating = (theme) => {
    return {
        MuiRating: {
            defaultProps: {
            // emptyIcon: <StarIcon />,
            // icon: <StarIcon />,
            },
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        opacity: 0.48,
                    },
                },
                iconEmpty: { color: theme.palette.grey[50048] },
                sizeSmall: { '& svg': Object.assign({}, ICON_SMALL) },
                sizeLarge: { '& svg': Object.assign({}, ICON_LARGE) },
            },
        },
    };
};
exports.default = Rating;
