"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var us = {
    location: {
        countryCode: 'us',
        countryName: 'United States',
        gps: {
            LatLng: {
                lat: 39.486351,
                lng: -96.241285,
            },
            zoom: 16,
        },
        currency: {
            code: 'usd',
            symbol: '$',
        },
        // For the US, tax should be set at the state level, not the federal level
        // For example, there is not just one sales tax rate for the whole US
        // Each state has its own tax rate, so it should be set per state
        tax: {
            rate: 0,
            label: 'Sales tax',
            getTax: function (val) { return Math.round(val * 100 * 0) / 100; },
        },
    },
    financePartners: [],
    pages: {
        signup: {
            heading: 'We make solar simple',
            content: "Hohm Energy provides you with your custom proposal in minutes, and access to our marketplace of trusted, professional solar installers.<br /><br />Sign up to Hohm Energy and get access to the following benefits:",
            offerings: [
                {
                    id: 0,
                    heading: '30% tax credit',
                    content: 'Take advantage of the 30% Federal tax credit to maximize your savings. Additional State incentives may apply, increasing your total savings to as much as 50%',
                },
                {
                    id: 1,
                    heading: 'Save money',
                    content: "Solar generates power from the sun, saving you and your family money on your rising electricity bill.",
                },
                {
                    id: 2,
                    heading: 'Affordable finance with no upfront cost',
                    content: "Solar finance has never been easier. Apply with our approved range of financiers for the most affordable solar loan.",
                },
                {
                    id: 3,
                    heading: 'Get the best price',
                    content: "Hohm Energy has negotiated the best prices from product suppliers and solar installers to ensure best value for you.",
                },
                {
                    id: 4,
                    heading: 'Your own Energy Advisor',
                    content: "Hohm Energy is with you every step of the way to answer any questions, manage the process and ensure your solar journey is as seamless as possible.",
                },
            ],
        },
    },
};
exports.default = us;
