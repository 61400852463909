import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export default makeStyles((theme: Theme) =>
	createStyles({
		drawerPaper: {
			overflowX: 'hidden',
			overflowY: 'auto',
			border: theme.palette.primary.main,
			background: theme.palette.primary.main,
			borderTopRightRadius: '0px',
			borderBottomRightRadius: '0px',
			maxWidth: '400px',
			[theme.breakpoints.down('md')]: {
				height: '100%',
				width: '100vw',
				maxWidth: '100vw',
			},
		},
		closeContainer: {
			marginTop: '15px',
			marginRight: '15px',
			paddingTop: '10px',
			paddingRight: '10px',
		},
		closeIcon: {
			color: 'white',
			fontSize: 'xx-large',
			fontWeight: 'bold',
		},
		drawerItems: {
			paddingLeft: theme.spacing(5),
			paddingRight: theme.spacing(5),
			[theme.breakpoints.down('md')]: {
				paddingLeft: theme.spacing(1),
				paddingRight: theme.spacing(1),
			},
		},
		drawerItemsText: {
			fontSize: 30,
			fontWeight: 800,
			marginTop: 10,
			marginBottom: 10,
			color: 'white',
		},
	})
);
