"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var fnb = {
    theme: 'fnb',
    brand: {
        name: 'FNB',
        legalName: 'FNB',
        logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/fnb/88a93794-c079-490f-9ee8-d6d19a4c797c.png',
        icon: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/fnb/88a93794-c079-490f-9ee8-d6d19a4c797c.png',
    },
    financePartners: [
        {
            name: 'FNB',
            logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/fnb/88a93794-c079-490f-9ee8-d6d19a4c797c.png',
        },
    ],
    campaignPartners: [
        {
            name: 'FNB',
            logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/fnb/88a93794-c079-490f-9ee8-d6d19a4c797c.png',
        },
    ],
    pages: {
        signup: {
            sections: {
                titleSection: {
                    title: "From start to solar, we've got your back.",
                },
            },
        },
    },
    poweredBy: {
        name: 'Hohm Energy',
        legalName: 'Hohm Services (Pty) Ltd.',
        logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/general/HohmEnergyLogoOrange.png',
    },
    partner: {
        name: 'Hohm Energy',
        legalName: 'Hohm Services (Pty) Ltd.',
        logo: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/general/HohmEnergyLogoOrange.png',
    },
    packages: {
        hohmPdf: {
            hideFinancePages: true,
            hideBrandLogo: false,
        },
    },
    buyOnly: true,
    hideHomeLoanAddition: true,
    hideSolarFinance: true,
    buyLabel: 'FNB Solar Finance',
    hideSubscribeBuyCTA: true,
    financeType: 'fnb-solar-finance',
};
exports.default = fnb;
