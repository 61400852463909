"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.epvCommentsQuerySchema = exports.epvCommentsUpdateSchema = exports.epvCommentsWriteSchema = exports.epvCommentsReadSchema = exports.epvCommentsSchema = exports.EPV_COMMENTS_TYPES = exports.EPV_COMMENTS_CATEGORIES = void 0;
var typebox_1 = require("@feathersjs/typebox");
exports.EPV_COMMENTS_CATEGORIES = [
    'Panel Size',
    'Circuit Breaker',
    'System Type',
    'COC',
    'Surge Protection',
    'Other',
];
exports.EPV_COMMENTS_TYPES = ['Internal', 'Client Facing'];
// feathers v5 types
exports.epvCommentsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    epId: typebox_1.Type.String(),
    engineeredProposalVersionId: typebox_1.Type.String(),
    comments: typebox_1.Type.String(),
    type: (0, typebox_1.StringEnum)(__spreadArray([], exports.EPV_COMMENTS_TYPES, true)),
    categories: typebox_1.Type.Array(typebox_1.Type.String()),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.epvCommentsReadSchema = typebox_1.Type.Omit(exports.epvCommentsSchema, []);
exports.epvCommentsWriteSchema = typebox_1.Type.Omit(exports.epvCommentsSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.epvCommentsUpdateSchema = typebox_1.Type.Partial(exports.epvCommentsSchema, {
    $id: 'EPVCommentsUpdate',
});
var epvCommentsQueryProperties = typebox_1.Type.Partial(exports.epvCommentsSchema, {
    $id: 'EPVCommentsQuery',
});
exports.epvCommentsQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(epvCommentsQueryProperties),
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
