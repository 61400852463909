"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.currenciesTempData = void 0;
exports.currenciesTempData = JSON.stringify([
    {
        id: 1,
        exchangeRates: [
            { currency: 'zar', value: 16.72129, isoCode: 'ZAR' },
            { currency: 'eur', value: 0.98347, isoCode: 'EUR' },
        ],
        baseCurrency: 'usd',
        createdAt: '2022-07-08T14:17:07.687Z',
        updatedAt: '2022-07-08T14:17:07.687Z',
    },
    {
        id: 2,
        exchangeRates: [
            { currency: 'zar', value: 17.002339, isoCode: 'ZAR' },
            { currency: 'usd', value: 1.016808, isoCode: 'USD' },
        ],
        baseCurrency: 'eur',
        createdAt: '2022-07-08T14:17:07.694Z',
        updatedAt: '2022-07-08T14:17:07.694Z',
    },
    {
        id: 3,
        exchangeRates: [
            { currency: 'usd', value: 0.059804, isoCode: 'USD' },
            { currency: 'eur', value: 0.058815, isoCode: 'EUR' },
        ],
        baseCurrency: 'zar',
        createdAt: '2022-07-08T14:17:07.744Z',
        updatedAt: '2022-07-08T14:17:07.744Z',
    },
]);
