"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.travelCostsSchema = exports.travelExpensesQuerySchema = exports.travelExpensesUpdateSchema = exports.travelExpensesWriteSchema = exports.travelExpensesReadSchema = exports.travelExpensesSchema = exports.INSTALL_AREA_TYPE = void 0;
var typebox_1 = require("@feathersjs/typebox");
var general_types_1 = require("./general-types");
exports.INSTALL_AREA_TYPE = ['Core', 'Outlying'];
exports.travelExpensesSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    region: typebox_1.Type.String(),
    coordinates: general_types_1.coordinateSchema,
    projectManagementFees: typebox_1.Type.Number(),
    outlyingAreaCharge: typebox_1.Type.Number(),
    drivingDistance: typebox_1.Type.Number(),
    areaType: (0, typebox_1.StringEnum)(__spreadArray([], exports.INSTALL_AREA_TYPE, true)),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.travelExpensesReadSchema = typebox_1.Type.Omit(exports.travelExpensesSchema, []);
exports.travelExpensesWriteSchema = typebox_1.Type.Omit(exports.travelExpensesReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.travelExpensesUpdateSchema = typebox_1.Type.Partial(exports.travelExpensesSchema, {
    $id: 'TravelExpensesUpdate',
});
var travelExpensesQueryProperties = typebox_1.Type.Partial(exports.travelExpensesSchema, {
    $id: 'TravelExpensesQuery',
});
exports.travelExpensesQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(travelExpensesQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
exports.travelCostsSchema = typebox_1.Type.Intersect([
    exports.travelExpensesReadSchema,
    typebox_1.Type.Object({
        distanceFromClosestRegion: typebox_1.Type.Number(),
        closestRegion: typebox_1.Type.Optional(typebox_1.Type.String()),
    }),
]);
