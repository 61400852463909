"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fToNow = exports.fDateTimeSuffix = exports.fTimestamp = exports.fDateTime = exports.fDate = void 0;
var date_fns_1 = require("date-fns");
// ----------------------------------------------------------------------
function fDate(date) {
    return (0, date_fns_1.format)(new Date(date), 'dd MMMM yyyy');
}
exports.fDate = fDate;
function fDateTime(date) {
    return (0, date_fns_1.format)(new Date(date), 'dd MMM yyyy p');
}
exports.fDateTime = fDateTime;
function fTimestamp(date) {
    return (0, date_fns_1.getTime)(new Date(date));
}
exports.fTimestamp = fTimestamp;
function fDateTimeSuffix(date) {
    return (0, date_fns_1.format)(new Date(date), 'dd/MM/yyyy hh:mm p');
}
exports.fDateTimeSuffix = fDateTimeSuffix;
function fToNow(date) {
    return (0, date_fns_1.formatDistanceToNow)(new Date(date), {
        addSuffix: true,
    });
}
exports.fToNow = fToNow;
