"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.areaOfOperationQuerySchema = exports.areaOfOperationUpdateSchema = exports.areaOfOperationWriteSchema = exports.areaOfOperationReadSchema = exports.areaOfOperationSchema = exports.SUPPORTED_REGIONS = exports.US_REGIONS = exports.SA_REGIONS = exports.SUPPORTED_OPERATIONS = void 0;
var typebox_1 = require("@feathersjs/typebox");
exports.SUPPORTED_OPERATIONS = [
    'sales',
    'installation',
    'operations and maintenance',
    'civil engineering',
    'electrical engineering',
    'supply',
];
exports.SA_REGIONS = [
    // SA regions
    'gauteng',
    'western cape',
    'northern cape',
    'eastern cape',
    'free state',
    'limpopo',
    'mpumalanga',
    'kwazulu-natal',
    'north west',
];
exports.US_REGIONS = [
    // US regions
    'alabama',
    'alaska',
    'arizona',
    'arkansas',
    'california',
    'colorado',
    'connecticut',
    'delaware',
    'florida',
    'georgia',
    'hawaii',
    'idaho',
    'illinois',
    'indiana',
    'iowa',
    'kansas',
    'kentucky',
    'louisiana',
    'maine',
    'maryland',
    'massachusetts',
    'michigan',
    'minnesota',
    'mississippi',
    'missouri',
    'montana',
    'nebraska',
    'nevada',
    'newhampshire',
    'newjersey',
    'newmexico',
    'newyork',
    'northcarolina',
    'northdakota',
    'ohio',
    'oklahoma',
    'oregon',
    'pennsylvania',
    'rhodeisland',
    'southcarolina',
    'southdakota',
    'tennessee',
    'texas',
    'utah',
    'vermont',
    'virginia',
    'washington',
    'westvirginia',
    'wisconsin',
    'wyoming',
];
exports.SUPPORTED_REGIONS = __spreadArray(__spreadArray([], exports.SA_REGIONS, true), exports.US_REGIONS, true);
exports.areaOfOperationSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    region: (0, typebox_1.StringEnum)(__spreadArray([], exports.SUPPORTED_REGIONS, true)),
    operation: (0, typebox_1.StringEnum)(__spreadArray([], exports.SUPPORTED_OPERATIONS, true)),
    shortName: typebox_1.Type.String(),
    organisationId: typebox_1.Type.String(),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.areaOfOperationReadSchema = typebox_1.Type.Omit(exports.areaOfOperationSchema, []);
exports.areaOfOperationWriteSchema = typebox_1.Type.Omit(exports.areaOfOperationReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.areaOfOperationUpdateSchema = typebox_1.Type.Partial(exports.areaOfOperationSchema, {
    $id: 'AreaOfOperationUpdate',
});
var areaOfOperationQueryProperties = typebox_1.Type.Partial(exports.areaOfOperationSchema, {
    $id: 'AreaOfOperationQuery',
});
exports.areaOfOperationQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(areaOfOperationQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
