// @mui
import { styled } from '@mui/material/styles';
import {
	Divider,
	MenuItem,
	Popover,
	PopoverProps,
	Stack,
	Typography,
} from '@mui/material';
import { UserTypes } from 'hohm-types';
// @local
import { Navlink } from '../navigation/Navlink';

const ArrowStyle = styled('span')(({ theme }) => ({
	[theme.breakpoints.up('sm')]: {
		top: -7,
		zIndex: 1,
		width: 12,
		right: 20,
		height: 12,
		content: "''",
		position: 'absolute',
		borderRadius: '0 0 4px 0',
		transform: 'rotate(-135deg)',
		background: theme.palette.background.paper,
		borderRight: `solid 1px ${theme.palette.grey[500_12]}`,
		borderBottom: `solid 1px ${theme.palette.grey[500_12]}`,
	},
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
	...theme.typography.body2,
	borderRadius: 1,
}));

const StyledStack = styled(Stack)(() => ({
	padding: '8px',
}));

const StyledDivider = styled(Divider)(() => ({
	borderStyle: 'dashed',
}));

const ellipsisEffect = {
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
} as const;

const StyledSubtitleTypography = styled(Typography)(({ theme }) => ({
	...theme.typography.subtitle1,
	...ellipsisEffect,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
	...theme.typography.body2,
	color: theme.palette.text.secondary,
	...ellipsisEffect,
}));

type TMenuPopoverItem = { text: string; href: string };

export type TMenuPopoverProps = {
	user?: Partial<Pick<UserTypes.IUserRead, 'email' | 'fullName'>>;
	links?: TMenuPopoverItem[];
	logout?: () => void;
	handleClose: () => void;
} & PopoverProps;
export const MenuPopover = ({
	sx,
	user,
	links,
	logout,
	handleClose,
	...other
}: TMenuPopoverProps) => {
	return (
		<Popover
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			PaperProps={{
				sx: {
					mt: 1.5,
					ml: 0.5,
					width: 200,
					borderRadius: 1.5,
					overflow: 'inherit',
					boxShadow: (theme) => theme.customShadows.z20,
					border: (theme) =>
						`solid 1px ${theme.palette.grey[500_12]}`,
					...sx,
				},
			}}
			{...other}
		>
			<ArrowStyle />

			<StyledStack spacing={0.5}>
				<StyledStack spacing={0.5}>
					{user?.fullName ? (
						<StyledSubtitleTypography>
							{user.fullName}
						</StyledSubtitleTypography>
					) : null}
					{user?.email ? (
						<StyledTypography>{user.email}</StyledTypography>
					) : null}
				</StyledStack>
			</StyledStack>

			{links ? (
				<>
					<StyledDivider />

					<StyledStack>
						{links.map(({ href, text }) => (
							<Navlink key={text} color="inherit" href={href}>
								<StyledMenuItem onClick={handleClose}>
									{text}
								</StyledMenuItem>
							</Navlink>
						))}
					</StyledStack>
				</>
			) : null}

			{logout ? (
				<>
					<StyledDivider />
					<StyledStack spacing={0.5}>
						<StyledMenuItem onClick={logout}>Logout</StyledMenuItem>
					</StyledStack>
				</>
			) : null}
		</Popover>
	);
};
