// @mui
import { styled, Typography } from '@mui/material';
// types
import { Branding } from 'hohm-types';
// @local
import { PrivacyPolicy } from './PrivacyPolicy';

export type TCopyrightFooterProps = {
	type?: 'login' | 'signUp';
};
export const CopyrightFooter = ({ type }: TCopyrightFooterProps) => {
	const currentYear = new Date().getFullYear();
	const signUpCustomDesign =
		type === 'login' ? {} : { marginTop: '1rem', fontSize: 14 };

	const StyledTypography = styled(Typography)(({ theme }) => ({
		color: '#112f48',
		opacity: 1,
		fontSize: 13,
		[theme.breakpoints.down('md')]: {
			fontSize: 12,
		},
		textAlign: 'center',
		letterSpacing: 0,
		justifyContent: 'center',
		...signUpCustomDesign,
	}));

	return (
		<StyledTypography>
			{`Copyright © ${currentYear} ${Branding.brandName}.`}
			<br />
			All Rights Reserved. <PrivacyPolicy />
		</StyledTypography>
	);
};
