import { useMemo } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useRouter } from 'next/router';
import { Typography, ListItem, ListItemText } from '@mui/material';
// @icons
import { ChevronRight } from '@mui/icons-material';
// @components
import { Navlink } from 'hohm-ui';
// @local
// eslint-disable-next-line import/no-extraneous-dependencies
import { Analytics } from 'hohm-utilities';
import useStyles from './styles';

interface IProps {
	link: string;
	handleDrawerClose: () => void;
	label: string;
	inApp: boolean;
}

const AuthItem = ({ inApp, label, link, handleDrawerClose }: IProps) => {
	const classes = useStyles();
	const router = useRouter();
	const { pathname } = router;

	const match = pathname === link;

	const linkWithUTM = useMemo(
		() => Analytics.getLinkWithUTMAndCampaignParams(link, router.query),
		[link, router.query]
	);

	return inApp ? (
		<Navlink href={linkWithUTM}>
			<ListItem onClick={handleDrawerClose} button disabled={match}>
				<ListItemText
					primary={
						<Typography
							variant="h3"
							className={classes.drawerItemsText}
						>
							<ChevronRight /> {label}
						</Typography>
					}
				/>
			</ListItem>
		</Navlink>
	) : (
		<ListItem
			onClick={handleDrawerClose}
			button
			component="a"
			href={`${process.env.NEXT_PUBLIC_LANDING_URL}${linkWithUTM}`}
		>
			<ListItemText
				primary={
					<Typography
						variant="h3"
						className={classes.drawerItemsText}
					>
						<ChevronRight /> {label}
					</Typography>
				}
			/>
		</ListItem>
	);
};

export default AuthItem;
