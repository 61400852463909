"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.projectFixedCostQuerySchema = exports.projectFixedCostUpdateSchema = exports.projectFixedCostWriteSchema = exports.appliedProjectFixedCostSchema = exports.projectFixedCostReadSchema = exports.projectFixedCostSchema = exports.CATEGORIES = void 0;
var typebox_1 = require("@feathersjs/typebox");
var currency_types_1 = require("./currency.types");
var inverter_types_1 = require("./inverter.types");
var general_types_1 = require("./general-types");
exports.CATEGORIES = [
    'value adds',
    'special add-ons',
    'core system',
    'maintenance',
];
exports.projectFixedCostSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    name: typebox_1.Type.String(),
    description: typebox_1.Type.Optional(typebox_1.Type.String()),
    systemTypes: typebox_1.Type.Array((0, typebox_1.StringEnum)(__spreadArray([], general_types_1.SYSTEM_TYPES, true))),
    value: typebox_1.Type.Number(),
    currency: (0, typebox_1.StringEnum)(__spreadArray([], currency_types_1.SUPPORTED_CURRENCY, true)),
    systemType: (0, typebox_1.StringEnum)(__spreadArray([], general_types_1.SYSTEM_TYPES, true)),
    supportedCountries: typebox_1.Type.Array((0, typebox_1.StringEnum)(__spreadArray([], currency_types_1.SUPPORTED_COUNTRIES, true))),
    supportedPhases: typebox_1.Type.Array((0, typebox_1.StringEnum)(__spreadArray([], inverter_types_1.PHASES, true))),
    category: typebox_1.Type.String(),
    isOptional: typebox_1.Type.Boolean(),
    // Relationships
    organisationId: typebox_1.Type.String(),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.projectFixedCostReadSchema = typebox_1.Type.Omit(exports.projectFixedCostSchema, []);
exports.appliedProjectFixedCostSchema = typebox_1.Type.Intersect([
    exports.projectFixedCostReadSchema,
    typebox_1.Type.Object({
        quantity: typebox_1.Type.Optional(typebox_1.Type.Number()),
    }),
]);
exports.projectFixedCostWriteSchema = typebox_1.Type.Omit(exports.projectFixedCostReadSchema, ['id', 'createdAt', 'updatedAt']);
exports.projectFixedCostUpdateSchema = typebox_1.Type.Partial(exports.projectFixedCostSchema, {
    $id: 'ProjectFixedCostUpdate',
});
var projectFixedCostQueryProperties = typebox_1.Type.Partial(exports.projectFixedCostSchema, {
    $id: 'ProjectFixedCostQuery',
});
exports.projectFixedCostQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(projectFixedCostQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
