"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SUPPORTED_COUNTRY_CODES = exports.SUPPORTED_COUNTRY_CURRENCIES = exports.SUPPORTED_CURRENCY_SYMBOLS = exports.SUPPORTED_CURRENCY_SYMBOLS_ARRAY = exports.SUPPORTED_ISO_CODE = exports.COUNTRY_CODES = exports.SUPPORTED_COUNTRIES = exports.PRICE_CATEGORIES = exports.currencyQuerySchema = exports.currencyUpdateSchema = exports.currencyWriteSchema = exports.currencyReadSchema = exports.currencySchema = exports.SUPPORTED_CURRENCY = void 0;
var typebox_1 = require("@feathersjs/typebox");
exports.SUPPORTED_CURRENCY = ['zar', 'usd', 'eur'];
exports.currencySchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    baseCurrency: (0, typebox_1.StringEnum)(__spreadArray([], exports.SUPPORTED_CURRENCY, true)),
    exchangeRates: typebox_1.Type.Array(typebox_1.Type.Object({
        value: typebox_1.Type.Number(),
        isoCode: typebox_1.Type.String(),
        currency: (0, typebox_1.StringEnum)(__spreadArray([], exports.SUPPORTED_CURRENCY, true)),
    })),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.currencyReadSchema = typebox_1.Type.Omit(exports.currencySchema, []);
exports.currencyWriteSchema = typebox_1.Type.Omit(exports.currencyReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.currencyUpdateSchema = typebox_1.Type.Partial(exports.currencySchema, {
    $id: 'CurrencyUpdate',
});
var currencyQueryProperties = typebox_1.Type.Partial(exports.currencySchema, {
    $id: 'CurrencyQuery',
});
exports.currencyQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(currencyQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
/* -1 will offer automatic detection of the appropriate nearest value */
var SUPPORTED_NEAREST_VALUES = [-1, 10, 100, 1000, 10000, 100000];
var SUPPORTED_NEAREST_PREFIXES = ['est.', '±'];
exports.PRICE_CATEGORIES = [
    'value adds',
    'special add-ons',
    'core system',
];
exports.SUPPORTED_COUNTRIES = ['south africa', 'united states'];
exports.COUNTRY_CODES = [
    'af',
    'ax',
    'al',
    'dz',
    'as',
    'ad',
    'ao',
    'ai',
    'aq',
    'ag',
    'ar',
    'am',
    'aw',
    'au',
    'at',
    'az',
    'bs',
    'bh',
    'bd',
    'bb',
    'by',
    'be',
    'bz',
    'bj',
    'bm',
    'bt',
    'bo',
    'ba',
    'bw',
    'bv',
    'br',
    'io',
    'bn',
    'bg',
    'bf',
    'bi',
    'kh',
    'cm',
    'ca',
    'cv',
    'ky',
    'cf',
    'td',
    'cl',
    'cn',
    'cx',
    'cc',
    'co',
    'km',
    'cg',
    'cd',
    'ck',
    'cr',
    'ci',
    'hr',
    'cu',
    'cy',
    'cz',
    'dk',
    'dj',
    'dm',
    'do',
    'ec',
    'eg',
    'sv',
    'gq',
    'er',
    'ee',
    'et',
    'fk',
    'fo',
    'fj',
    'fi',
    'fr',
    'gf',
    'pf',
    'tf',
    'ga',
    'gm',
    'ge',
    'de',
    'gh',
    'gi',
    'gr',
    'gl',
    'gd',
    'gp',
    'gu',
    'gt',
    'gg',
    'gn',
    'gw',
    'gy',
    'ht',
    'hm',
    'va',
    'hn',
    'hk',
    'hu',
    'is',
    'in',
    'id',
    'ir',
    'iq',
    'ie',
    'im',
    'il',
    'it',
    'jm',
    'jp',
    'je',
    'jo',
    'kz',
    'ke',
    'ki',
    'kp',
    'kr',
    'kw',
    'kg',
    'la',
    'lv',
    'lb',
    'ls',
    'lr',
    'ly',
    'li',
    'lt',
    'lu',
    'mo',
    'mk',
    'mg',
    'mw',
    'my',
    'mv',
    'ml',
    'mt',
    'mh',
    'mq',
    'mr',
    'mu',
    'yt',
    'mx',
    'fm',
    'md',
    'mc',
    'mn',
    'ms',
    'ma',
    'mz',
    'mm',
    'na',
    'nr',
    'np',
    'nl',
    'an',
    'nc',
    'nz',
    'ni',
    'ne',
    'ng',
    'nu',
    'nf',
    'mp',
    'no',
    'om',
    'pk',
    'pw',
    'ps',
    'pa',
    'pg',
    'py',
    'pe',
    'ph',
    'pn',
    'pl',
    'pt',
    'pr',
    'qa',
    're',
    'ro',
    'ru',
    'rw',
    'sh',
    'kn',
    'lc',
    'pm',
    'vc',
    'ws',
    'sm',
    'st',
    'sa',
    'sn',
    'cs',
    'sc',
    'sl',
    'sg',
    'sk',
    'si',
    'sb',
    'so',
    'za',
    'gs',
    'es',
    'lk',
    'sd',
    'sr',
    'sj',
    'sz',
    'se',
    'ch',
    'sy',
    'tw',
    'tj',
    'tz',
    'th',
    'tl',
    'tg',
    'tk',
    'to',
    'tt',
    'tn',
    'tr',
    'tm',
    'tc',
    'tv',
    'ug',
    'ua',
    'ae',
    'gb',
    'us',
    'um',
    'uy',
    'uz',
    'vu',
    've',
    'vn',
    'vg',
    'vi',
    'wf',
    'eh',
    'ye',
    'zm',
    'zw',
];
exports.SUPPORTED_ISO_CODE = [
    'JPY',
    'CNY',
    'CHF',
    'CAD',
    'MXN',
    'INR',
    'BRL',
    'RUB',
    'KRW',
    'IDR',
    'TRY',
    'SAR',
    'SEK',
    'NGN',
    'PLN',
    'ARS',
    'NOK',
    'TWD',
    'IRR',
    'AED',
    'COP',
    'THB',
    'ZAR',
    'DKK',
    'MYR',
    'SGD',
    'ILS',
    'HKD',
    'EGP',
    'PHP',
    'CLP',
    'PKR',
    'IQD',
    'DZD',
    'KZT',
    'QAR',
    'CZK',
    'PEN',
    'RON',
    'VND',
    'BDT',
    'HUF',
    'UAH',
    'AOA',
    'MAD',
    'OMR',
    'CUC',
    'BYR',
    'AZN',
    'LKR',
    'SDG',
    'SYP',
    'MMK',
    'DOP',
    'UZS',
    'KES',
    'GTQ',
    'URY',
    'HRV',
    'MOP',
    'ETB',
    'CRC',
    'TZS',
    'TMT',
    'TND',
    'PAB',
    'LBP',
    'RSD',
    'LYD',
    'GHS',
    'YER',
    'BOB',
    'BHD',
    'CDF',
    'PYG',
    'UGX',
    'SVC',
    'TTD',
    'AFN',
    'NPR',
    'HNL',
    'BIH',
    'BND',
    'ISK',
    'KHR',
    'GEL',
    'MZN',
    'BWP',
    'PGK',
    'JMD',
    'XAF',
    'NAD',
    'ALL',
    'SSP',
    'MUR',
    'MNT',
    'NIO',
    'LAK',
    'MKD',
    'AMD',
    'MGA',
    'XPF',
    'TJS',
    'HTG',
    'BSD',
    'MDL',
    'RWF',
    'KGS',
    'GNF',
    'SRD',
    'SLL',
    'XOF',
    'MWK',
    'FJD',
    'ERN',
    'SZL',
    'GYD',
    'BIF',
    'KYD',
    'MVR',
    'LSL',
    'LRD',
    'CVE',
    'DJF',
    'SCR',
    'SOS',
    'GMD',
    'KMF',
    'STD',
    'XRP',
    'AUD',
    'BGN',
    'BTC',
    'JOD',
    'GBP',
    'ETH',
    'EUR',
    'LTC',
    'NZD',
];
exports.SUPPORTED_CURRENCY_SYMBOLS_ARRAY = ['R', '$', '€'];
exports.SUPPORTED_CURRENCY_SYMBOLS = {
    zar: 'R',
    usd: '$',
    eur: '€',
};
exports.SUPPORTED_COUNTRY_CURRENCIES = {
    'south africa': 'zar',
    'united states': 'usd',
};
exports.SUPPORTED_COUNTRY_CODES = {
    za: 'zar',
    us: 'usd',
};
