'use client';

import { useEffect, useState } from 'react';
import Link from 'next/link';
// @mui
import {
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	styled,
} from '@mui/material';
// utilities
import { URL } from 'hohm-utilities';
// @local
import { TMenuItem } from './menu.types';

const color = 'hsl(212, 20%, 68%)';
const textDefaultColor = 'hsl(212, 20%, 13%)';

const StyledListItemIcon = styled(ListItemIcon)(
	({ selected }: { selected: boolean }) => ({
		width: '24px',
		height: '24px',
		...(!selected && { color }),
	})
);

export type TVerticalSidebarProps = {
	menu: TMenuItem[];
	pathname: string;
	queryName?: string;
};
export const VerticalSidebar = ({
	menu,
	pathname,
	queryName = 'section',
}: TVerticalSidebarProps) => {
	const [openItems, setOpenItems] = useState<string[]>([]);

	const toggleCollapse = (menuItem: string) => () => {
		setOpenItems(openItems.includes(menuItem) ? [] : [menuItem]);
	};

	useEffect(() => {
		menu.find(
			({ href }) =>
				href &&
				!openItems.includes(href) &&
				pathname === href &&
				toggleCollapse(href)()
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	return (
		<List disablePadding>
			{menu.map(
				({
					href,
					text,
					Icon,
					onClick,
					selected = (href && openItems.includes(href)) ||
						pathname ===
							(href?.includes('?')
								? URL.getFromQuery({
										key: queryName || '',
										search: href.split('?')[1],
									})
								: ''),
				}) => (
					<ListItem disablePadding key={text}>
						<Link href={href || ''} style={{ width: '100%' }}>
							<ListItemButton
								sx={{
									'&.Mui-selected': {
										borderRight: 'solid 5px',
										borderColor: 'primary.main',
										backgroundColor: 'rgba(0, 0, 0,0.03)',
										'& span': {
											color: 'secondary.light',
											fontWeight: 'bold',
										},
										'&:hover': {
											backgroundColor:
												'rgba(0, 0, 0,0.03)',
										},
									},
								}}
								{...{
									href,
									onClick,
									selected,
								}}
							>
								<StyledListItemIcon {...{ selected }}>
									{Icon ? <Icon /> : null}
								</StyledListItemIcon>
								<ListItemText
									sx={{
										display: {
											xs: 'none',
											md: 'block',
										},
									}}
									primary={text}
									primaryTypographyProps={{
										color: textDefaultColor,
									}}
								/>
							</ListItemButton>
						</Link>
					</ListItem>
				)
			)}
		</List>
	);
};
