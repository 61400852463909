"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_ROOF_TYPE = exports.getKitConfig = exports.kits = exports.defaultConfig = exports.KitNameDictionary = exports.PACKAGE_TAGS = exports.KIT_NAMES = exports.prodHubSpotMeetingUrl = exports.devHubSpotMeetingUrl = exports.siteVisitForm = exports.appDebugToken = exports.cloudfareLocationEndpoint = exports.VAT_RATE = exports.SPARK_MARGIN = exports.smartMeters = void 0;
exports.smartMeters = {
    singlePhase: {
        description: "New Siemens Single Phase Utility\n\t\t\t\t\t\t\t\t\t\t\tGrade Meter + installation with\n\t\t\t\t\t\t\t\t\t\t\tSmart Metering Portal Service for 60\n\t\t\t\t\t\t\t\t\t\t\tmonths.",
        price: 7100,
    },
    threePhase: {
        description: "New Siemens 3 Phase Utility Grade Meter +\n\t\t\t\t\t\t\t\t\t installation with Smart Metering Portal Service for\n\t\t\t\t\t\t\t\t\t  60 months.",
        price: 11300,
    },
};
exports.SPARK_MARGIN = 10;
exports.VAT_RATE = 0.15;
// Location
exports.cloudfareLocationEndpoint = 'https://young-moon-bf1b.emir2094.workers.dev';
exports.appDebugToken = 'f636da8c-5b8f-11ed-9b6a-0242ac120002';
// Site Visit
exports.siteVisitForm = 'https://9hheoli75kv.typeform.com/to/bLQ7cgdp';
// Hubspot
exports.devHubSpotMeetingUrl = 'https://meetings.hubspot.com/dev128/testing-vsv-kickoff';
exports.prodHubSpotMeetingUrl = 'https://meetings.hubspot.com/welcome-hohm/vsv-kickoff-meeting';
exports.KIT_NAMES = [
    'Flash Services',
    'Spark Services',
    'Flash Plus Services',
    'Spark Plus Services',
    'Glow Services',
    'Shine Services',
];
exports.PACKAGE_TAGS = [
    'SHINE',
    'GLOW',
    'FLASH PLUS',
    'SPARK PLUS',
    'FLASH',
    'SPARK',
];
exports.KitNameDictionary = {
    'Shine Services': 'SHINE',
    'Glow Services': 'GLOW',
    'Flash Plus Services': 'FLASH PLUS',
    'Spark Plus Services': 'SPARK PLUS',
    'Flash Services': 'FLASH',
    'Spark Services': 'SPARK',
};
exports.defaultConfig = {
    panel: {
        quantity: 6,
        watts: 425,
    },
    desiredBackupHours: 4,
    systemType: 'hybrid',
};
exports.kits = {
    'Flash Services': {
        systemType: 'storage',
        panel: {
            quantity: 0,
            watts: 0,
        },
        desiredBackupHours: 4,
    },
    'Spark Services': {
        systemType: 'storage',
        panel: {
            quantity: 0,
            watts: 0,
        },
        desiredBackupHours: 4,
    },
    'Flash Plus Services': {
        systemType: 'hybrid',
        panel: {
            quantity: 6,
            watts: 425,
        },
        desiredBackupHours: 4,
    },
    'Spark Plus Services': {
        systemType: 'hybrid',
        panel: {
            quantity: 8,
            watts: 425,
        },
        desiredBackupHours: 4,
    },
    'Glow Services': {
        systemType: 'hybrid',
        panel: {
            quantity: 10,
            watts: 555,
        },
        desiredBackupHours: 4,
    },
    'Shine Services': {
        systemType: 'hybrid',
        panel: {
            quantity: 12,
            watts: 555,
        },
        desiredBackupHours: 4,
    },
};
var getKitConfig = function (_a) {
    var kitName = _a.kitName, _b = _a.config, config = _b === void 0 ? exports.defaultConfig : _b;
    var matchedKit = exports.kits[kitName];
    if (matchedKit === null) {
        return config;
    }
    return matchedKit;
};
exports.getKitConfig = getKitConfig;
exports.DEFAULT_ROOF_TYPE = 'tile';
