"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mountingConsumableQuerySchema = exports.mountingConsumableUpdateSchema = exports.mountingConsumableWriteSchema = exports.mountingConsumableReadSchema = exports.mountingConsumableSchema = void 0;
var typebox_1 = require("@feathersjs/typebox");
var consumable_types_1 = require("./consumable.types");
// type TLoadEager = 'consumables';
var loadEagerSchema = (0, typebox_1.StringEnum)(['consumables']);
exports.mountingConsumableSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    quantity: typebox_1.Type.Number(),
    consumableId: typebox_1.Type.String(),
    systemDesignId: typebox_1.Type.String(),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.mountingConsumableReadSchema = typebox_1.Type.Intersect([
    exports.mountingConsumableSchema,
    typebox_1.Type.Object({
        consumable: typebox_1.Type.Optional(consumable_types_1.consumableSchema),
    }),
]);
exports.mountingConsumableWriteSchema = typebox_1.Type.Omit(exports.mountingConsumableReadSchema, ['id', 'createdAt', 'updatedAt']);
exports.mountingConsumableUpdateSchema = typebox_1.Type.Partial(exports.mountingConsumableSchema, {
    $id: 'MountingConsumableUpdate',
});
var mountingConsumableQueryProperties = typebox_1.Type.Partial(exports.mountingConsumableSchema, {
    $id: 'MountingConsumableQuery',
});
exports.mountingConsumableQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(mountingConsumableQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({
        $loadEager: typebox_1.Type.Optional(typebox_1.Type.Array(loadEagerSchema)),
    }, { additionalProperties: false }),
], { additionalProperties: false });
