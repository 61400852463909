"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// @mui
const styles_1 = require("@mui/material/styles");
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Autocomplete = (theme) => {
    const isLight = theme.palette.mode === 'light';
    const boxShadow = `0 0 2px 0 ${(0, styles_1.alpha)(isLight ? theme.palette.grey[500] : theme.palette.common.black, 0.2)}, ${theme.customShadows.z20}`;
    return {
        MuiAutocomplete: {
            styleOverrides: {
                paper: {
                    boxShadow,
                },
                listbox: {
                    padding: theme.spacing(0, 1),
                    '& .MuiAutocomplete-option': {
                        padding: theme.spacing(1),
                        margin: theme.spacing(1, 0),
                        borderRadius: theme.shape.borderRadius,
                    },
                },
            },
        },
    };
};
exports.default = Autocomplete;
