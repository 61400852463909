const getBase64Payload = (accessToken: string) => {
	try {
		return accessToken.split('.')[1];
	} catch (e) {
		throw new Error('Invalid accessToken');
	}
};

const decodeBase64Payload = (base64Payload: string) => {
	try {
		return atob(base64Payload);
	} catch (e) {
		throw new Error('Invalid Base64 Payload');
	}
};

const getExpirationTimeFromDecodedPayload = (decodedPayload: string) => {
	try {
		return JSON.parse(decodedPayload).exp;
	} catch (e) {
		throw new Error('Invalid Decoded Payload');
	}
};

export const getExpirationTime = (accessToken: string) => {
	// Extract the payload from the accessToken
	const base64Payload = getBase64Payload(accessToken);

	// Decode the payload and parse the JSON
	const decodedPayload = decodeBase64Payload(base64Payload);

	return getExpirationTimeFromDecodedPayload(decodedPayload);
};

export const isValidToken = (accessToken: string): boolean => {
	const expirationTime = getExpirationTime(accessToken);

	return typeof expirationTime === 'number' && expirationTime > 0;
};
