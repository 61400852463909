"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.subscriptionRequestsWriteSchema = exports.subscriptionRequestReadSchema = exports.SystemDataSchema = exports.PropertySchema = exports.ConfigurationSchema = exports.AddedCostsSchema = exports.MonthlyPaymentSchema = exports.PaymentInfoSchema = exports.Status = exports.SUBSCRIPTION_STATES = exports.ANNUAL_INCREASE = exports.DEFAULT_CREDIT_SCORE = exports.DEFAULT_TERM_IN_MONTHS = void 0;
var typebox_1 = require("@feathersjs/typebox");
exports.DEFAULT_TERM_IN_MONTHS = 60;
exports.DEFAULT_CREDIT_SCORE = 620;
exports.ANNUAL_INCREASE = 7.5;
exports.SUBSCRIPTION_STATES = [
    'system information submitted',
    'interested',
    'not interested',
    'pending',
];
exports.Status = typebox_1.Type.Optional(typebox_1.Type.Union(exports.SUBSCRIPTION_STATES.map(function (n) { return typebox_1.Type.Literal(n); })));
exports.PaymentInfoSchema = typebox_1.Type.Array(typebox_1.Type.Object({
    month: typebox_1.Type.Number(),
    payment: typebox_1.Type.Number(),
}));
exports.MonthlyPaymentSchema = typebox_1.Type.Object({
    withPriceLock: typebox_1.Type.Object({
        monthlyPayments: exports.PaymentInfoSchema,
    }),
    withoutPriceLock: typebox_1.Type.Object({
        monthlyPayments: exports.PaymentInfoSchema,
    }),
});
exports.AddedCostsSchema = typebox_1.Type.Optional(typebox_1.Type.Array(typebox_1.Type.Object({
    name: typebox_1.Type.String(),
    cost: typebox_1.Type.Number(),
})));
exports.ConfigurationSchema = typebox_1.Type.Object({
    priceLock: typebox_1.Type.Boolean(),
    inputCost: typebox_1.Type.Number(),
    termInMonths: typebox_1.Type.Optional(typebox_1.Type.Number()),
    phase: typebox_1.Type.Union([typebox_1.Type.Literal('single'), typebox_1.Type.Literal('3-phase')]),
    breakerSize: typebox_1.Type.Number(),
});
exports.PropertySchema = typebox_1.Type.Object({
    address: typebox_1.Type.String(),
    city: typebox_1.Type.String(),
    province: typebox_1.Type.String(),
    postalCode: typebox_1.Type.String(),
});
exports.SystemDataSchema = typebox_1.Type.Object({
    phoneNumber: typebox_1.Type.String(),
    email: typebox_1.Type.String(),
    proRataWaived: typebox_1.Type.Boolean(),
    activationFeeWaived: typebox_1.Type.Boolean(),
    subscriptionMonthsWaived: typebox_1.Type.Integer(),
    batteryBrand: typebox_1.Type.String(),
    batteryModel: typebox_1.Type.String(),
    batteryQuantity: typebox_1.Type.Integer(),
    inverterBrand: typebox_1.Type.String(),
    inverterModel: typebox_1.Type.String(),
    inverterQuantity: typebox_1.Type.Integer(),
    panelBrand: typebox_1.Type.String(),
    panelModel: typebox_1.Type.String(),
    panelQuantity: typebox_1.Type.Integer(),
});
exports.subscriptionRequestReadSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    // Data for generation of request
    configuration: exports.ConfigurationSchema,
    property: typebox_1.Type.Optional(exports.PropertySchema),
    // Calculated Data
    totalCost: typebox_1.Type.Optional(typebox_1.Type.Number()),
    activationFee: typebox_1.Type.Optional(typebox_1.Type.Number()),
    monthlyPayments: typebox_1.Type.Optional(exports.PaymentInfoSchema),
    addedCosts: typebox_1.Type.Optional(exports.AddedCostsSchema),
    // Status
    status: exports.Status,
    // Requestor data
    source: typebox_1.Type.String(),
    requestorSourceId: typebox_1.Type.String(),
    // Relationship
    creditProfileId: typebox_1.Type.String(),
    // System data
    systemData: typebox_1.Type.Optional(exports.SystemDataSchema),
    // Ad-hoc data
    createdAt: typebox_1.Type.String({ format: 'date-time' }),
    updatedAt: typebox_1.Type.String({ format: 'date-time' }),
}, { $id: 'SubscriptionRequests', additionalProperties: false });
exports.subscriptionRequestsWriteSchema = typebox_1.Type.Omit(exports.subscriptionRequestReadSchema, ['id', 'createdAt', 'updatedAt'], {
    $id: 'SubscriptionRequestsData',
});
