"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.documentQuerySchema = exports.documentUpdateSchema = exports.documentWriteSchema = exports.documentReadSchema = exports.documentSchema = exports.jsonDocumentSchema = exports.DOCUMENT_ENTITY_NAMES = exports.DOMAINS = exports.ADVISOR_DOCUMENTS = exports.EPKIT_DOCUMENTS = exports.DOCUMENT_TYPES = void 0;
var typebox_1 = require("@feathersjs/typebox");
exports.DOCUMENT_TYPES = [
    'ecommerce',
    'proposal pdf',
    'engineered proposal pdf',
    'installer ep pdf',
    'invoice pdf',
    'sseg pdf',
    'issue picture',
];
exports.EPKIT_DOCUMENTS = [
    'db-board',
    'monthly-bill',
    'panel-layout',
];
exports.ADVISOR_DOCUMENTS = [
    'invoice',
    'receipt',
    'resolution picture',
    'issue picture',
    'invoice pdf',
    'user manual',
    'warranty',
    'specification',
    'sseg pdf',
    'photographic evidence (installed batteries)',
    'photographic evidence (installed distribution board)',
    'photographic evidence (installed inverter)',
    'photographic evidence (installed panels)',
    'photographic evidence of plant sharing',
    'post-installation certification of compliance (COC)',
    'commissioning inspection report',
    'installation sign off document',
    'pod',
];
exports.DOMAINS = [
    'Client',
    'Technical',
    'Supply Side',
    'Fincancial Side',
];
exports.DOCUMENT_ENTITY_NAMES = ['battery', 'inverter', 'panel'];
exports.jsonDocumentSchema = typebox_1.Type.Object({
    type: (0, typebox_1.StringEnum)(__spreadArray([], exports.DOCUMENT_TYPES, true)),
    name: typebox_1.Type.String(),
    url: typebox_1.Type.String(),
});
exports.documentSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    name: typebox_1.Type.String(),
    type: (0, typebox_1.StringEnum)(__spreadArray(__spreadArray(__spreadArray([], exports.DOCUMENT_TYPES, true), exports.EPKIT_DOCUMENTS, true), exports.ADVISOR_DOCUMENTS, true)),
    uri: typebox_1.Type.Optional(typebox_1.Type.String()),
    sizeKb: typebox_1.Type.Optional(typebox_1.Type.Number()),
    documentPath: typebox_1.Type.String(),
    fileType: typebox_1.Type.Optional(typebox_1.Type.String()),
    // Relationships
    organisationId: typebox_1.Type.Optional(typebox_1.Type.String()),
    userId: typebox_1.Type.Optional(typebox_1.Type.String()),
    projectId: typebox_1.Type.Optional(typebox_1.Type.String()),
    propertyId: typebox_1.Type.Optional(typebox_1.Type.String()),
    engineeredProposalId: typebox_1.Type.Optional(typebox_1.Type.String()),
    entityName: typebox_1.Type.Optional((0, typebox_1.StringEnum)(__spreadArray([], exports.DOCUMENT_ENTITY_NAMES, true))),
    entityId: typebox_1.Type.Optional(typebox_1.Type.String()),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.documentReadSchema = typebox_1.Type.Omit(exports.documentSchema, []);
exports.documentWriteSchema = typebox_1.Type.Omit(exports.documentReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.documentUpdateSchema = typebox_1.Type.Partial(exports.documentSchema, {
    $id: 'DocumentUpdate',
});
var documentQueryProperties = typebox_1.Type.Partial(exports.documentSchema, {
    $id: 'DocumentQuery',
});
exports.documentQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(documentQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
