"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Skeleton = (theme) => {
    return {
        MuiSkeleton: {
            defaultProps: {
                animation: 'wave',
            },
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.background.neutral,
                },
            },
        },
    };
};
exports.default = Skeleton;
