"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var skyworthenergy = {
    theme: 'skyworthenergy',
    brand: {
        name: 'Skyworth Energy',
        legalName: 'Skyworth Energy',
        logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/skyworth/skyworth-energy.png',
        icon: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/skyworth/skyworth-energy.png',
    },
    financePartners: [
        {
            name: 'Nedbank',
            logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/Nedbank.png',
        },
        {
            name: 'MFC',
            logo: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/NEDBANK_MFC_Primary%20endorsed%20identity_RGB_Eng_NoMargin.png',
        },
        {
            name: 'Glint',
            logo: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/glint/glint-logo.png',
        },
    ],
    campaignPartners: [
        {
            name: 'Skyworth Energy',
            logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/skyworth/skyworth-energy.png',
        },
    ],
    pages: {
        signup: {
            sections: {
                titleSection: {
                    title: "From start to solar, we've got your back.",
                },
            },
        },
    },
    poweredBy: {
        name: 'Hohm Energy',
        legalName: 'Hohm Services (Pty) Ltd.',
        logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/general/HohmEnergyLogoOrange.png',
    },
    partner: {
        name: 'Hohm Energy',
        legalName: 'Hohm Services (Pty) Ltd.',
        logo: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/general/HohmEnergyLogoOrange.png',
    },
    packages: {
        hohmPdf: {
            customBookACall: false,
            hideBrandLogo: false,
            hideFinancePages: false,
            hideInvoicePage: false,
        },
    },
    financeOptions: [
        {
            label: 'Solar or battery subscription',
            value: 'glint',
            default: true,
        },
        {
            label: 'Cash',
            value: 'cash',
        },
        {
            label: 'FNB Solar Finance',
            value: 'fnb-solar-finance',
        },
    ],
};
exports.default = skyworthenergy;
