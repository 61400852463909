import React from 'react';
import NumberFormat, {
	InputAttributes,
	NumberFormatProps,
} from 'react-number-format';
// @local
import { Input } from './Input';

interface ICustomProps {
	name: string;
	onChange: (event: { target: { name: string; value: string } }) => void;
}

export const numberFormattedInput = React.forwardRef<
	NumberFormat<InputAttributes>,
	ICustomProps & NumberFormatProps
>(({ onChange, name, ...others }, ref) => (
	<NumberFormat
		getInputRef={ref}
		onValueChange={({ value }) => {
			onChange({
				target: {
					name,
					value,
				},
			});
		}}
		thousandSeparator
		isNumericString
		{...others}
	/>
));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const NumberInput = ({ InputProps, ...props }: any) => (
	<Input
		fullWidth
		InputProps={{
			inputComponent: numberFormattedInput,
			...InputProps,
		}}
		{...props}
	/>
);
