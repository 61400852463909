"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// @local
// import {
// 	TreeViewCollapseIcon,
// 	TreeViewExpandIcon,
// 	TreeViewEndIcon,
// } from '../../../icons';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TreeView = (theme) => {
    return {
        MuiTreeView: {
            defaultProps: {
            // defaultCollapseIcon: (
            // 	<TreeViewCollapseIcon sx={{ width: 20, height: 20 }} />
            // ),
            // defaultExpandIcon: (
            // 	<TreeViewExpandIcon sx={{ width: 20, height: 20 }} />
            // ),
            // defaultEndIcon: (
            // 	<TreeViewEndIcon
            // 		sx={{ color: 'text.secondary', width: 20, height: 20 }}
            // 	/>
            // ),
            },
        },
        MuiTreeItem: {
            styleOverrides: {
                label: Object.assign({}, theme.typography.body2),
                iconContainer: { width: 'auto' },
            },
        },
    };
};
exports.default = TreeView;
