"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// @local
// import { CloseIcon } from '../../../icons';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Chip = (theme) => {
    return {
        MuiChip: {
            defaultProps: {
            // deleteIcon: <CloseIcon />,
            },
            styleOverrides: {
            // 	avatarColorInfo: {
            // 		color: theme.palette.info.contrastText,
            // 		backgroundColor: theme.palette.info.dark
            // 	},
            // avatarColorSuccess: {
            // 	color: 'white',
            // 	backgroundColor: theme.palette.success.dark
            // }
            // 	avatarColorWarning: {
            // 		color: theme.palette.warning.contrastText,
            // 		backgroundColor: theme.palette.warning.dark
            // 	},
            // 	avatarColorError: {
            // 		color: theme.palette.error.contrastText,
            // 		backgroundColor: theme.palette.error.dark
            // 	}
            },
        },
    };
};
exports.default = Chip;
