"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const themeOptions = {
    palette: {
        primary: {
            lighter: '#231f20',
            light: '#231f20',
            dark: '#231f20',
            darker: '#231f20',
            main: '#231f20',
            contrastText: '#FFFFFF',
        },
        secondary: {
            lighter: '#3AA445',
            light: '#3AA445',
            dark: '#3AA445',
            darker: '#3AA445',
            main: '#3AA445',
            contrastText: '#FFFFFF',
        },
    },
    shape: {
        borderRadius: 4,
    },
};
exports.default = themeOptions;
