"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CONSUMER_TYPES = exports.panelMappingSchema = exports.SYSTEM_TYPES = exports.pointSchema = exports.coordinateSchema = exports.IOverlayType = exports.IUserTypeComponents = void 0;
var typebox_1 = require("@feathersjs/typebox");
var IUserTypeComponents;
(function (IUserTypeComponents) {
    IUserTypeComponents["super"] = "super";
    IUserTypeComponents["client"] = "client";
    IUserTypeComponents["epc"] = "epc";
    IUserTypeComponents["advisor"] = "energy-advisor";
    // eslint-disable-next-line @typescript-eslint/naming-convention
    IUserTypeComponents["investecAdvisor"] = "investec-advisor";
})(IUserTypeComponents || (exports.IUserTypeComponents = IUserTypeComponents = {}));
var IOverlayType;
(function (IOverlayType) {
    IOverlayType["CIRCLE"] = "circle";
    IOverlayType["MARKER"] = "marker";
    IOverlayType["POLYGON"] = "polygon";
    IOverlayType["POLYLINE"] = "polyline";
    IOverlayType["RECTANGLE"] = "rectangle";
})(IOverlayType || (exports.IOverlayType = IOverlayType = {}));
exports.coordinateSchema = typebox_1.Type.Tuple([typebox_1.Type.Number(), typebox_1.Type.Number()]);
exports.pointSchema = typebox_1.Type.Object({
    type: typebox_1.Type.Literal('Point'),
    coordinates: exports.coordinateSchema,
});
exports.SYSTEM_TYPES = ['hybrid', 'grid-tied', 'storage'];
exports.panelMappingSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Optional(typebox_1.Type.String()),
    rotationAngle: typebox_1.Type.Optional(typebox_1.Type.Number()),
    coords: typebox_1.Type.Array(typebox_1.Type.Object({
        lat: typebox_1.Type.Number(),
        lng: typebox_1.Type.Number(),
    }), {
        minItems: 4,
        maxItems: 4,
    }),
});
exports.CONSUMER_TYPES = [
    'residential',
    'CIA', // commercial, industrial, agricultural
];
