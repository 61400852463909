"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.certificateQuerySchema = exports.certificateUpdateSchema = exports.certificateWriteSchema = exports.certificateReadSchema = exports.certificateSchema = void 0;
var typebox_1 = require("@feathersjs/typebox");
exports.certificateSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    type: typebox_1.Type.String(),
    issueDate: typebox_1.Type.Date(),
    expiryDate: typebox_1.Type.Date(),
    status: typebox_1.Type.String(),
    fileUrl: typebox_1.Type.String(),
    organisationId: typebox_1.Type.String(),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.certificateReadSchema = typebox_1.Type.Omit(exports.certificateSchema, []);
exports.certificateWriteSchema = typebox_1.Type.Omit(exports.certificateReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.certificateUpdateSchema = typebox_1.Type.Partial(exports.certificateSchema, {
    $id: 'CertificateUpdate',
});
var certificateQueryProperties = typebox_1.Type.Partial(exports.certificateSchema, {
    $id: 'CertificateQuery',
});
exports.certificateQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(certificateQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
