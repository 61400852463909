import isString from 'lodash/isString';
import { ReactNode } from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import { Icon } from '@iconify/react';
// @mui
import { Box, Typography, Paper, SxProps } from '@mui/material';
import { alpha, Theme, styled } from '@mui/material/styles';
// @utilities
import { FormatNumber } from 'hohm-utilities';
// @local
import { Image } from '../data-display/Image';

const RootStyle = styled('div')(({ theme }) => ({
	width: 144,
	height: 144,
	margin: 'auto',
	borderRadius: '50%',
	padding: theme.spacing(1),
	border: `1px dashed ${theme.palette.grey[500]}`,
}));

const DropZoneStyle = styled('div')({
	zIndex: 0,
	width: '100%',
	height: '100%',
	outline: 'none',
	display: 'flex',
	overflow: 'hidden',
	borderRadius: '50%',
	position: 'relative',
	alignItems: 'center',
	justifyContent: 'center',
	'& > *': { width: '100%', height: '100%' },
	'&:hover': {
		cursor: 'pointer',
		'& .placeholder': {
			zIndex: 9,
		},
	},
});

const PlaceholderStyle = styled('div')(({ theme }) => ({
	display: 'flex',
	position: 'absolute',
	alignItems: 'center',
	flexDirection: 'column',
	justifyContent: 'center',
	color: theme.palette.text.secondary,
	// backgroundColor: theme.palette.background.neutral,
	transition: theme.transitions.create('opacity', {
		easing: theme.transitions.easing.easeInOut,
		duration: theme.transitions.duration.shorter,
	}),
	'&:hover': { opacity: 0.72 },
}));

interface ICustomFile extends File {
	path?: string;
	preview?: string;
}

export type TUploadAvatarProps = DropzoneOptions & {
	error?: boolean;
	file: ICustomFile | string | null;
	caption?: ReactNode;
	sx?: SxProps<Theme>;
};
export const UploadAvatar = ({
	error,
	file,
	caption,
	sx,
	...other
}: TUploadAvatarProps) => {
	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragReject,
		fileRejections,
	} = useDropzone({
		multiple: false,
		...other,
	});

	function ShowRejectionItems() {
		return (
			<Paper
				variant="outlined"
				sx={{
					py: 1,
					px: 2,
					my: 2,
					borderColor: 'error.light',
					bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
				}}
			>
				{fileRejections.map(({ file: newFile, errors }) => {
					const { path, size }: ICustomFile = newFile;

					return (
						<Box key={path} sx={{ my: 1 }}>
							<Typography variant="subtitle2" noWrap>
								{path} - {FormatNumber.fData(size)}
							</Typography>
							{errors.map((e) => (
								<Typography
									key={e.code}
									variant="caption"
									component="p"
								>
									- {e.message}
								</Typography>
							))}
						</Box>
					);
				})}
			</Paper>
		);
	}

	return (
		<>
			<RootStyle sx={sx}>
				<DropZoneStyle
					{...getRootProps()}
					sx={{
						...(isDragActive && { opacity: 0.72 }),
						...((isDragReject || error) && {
							color: 'error.main',
							borderColor: 'error.light',
							bgcolor: 'error.lighter',
						}),
					}}
				>
					<input {...getInputProps()} />

					{file && (
						<Image
							alt="avatar"
							src={isString(file) ? file : file.preview}
							sx={{ zIndex: 8 }}
						/>
					)}

					<PlaceholderStyle
						className="placeholder"
						sx={{
							...(file && {
								opacity: 0,
								color: 'common.white',
								bgcolor: 'grey.900',
								'&:hover': { opacity: 0.72 },
							}),
						}}
					>
						<Icon
							icon="ic:round-add-a-photo"
							style={{ width: 24, height: 24, marginBottom: 1 }}
						/>
						<Typography variant="caption">
							{file ? 'Update photo' : 'Upload photo'}
						</Typography>
					</PlaceholderStyle>
				</DropZoneStyle>
			</RootStyle>

			{caption}

			{fileRejections.length > 0 && <ShowRejectionItems />}
		</>
	);
};
