import * as React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

export interface IInformationalBlockProps {
	title: string;
	description: string;
	image: string; // could be file or url
}

export const InformationalBlock = ({
	title,
	description,
	image,
}: IInformationalBlockProps) => {
	const theme = useTheme();

	return (
		<Box
			sx={{
				alignSelf: 'stretch',
				borderRadius: '20px',
				backgroundColor: theme.palette.grey[200],
				display: 'flex',
				height: '100%',
				flexDirection: 'column',
				padding: '30px 30px 50px',
				marginBottom: theme.spacing(2),
			}}
		>
			<img
				loading="lazy"
				sizes="(max-width: 638px) 15vw, (max-width: 998px) 10vw, 7vw"
				src={image}
				alt="Informational Block Icon"
				style={{
					aspectRatio: '1',
					objectFit: 'contain',
					objectPosition: 'center',
					width: '90px',
				}}
			/>
			<Typography
				color="textPrimary"
				sx={{
					letterSpacing: '0.15px',
					marginTop: '10px',
					fontWeight: '700',
					fontSize: '20px',
					lineHeight: '134%',
					maxWidth: '300px',
				}}
			>
				{title}
			</Typography>
			<Typography
				color="textPrimary"
				sx={{
					letterSpacing: '0.15px',
					marginTop: '10px',
					fontWeight: '400',
					fontSize: '16px',
					lineHeight: '24px',
					maxWidth: '270px',
				}}
			>
				{description}
			</Typography>
		</Box>
	);
};
