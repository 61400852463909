"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const themeOptions = {
    palette: {
        primary: {
            lighter: '#223143',
            light: '#223143',
            dark: '#223143',
            darker: '#223143',
            main: '#223143',
            contrastText: '#FFFFFF',
        },
        secondary: {
            lighter: '#16202B',
            light: '#16202B',
            main: '#16202B',
            dark: '#16202B',
            darker: '#16202B',
            contrastText: '#FFFFFF',
        },
    },
};
exports.default = themeOptions;
