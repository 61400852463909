'use client';

import { useState, useEffect } from 'react';
// @mui
import {
	DialogTitle,
	DialogContentText,
	DialogContent,
	DialogActions,
	Dialog,
	Button,
	Autocomplete,
	TextField,
} from '@mui/material';
// types
import { CountryCodes } from 'hohm-types';
// @local
import { DialogTransition } from '../utils/DialogTransition';
import { TLocationDialogProps } from './utils/types';

export type { TLocationDialogProps };
export const LocationDialog = ({
	open,
	handleClose,
	myCountryCode,
	confirmLocation,
}: TLocationDialogProps) => {
	const [value, setValue] = useState<CountryCodes.ICountry | null>(null);
	const [inputValue, setInputValue] = useState<string>('');

	useEffect(() => {
		if (myCountryCode) {
			const countryObject =
				CountryCodes.getCountryObjectByCountryCode(myCountryCode);

			setValue(countryObject as CountryCodes.ICountry | null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [myCountryCode]);

	const confirmCountry = () => {
		confirmLocation(value?.code as CountryCodes.TCountryCode);
		handleClose();
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			keepMounted
			TransitionComponent={DialogTransition}
		>
			<DialogTitle>Please indicate your current Location</DialogTitle>
			<DialogContent>
				<DialogContentText>
					We need to know your location to help you find the best
					advisor for you.
				</DialogContentText>
				<Autocomplete
					sx={{ mt: 2 }}
					value={value}
					onChange={(
						event: unknown,
						newValue: CountryCodes.ICountry | null
					) => {
						setValue(newValue);
					}}
					inputValue={inputValue}
					onInputChange={(event, newInputValue) => {
						setInputValue(newInputValue);
					}}
					getOptionLabel={(option) => option.name}
					options={CountryCodes.SUPPORTED_COUNTRIES}
					renderInput={(params) => (
						<TextField {...params} label="Country" />
					)}
				/>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" onClick={confirmCountry}>
					Accept
				</Button>
			</DialogActions>
		</Dialog>
	);
};
