"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFromQuery = exports.buildUrl = exports.addProtocol = void 0;
// this function will add missing URL Protocol prefix if needed
var addProtocol = function (_a) {
    var url = _a.url, _b = _a.protocol, protocol = _b === void 0 ? 'https' : _b;
    return "".concat((url === null || url === void 0 ? void 0 : url.startsWith(protocol)) ? '' : "".concat(protocol, "://")).concat(url);
};
exports.addProtocol = addProtocol;
var buildUrl = function (_a) {
    var pathname = _a.pathname, query = _a.query;
    return "".concat(pathname, "/").concat(query
        ? "?".concat(Object.entries(query)
            .map(function (_a) {
            var key = _a[0], val = _a[1];
            return "".concat(key, "=").concat(val);
        })
            .join('&'))
        : '');
};
exports.buildUrl = buildUrl;
var getFromQuery = function (_a) {
    var key = _a.key, search = _a.search, _b = _a.defaultValue, defaultValue = _b === void 0 ? '' : _b;
    try {
        var paramsString = search || window.location.search;
        var searchParams = new URLSearchParams(paramsString);
        var loadCampaign = searchParams.get(key) || defaultValue;
        return loadCampaign;
    }
    catch (e) {
        return defaultValue;
    }
};
exports.getFromQuery = getFromQuery;
