import * as React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
// @mui
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export interface ICarouselProps {
	children: React.ReactNode;
}

export const Carousel = ({ children }: ICarouselProps) => {
	const [activeStep, setActiveStep] = React.useState(0);

	const theme = useTheme();
	const maxSteps = React.Children.count(children);
	const isRTL = theme.direction === 'rtl';

	const handleNext = () => {
		setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
	};

	const handleBack = () => {
		setActiveStep(
			(prevActiveStep) => (prevActiveStep - 1 + maxSteps) % maxSteps
		);
	};

	const handleStepChange = (step: number) => {
		setActiveStep(step);
	};

	return (
		<Grid
			container
			justifyContent="center"
			direction="column"
			sx={{
				overflowX: 'hidden',
			}}
		>
			<AutoPlaySwipeableViews
				axis={isRTL ? 'x-reverse' : 'x'}
				index={activeStep}
				onChangeIndex={handleStepChange}
				enableMouseEvents
			>
				{children}
			</AutoPlaySwipeableViews>
			{maxSteps > 1 && (
				<MobileStepper
					steps={maxSteps}
					position="static"
					activeStep={activeStep}
					style={{
						backgroundColor: 'transparent',
						transform: 'translateY(-100%)',
						maxWidth: '100%',
						height: '100%',
					}}
					nextButton={
						<Button
							size="small"
							onClick={handleNext}
							disabled={maxSteps < 2}
						>
							{isRTL ? (
								<KeyboardArrowLeft />
							) : (
								<KeyboardArrowRight />
							)}
						</Button>
					}
					backButton={
						<Button
							size="small"
							onClick={handleBack}
							disabled={maxSteps < 2}
						>
							{isRTL ? (
								<KeyboardArrowRight />
							) : (
								<KeyboardArrowLeft />
							)}
						</Button>
					}
				/>
			)}
		</Grid>
	);
};
