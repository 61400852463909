"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const themeOptions = {
    palette: {
        primary: {
            lighter: '#7FBB59',
            light: '#7FBB59',
            dark: '#7FBB59',
            darker: '#7FBB59',
            main: '#7FBB59',
            contrastText: '#FFFFFF',
        },
        secondary: {
            lighter: '#575757',
            light: '#575757',
            dark: '#575757',
            darker: '#575757',
            main: '#575757',
            contrastText: '#FFFFFF',
        },
    },
    shape: {
        borderRadius: 6,
    },
};
exports.default = themeOptions;
