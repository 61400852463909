'use client';

import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	IconButton,
	CircularProgress,
	Stack,
	Typography,
	Divider,
	Box,
	BoxProps,
} from '@mui/material';
// @icons
import {
	Delete as DeleteIcon,
	CloudUpload as CloudUploadIcon,
	CheckCircle as CheckCircleIcon,
} from '@mui/icons-material';
// utilities
import { Upload, logger } from 'hohm-utilities';
// @local
import { toast } from 'react-toastify';
import { Button } from '../Button';

export interface IFile {
	url: string;
	name: string;
}

interface IFileMetadata {
	file: File;
	preview?: string;
	progress?: number | null;
	isUploading?: boolean;
}

const UploadedFile = () => (
	<Stack direction="row" alignItems="center" spacing={1}>
		<Typography>Uploaded</Typography>
		<CheckCircleIcon
			style={{
				color: 'green',
				marginRight: '4px',
			}}
		/>
	</Stack>
);

const UploadingFile = () => (
	<Stack direction="row" spacing={1} alignItems="center">
		<Typography>Uploading...</Typography>
		<CircularProgress size={16} variant="indeterminate" color="secondary" />
	</Stack>
);

const InProgressFile = ({ metadata }: { metadata: IFileMetadata }) => (
	<div>
		{`${Math.round(metadata.file.size / 1024)} KB (${
			metadata.progress || 0
		}%)`}
	</div>
);

export interface IDropzoneProps extends BoxProps {
	onUpload: (file: { name: string; url: string }) => void;
	files: IFile[];
	jwt: Promise<string | null>;
	maxFiles?: number;
}

export const Dropzone = ({
	files,
	onUpload,
	jwt,
	maxFiles = 1,
	...rest
}: IDropzoneProps) => {
	const [fileMetadatas, setFileMetadatas] = useState<IFileMetadata[]>([]);
	const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);

	const onDrop = useCallback((acceptedFiles: File[]) => {
		if (
			maxFiles &&
			fileMetadatas.length + acceptedFiles.length > maxFiles
		) {
			toast.warning(`Maximum ${maxFiles} file(s) allowed.`);

			// reset file meta datas
			setFileMetadatas([]);

			return;
		}

		setFileMetadatas((prevMetadatas) => [
			...prevMetadatas,
			...acceptedFiles.map((file) => ({
				file,
				preview: URL.createObjectURL(file),
				progress: null,
				isUploading: false,
			})),
		]);
	}, []);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		maxFiles: 10,
		maxSize: 10 * 1024 * 1024, // 10MB
	});

	const handleRemove = (metadataToRemove: IFileMetadata) => {
		setFileMetadatas((prevMetadatas) =>
			prevMetadatas.filter(
				(metadata) => metadata.file !== metadataToRemove.file
			)
		);
		if (metadataToRemove.preview) {
			URL.revokeObjectURL(metadataToRemove.preview); // Free up memory
		}
	};

	const uploadBill = async (
		file: File,
		setProgress: (progress: number | null) => void
	): Promise<boolean> => {
		const result = (await Upload.uploadPicture({
			file,
			progressUpdate: (progress) => {
				setProgress(progress);
			},
			token: (await jwt) || '',
		})) as {
			success: boolean;
			url: string;
		};

		if (result.success === true) {
			onUpload({ name: file.name, url: result.url });
		} else {
			logger.log('Error uploading picture', result);
		}

		return result.success;
	};

	const uploadAllFiles = async () => {
		const uploadPromises = fileMetadatas.map(async (metadata) => {
			// Set isUploading to true when starting the upload
			setFileMetadatas((prevMetadatas) => {
				return prevMetadatas.map((m) =>
					m.file.name === metadata.file.name
						? { ...m, progress: 0, isUploading: true } // Set isUploading to true
						: m
				);
			});

			const success = await uploadBill(metadata.file, (progressValue) => {
				setFileMetadatas((prevMetadatas) => {
					return prevMetadatas.map((m) =>
						m.file.name === metadata.file.name
							? { ...m, progress: progressValue }
							: m
					);
				});
			});

			if (success) {
				setUploadedFiles((prevUploadedFiles) => [
					...prevUploadedFiles,
					metadata.file.name,
				]);
			}

			// Set isUploading to false when the upload finishes
			setFileMetadatas((prevMetadatas) => {
				return prevMetadatas.map((m) =>
					m.file.name === metadata.file.name
						? { ...m, isUploading: false } // Set isUploading to false
						: m
				);
			});
		});

		await Promise.all(uploadPromises);
	};

	return (
		<Box {...rest}>
			<div
				{...getRootProps()}
				style={{
					border: '1px dashed gray',
					padding: '20px',
					textAlign: 'center',
				}}
			>
				<input {...getInputProps()} />
				<CloudUploadIcon fontSize="large" />
				<p>Drag & drop some files here, or click to select files</p>
			</div>

			<List>
				{fileMetadatas
					.filter(
						(metadata) =>
							!uploadedFiles.includes(metadata.file.name)
					)
					.map((metadata) => (
						<ListItem key={metadata.file.name}>
							<ListItemText
								primary={metadata.file.name}
								secondary={
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
										}}
									>
										{metadata.progress === 100 &&
										metadata.isUploading ? (
											<UploadingFile />
										) : (
											<InProgressFile
												metadata={metadata}
											/>
										)}
									</div>
								}
							/>

							<ListItemSecondaryAction>
								<IconButton
									edge="end"
									onClick={() => handleRemove(metadata)}
								>
									<DeleteIcon />
								</IconButton>
							</ListItemSecondaryAction>
						</ListItem>
					))}

				<Divider />

				{files.map((uploadedFile) => (
					<ListItem key={uploadedFile.name}>
						<ListItemText
							primary={uploadedFile.name}
							secondary={
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<UploadedFile />
								</div>
							}
						/>
					</ListItem>
				))}
			</List>

			<Button
				fullWidth
				color="secondary"
				variant="outlined"
				onClick={uploadAllFiles}
			>
				Upload All
			</Button>
		</Box>
	);
};
