"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.utilityQuerySchema = exports.utilityUpdateSchema = exports.utilityWriteSchema = exports.utilityReadSchema = exports.utilitySchema = exports.allUtilities = exports.dayNightSplits = exports.utilityCategories = void 0;
var typebox_1 = require("@feathersjs/typebox");
exports.utilityCategories = [
    'general',
    'kitchen',
    'entertainment',
    'lighting',
    'bedrooms',
    'cooling',
    'heating',
    'pumps',
    'office',
    'all selected',
];
exports.dayNightSplits = [
    '10/90',
    '20/80',
    '30/70',
    '40/60',
    '50/50',
    '60/40',
    '70/30',
    '80/20',
    '90/10',
];
exports.allUtilities = [
    'plugs',
    'fridge',
    'chest freezer',
    'microwave',
    'toaster',
    'iron',
    'air fryer',
    'kettle',
    'washing machine',
    'washing dryer',
    'dish washer',
    'tv',
    'decoder',
    'wifi',
    'cellphone charger',
    'sound system',
    'gaming console',
    'led downlights',
    'led bulbs',
    'outdoor lights',
    'hair dryer',
    'hair straightener',
    'aircon',
    'fan',
    'geyser',
    'stove',
    'water heater',
    'heater',
    'pool pump',
    'bore hole',
    'irrigation',
    'pressure pump',
    'computer',
    'laptop',
    'printer',
];
exports.utilitySchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    name: (0, typebox_1.StringEnum)(__spreadArray([], exports.allUtilities, true)),
    watts: typebox_1.Type.Number(),
    category: (0, typebox_1.StringEnum)(__spreadArray([], exports.utilityCategories, true)),
    description: typebox_1.Type.Optional(typebox_1.Type.String()),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.utilityReadSchema = typebox_1.Type.Omit(exports.utilitySchema, []);
exports.utilityWriteSchema = typebox_1.Type.Omit(exports.utilityReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.utilityUpdateSchema = typebox_1.Type.Partial(exports.utilitySchema, {
    $id: 'UtilityUpdate',
});
var utilityQueryProperties = typebox_1.Type.Partial(exports.utilitySchema, {
    $id: 'UtilityQuery',
});
exports.utilityQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(utilityQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
