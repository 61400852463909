'use client';

import { BaseSyntheticEvent, SyntheticEvent, useState } from 'react';
// @mui
import { Autocomplete as MuiAutocomplete, InputAdornment } from '@mui/material';
// @local
import { Input } from './Input';
import { CircularProgress } from '../feedback/CircularProgress';
import { TAutocompleteProps } from './utils/types';

export type { TAutocompleteProps };
export const Autocomplete = <T,>({
	options = [],
	onChange,
	InputProps,
	noOptionsText = 'No options',
	...otherProps
}: TAutocompleteProps<SyntheticEvent, T>) => {
	const [open, setOpen] = useState(false);

	const {
		value,
		loading,
		disabled = false,
		popupIcon,
		placeholder = 'Type to search',
		defaultValue,
		getOptionLabel,
		isOptionEqualToValue,
	} = otherProps;

	const isDisabled = !!loading || disabled;

	const onChangeProps = onChange;

	const onBlur = () => {
		setOpen(false);
	};

	const toggleMenu = (event: BaseSyntheticEvent) => {
		const element = event?.target as HTMLInputElement;

		if (isDisabled) setOpen(false);
		else if (element.getAttribute('data-testid') === 'ArrowDropDownIcon') {
			setOpen((currOpenState) => !currOpenState);
		} else if (element?.value?.length > 0 || options?.length > 0)
			setOpen(true);
	};

	onChange = (event, newValue) => {
		if (onChangeProps) onChangeProps(event, newValue);
		setOpen(false);
	};

	const onInputChange = (event: SyntheticEvent<Element, Event>) => {
		const target = event?.target as HTMLInputElement;
		const currentValue = target?.value;

		setOpen(!((currentValue === '' && open) || !target));
	};

	const InputPropsData = {
		...InputProps,
		placeholder,
		onClickCapture: toggleMenu,
	};

	const customEvents = {
		onBlur,
		onFocus: toggleMenu,
		onChange,
		onInputChange,
	};

	return (
		<MuiAutocomplete
			{...{
				open,
				value,
				loading,
				options,
				disabled: isDisabled,
				defaultValue,
				noOptionsText,
				getOptionLabel,
				isOptionEqualToValue,
				...customEvents,
			}}
			fullWidth
			popupIcon={popupIcon ? undefined : ''}
			renderInput={(props) => (
				<Input
					{...props}
					InputProps={{
						...props.InputProps,
						...InputPropsData,
						endAdornment: (
							<>
								{loading ? (
									<InputAdornment position="end">
										<CircularProgress
											size={20}
											color="inherit"
										/>
									</InputAdornment>
								) : null}
								{props.InputProps.endAdornment}
							</>
						),
					}}
					InputLabelProps={{
						...props.InputLabelProps,
						shrink: true,
					}}
					{...otherProps}
				/>
			)}
			autoHighlight
		/>
	);
};
