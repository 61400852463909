"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var magnetoenergy = {
    theme: 'magnetoenergy',
    brand: {
        name: 'Magneto Energy',
        legalName: 'Magneto Energy',
        logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/MagnetoEnergy/Magneto-Renewable-Energy-Logo-White-background-v1-OL-01.png',
        icon: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/MagnetoEnergy/Magneto-Renewable-Energy-Logo-White-background-v1-OL-01.png',
    },
    financePartners: [],
    pages: {
        signup: {
            sections: {
                titleSection: {
                    title: "From start to solar, we've got your back.",
                },
            },
        },
    },
    partner: {
        name: 'Hohm Energy',
        legalName: 'Hohm Services (Pty) Ltd.',
        logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/general/HohmEnergyLogoOrange.png',
    },
    poweredBy: {
        name: 'Hohm Energy',
        legalName: 'Hohm Services (Pty) Ltd.',
        logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/general/HohmEnergyLogoOrange.png',
    },
    modules: {
        draftProposal: {
            enableRentalBanner: false,
        },
    },
    financeOptions: [
        {
            label: 'Cash',
            value: 'cash',
        },
        {
            label: 'Solar Finance',
            value: 'nedbank-mfc',
            default: true,
        },
        {
            label: 'Home Loan',
            value: 'home-loan',
        },
    ],
};
exports.default = magnetoenergy;
