"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.projectQuerySchema = exports.projectUpdateSchema = exports.projectWriteSchema = exports.projectReadSchema = exports.projectSchema = exports.PROJECT_TYPES = exports.DEAL_PROBABILITY = exports.hubspotProjectStageOrder = exports.HUBSPOT_UNMODIFIABLE_STAGES = exports.HUBSPOT_PROJECT_STAGES = exports.LOGS_TYPES = exports.PROJECT_EVENT_STAGES = void 0;
var typebox_1 = require("@feathersjs/typebox");
exports.PROJECT_EVENT_STAGES = [
    'Sign Up',
    'Initial Proposal',
    'Engineering Proposal',
    'Finance',
    'Quality Control',
    'Deployment',
];
// Centralize LogTypes to fix circular dependency
exports.LOGS_TYPES = [
    // stage 1
    'generated indicative proposal',
    'cohere reviewed',
    'followed up and offered assistance',
    'notes about clients completed',
    'site visit booked',
    'site visit drop off',
    // stage 2
    'financing process',
    // stage 3
    'indicative proposal notes reviewed',
    'generated initial engineering proposal',
    'panel layout created',
    'site visit conducted',
    'generated final engineering proposal',
    'EP accepted by engineer',
    'submitted to deployment',
    // stage 4
    'installation date confirmed',
    'project planning with internal engineer',
    'project plans signed off by client',
    'client invoiced',
    'stock from supplier procured',
    'project planning with installer',
    'project planning with client',
    'pre installation site visit',
    'installation',
    'snagging',
    'commissioning and sign off',
    // stage 5
    'hand over',
    'Q1 performance report',
    'Q2 performance report',
    'panel clean',
    'Q3 performance report',
    'Q4 performance report',
];
exports.HUBSPOT_PROJECT_STAGES = [
    'Client signed up',
    'Qualified lead',
    'VSV scheduled',
    'VSV conducted & EP to accept',
    'AF - Documents outstanding',
    'AF - Document submitted',
    'AF - Document sent to the bank',
    'AF - Client to accept finance',
    'GL - Contract to sign',
    'CA - Deposit to pay',
    'HL - Documents to send',
    'HL - Document submitted',
    'HL - Document sent to the bank',
    'HL - Client to accept finance',
    'HL - Client to pay deposit',
    'PSI booking',
    'PSI in progress',
    'COC remediation',
    'To approve final EP',
    'AF - Bank to approve',
    'AF - Client to sign contract',
    'AF - Awaiting bank payment',
    'CA and HL - Client to pay invoice',
    'GL - Client to pay initiation fee',
    'Payment confirmation',
    'COC Still Needed',
    'Procurement & Scheduling',
    'Installation in Progress',
    'Installation Completed',
    'CA and HL - To generate final invoice',
    'Handover',
    'Closed lost',
    'Closed won',
    'These are Tests. Ignore them.',
    'EPC deals',
    'Nurturing',
];
exports.HUBSPOT_UNMODIFIABLE_STAGES = [
    'AF - Bank to approve',
    'AF - Client to sign contract',
    'AF - Awaiting bank payment',
    'CA and HL - Client to pay invoice',
    'GL - Client to pay initiation fee',
    'Payment confirmation',
    'COC Still Needed',
    'Procurement & Scheduling',
    'Installation in Progress',
    'Installation Completed',
    'CA and HL - To generate final invoice',
    'Handover',
    'Closed lost',
    'Closed won',
    'These are Tests. Ignore them.',
    'EPC deals',
    'Nurturing',
];
exports.hubspotProjectStageOrder = {
    'Client signed up': 1,
    'Qualified lead': 2,
    'VSV scheduled': 3,
    'VSV conducted & EP to accept': 4,
    'AF - Documents outstanding': 5,
    'AF - Document submitted': 6,
    'AF - Document sent to the bank': 7,
    'AF - Client to accept finance': 8,
    'GL - Contract to sign': 9,
    'CA - Deposit to pay': 10,
    'HL - Documents to send': 11,
    'HL - Document submitted': 12,
    'HL - Document sent to the bank': 13,
    'HL - Client to accept finance': 14,
    'HL - Client to pay deposit': 15,
    'PSI booking': 16,
    'PSI in progress': 17,
    'COC remediation': 18,
    'To approve final EP': 19,
    'AF - Bank to approve': 20,
    'AF - Client to sign contract': 21,
    'AF - Awaiting bank payment': 22,
    'CA and HL - Client to pay invoice': 23,
    'GL - Client to pay initiation fee': 24,
    'Payment confirmation': 25,
    'COC Still Needed': 26,
    'Procurement & Scheduling': 27,
    'Installation in Progress': 28,
    'Installation Completed': 29,
    'CA and HL - To generate final invoice': 30,
    Handover: 31,
    'Closed lost': 32,
    'Closed won': 33,
    'These are Tests. Ignore them.': 34,
    'EPC deals': 35,
    Nurturing: 36,
};
exports.DEAL_PROBABILITY = [
    {
        label: 'Low',
        value: 50,
    },
    {
        label: 'Medium',
        value: 75,
    },
    {
        label: 'High',
        value: 90,
    },
    {
        label: 'Very High',
        value: 100,
    },
];
exports.PROJECT_TYPES = ['solar', 'storage'];
var loadEagerSchema = (0, typebox_1.StringEnum)([
    'indicativeProposalAccepted',
    'indicativeProposals',
    'engineeredProposals',
    'pricingManager',
    'installer',
    'property',
    'projectOwner',
]);
var hubspotDealFilesSchema = typebox_1.Type.Object({
    epPdfHubspotNoteId: typebox_1.Type.Optional(typebox_1.Type.String()),
    epPdfHubspotFileId: typebox_1.Type.Optional(typebox_1.Type.String()),
    ipPdfHubspotNoteId: typebox_1.Type.Optional(typebox_1.Type.String()),
    ipPdfHubspotFileId: typebox_1.Type.Optional(typebox_1.Type.String()),
});
exports.projectSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    status: (0, typebox_1.StringEnum)(__spreadArray([], exports.LOGS_TYPES, true)),
    droppedOff: typebox_1.Type.Boolean(),
    expectedCloseDate: typebox_1.Type.Optional(typebox_1.Type.Date()),
    dealProbability: typebox_1.Type.Optional(typebox_1.Type.Number()),
    budget: typebox_1.Type.Number(),
    stage: (0, typebox_1.StringEnum)(__spreadArray([], exports.PROJECT_EVENT_STAGES, true)),
    indicativeProposalsIds: typebox_1.Type.Array(typebox_1.Type.String()),
    indicativeProposalAcceptedId: typebox_1.Type.String(),
    engineeringQuoteAcceptedId: typebox_1.Type.Optional(typebox_1.Type.String()),
    projectOwnerId: typebox_1.Type.String(),
    pricingManagerId: typebox_1.Type.String(),
    propertyId: typebox_1.Type.String(),
    installerId: typebox_1.Type.String(),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
    hubspotDealId: typebox_1.Type.Optional(typebox_1.Type.String()),
    dealSegment: typebox_1.Type.Optional(typebox_1.Type.String()),
    hubspotStage: typebox_1.Type.Optional((0, typebox_1.StringEnum)(__spreadArray([], exports.HUBSPOT_PROJECT_STAGES, true))),
    hubspotDealFiles: hubspotDealFilesSchema,
});
exports.projectReadSchema = typebox_1.Type.Omit(exports.projectSchema, []);
exports.projectWriteSchema = typebox_1.Type.Omit(exports.projectReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.projectUpdateSchema = typebox_1.Type.Partial(exports.projectSchema, {
    $id: 'ProjectUpdate',
});
var projectQueryProperties = typebox_1.Type.Partial(exports.projectSchema, {
    $id: 'ProjectQuery',
});
exports.projectQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(projectQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({
        $loadEager: typebox_1.Type.Optional(typebox_1.Type.Array(loadEagerSchema)),
    }, { additionalProperties: false }),
], { additionalProperties: false });
