"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.propertyUtilityQuerySchema = exports.propertyUtilityUpdateSchema = exports.propertyUtilityWriteSchema = exports.propertyUtilityReadSchema = exports.propertyUtilitySchema = void 0;
var typebox_1 = require("@feathersjs/typebox");
exports.propertyUtilitySchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    quantity: typebox_1.Type.Number(),
    isEssential: typebox_1.Type.Boolean(),
    isConstant: typebox_1.Type.Boolean(),
    hoursOfUse: typebox_1.Type.Number(),
    utilityPowerRating: typebox_1.Type.Number(),
    consumption: typebox_1.Type.Number(),
    // Relationships
    propertyId: typebox_1.Type.String(),
    utilityId: typebox_1.Type.String(),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.propertyUtilityReadSchema = typebox_1.Type.Omit(exports.propertyUtilitySchema, []);
exports.propertyUtilityWriteSchema = typebox_1.Type.Omit(exports.propertyUtilityReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.propertyUtilityUpdateSchema = typebox_1.Type.Partial(exports.propertyUtilitySchema, {
    $id: 'PropertyUtilityUpdate',
});
var propertyUtilityQueryProperties = typebox_1.Type.Partial(exports.propertyUtilitySchema, {
    $id: 'PropertyUtilityQuery',
});
exports.propertyUtilityQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(propertyUtilityQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
