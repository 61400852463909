import { ReactNode } from 'react';
// @mui
import { Card, Stack, Typography } from '@mui/material';
import { Person, Email, LocalPhone } from '@mui/icons-material';

export type TInstallerUserDetailsProps = {
	name?: string;
	email?: string;
	contactNumber?: string;
	title?: string;
	extraComponent?: ReactNode;
};
export const InstallerUserDetails = ({
	name,
	email,
	contactNumber,
	title,
	extraComponent,
}: TInstallerUserDetailsProps) => (
	<Card sx={{ height: '100%', minWidth: '350px' }}>
		<Stack m={4} direction="column">
			<Stack direction="row" justifyContent="space-between">
				<Typography variant="h6">{title}</Typography>
				{extraComponent}
			</Stack>

			<Stack direction="row" mt={1}>
				<Person />
				<Typography ml={2}>{name || 'N/A'}</Typography>
			</Stack>

			<Stack direction="row" mt={1}>
				<Email />
				<Typography ml={2}>{email || 'N/A'}</Typography>
			</Stack>

			<Stack direction="row" mt={1}>
				<LocalPhone />
				<Typography ml={2}>{contactNumber || 'N/A'}</Typography>
			</Stack>
		</Stack>
	</Card>
);
