"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const themeOptions = {
    palette: {
        primary: {
            lighter: '#E7C100',
            light: '#E7C100',
            dark: '#E7C100',
            darker: '#E7C100',
            main: '#E7C100',
            contrastText: '#000000',
        },
        secondary: {
            lighter: '#000000',
            light: '#000000',
            dark: '#000000',
            darker: '#000000',
            main: '#000000',
            contrastText: '#FFFFFF',
        },
    },
    shape: {
        borderRadius: 4,
    },
};
exports.default = themeOptions;
