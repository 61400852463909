"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.engineeredProposalQuerySchema = exports.engineeredProposalUpdateSchema = exports.engineeredProposalWriteSchema = exports.engineeredProposalReadSchema = exports.engineeredProposalSchema = exports.SEQUENCES = exports.lockedKitSchema = exports.systemLossesPercentagesDefaults = exports.systemLossesPercentagesSchema = exports.CLIENT_READ_STATES = exports.generateEPInputDataSchema = exports.generateEPInputConfigSchema = exports.persistedCostsSchema = exports.epSystemAnalysisSchema = exports.energyAnalysisSchema = exports.systemSchema = exports.roofAreaConfigSchema = exports.propertyConfigSchema = exports.batterySetupSchema = exports.inverterSetupSchema = exports.panelSetupSchema = exports.productsAddOnsSchema = exports.essentialLoadsSchema = exports.cablingSchema = exports.PV_CABLE_OPTIONS_MM = exports.BATTERY_CABLE_OPTIONS_MM = exports.financeSchema = exports.generateEpDataSchema = exports.engineeredproposalConfigSchema = exports.PRICE_CATEGORIES = exports.cableRunSchema = exports.CABLE_LOCATION = exports.SPECIAL_ADD_ONS = exports.VALUE_ADDS = exports.PRE_ISTALLATION_ADD_ONS = exports.FINANCE_METHODS = exports.projectDetailsSchema = exports.detailsSchema = exports.generatedEpDraftSchema = exports.initialEpConfigSchema = exports.ACTION_TYPES = exports.proposalPricingSchema = exports.INSTALLATION_TYPES = void 0;
var typebox_1 = require("@feathersjs/typebox");
var country_codes_1 = require("../constants/country-codes");
var consumable_types_1 = require("./consumable.types");
var currency_types_1 = require("./currency.types");
var document_types_1 = require("./document.types");
var indicative_proposal_types_1 = require("./indicative-proposal.types");
var general_types_1 = require("./general-types");
var product_kit_types_1 = require("./product-kit.types");
var project_fixed_cost_types_1 = require("./project-fixed-cost.types");
var roof_area_types_1 = require("./roof-area.types");
var service_plan_types_1 = require("./service-plan.types");
var tax_types_1 = require("./tax.types");
var project_credit_items_types_1 = require("./project-credit-items.types");
var engineered_proposal_version_constants_types_1 = require("./engineered-proposal-version-constants.types");
var custom_labor_line_item_types_1 = require("./custom-labor-line-item.types");
// installation type
exports.INSTALLATION_TYPES = ['default', 'custom'];
exports.proposalPricingSchema = typebox_1.Type.Object({
    currency: (0, typebox_1.StringEnum)(__spreadArray([], currency_types_1.SUPPORTED_CURRENCY, true)),
    productKit: typebox_1.Type.Object({
        total: typebox_1.Type.Number(),
        breakdown: product_kit_types_1.appliedProductKitSchema,
    }),
    mounting: typebox_1.Type.Object({
        total: typebox_1.Type.Number(),
        breakdown: typebox_1.Type.Array(consumable_types_1.appliedConsumableSchema),
    }),
    installation: typebox_1.Type.Object({
        total: typebox_1.Type.Number(),
        installationType: (0, typebox_1.StringEnum)(__spreadArray([], exports.INSTALLATION_TYPES, true)),
        breakdown: typebox_1.Type.Optional(typebox_1.Type.Object({
            installSize: typebox_1.Type.Object({
                inverterInstallSize: typebox_1.Type.Number(),
                baseNoOfDays: typebox_1.Type.Number(),
            }),
            property: typebox_1.Type.Object({
                propertyStories: typebox_1.Type.Optional(typebox_1.Type.Number()),
                propertyStoriesNoOfDays: typebox_1.Type.Number(),
            }),
            panels: typebox_1.Type.Object({
                noOfPanels: typebox_1.Type.Optional(typebox_1.Type.Number()),
                additionalPanels: typebox_1.Type.Number(),
                additionalPanelsNoOfDays: typebox_1.Type.Number(),
            }),
            roof: typebox_1.Type.Object({
                pitchOfRoof: typebox_1.Type.Optional(typebox_1.Type.Number()),
                roofAngleNoOfDays: typebox_1.Type.Number(),
            }),
            installerId: typebox_1.Type.Optional(typebox_1.Type.String()),
            extraLaborItemsNoOfDays: typebox_1.Type.Number(),
            extraLaborItemsNo: typebox_1.Type.Number(),
            totalNoOfDays: typebox_1.Type.Number(),
            installationDayRate: typebox_1.Type.Number(),
        })),
        customBreakdown: typebox_1.Type.Optional(typebox_1.Type.Array(custom_labor_line_item_types_1.customLaborLineItemReadSchema)),
    }),
    projectFixedCosts: typebox_1.Type.Object({
        total: typebox_1.Type.Number(),
        breakdown: typebox_1.Type.Array(project_fixed_cost_types_1.appliedProjectFixedCostSchema),
    }),
    creditLineItems: typebox_1.Type.Object({
        total: typebox_1.Type.Number(),
        breakdown: typebox_1.Type.Array(project_credit_items_types_1.projectCreditItemReadSchema),
    }),
    discount: typebox_1.Type.Object({
        total: typebox_1.Type.Number(),
        percent: typebox_1.Type.Number(),
    }),
    servicePlan: typebox_1.Type.Object({
        total: typebox_1.Type.Number(),
        plan: typebox_1.Type.Optional(service_plan_types_1.servicePlanReadSchema),
    }),
    margin: typebox_1.Type.Object({
        total: typebox_1.Type.Number(),
        percent: typebox_1.Type.Number(),
    }),
    consumablesMarkup: typebox_1.Type.Object({
        percent: typebox_1.Type.Number(),
    }),
    mountingConsumablesMarkup: typebox_1.Type.Object({
        percent: typebox_1.Type.Number(),
    }),
    contingency: typebox_1.Type.Object({
        total: typebox_1.Type.Number(),
        percent: typebox_1.Type.Number(),
    }),
    deposit: typebox_1.Type.Object({
        total: typebox_1.Type.Number(),
        percent: typebox_1.Type.Number(),
    }),
    taxes: typebox_1.Type.Object({
        total: typebox_1.Type.Number(),
        breakdown: typebox_1.Type.Array(tax_types_1.appliedTaxSchema),
    }),
    totals: typebox_1.Type.Object({
        final: typebox_1.Type.Number(),
    }),
});
exports.ACTION_TYPES = [
    'system save version',
    'advisor save version',
];
exports.initialEpConfigSchema = typebox_1.Type.Object({
    config: typebox_1.Type.Object({
        epId: typebox_1.Type.Optional(typebox_1.Type.String()),
        targetCurrency: (0, typebox_1.StringEnum)(__spreadArray([], currency_types_1.SUPPORTED_CURRENCY, true)),
        productKitId: typebox_1.Type.Optional(typebox_1.Type.String()),
    }),
    action: typebox_1.Type.Optional((0, typebox_1.StringEnum)(__spreadArray([], exports.ACTION_TYPES, true))),
    installerOrgId: typebox_1.Type.Optional(typebox_1.Type.String()),
    tag: (0, typebox_1.StringEnum)(__spreadArray([], engineered_proposal_version_constants_types_1.EPV_TAGS, true)),
});
exports.generatedEpDraftSchema = typebox_1.Type.Object({
    pricing: exports.proposalPricingSchema,
    desiredStorageInKwh: typebox_1.Type.Number(),
    targetKwpArraySize: typebox_1.Type.Number(),
    systemType: (0, typebox_1.StringEnum)(__spreadArray([], general_types_1.SYSTEM_TYPES, true)),
});
exports.detailsSchema = typebox_1.Type.Object({
    size: typebox_1.Type.Number(),
    cost: typebox_1.Type.String(),
    qty: typebox_1.Type.Number(),
    brand: typebox_1.Type.Optional(typebox_1.Type.String()),
});
exports.projectDetailsSchema = typebox_1.Type.Object({
    panel: exports.detailsSchema,
    inverter: exports.detailsSchema,
    battery: exports.detailsSchema,
    projectcost: typebox_1.Type.String(),
    systemType: typebox_1.Type.String(),
    desiredBackupHours: typebox_1.Type.Number(),
    desiredKwp: typebox_1.Type.Number(),
});
exports.FINANCE_METHODS = ['asset', 'cash', 'rental', 'PPA'];
exports.PRE_ISTALLATION_ADD_ONS = [
    'Engineering and construction work to prep the site.',
    'DB board and load tracing and rewiring.',
    'Structural Sign Off',
    'Pool heating tubes will need to be removed to cater for new panel installation.',
    'All exposed cable runs to be covered with white surface mount trunking/conduit',
];
exports.VALUE_ADDS = ['Hohm Service Plan', 'Electrical Audit'];
exports.SPECIAL_ADD_ONS = [
    'Geyser Solution',
    'LED Lights',
    'Retrofit solar geyser',
    'CBI Astute SMART breaker',
    'CBI Astute SMART plug',
    'CBI Astute SMART controller',
];
exports.CABLE_LOCATION = ['db board', 'inverter', 'panels'];
exports.cableRunSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    cableMeters: typebox_1.Type.Number(),
    type: typebox_1.Type.String(),
    coordinates: typebox_1.Type.Array(typebox_1.Type.Array(typebox_1.Type.Number(), { maxItems: 2, minItems: 2 })),
    cableFrom: (0, typebox_1.StringEnum)(__spreadArray([], exports.CABLE_LOCATION, true)),
    cableTo: (0, typebox_1.StringEnum)(__spreadArray([], exports.CABLE_LOCATION, true)),
});
exports.PRICE_CATEGORIES = [
    'core system',
    'value adds',
    'special add-ons',
];
exports.engineeredproposalConfigSchema = typebox_1.Type.Object({
    epId: typebox_1.Type.String(),
    finance: typebox_1.Type.Optional(indicative_proposal_types_1.proposalFinanceSchema),
    marginPercentage: typebox_1.Type.Optional(typebox_1.Type.Number()),
    contingencyPercentage: typebox_1.Type.Optional(typebox_1.Type.Number()),
    discountPercentage: typebox_1.Type.Optional(typebox_1.Type.Number()),
    depositAmount: typebox_1.Type.Optional(typebox_1.Type.Number()),
    servicePlanId: typebox_1.Type.Optional(typebox_1.Type.String()),
    targetCurrency: (0, typebox_1.StringEnum)(__spreadArray([], currency_types_1.SUPPORTED_CURRENCY, true)),
});
exports.generateEpDataSchema = typebox_1.Type.Object({
    action: typebox_1.Type.Optional((0, typebox_1.StringEnum)(__spreadArray([], exports.ACTION_TYPES, true))),
    config: exports.engineeredproposalConfigSchema,
});
exports.financeSchema = typebox_1.Type.Object({
    monthlyPayments: typebox_1.Type.Number(),
    financeTerms: indicative_proposal_types_1.financeTermsSchema,
    financeMethod: (0, typebox_1.StringEnum)(__spreadArray([], exports.FINANCE_METHODS, true)),
});
exports.BATTERY_CABLE_OPTIONS_MM = [0, 25, 35, 50, 70];
exports.PV_CABLE_OPTIONS_MM = [6];
exports.cablingSchema = typebox_1.Type.Object({
    ac: typebox_1.Type.Object({
        acMeters: typebox_1.Type.Number(),
        earthMeters: typebox_1.Type.Number(),
    }),
    dc: typebox_1.Type.Object({
        batteryCabling: typebox_1.Type.Object({
            thickness: typebox_1.Type.Number(),
            redMeters: typebox_1.Type.Number(),
            blackMeters: typebox_1.Type.Number(),
        }),
        pvCabling: typebox_1.Type.Object({
            thickness: typebox_1.Type.Number(),
            redMeters: typebox_1.Type.Number(),
            blackMeters: typebox_1.Type.Number(),
            earthMeters: typebox_1.Type.Number(),
        }),
    }),
    accessories: typebox_1.Type.Optional(typebox_1.Type.Object({
        cost: typebox_1.Type.Number(),
        currency: typebox_1.Type.String(),
    })),
});
exports.essentialLoadsSchema = typebox_1.Type.Object({
    geyser: typebox_1.Type.Boolean(),
    freezer: typebox_1.Type.Boolean(),
    fridge: typebox_1.Type.Boolean(),
    generalPlugs: typebox_1.Type.Boolean(),
    wifi: typebox_1.Type.Boolean(),
    cctv: typebox_1.Type.Boolean(),
    poolPump: typebox_1.Type.Boolean(),
});
exports.productsAddOnsSchema = typebox_1.Type.Object({
    geyserSolution: typebox_1.Type.Boolean(),
    ledLights: typebox_1.Type.Boolean(),
    electricalAudit: typebox_1.Type.Boolean(),
    maintenanceServicePlan: typebox_1.Type.Boolean(),
});
exports.panelSetupSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    quantity: typebox_1.Type.Number(),
    mapping: typebox_1.Type.Array(general_types_1.panelMappingSchema),
});
exports.inverterSetupSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Optional(typebox_1.Type.String()),
    quantity: typebox_1.Type.Optional(typebox_1.Type.Number()),
});
exports.batterySetupSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Optional(typebox_1.Type.String()),
    quantity: typebox_1.Type.Optional(typebox_1.Type.Number()),
});
exports.propertyConfigSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    propertyType: typebox_1.Type.String(),
    coordinates: typebox_1.Type.Array(typebox_1.Type.Number()),
    location: typebox_1.Type.Object({
        country: typebox_1.Type.String(),
        countryCode: (0, typebox_1.StringEnum)(__spreadArray([], country_codes_1.COUNTRY_SUPPORTED_CODES, true)),
        city: typebox_1.Type.String(),
        region: typebox_1.Type.String(),
        address: typebox_1.Type.String(),
    }),
});
exports.roofAreaConfigSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Optional(typebox_1.Type.String()),
    area: typebox_1.Type.Array(typebox_1.Type.Array(typebox_1.Type.Number())),
    areaSize: typebox_1.Type.Number(),
    pitch: typebox_1.Type.Number(),
    azimuth: typebox_1.Type.Optional(typebox_1.Type.Number()),
    direction: (0, typebox_1.StringEnum)(__spreadArray([], roof_area_types_1.ROOF_DIRECTIONS, true)),
    mountType: (0, typebox_1.StringEnum)(__spreadArray([], roof_area_types_1.MOUNTING_TYPES, true)),
});
exports.systemSchema = typebox_1.Type.Object({
    kwpSize: typebox_1.Type.Number(),
    kwhStorage: typebox_1.Type.Number(),
    hoursOfBackup: typebox_1.Type.Number(),
    panelArea: typebox_1.Type.Number(),
});
exports.energyAnalysisSchema = typebox_1.Type.Object({
    averageHourlykWhUsage: typebox_1.Type.Number(),
    annualkWhEnergy: typebox_1.Type.Number(),
});
exports.epSystemAnalysisSchema = typebox_1.Type.Object({
    totalSavings: typebox_1.Type.Number(),
    levelisedCostOfSolar: typebox_1.Type.Number(),
    paybackPeriod: typebox_1.Type.Number(),
    onFinance: indicative_proposal_types_1.onFinanceSchema,
    projections: typebox_1.Type.Array(indicative_proposal_types_1.projectionSchema),
    annualSavings: typebox_1.Type.Number(),
});
exports.persistedCostsSchema = typebox_1.Type.Object({
    projectFixedCosts: typebox_1.Type.Array(typebox_1.Type.Object({
        name: typebox_1.Type.String(),
        unitCost: typebox_1.Type.Number(),
        category: typebox_1.Type.String(),
    })),
});
exports.generateEPInputConfigSchema = typebox_1.Type.Object({
    epId: typebox_1.Type.String(),
    finance: typebox_1.Type.Optional(exports.financeSchema),
    marginPercentage: typebox_1.Type.Optional(typebox_1.Type.Number()),
    contingencyPercentage: typebox_1.Type.Optional(typebox_1.Type.Number()),
    discountPercentage: typebox_1.Type.Optional(typebox_1.Type.Number()),
    depositAmount: typebox_1.Type.Optional(typebox_1.Type.Number()),
    servicePlanId: typebox_1.Type.Optional(typebox_1.Type.String()),
    targetCurrency: (0, typebox_1.StringEnum)(__spreadArray([], currency_types_1.SUPPORTED_CURRENCY, true)),
});
exports.generateEPInputDataSchema = typebox_1.Type.Object({
    action: typebox_1.Type.Optional((0, typebox_1.StringEnum)(__spreadArray([], exports.ACTION_TYPES, true))),
    config: exports.generateEPInputConfigSchema,
});
exports.CLIENT_READ_STATES = [
    'pending',
    'ready for client',
    'accepted by client',
    'rejected by client',
    'ready for energy-advisor',
    'accepted by energy-advisor',
    'rejected by energy-advisor',
    'rejected by final authority',
];
exports.systemLossesPercentagesSchema = typebox_1.Type.Object({
    acSystem: typebox_1.Type.Number(),
    shading: typebox_1.Type.Number(),
    reflection: typebox_1.Type.Number(),
    soiling: typebox_1.Type.Number(),
    irradiance: typebox_1.Type.Number(),
    temperature: typebox_1.Type.Number(),
    mismatch: typebox_1.Type.Number(),
    wiring: typebox_1.Type.Number(),
    clipping: typebox_1.Type.Number(),
    inverters: typebox_1.Type.Number(),
});
// TODO : can be renamed to SYSTEM_LOSSES_PERCENTAGES_DEFAULTS
exports.systemLossesPercentagesDefaults = {
    acSystem: 0.33,
    shading: 0.27,
    reflection: 3.3,
    soiling: 2,
    irradiance: 0.37,
    temperature: 4.37,
    mismatch: 1.67,
    wiring: 0.33,
    clipping: 0.13,
    inverters: 3.5,
};
exports.lockedKitSchema = typebox_1.Type.Object({
    panelId: typebox_1.Type.String(),
    batteryId: typebox_1.Type.String(),
    inverterId: typebox_1.Type.String(),
});
exports.SEQUENCES = ['cash', 'finance'];
var loadEagerSchema = (0, typebox_1.StringEnum)(['quoteOwner', 'installer']);
exports.engineeredProposalSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    systemType: (0, typebox_1.StringEnum)(__spreadArray([], general_types_1.SYSTEM_TYPES, true)),
    state: (0, typebox_1.StringEnum)(__spreadArray([], exports.CLIENT_READ_STATES, true)),
    documents: typebox_1.Type.Array(document_types_1.jsonDocumentSchema),
    desiredKwp: typebox_1.Type.Number(),
    desiredBackupHours: typebox_1.Type.Number(),
    outdoorInstallation: typebox_1.Type.Optional(typebox_1.Type.Boolean()),
    trenchingLength: typebox_1.Type.Optional(typebox_1.Type.Number()),
    lockedKit: typebox_1.Type.Optional(exports.lockedKitSchema),
    sequence: (0, typebox_1.StringEnum)(__spreadArray([], exports.SEQUENCES, true)),
    installationType: (0, typebox_1.StringEnum)(__spreadArray([], exports.INSTALLATION_TYPES, true)),
    installerId: typebox_1.Type.String(),
    pricingOrganisationId: typebox_1.Type.String(),
    roofAreaId: typebox_1.Type.String(),
    proposalOwnerId: typebox_1.Type.String(),
    propertyId: typebox_1.Type.String(),
    projectId: typebox_1.Type.String(),
    indicativeProposalId: typebox_1.Type.Optional(typebox_1.Type.String()),
    systemDesignId: typebox_1.Type.Optional(typebox_1.Type.String()),
    engineeredProposalPricingId: typebox_1.Type.Optional(typebox_1.Type.String()),
    syncedEpvId: typebox_1.Type.Optional(typebox_1.Type.String()),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.engineeredProposalReadSchema = typebox_1.Type.Omit(exports.engineeredProposalSchema, []);
exports.engineeredProposalWriteSchema = typebox_1.Type.Omit(exports.engineeredProposalReadSchema, ['id', 'createdAt', 'updatedAt']);
exports.engineeredProposalUpdateSchema = typebox_1.Type.Partial(exports.engineeredProposalSchema, {
    $id: 'EngineeredProposalUpdate',
});
var engineeredProposalQueryProperties = typebox_1.Type.Partial(exports.engineeredProposalSchema, {
    $id: 'EngineeredProposalQuery',
});
exports.engineeredProposalQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(engineeredProposalQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({
        $loadEager: typebox_1.Type.Optional(typebox_1.Type.Array(loadEagerSchema)),
    }, { additionalProperties: false }),
], { additionalProperties: false });
