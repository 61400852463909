// @local
import { CustomMenuItem } from './MenuItem';
import { TSelectProps } from '../utils/types';
import { inputsDefaultStyle } from '../utils/constants';
import { Input } from '../Input';

export type { TSelectProps };
export const Select = ({
	children,
	menuItems,
	menuItemTitle,
	...rest
}: TSelectProps) => (
	<Input
		fullWidth
		{...rest}
		select
		InputProps={{
			style: inputsDefaultStyle,
		}}
	>
		{menuItems
			? CustomMenuItem({ title: menuItemTitle, menuItems })
			: children}
	</Input>
);
