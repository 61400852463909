"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@mui/material/styles");
const theme_utils_1 = require("../../utils/theme-utils");
const COMMON = { black: '#000', white: '#fff' };
const GREY = {
    0: '#FFFFFF',
    100: '#F9FAFB',
    200: '#F4F6F8',
    300: '#DFE3E8',
    400: '#C4CDD5',
    500: '#919EAB',
    600: '#637381',
    700: '#454F5B',
    800: '#212B36',
    900: '#161C24',
    5008: (0, styles_1.alpha)('#919EAB', 0.08),
    50012: (0, styles_1.alpha)('#919EAB', 0.12),
    50016: (0, styles_1.alpha)('#919EAB', 0.16),
    50024: (0, styles_1.alpha)('#919EAB', 0.24),
    50032: (0, styles_1.alpha)('#919EAB', 0.32),
    50048: (0, styles_1.alpha)('#919EAB', 0.48),
    50056: (0, styles_1.alpha)('#919EAB', 0.56),
    50080: (0, styles_1.alpha)('#919EAB', 0.8),
};
const TEXT = {
    primary: GREY[800],
    secondary: GREY[600],
    disabled: GREY[500],
};
const BACKGROUND = {
    paper: '#fff',
    default: '#fff',
    neutral: GREY[200],
};
const PRIMARY = {
    lighter: '#FF797B',
    light: '#FF797B',
    main: '#FF585B',
    dark: '#B23D3F',
    darker: '#B23D3F',
    contrastText: '#FFFFFF',
};
const SECONDARY = {
    lighter: '#3E4F6E',
    light: '#3E4F6E',
    main: '#0E244A',
    dark: '#091933',
    darker: '#091933',
    contrastText: '#FFFFFF',
};
const INFO = {
    lighter: '#D0F2FF',
    light: '#0aa0d6',
    main: '#087CA7',
    dark: '#066688',
    darker: '#043A4e',
    contrastText: '#FFFFFF',
};
const SUCCESS = {
    lighter: '#E9FCD4',
    light: '#AAF27F',
    main: '#54D62C',
    dark: '#229A16',
    darker: '#08660D',
    contrastText: GREY[800],
};
const WARNING = {
    lighter: '#FFF7CD',
    light: '#FFE16A',
    main: '#FFC107',
    dark: '#B78103',
    darker: '#7A4F01',
    contrastText: GREY[800],
};
const ERROR = {
    lighter: '#FFE7D9',
    light: '#FFA48D',
    main: '#FF4842',
    dark: '#B72136',
    darker: '#7A0C2E',
    contrastText: '#FFFFFF',
};
const GRADIENTS = {
    primary: (0, theme_utils_1.createGradient)(PRIMARY.light, PRIMARY.main),
    info: (0, theme_utils_1.createGradient)(INFO.light, INFO.main),
    success: (0, theme_utils_1.createGradient)(SUCCESS.light, SUCCESS.main),
    warning: (0, theme_utils_1.createGradient)(WARNING.light, WARNING.main),
    error: (0, theme_utils_1.createGradient)(ERROR.light, ERROR.main),
};
const CHART_COLORS = {
    violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
    blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
    green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
    yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
    red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};
const ACTION = {
    hover: GREY[5008],
    selected: GREY[50016],
    disabled: GREY[50080],
    disabledBackground: GREY[50024],
    focus: GREY[50024],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
};
const BREAKPOINTS = {
    values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
    },
};
const SHAPE = { borderRadius: 8 };
const SHADOWS = (0, theme_utils_1.createShadow)(GREY[500]);
const CUSTOM_SHADOWS = {
    z1: `0 1px 2px 0 ${(0, styles_1.alpha)(GREY[500], 0.12)}`,
    z8: `0 8px 16px 0 ${(0, styles_1.alpha)(GREY[500], 0.12)}`,
    z12: `0 12px 24px -4px ${(0, styles_1.alpha)(GREY[500], 0.12)}`,
    z16: `0 16px 32px -4px ${(0, styles_1.alpha)(GREY[500], 0.12)}`,
    z20: `0 20px 40px -4px ${(0, styles_1.alpha)(GREY[500], 0.12)}`,
    z24: `0 24px 48px 0 ${(0, styles_1.alpha)(GREY[500], 0.12)}`,
    primary: `0 8px 16px 0 ${(0, styles_1.alpha)(PRIMARY.main, 0.24)}`,
    info: `0 8px 16px 0 ${(0, styles_1.alpha)(INFO.main, 0.24)}`,
    secondary: `0 8px 16px 0 ${(0, styles_1.alpha)(SECONDARY.main, 0.24)}`,
    success: `0 8px 16px 0 ${(0, styles_1.alpha)(SUCCESS.main, 0.24)}`,
    warning: `0 8px 16px 0 ${(0, styles_1.alpha)(WARNING.main, 0.24)}`,
    error: `0 8px 16px 0 ${(0, styles_1.alpha)(ERROR.main, 0.24)}`,
};
const TYPOGRAPHY_FONT_FAMILY = "'Quicksand', sans-serif;";
const TYPOGRAPHY_FONT_WEIGHT_REGULAR = 400;
const TYPOGRAPHY_FONT_WEIGHT_MEDIUM = 600;
const TYPOGRAPHY_FONT_WEIGHT_BOLD = 700;
const themeOptions = {
    palette: {
        common: COMMON,
        grey: GREY,
        text: TEXT,
        background: BACKGROUND,
        primary: PRIMARY,
        secondary: SECONDARY,
        info: INFO,
        success: SUCCESS,
        warning: WARNING,
        error: ERROR,
        gradients: GRADIENTS,
        chart: CHART_COLORS,
        action: ACTION,
        divider: GREY[50024],
    },
    breakpoints: BREAKPOINTS,
    shape: SHAPE,
    shadows: SHADOWS,
    customShadows: CUSTOM_SHADOWS,
    typography: {
        fontFamily: TYPOGRAPHY_FONT_FAMILY,
        fontWeightRegular: TYPOGRAPHY_FONT_WEIGHT_REGULAR,
        fontWeightMedium: TYPOGRAPHY_FONT_WEIGHT_MEDIUM,
        fontWeightBold: TYPOGRAPHY_FONT_WEIGHT_BOLD,
        h1: Object.assign({ fontWeight: 700, lineHeight: 80 / 64, fontSize: (0, theme_utils_1.pxToRem)(40), letterSpacing: 2 }, (0, theme_utils_1.responsiveFontSizes)({ sm: 52, md: 58, lg: 64 })),
        h2: Object.assign({ fontWeight: 700, lineHeight: 64 / 48, fontSize: (0, theme_utils_1.pxToRem)(32) }, (0, theme_utils_1.responsiveFontSizes)({ sm: 40, md: 44, lg: 48 })),
        h3: Object.assign({ fontWeight: 700, lineHeight: 1.5, fontSize: (0, theme_utils_1.pxToRem)(24) }, (0, theme_utils_1.responsiveFontSizes)({ sm: 26, md: 30, lg: 32 })),
        h4: Object.assign({ fontWeight: 700, lineHeight: 1.5, fontSize: (0, theme_utils_1.pxToRem)(20) }, (0, theme_utils_1.responsiveFontSizes)({ sm: 20, md: 24, lg: 24 })),
        h5: Object.assign({ fontWeight: 700, lineHeight: 1.5, fontSize: (0, theme_utils_1.pxToRem)(18) }, (0, theme_utils_1.responsiveFontSizes)({ sm: 19, md: 20, lg: 20 })),
        h6: Object.assign({ fontWeight: 700, lineHeight: 28 / 18, fontSize: (0, theme_utils_1.pxToRem)(17) }, (0, theme_utils_1.responsiveFontSizes)({ sm: 18, md: 18, lg: 18 })),
        subtitle1: {
            fontWeight: 600,
            lineHeight: 1.5,
            fontSize: (0, theme_utils_1.pxToRem)(16),
        },
        subtitle2: {
            fontWeight: 600,
            lineHeight: 22 / 14,
            fontSize: (0, theme_utils_1.pxToRem)(14),
        },
        body1: {
            lineHeight: 1.5,
            fontSize: (0, theme_utils_1.pxToRem)(16),
        },
        body2: {
            lineHeight: 22 / 14,
            fontSize: (0, theme_utils_1.pxToRem)(14),
        },
        caption: {
            lineHeight: 1.5,
            fontSize: (0, theme_utils_1.pxToRem)(12),
        },
        overline: {
            fontWeight: 700,
            lineHeight: 1.5,
            fontSize: (0, theme_utils_1.pxToRem)(12),
            textTransform: 'uppercase',
        },
        button: {
            fontWeight: 700,
            lineHeight: 24 / 14,
            fontSize: (0, theme_utils_1.pxToRem)(14),
            textTransform: 'capitalize',
        },
    },
};
exports.default = themeOptions;
