import React from 'react';
// @mui
import { Paper, Typography, ButtonBase, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
// @icons
import { SvgIconComponent } from '@mui/icons-material';

const StyledTypography = styled(Typography)<{
	selected?: boolean;
}>(({ theme, selected }) => ({
	...(selected && {
		color: theme.palette.primary.main,
	}),
}));

const StyledPaper = styled(Paper)<{
	selected?: boolean;
}>(({ theme, selected }) => ({
	...(selected
		? {
				padding: '15px',
				borderRadius: '12px',
				width: '100%',
				height: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
				border: '2px solid #607d8b',
				borderColor: theme.palette.primary.main,
		  }
		: {
				padding: '15px',
				borderRadius: '12px',
				width: '100%',
				height: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
				border: '2px solid #607d8b',
				borderColor: theme.palette.grey[400],
		  }),
}));

const StyledButtonBase = styled(ButtonBase)(() => ({
	width: '100%',
	height: '100%',
	borderRadius: '12px',
}));

export type TTileButtonProps = {
	label?: string;
	subtext?: string;
	Icon?: SvgIconComponent | React.FunctionComponent;
	iconPath?: string;
	onClick?: () => void;
	selected?: boolean;
};
export const TileButton = ({
	selected,
	Icon,
	label,
	onClick,
	subtext,
	iconPath,
}: TTileButtonProps) => (
	<StyledButtonBase onClick={onClick}>
		<StyledPaper selected={selected}>
			<Stack
				direction="row"
				spacing={3}
				justifyContent="center"
				alignItems="center"
			>
				{iconPath ? (
					<img
						src={iconPath}
						alt="icon"
						style={{
							width: '50px',
							height: '50px',
						}}
					/>
				) : Icon ? (
					<Icon
						sx={{
							width: '50px',
							height: '50px',
							stroke: '#ffffff',
							strokeWidth: 1,
						}}
						htmlColor="#aaa"
					/>
				) : null}

				<StyledTypography selected={selected}>{label}</StyledTypography>
			</Stack>

			{subtext ? <Typography>{subtext}</Typography> : null}
		</StyledPaper>
	</StyledButtonBase>
);
