'use client';

import { useEffect, useState } from 'react';
import { getCookie, setCookie } from 'cookies-next';
// @mui
import {
	AppBar,
	Button,
	Typography,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Stack,
	Hidden,
	useMediaQuery,
	Theme,
	Box,
	useTheme,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
// @local
import { DialogTransition } from '../utils/DialogTransition';

export type TTrackingConsentProps = {
	onAgreement?: () => void;
	onHideBanner?: () => void;
};
export const TrackingConsent = ({
	onAgreement,
	onHideBanner,
}: TTrackingConsentProps) => {
	const theme = useTheme();
	const isMobile = useMediaQuery((_theme: Theme) =>
		_theme.breakpoints.down('lg')
	);
	const [hasConsent, setHasConsent] = useState(true);
	const [hideBanner, setHideBanner] = useState(false);
	const [openBannerDialogMobile, setOpenBannerDialogMobile] = useState(false);
	const [openMoreInformation, setOpenMoreInformation] = useState(false);

	const handleHideBanner = () => {
		setHideBanner(true);
		if (onHideBanner) onHideBanner();
	};

	const handleMoreInfoClickOpen = () => {
		setOpenBannerDialogMobile(false);
		setOpenMoreInformation(true);
	};

	const handleMoreInfoClose = () => {
		setOpenMoreInformation(false);
		setOpenBannerDialogMobile(true);
	};

	const handleAgreement = () => {
		const cookieExpiration = new Date();

		cookieExpiration.setDate(cookieExpiration.getDate() + 30);
		setCookie('trackingConsent', 'true', { expires: cookieExpiration });
		setHasConsent(true);

		if (document) {
			document.getElementsByTagName('body')[0].style.marginBottom = '0px';
		}
		if (onAgreement) onAgreement();
	};

	const renderMoreInfo = () => (
		<Dialog
			open={openMoreInformation}
			TransitionComponent={DialogTransition}
			keepMounted
			onClose={handleMoreInfoClose}
			aria-describedby="alert-dialog-slide-description"
		>
			<DialogTitle>Analytics and Tracking Consent</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-slide-description">
					<Stack spacing={2}>
						<Typography variant="body2">
							We use cookies to ensure that we give you the best
							experience on our website. If you continue to use
							this site we will assume that you agree to our use
							of cookies.
						</Typography>
						<Typography variant="body2">
							Analytics and tracking technologies currently in use
							are:
						</Typography>
						<Stack direction="row" spacing={2}>
							<Typography variant="body2" width="40%">
								<ul>
									{[
										'Google Analytics',
										'Google Tag Manager',
										'Facebook Pixel',
										'Hotjar Heatmaps',
									].map((item) => (
										<li key={item}>{item}</li>
									))}
								</ul>
							</Typography>
							<Typography variant="body2">
								<ul>
									{[
										'Mixpanel Analytics',
										'Vercel Insights',
									].map((item) => (
										<li key={item}>{item}</li>
									))}
								</ul>
							</Typography>
						</Stack>
					</Stack>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleMoreInfoClose}>Close</Button>
			</DialogActions>
		</Dialog>
	);

	useEffect(() => {
		const consentCookie = getCookie('trackingConsent');

		if (consentCookie === true) {
			setHasConsent(true);
		} else {
			setHasConsent(false);
			setOpenBannerDialogMobile(true);
		}
	}, []);

	if (!hasConsent && !hideBanner) {
		if (isMobile) {
			return (
				<>
					(
					<Dialog
						open={openBannerDialogMobile}
						TransitionComponent={DialogTransition}
						keepMounted
					>
						<Stack
							direction="column"
							p={2}
							sx={{ backgroundColor: theme.palette.primary.main }}
						>
							<Typography variant="body2" color="white">
								This website uses cookies. By closing this
								banner or continuing to use this site, you agree
								to our use of cookies.
							</Typography>

							<Stack gap={1} mt={6} direction="row" mb={1}>
								<Button
									variant="outlined"
									color="secondary"
									onClick={handleMoreInfoClickOpen}
									sx={{ flex: 2 }}
								>
									More Information
								</Button>
								<Button
									variant="contained"
									color="secondary"
									onClick={handleAgreement}
									sx={{ flex: 1 }}
								>
									I Agree
								</Button>
							</Stack>

							<Button
								variant="text"
								color="inherit"
								onClick={handleHideBanner}
								sx={{
									textDecoration: 'underline',
									color: 'white',
									alignSelf: 'center',
								}}
							>
								Dismiss
							</Button>
						</Stack>
					</Dialog>
					){renderMoreInfo()}
				</>
			);
		}

		return (
			<>
				<AppBar
					position="fixed"
					color="primary"
					sx={{
						top: 'auto',
						bottom: 0,
						width: '600px',
						left: 0,
						borderRadius: 1,
						ml: 1,
						mb: 1,
					}}
					elevation={12}
				>
					<Hidden lgDown>
						<Stack
							direction="column"
							p={2}
							ml={0.1}
							justifyContent="space-between"
							sx={{
								boxShadow:
									'-4px 3px 32px -1px rgba(0,0,0,0.85)',
								borderRadius: 1,
							}}
						>
							<Stack
								direction="row"
								justifyContent="space-between"
							>
								<Typography variant="body2">
									This website uses cookies. By closing this
									banner or continuing to use this site, you
									agree to our use of cookies.
								</Typography>
								<Box
									onClick={handleHideBanner}
									sx={{ cursor: 'pointer' }}
								>
									<CloseIcon sx={{ ml: 2 }} />
								</Box>
							</Stack>

							<Stack
								direction="row"
								spacing={2}
								mr={0}
								ml={2}
								mt={2}
								alignSelf="flex-end"
							>
								<Button
									variant="contained"
									color="secondary"
									onClick={handleMoreInfoClickOpen}
								>
									More Information
								</Button>
								<Button
									variant="contained"
									color="secondary"
									onClick={handleAgreement}
									sx={{ width: '100px' }}
								>
									I Agree
								</Button>
							</Stack>
						</Stack>
					</Hidden>
				</AppBar>
				{renderMoreInfo()}
			</>
		);
	}

	return null;
};
