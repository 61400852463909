"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.batteryQuerySchema = exports.batteryUpdateSchema = exports.batteryWriteSchema = exports.batteryReadSchema = exports.batterySchema = exports.BATTERY_TECHNOLOGIES = void 0;
var typebox_1 = require("@feathersjs/typebox");
var currency_types_1 = require("./currency.types");
exports.BATTERY_TECHNOLOGIES = [
    'lithium iron phosphate',
    'valve regulated lead acid',
];
var batteryTechnologySchema = (0, typebox_1.StringEnum)(__spreadArray([], exports.BATTERY_TECHNOLOGIES, true));
exports.batterySchema = typebox_1.Type.Object({
    id: typebox_1.Type.Number(),
    brand: typebox_1.Type.String(),
    model: typebox_1.Type.String(),
    kwh: typebox_1.Type.Number(),
    technology: batteryTechnologySchema,
    cost: typebox_1.Type.Number(),
    price: typebox_1.Type.Number(), // calculated field
    markupPercentage: typebox_1.Type.Number(),
    productPicture: typebox_1.Type.Optional(typebox_1.Type.String()),
    oemLogo: typebox_1.Type.Optional(typebox_1.Type.String()),
    benefits: typebox_1.Type.Optional(typebox_1.Type.Array(typebox_1.Type.String())),
    description: typebox_1.Type.Optional(typebox_1.Type.String()),
    isArchived: typebox_1.Type.Optional(typebox_1.Type.Boolean()),
    isActive: typebox_1.Type.Optional(typebox_1.Type.Boolean()),
    currency: (0, typebox_1.StringEnum)(__spreadArray([], currency_types_1.SUPPORTED_CURRENCY, true)),
    cRating: typebox_1.Type.Number(),
    maxContinousDischarge: typebox_1.Type.Number(),
    depthOfDischarge: typebox_1.Type.Number(),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.batteryReadSchema = typebox_1.Type.Omit(exports.batterySchema, []);
exports.batteryWriteSchema = typebox_1.Type.Omit(exports.batteryReadSchema, [
    'id',
    'price',
    'createdAt',
    'updatedAt',
]);
exports.batteryUpdateSchema = typebox_1.Type.Partial(exports.batterySchema, {
    $id: 'BatteryUpdate',
});
var batteryQueryProperties = typebox_1.Type.Partial(exports.batterySchema, {
    $id: 'BatteryQuery',
});
exports.batteryQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(batteryQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
