"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var pathwayscu = {
    partner: {
        name: 'Pathways',
        legalName: 'Pathways Financial Credit Union',
        logo: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/signUp/image3.png',
    },
    financePartners: [],
    pages: {
        signup: {
            heading: 'We make solar simple',
            content: "Pathways and Hohm Energy have partnered to offer an industry-leading client solution, giving you an indicative proposal in minutes for a range of suitable solar options that meet your needs.<br /><br />Sign up to Hohm Energy and get access to the following benefits:",
            offerings: [
                {
                    id: 0,
                    heading: 'No upfront cost',
                    content: 'Your solar solution is financed by Pathways Financial Credit Union.',
                },
                {
                    id: 1,
                    heading: 'Get the best price',
                    content: "Hohm Energy has negotiated the best prices from product suppliers and solar installers to ensure best value for you.",
                },
                {
                    id: 2,
                    heading: "Your own Energy Advisor",
                    content: "Hohm Energy is with you every step of the way to answer any questions, manage the process and ensure your solar journey is as seamless as possible.",
                },
                {
                    id: 3,
                    heading: 'Get the right solar solution',
                    content: "Hohm Energy will make sure that your solar system meets your exact needs, using only quality products.",
                },
                {
                    id: 4,
                    heading: 'Hassle-free maintenance plan included',
                    content: "Hohm Energy maintains your solar system, so you have zero-hassle over your solar finance term.",
                },
            ],
            sections: {
                titleSection: {
                    title: "From start to solar, we've got your back.",
                },
            },
        },
    },
    financeOptions: [
        {
            label: 'Cash',
            value: 'cash',
        },
        {
            label: 'Solar Finance',
            value: 'nedbank-mfc',
            default: true,
        },
        {
            label: 'Home Loan',
            value: 'home-loan',
        },
    ],
};
exports.default = pathwayscu;
