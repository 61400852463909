import React from 'react';

interface IIframeProps {
	address?: string;
	title: string;
	height?: string;
	coords?: [number, number];
}

function MapIframe({ title, address, height = '300', coords }: IIframeProps) {
	const center = `${coords?.[0]}, ${coords?.[1]}`;

	const { GOOGLE_MAPS_API_KEY } = process.env;

	if (address) {
		return (
			<iframe
				title={title}
				width="100%"
				height={height}
				frameBorder="0"
				style={{ border: 0 }}
				allowFullScreen
				src={`https://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=${address}&z=14&maptype=satellite&output=embed`}
			/>
		);
	}

	return (
		<iframe
			title={title}
			width="100%"
			height={height}
			frameBorder="0"
			style={{ border: 0 }}
			allowFullScreen
			src={`https://www.google.com/maps/embed/v1/view?key=${GOOGLE_MAPS_API_KEY}&center=${center}&zoom=20&maptype=satellite`}
		/>
	);
}

export default MapIframe;
