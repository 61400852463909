"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var nedbank = {
    theme: 'nedbank',
    brand: {
        name: 'Nedbank',
        legalName: 'Nedbank',
        logo: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/Nedbank.png',
        icon: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/Nedbank.png',
    },
    financePartners: [
        {
            name: 'Nedbank',
            logo: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/Nedbank.png',
        },
    ],
    campaignPartners: [
        {
            name: 'Nedbank',
            logo: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/Nedbank.png',
        },
    ],
    pages: {
        signup: {
            sections: {
                titleSection: {
                    title: "From start to solar, we've got your back.",
                },
            },
        },
    },
    poweredBy: {
        name: 'Hohm Energy',
        legalName: 'Hohm Services (Pty) Ltd.',
        logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/general/HohmEnergyLogoOrange.png',
    },
    partner: {
        name: 'Hohm Energy',
        legalName: 'Hohm Services (Pty) Ltd.',
        logo: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/general/HohmEnergyLogoOrange.png',
    },
    supportedLoanBanks: ['Nedbank'],
    financeOptions: [
        {
            label: 'Cash',
            value: 'cash',
        },
        {
            label: 'Solar Finance',
            value: 'nedbank-mfc',
        },
        {
            label: 'Home Loan',
            value: 'home-loan',
            default: true,
        },
    ],
};
exports.default = nedbank;
