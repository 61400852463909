import React from 'react';

// @mui
import { Typography, IconButton, Snackbar, Alert } from '@mui/material';

// @icons
import { Close } from '@mui/icons-material';

interface IPdfNotifyProps {
	setPdfErrorOpen: React.Dispatch<React.SetStateAction<boolean>>;
	pdfErrorOpen: boolean;
	message: string;
}

function PdfErrorNotify({
	pdfErrorOpen,
	setPdfErrorOpen,
	message,
}: IPdfNotifyProps) {
	const handleClose = (
		event: React.SyntheticEvent | Event,
		reason?: string
	) => {
		if (reason === 'clickaway') {
			return;
		}
		setPdfErrorOpen(false);
	};

	return (
		<Snackbar
			open={pdfErrorOpen}
			onClose={handleClose}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
		>
			<Alert severity="error" variant="outlined" sx={{ width: '100%' }}>
				<Typography>{message}</Typography>
				<IconButton
					size="small"
					aria-label="close"
					color="inherit"
					onClick={handleClose}
				>
					<Close fontSize="small" />
				</IconButton>
			</Alert>
		</Snackbar>
	);
}

export default PdfErrorNotify;
