"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Avatar = (theme) => {
    return {
        MuiAvatar: {
            styleOverrides: {
                colorDefault: {
                    color: theme.palette.text.secondary,
                    backgroundColor: theme.palette.grey[400],
                },
            },
        },
        MuiAvatarGroup: {
            styleOverrides: {
                avatar: {
                    fontSize: 16,
                    fontWeight: theme.typography.fontWeightMedium,
                    '&:first-of-type': {
                        fontSize: 14,
                        color: theme.palette.primary.main,
                        backgroundColor: theme.palette.primary.lighter,
                    },
                },
            },
        },
    };
};
exports.default = Avatar;
