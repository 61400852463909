"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.leadScoreQuerySchema = exports.leadScoreUpdateSchema = exports.leadScoreWriteSchema = exports.leadScoreReadSchema = exports.leadScoreSchema = void 0;
var typebox_1 = require("@feathersjs/typebox");
exports.leadScoreSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Number(),
    intentScore: typebox_1.Type.Number(),
    estimateScore: typebox_1.Type.Number(),
    incomeClassScore: typebox_1.Type.Number(),
    feasibilityScore: typebox_1.Type.Number(),
    totalScore: typebox_1.Type.Number(),
    name: typebox_1.Type.String(),
    createdAt: typebox_1.Type.String({ format: 'date-time' }),
    updatedAt: typebox_1.Type.String({ format: 'date-time' }),
});
exports.leadScoreReadSchema = typebox_1.Type.Omit(exports.leadScoreSchema, []);
exports.leadScoreWriteSchema = typebox_1.Type.Omit(exports.leadScoreReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.leadScoreUpdateSchema = typebox_1.Type.Partial(exports.leadScoreSchema, {
    $id: 'LeadScoreUpdate',
});
var leadScoreQueryProperties = typebox_1.Type.Partial(exports.leadScoreSchema, {
    $id: 'LeadScoreQuery',
});
exports.leadScoreQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(leadScoreQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
