'use client';

// @mui
import {
	LocalizationProvider as MUILocalizationProvider,
	LocalizationProviderProps,
} from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';

export type TLocalizationProviderProps = Partial<LocalizationProviderProps>;
export const LocalizationProvider: React.FC<TLocalizationProviderProps> = ({
	children,
	...rest
}) => (
	<MUILocalizationProvider dateAdapter={AdapterDateFns} {...rest}>
		{children}
	</MUILocalizationProvider>
);

export default LocalizationProvider;
