"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.laborLineItemQuerySchema = exports.laborLineItemUpdateSchema = exports.laborLineItemWriteSchema = exports.laborLineItemReadSchema = exports.laborLineItemSchema = void 0;
var typebox_1 = require("@feathersjs/typebox");
exports.laborLineItemSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    quantity: typebox_1.Type.Number(),
    installationPricingId: typebox_1.Type.String(),
    engineeredProposalPricingId: typebox_1.Type.String(),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.laborLineItemReadSchema = typebox_1.Type.Omit(exports.laborLineItemSchema, []);
exports.laborLineItemWriteSchema = typebox_1.Type.Omit(exports.laborLineItemReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.laborLineItemUpdateSchema = typebox_1.Type.Partial(exports.laborLineItemSchema, {
    $id: 'LaborLineItemUpdate',
});
var laborLineItemQueryProperties = typebox_1.Type.Partial(exports.laborLineItemSchema, {
    $id: 'LaborLineItemQuery',
});
exports.laborLineItemQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(laborLineItemQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
