"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.internalManagementFeesQuerySchema = exports.internalManagementFeesUpdateSchema = exports.internalManagementFeesWriteSchema = exports.internalManagementFeesReadSchema = exports.internalManagementFeesSchema = exports.INTERNAL_FEE_TYPES = void 0;
var typebox_1 = require("@feathersjs/typebox");
var general_types_1 = require("./general-types");
exports.INTERNAL_FEE_TYPES = [
    'projectManagementFees',
    'consumablesMarkup',
    'mountingConsumablesMarkup',
    'installationLabourRatePerDay',
    'laborMarkup',
    'contingency',
    'ssegFee',
    'hohmRangerFeePerDay',
    'customHohmRangerFee',
    'customSssegFee',
    'other',
];
exports.internalManagementFeesSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Number(),
    startkWp: typebox_1.Type.Number(),
    endkWp: typebox_1.Type.Number(),
    percentageOfCost: typebox_1.Type.Number(),
    actualAmount: typebox_1.Type.Number(),
    feeType: typebox_1.Type.String(),
    consumerType: (0, typebox_1.StringEnum)(__spreadArray([], general_types_1.CONSUMER_TYPES, true)),
    createdAt: typebox_1.Type.String({ format: 'date-time' }),
    updatedAt: typebox_1.Type.String({ format: 'date-time' }),
});
exports.internalManagementFeesReadSchema = typebox_1.Type.Omit(exports.internalManagementFeesSchema, []);
exports.internalManagementFeesWriteSchema = typebox_1.Type.Omit(exports.internalManagementFeesReadSchema, ['id', 'createdAt', 'updatedAt']);
exports.internalManagementFeesUpdateSchema = typebox_1.Type.Partial(exports.internalManagementFeesSchema, {
    $id: 'InternalManagementFeesUpdate',
});
var internalManagementFeesQueryProperties = typebox_1.Type.Partial(exports.internalManagementFeesSchema, {
    $id: 'InternalManagementFeesQuery',
});
exports.internalManagementFeesQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(internalManagementFeesQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
