"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.creditCheckQuerySchema = exports.creditCheckUpdateSchema = exports.creditCheckWriteSchema = exports.creditCheckReadSchema = exports.creditCheckSchema = exports.GLINT_KIT_SERVICES = void 0;
var typebox_1 = require("@feathersjs/typebox");
exports.GLINT_KIT_SERVICES = [
    'Spark Services',
    'Spark Plus Services',
    'Glow Services',
    'Shine Services',
    'Flash Services',
    'Flash Plus Services',
];
exports.creditCheckSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    creditCheck: typebox_1.Type.Boolean(),
    creditCheckReason: typebox_1.Type.String(),
    address: typebox_1.Type.String(),
    homeOwner: typebox_1.Type.Boolean(),
    age: typebox_1.Type.Number(),
    idNumber: typebox_1.Type.String(),
    creditScore: typebox_1.Type.Number(),
    defaults: typebox_1.Type.Number(),
    judgements: typebox_1.Type.Object({
        amount: typebox_1.Type.Number(),
        rawValue: typebox_1.Type.Object({
            currentJudgements: typebox_1.Type.Number(),
            previousJudgements: typebox_1.Type.Number(),
            otherJudgements: typebox_1.Type.Number(),
        }),
    }),
    debtReview: typebox_1.Type.Object({
        isUnderDebtReview: typebox_1.Type.Boolean(),
        debtReviewReason: typebox_1.Type.String(),
        rawValue: typebox_1.Type.String(),
    }),
    arears: typebox_1.Type.Object({
        mnthsInArrears: typebox_1.Type.Number(),
        currBalance: typebox_1.Type.Number(),
        cummulativeArrearsAmount: typebox_1.Type.Number(),
        currMonthlyInstallment: typebox_1.Type.Number(),
    }),
    thickOrThin: typebox_1.Type.Object({
        type: (0, typebox_1.StringEnum)(['thick', 'thin']),
        thickOrThinReason: typebox_1.Type.String(),
        rawValue: typebox_1.Type.String(),
    }),
    truid_url: typebox_1.Type.String(),
    selected_kit: (0, typebox_1.StringEnum)(['Spark', 'Glow', 'Shine']),
    recommended_kit: (0, typebox_1.StringEnum)(['Spark', 'Glow', 'Shine']),
    userId: typebox_1.Type.String(),
    preliminaryCheck: typebox_1.Type.Boolean(),
    glintId: typebox_1.Type.String(),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.creditCheckReadSchema = typebox_1.Type.Omit(exports.creditCheckSchema, []);
exports.creditCheckWriteSchema = typebox_1.Type.Omit(exports.creditCheckReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.creditCheckUpdateSchema = typebox_1.Type.Partial(exports.creditCheckSchema, {
    $id: 'CreditCheckUpdate',
});
var creditCheckQueryProperties = typebox_1.Type.Partial(exports.creditCheckSchema, {
    $id: 'CreditCheckQuery',
});
exports.creditCheckQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(creditCheckQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
