// @mui
import { Badge, Avatar, AvatarProps, Tooltip } from '@mui/material';
// types
import { UserTypes } from 'hohm-types';
// @local
import { stringAvatar } from './Avatar';

export type TStakeholderAvatarProps = {
	user?: Partial<
		Pick<
			UserTypes.IUserRead,
			'picture' | 'firstName' | 'lastName' | 'fullName'
		>
	>;
	dotColor?: string;
	tooltipTitle?: string;
} & AvatarProps;

export const StakeholderAvatar = ({
	user,
	dotColor = 'info.main',
	tooltipTitle = 'Tooltip Title',
	...rest
}: TStakeholderAvatarProps) => (
	<Tooltip title={tooltipTitle}>
		<Badge
			overlap="circular"
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			variant="dot"
			sx={{
				'& .MuiBadge-badge': {
					bottom: 3,
					position: 'absolute',
					boxShadow: `0 0 0 2px background.neutral`,
					backgroundColor: dotColor,
				},
			}}
		>
			<Avatar
				{...(user &&
					stringAvatar(
						`${
							user?.fullName
								? user.fullName
								: `${user?.firstName || 'U'} ${
										user?.lastName || 'U'
								  }`
						}`
					))}
				sx={{ width: 40, height: 40 }}
				src={user?.picture}
				alt="user-profile-pic"
				{...rest}
			/>
		</Badge>
	</Tooltip>
);
