// @form
import { Controller } from 'react-hook-form';
// @mui
// @local
import { PhoneNumberInput } from '../PhoneNumberInput';
import { type TPhoneNumberInputControllerProps } from '../utils/types';

export type { TPhoneNumberInputControllerProps };
export const PhoneNumberInputController = <T,>({
	name,
	control,
	...rest
}: TPhoneNumberInputControllerProps<T>) => (
	<Controller
		{...{ name, control }}
		render={({ field, formState: { errors } }, { helperText } = rest) => (
			<PhoneNumberInput
				{...field}
				{...rest}
				error={!!errors[name]}
				// eslint-disable-next-line react/jsx-no-useless-fragment
				helperText={helperText || <>{errors[name]?.message}</>}
			/>
		)}
	/>
);
