import { AvatarProps, Avatar as MUIAvatar, styled } from '@mui/material';

export const stringAvatar = (name?: string) => {
	const [firstName, lastName] = (name || '').split(' ');

	if (firstName && lastName) {
		const firstLetter = firstName[0];
		const secondLetter = lastName[0];

		return {
			sx: {
				width: 32,
				height: 32,
			},
			children: `${firstLetter.toLocaleUpperCase()}${secondLetter.toLocaleUpperCase()}`,
		};
	}

	return {};
};

const StyledAvatar = styled(MUIAvatar)(() => ({
	fontSize: '16px',
}));

export type TAvatarProps = AvatarProps & { fullName?: string };

export const Avatar = ({ fullName, ...rest }: TAvatarProps) => (
	<StyledAvatar {...stringAvatar(fullName)} {...rest} />
);
