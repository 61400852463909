"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allUtilities = exports.utilityCategories = void 0;
exports.utilityCategories = [
    'general',
    'kitchen',
    'entertainment',
    'lighting',
    'bedrooms',
    'cooling',
    'heating',
    'pumps',
    'office',
    'all selected',
];
exports.allUtilities = [
    'plugs',
    'fridge',
    'chest freezer',
    'microwave',
    'toaster',
    'iron',
    'air fryer',
    'kettle',
    'washing machine',
    'washing dryer',
    'dish washer',
    'tv',
    'decoder',
    'wifi',
    'cellphone charger',
    'sound system',
    'gaming console',
    'led downlights',
    'led bulbs',
    'outdoor lights',
    'hair dryer',
    'hair straightener',
    'aircon',
    'fan',
    'geyser',
    'stove',
    'water heater',
    'heater',
    'pool pump',
    'bore hole',
    'irrigation',
    'pressure pump',
    'computer',
    'laptop',
    'printer',
];
