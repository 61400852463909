"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Progress = (theme) => {
    const isLight = theme.palette.mode === 'light';
    return {
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                    overflow: 'hidden',
                },
                bar: {
                    borderRadius: 4,
                },
                colorPrimary: {
                    backgroundColor: theme.palette.primary[isLight ? 'lighter' : 'darker'],
                },
                buffer: {
                    backgroundColor: 'transparent',
                },
            },
        },
    };
};
exports.default = Progress;
