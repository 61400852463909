// @form
import { Controller } from 'react-hook-form';
// @mui
// @local
import { Input } from '../Input';
import { TInputControllerProps } from '../utils/types';

export type { TInputControllerProps };
export const InputController = <T,>({
	name,
	control,
	...rest
}: TInputControllerProps<T>) => (
	<Controller
		{...{ name, control }}
		render={({ field, formState: { errors } }, { helperText } = rest) => (
			<Input
				{...field}
				{...rest}
				error={!!errors[name]}
				// eslint-disable-next-line react/jsx-no-useless-fragment
				helperText={helperText || <>{errors[name]?.message}</>}
			/>
		)}
	/>
);
