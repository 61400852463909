"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// @mui
const styles_1 = require("@mui/material/styles");
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Pagination = (theme) => {
    return {
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        fontWeight: theme.typography.fontWeightBold,
                    },
                },
                textPrimary: {
                    '&.Mui-selected': {
                        color: theme.palette.primary.main,
                        backgroundColor: (0, styles_1.alpha)(theme.palette.primary.main, 0.08),
                        '&:hover, &.Mui-focusVisible': {
                            backgroundColor: `${(0, styles_1.alpha)(theme.palette.primary.main, 0.24)} !important`,
                        },
                    },
                },
                outlined: {
                    border: `1px solid ${theme.palette.grey[50032]}`,
                },
                outlinedPrimary: {
                    '&.Mui-selected': {
                        backgroundColor: (0, styles_1.alpha)(theme.palette.primary.main, 0.08),
                        border: `1px solid ${(0, styles_1.alpha)(theme.palette.primary.main, 0.24)}`,
                    },
                },
            },
        },
    };
};
exports.default = Pagination;
