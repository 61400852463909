"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var picknpay = {
    theme: 'picknpay',
    brand: {
        name: 'Pick n Pay',
        legalName: 'Pick n Pay',
        logo: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/picknpay/picknpay-logo.png',
        icon: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/picknpay/picknpay-logo.png',
    },
    partner: {
        name: 'Hohm Energy',
        legalName: 'Hohm Services (Pty) Ltd.',
        logo: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/general/HohmEnergyLogoOrange.png',
    },
    poweredBy: {
        name: 'Hohm Energy',
        legalName: 'Hohm Services (Pty) Ltd.',
        logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/general/HohmEnergyLogoOrange.png',
    },
    pages: {
        client: {
            home: {
                heading: 'Welcome to PnP Home Solar and Battery Solutions',
            },
        },
        login: {
            heading: 'Welcome!',
        },
        signup: {
            heading: 'Claim your free solar or battery offer now.',
            content: 'Generate your customised proposal in 4 minutes.',
            promoImage: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/signUp/iPhone%20%281%29.png',
            callUsNumber: '010 157 1223',
            callUsText: '',
            offerings: [
                {
                    id: 0,
                    heading: 'Say goodbye to load shedding',
                    content: "Together with Hohm Energy, PnP Home will give you and your family peace of mind that you don't have to restructure your life around the inconvenience of load shedding.",
                },
                {
                    id: 1,
                    heading: 'Get the best price',
                    content: 'We have negotiated the best prices from product suppliers and installers to ensure the best value for you.',
                },
                {
                    id: 2,
                    heading: 'Your own energy advisor',
                    content: 'PnP Home and Hohm Energy are with you every step of the way to answer any questions, manage the process and ensure your solar or battery journey is as seamless as possible.',
                },
            ],
            sections: {
                titleSection: {
                    title: "From start to solar, we've got your back.",
                },
            },
        },
    },
    analytics: {
        partnerTagManager: {
            trackingId: 'GTM-59RG9PW9',
        },
    },
};
exports.default = picknpay;
