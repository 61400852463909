"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.propertyQuerySchema = exports.propertyUpdateSchema = exports.propertyWriteSchema = exports.propertyReadSchema = exports.propertySchema = exports.locationSchema = exports.ESSENTIAL_LOADS = exports.subDbBoardSchema = exports.dbBoardSchema = exports.dbBoardDefaultValuesSchema = exports.DB_TYPE = exports.DB_CONDITIONS = exports.phaseTypes = exports.commercialAndResidentialPropertyTypes = exports.EXISTING_BACKUP = exports.PROPERTY_TYPES = exports.COMMERCIAL_PROPERTY_TYPES = exports.RESIDENTIAL_PROPERTY_TYPES = exports.usageMap = exports.USAGES = exports.HIGH_USAGE = exports.MEDIUM_USAGE = exports.LOW_USAGE = exports.DEFAULT_LOCATION = void 0;
var typebox_1 = require("@feathersjs/typebox");
var country_codes_1 = require("../constants/country-codes");
var inverter_types_1 = require("./inverter.types");
var project_types_1 = require("./project.types");
var roof_area_types_1 = require("./roof-area.types");
var general_types_1 = require("./general-types");
exports.DEFAULT_LOCATION = {
    address: '29 Wessel Rd, Edenburg, RIVONIA, 2128, South Africa',
    city: 'RIVONIA',
    country: 'South Africa',
    countryCode: 'za',
    region: 'gauteng',
    countryShortName: 'za',
};
exports.LOW_USAGE = 470;
exports.MEDIUM_USAGE = 970;
exports.HIGH_USAGE = 2100;
exports.USAGES = ['low', 'medium', 'high'];
exports.usageMap = {
    low: exports.LOW_USAGE,
    medium: exports.MEDIUM_USAGE,
    high: exports.HIGH_USAGE,
};
exports.RESIDENTIAL_PROPERTY_TYPES = [
    'complex',
    'cluster',
    'free hold',
    'apartment',
];
exports.COMMERCIAL_PROPERTY_TYPES = [
    'agriculture',
    'commercial-office',
    'industrial',
    'retail-park',
    'shopping-center',
    'farm',
];
exports.PROPERTY_TYPES = __spreadArray(__spreadArray([], exports.COMMERCIAL_PROPERTY_TYPES, true), exports.RESIDENTIAL_PROPERTY_TYPES, true);
exports.EXISTING_BACKUP = ['none', 'ups', 'battery', 'generator'];
exports.commercialAndResidentialPropertyTypes = [
    { id: '1', type: 'agriculture' },
    { id: '2', type: 'commercial-office' },
    { id: '3', type: 'industrial' },
    { id: '4', type: 'retail-park' },
    { id: '5', type: 'shopping-center' },
    { id: '6', type: 'farm' },
    { id: '7', type: 'cluster' },
    { id: '8', type: 'complex' },
    { id: '9', type: 'apartment' },
    { id: '10', type: 'free hold' },
];
exports.phaseTypes = [
    { id: '1', phase: 'single' },
    { id: '2', phase: '3-phase' },
];
exports.DB_CONDITIONS = [
    'In order',
    'Minimum work required',
    'Extensive work required',
];
exports.DB_TYPE = ['mainDb', 'subDb'];
exports.dbBoardDefaultValuesSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    condition: (0, typebox_1.StringEnum)(__spreadArray(__spreadArray([], exports.DB_CONDITIONS, true), [''], false)),
    pictureUrl: typebox_1.Type.String(),
    areaPictureUrl: typebox_1.Type.String(),
    distanceMeters: typebox_1.Type.Number(),
    availableWallSpace: typebox_1.Type.Object({
        answer: typebox_1.Type.Boolean(),
        note: typebox_1.Type.String(),
    }),
});
exports.dbBoardSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Optional(typebox_1.Type.String()),
    condition: (0, typebox_1.StringEnum)(__spreadArray([], exports.DB_CONDITIONS, true)),
    cocRequired: typebox_1.Type.Boolean(),
    pictureUrl: typebox_1.Type.Optional(typebox_1.Type.String()),
    areaPictureUrl: typebox_1.Type.Optional(typebox_1.Type.String()),
    distanceMeters: typebox_1.Type.Optional(typebox_1.Type.Number()),
    availableWallSpace: typebox_1.Type.Optional(typebox_1.Type.Object({
        answer: typebox_1.Type.Boolean(),
        note: typebox_1.Type.String(),
    })),
});
exports.subDbBoardSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    condition: (0, typebox_1.StringEnum)(__spreadArray([], exports.DB_CONDITIONS, true)),
    cocRequired: typebox_1.Type.Boolean(),
    typeOfDb: typebox_1.Type.Optional(typebox_1.Type.String()),
    pictureUrl: typebox_1.Type.Optional(typebox_1.Type.String()),
});
exports.ESSENTIAL_LOADS = [
    'geyser',
    'freezer',
    'fridge',
    'general plugs',
    'wifi',
    'cctv',
    'pool pump',
    'security',
];
exports.locationSchema = typebox_1.Type.Object({
    country: typebox_1.Type.String(),
    countryShortName: typebox_1.Type.Optional((0, typebox_1.StringEnum)(__spreadArray([], country_codes_1.COUNTRY_SUPPORTED_CODES, true))),
    address: typebox_1.Type.String(),
    city: typebox_1.Type.String(),
    region: typebox_1.Type.String(),
    countryCode: typebox_1.Type.Optional((0, typebox_1.StringEnum)(__spreadArray([], country_codes_1.COUNTRY_SUPPORTED_CODES, true))),
});
// type TLoadEager = 'roofAreas' | 'monthlyBills';
var loadEagerSchema = (0, typebox_1.StringEnum)(['roofAreas', 'monthlyBills']);
exports.propertySchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    phase: (0, typebox_1.StringEnum)(__spreadArray([], inverter_types_1.PHASES, true)),
    propertyType: (0, typebox_1.StringEnum)(__spreadArray([], exports.PROPERTY_TYPES, true)),
    propertyStories: typebox_1.Type.Number(),
    coordinates: general_types_1.coordinateSchema,
    location: exports.locationSchema,
    primaryDbBoard: exports.dbBoardSchema,
    primaryDbBoardPicture: typebox_1.Type.String(),
    subDbBoards: typebox_1.Type.Array(exports.subDbBoardSchema),
    essentialLoads: typebox_1.Type.Optional(typebox_1.Type.Record(typebox_1.Type.Union(exports.ESSENTIAL_LOADS.map(function (n) { return typebox_1.Type.Literal(n); })), typebox_1.Type.Boolean())),
    averageHourlyKwhUsage: typebox_1.Type.Number(),
    existingBackup: (0, typebox_1.StringEnum)(__spreadArray([], exports.EXISTING_BACKUP, true)),
    // Relationships
    propertyOwnerId: typebox_1.Type.String(),
    roofAreaIds: typebox_1.Type.Optional(typebox_1.Type.Array(typebox_1.Type.String())),
    createLoadProfile: typebox_1.Type.Boolean(),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.propertyReadSchema = typebox_1.Type.Intersect([
    exports.propertySchema,
    typebox_1.Type.Object({
        roofAreas: typebox_1.Type.Optional(typebox_1.Type.Array(roof_area_types_1.roofAreaReadSchema)),
        projects: typebox_1.Type.Optional(typebox_1.Type.Array(project_types_1.projectReadSchema)),
    }),
]);
exports.propertyWriteSchema = typebox_1.Type.Omit(exports.propertyReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.propertyUpdateSchema = typebox_1.Type.Partial(exports.propertySchema, {
    $id: 'PropertyUpdate',
});
var propertyQueryProperties = typebox_1.Type.Partial(exports.propertySchema, {
    $id: 'PropertyQuery',
});
exports.propertyQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(propertyQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({
        $loadEager: typebox_1.Type.Optional(typebox_1.Type.Array(loadEagerSchema)),
    }, { additionalProperties: false }),
], { additionalProperties: false });
