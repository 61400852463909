"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isTextExistsInDom = void 0;
var isTextExistsInDom = function (searchText) {
    var rootNode = document.body;
    if (!rootNode || !searchText) {
        return false;
    }
    var walker = document.createTreeWalker(rootNode, NodeFilter.SHOW_TEXT, {
        acceptNode: function (node) {
            var _a;
            return ((_a = node.textContent) === null || _a === void 0 ? void 0 : _a.includes(searchText))
                ? NodeFilter.FILTER_ACCEPT
                : NodeFilter.FILTER_SKIP;
        },
    });
    return walker.nextNode() !== null;
};
exports.isTextExistsInDom = isTextExistsInDom;
