import React from 'react';
// @mui
import { Stack, Typography, StackProps, SxProps } from '@mui/material';

export type TFieldValueProps = {
	title: string;
	row?: boolean;
	titleSx?: SxProps;
	valueSx?: SxProps;
	value?: string;
	WrapperProps?: StackProps;
	titleComponent?: React.ReactNode;
	valueComponent?: React.ReactNode;
	noWrap?: boolean;
};

const DefaultColumnProps: StackProps = {
	width: 200,
};

const DefaultRowProps: StackProps = {
	spacing: 2,
	sx: { py: 1 },
	direction: 'row',
	alignItems: 'center',
};

export const CardColumnProps = {
	minWidth: 200,
	sx: { px: 4 },
};

const defaultTitleSx = {
	fontWeight: 'bold',
	fontSize: '14px',
	lineHeight: '18px',
};

const defaultValueSx = {
	fontSize: '12px',
	color: 'GrayText',
	lineHeight: '18px',
	wordWrap: 'break-word !important',
};

export const FieldValue = ({
	title,
	value,
	row = false,
	WrapperProps,
	titleComponent,
	valueComponent,
	titleSx = defaultTitleSx,
	valueSx = defaultValueSx,
	noWrap,
}: TFieldValueProps) => {
	const CustomProps =
		WrapperProps || (row ? DefaultRowProps : DefaultColumnProps);

	return (
		<Stack {...CustomProps}>
			{titleComponent || (
				<Typography noWrap={noWrap} sx={titleSx}>
					{title}
				</Typography>
			)}
			{valueComponent || (
				<Typography noWrap={noWrap} sx={valueSx}>
					{value || '—'}
				</Typography>
			)}
		</Stack>
	);
};
