"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.servicePlanQuerySchema = exports.servicePlanUpdateSchema = exports.servicePlanWriteSchema = exports.servicePlanReadSchema = exports.servicePlanSchema = exports.maintenanceTypeIncludes = exports.SUPPORTED_SERVICEPLAN_TYPES = exports.SUPPORTED_SERVICEPLAN_NAMES = void 0;
var typebox_1 = require("@feathersjs/typebox");
var currency_types_1 = require("./currency.types");
exports.SUPPORTED_SERVICEPLAN_NAMES = [
    '1-Year Package: Light',
    '1-Year Package: Premium',
    '2-Year Package: Light',
    '2-Year Package: Premium',
    '5-Year Package: Light',
    '5-Year Package: Premium',
    '6-Year Package: Light',
    '6-Year Package: Premium',
    '1-Year Investec Package: Premium',
];
exports.SUPPORTED_SERVICEPLAN_TYPES = ['light', 'premium'];
exports.maintenanceTypeIncludes = [
    {
        id: 1,
        name: '1-Year Package: Light',
        includes: [
            'Warranty and repair management',
            'Customer Support',
            'Education, Energy, Updates & Tips',
        ],
    },
    {
        id: 2,
        name: '1-Year Package: Premium',
        includes: [
            'Warranty and repair management',
            'Customer Support',
            'Education, Energy, Updates & Tips',
        ],
    },
    {
        id: 3,
        name: '2-Year Package: Light',
        includes: [
            'Warranty and repair management',
            'Customer Support',
            'Education, Energy, Updates & Tips',
            'Panel Cleaning',
        ],
    },
    {
        id: 4,
        name: '2-Year Package: Premium',
        includes: [
            'Warranty and repair management',
            'Customer Support',
            'Education, Energy, Updates & Tips',
            'Panel Cleaning',
            'Performance Reports',
        ],
    },
    {
        id: 5,
        name: '5-Year Package: Light',
        includes: [
            'Warranty and repair management',
            'Customer Support',
            'Education, Energy, Updates & Tips',
            'Panel Cleaning',
            'Performance Reports',
            'System Inspection',
            'System Enhancement',
        ],
    },
    {
        id: 6,
        name: '5-Year Package: Premium',
        includes: [
            'Warranty and repair management',
            'Customer Support',
            'Education, Energy, Updates & Tips',
            'Panel Cleaning',
            'Performance Reports',
            'System Inspection',
            'System Enhancement',
        ],
    },
    {
        id: 7,
        name: '6-Year Package: Light',
        includes: [
            'Warranty and repair management',
            'Customer Support',
            'Education, Energy, Updates & Tips',
            'Panel Cleaning',
            'Performance Reports',
            'System Inspection',
            'System Enhancement',
        ],
    },
    {
        id: 8,
        name: '6-Year Package: Premium',
        includes: [
            'Warranty and repair management',
            'Customer Support',
            'Education, Energy, Updates & Tips',
            'Panel Cleaning',
            'Performance Reports',
            'System Inspection',
            'System Enhancement',
        ],
    },
    {
        id: 9,
        name: 'No Plan',
        includes: ['Warranty and repair management', 'Customer Support'],
    },
    {
        id: 10,
        name: '1-Year Investec Package: Premium',
        includes: [
            'Warranty and repair management',
            'Customer Support',
            'Education, Energy, Updates & Tips',
        ],
    },
];
exports.servicePlanSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    name: (0, typebox_1.StringEnum)(__spreadArray([], exports.SUPPORTED_SERVICEPLAN_NAMES, true)),
    type: (0, typebox_1.StringEnum)(__spreadArray([], exports.SUPPORTED_SERVICEPLAN_TYPES, true)),
    description: typebox_1.Type.String(),
    timespanInMonths: typebox_1.Type.Number(),
    value: typebox_1.Type.Number(),
    currency: (0, typebox_1.StringEnum)(__spreadArray([], currency_types_1.SUPPORTED_CURRENCY, true)),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.servicePlanReadSchema = typebox_1.Type.Omit(exports.servicePlanSchema, []);
exports.servicePlanWriteSchema = typebox_1.Type.Omit(exports.servicePlanReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.servicePlanUpdateSchema = typebox_1.Type.Partial(exports.servicePlanSchema, {
    $id: 'ServicePlanUpdate',
});
var servicePlanQueryProperties = typebox_1.Type.Partial(exports.servicePlanSchema, {
    $id: 'ServicePlanQuery',
});
exports.servicePlanQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(servicePlanQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
