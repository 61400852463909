"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LoadingButton = (theme) => {
    return {
        MuiLoadingButton: {
            styleOverrides: {
                root: {
                    '&.MuiButton-text': {
                        '& .MuiLoadingButton-startIconPendingStart': {
                            marginLeft: 0,
                        },
                        '& .MuiLoadingButton-endIconPendingEnd': {
                            marginRight: 0,
                        },
                    },
                },
            },
        },
    };
};
exports.default = LoadingButton;
