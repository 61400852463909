"use strict";
// Constants
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoofAreaTypes = exports.PropertyUtilitiesTypes = exports.PropertyTypes = exports.ProjectTypes = exports.ProjectFixedCostTypes = exports.ProductKitTypes = exports.PermissionTypes = exports.PaymentTypes = exports.PanelTypes = exports.OrganisationTypes = exports.MountingConsumableTypes = exports.MonthlyBillTypes = exports.LoadProfileTypes = exports.LeadScoreTypes = exports.KitPanelTypes = exports.KitConsumableTypes = exports.InverterTypes = exports.InstallationVisitTypes = exports.IndicativeProposalTypes = exports.FileTypes = exports.EPVCommentsTypes = exports.EngineeredProposalTypes = exports.EngineeredProposalVersionConstantTypes = exports.EngineeredProposalVersionTypes = exports.DocumentTypes = exports.CurrencyTypes = exports.CommunicationLogsTypes = exports.ConsumableTypes = exports.ClientLogoTypes = exports.CertificationTypes = exports.CaseStudyTypes = exports.CampaignConstantTypes = exports.CampaignTypes = exports.CablingTypes = exports.BusinessTypes = exports.BatteryTypes = exports.AreasOfOperationTypes = exports.APIConnectionTypes = exports.PaymentOptionTypes = exports.SubscriptionFeeTypes = exports.SubscriptionApplicationTypes = exports.CreditProfileTypes = exports.FinanceRequestTypes = exports.SubscriptionRequestTypes = exports.GlintConstants = exports.Variables = exports.Images = exports.Currencies = exports.CountryCodes = exports.Branding = void 0;
exports.ReportsTypes = exports.HubspotTypes = exports.AirtableTypes = exports.CreditCheckTypes = exports.GeneralTypes = exports.HohmI18nTypes = exports.SwitchxTypes = exports.AWSTypes = exports.BusinessLogicTypes = exports.ColorTypes = exports.OAuthTypes = exports.TaxInvoiceTypes = exports.WebsocketTypes = exports.DateTypes = exports.CommunicationSubscriptionTypes = exports.TravelExpensesTypes = exports.LaborLineItemTypes = exports.InternalManagementFeesPricingTypes = exports.PricingLineItemTypes = exports.InstallationPricingTypes = exports.EngineeredProposalPricingTypes = exports.CustomLaborLineItemTypes = exports.DraftProposalTypes = exports.ProjectCreditItemsTypes = exports.UtilitiesTypes = exports.UtilityTypes = exports.UserTypes = exports.TaxTypes = exports.TariffEscalationTypes = exports.SystemDesignTypes = exports.SubscriberTypes = exports.NotificationTypes = exports.SolarRadiationTypes = exports.SiteVisitTypes = exports.ServicePlanTypes = void 0;
exports.Branding = __importStar(require("./constants/branding"));
exports.CountryCodes = __importStar(require("./constants/country-codes"));
exports.Currencies = __importStar(require("./constants/currencies"));
exports.Images = __importStar(require("./constants/images"));
exports.Variables = __importStar(require("./constants/variables"));
exports.GlintConstants = __importStar(require("./constants/glint"));
// Models
// Fintech
exports.SubscriptionRequestTypes = __importStar(require("./fintech/subscription-request.types"));
exports.FinanceRequestTypes = __importStar(require("./fintech/finance-request.types"));
exports.CreditProfileTypes = __importStar(require("./fintech/credit-profile.types"));
exports.SubscriptionApplicationTypes = __importStar(require("./fintech/subscription-applications.types"));
exports.SubscriptionFeeTypes = __importStar(require("./fintech/subscription-fee.types"));
exports.PaymentOptionTypes = __importStar(require("./models/payment-options.types"));
exports.APIConnectionTypes = __importStar(require("./models/api-connection.types"));
exports.AreasOfOperationTypes = __importStar(require("./models/areas-of-operation.types"));
exports.BatteryTypes = __importStar(require("./models/battery.types"));
exports.BusinessTypes = __importStar(require("./models/business.types"));
exports.CablingTypes = __importStar(require("./models/cabling.types"));
exports.CampaignTypes = __importStar(require("./models/campaign.types"));
exports.CampaignConstantTypes = __importStar(require("./models/campaign-constants.types"));
exports.CaseStudyTypes = __importStar(require("./models/case-study.types"));
exports.CertificationTypes = __importStar(require("./models/certification.types"));
exports.ClientLogoTypes = __importStar(require("./models/client-logo.types"));
exports.ConsumableTypes = __importStar(require("./models/consumable.types"));
exports.CommunicationLogsTypes = __importStar(require("./models/communication-logs.types"));
exports.CurrencyTypes = __importStar(require("./models/currency.types"));
exports.DocumentTypes = __importStar(require("./models/document.types"));
exports.EngineeredProposalVersionTypes = __importStar(require("./models/engineered-proposal-version.types"));
exports.EngineeredProposalVersionConstantTypes = __importStar(require("./models/engineered-proposal-version-constants.types"));
exports.EngineeredProposalTypes = __importStar(require("./models/engineered-proposal.types"));
exports.EPVCommentsTypes = __importStar(require("./models/epv-comments.types"));
exports.FileTypes = __importStar(require("./models/file.types"));
exports.IndicativeProposalTypes = __importStar(require("./models/indicative-proposal.types"));
exports.InstallationVisitTypes = __importStar(require("./models/installation-visit.types"));
exports.InverterTypes = __importStar(require("./models/inverter.types"));
exports.KitConsumableTypes = __importStar(require("./models/kit-consumable.types"));
exports.KitPanelTypes = __importStar(require("./models/kit-panel.types"));
exports.LeadScoreTypes = __importStar(require("./models/lead-score.types"));
exports.LoadProfileTypes = __importStar(require("./models/load-profile.types"));
exports.MonthlyBillTypes = __importStar(require("./models/monthly-bill.types"));
exports.MountingConsumableTypes = __importStar(require("./models/mounting-consumable.types"));
exports.OrganisationTypes = __importStar(require("./models/organisation.types"));
exports.PanelTypes = __importStar(require("./models/panel.types"));
exports.PaymentTypes = __importStar(require("./models/payment.types"));
exports.PermissionTypes = __importStar(require("./models/permission.types"));
exports.ProductKitTypes = __importStar(require("./models/product-kit.types"));
exports.ProjectFixedCostTypes = __importStar(require("./models/project-fixed-cost.types"));
exports.ProjectTypes = __importStar(require("./models/project.types"));
exports.PropertyTypes = __importStar(require("./models/property.types"));
exports.PropertyUtilitiesTypes = __importStar(require("./models/property-utilities.types"));
exports.RoofAreaTypes = __importStar(require("./models/roof-area.types"));
exports.ServicePlanTypes = __importStar(require("./models/service-plan.types"));
exports.SiteVisitTypes = __importStar(require("./models/site-visit.types"));
exports.SolarRadiationTypes = __importStar(require("./models/solar-radiation.types"));
exports.NotificationTypes = __importStar(require("./models/notification.types"));
exports.SubscriberTypes = __importStar(require("./models/subscriber.types"));
exports.SystemDesignTypes = __importStar(require("./models/system-design.types"));
exports.TariffEscalationTypes = __importStar(require("./models/tariff-escalation.types"));
exports.TaxTypes = __importStar(require("./models/tax.types"));
exports.UserTypes = __importStar(require("./models/user.types"));
exports.UtilityTypes = __importStar(require("./models/utility.types"));
exports.UtilitiesTypes = __importStar(require("./models/utilities.types"));
exports.ProjectCreditItemsTypes = __importStar(require("./models/project-credit-items.types"));
exports.DraftProposalTypes = __importStar(require("./models/draft-proposal.types"));
exports.CustomLaborLineItemTypes = __importStar(require("./models/custom-labor-line-item.types"));
// Models - pricing
exports.EngineeredProposalPricingTypes = __importStar(require("./models/engineered-proposal-pricing.types"));
exports.InstallationPricingTypes = __importStar(require("./models/installation-pricing.types"));
exports.PricingLineItemTypes = __importStar(require("./models/pricing-line-item.types"));
exports.InternalManagementFeesPricingTypes = __importStar(require("./models/internal-management-fees.types"));
exports.LaborLineItemTypes = __importStar(require("./models/labor-line-item.types"));
exports.TravelExpensesTypes = __importStar(require("./models/travel-expenses.types"));
// Models - communication subscriptions
exports.CommunicationSubscriptionTypes = __importStar(require("./models/comms-subscriptions.types"));
// Utility types
exports.DateTypes = __importStar(require("./utility-types/date.types"));
exports.WebsocketTypes = __importStar(require("./utility-types/websocket.types"));
exports.TaxInvoiceTypes = __importStar(require("./utility-types/tax-invoice.types"));
exports.OAuthTypes = __importStar(require("./utility-types/oauth-types"));
exports.ColorTypes = __importStar(require("./utility-types/color.types"));
exports.BusinessLogicTypes = __importStar(require("./utility-types/business-logic.types"));
// Services types
exports.AWSTypes = __importStar(require("./services/third-parties/AWS/s3.types"));
exports.SwitchxTypes = __importStar(require("./services/third-parties/Switchx.types"));
// Packages
exports.HohmI18nTypes = __importStar(require("./packages/hohm-i18n/hohm-i18n.types"));
exports.GeneralTypes = __importStar(require("./models/general-types"));
// Credit Check
exports.CreditCheckTypes = __importStar(require("./models/credit-check.types"));
// Airtable types
exports.AirtableTypes = __importStar(require("./models/airtable.types"));
// Hubspot types
exports.HubspotTypes = __importStar(require("./models/hubspot.types"));
// Reports
exports.ReportsTypes = __importStar(require("./models/reports.types"));
