"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fileQuerySchema = exports.fileUpdateSchema = exports.fileWriteSchema = exports.fileReadSchema = exports.fileSchema = exports.SUPPORTED_FILE_TYPES = void 0;
var typebox_1 = require("@feathersjs/typebox");
exports.SUPPORTED_FILE_TYPES = ['image', 'document'];
exports.fileSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    name: typebox_1.Type.String(),
    mimeType: typebox_1.Type.String(),
    sizeKb: typebox_1.Type.Optional(typebox_1.Type.Number()),
    filesPath: typebox_1.Type.String(),
    type: (0, typebox_1.StringEnum)(__spreadArray([], exports.SUPPORTED_FILE_TYPES, true)),
    // Relationships
    productId: typebox_1.Type.String(),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.fileReadSchema = typebox_1.Type.Omit(exports.fileSchema, []);
exports.fileWriteSchema = typebox_1.Type.Omit(exports.fileReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.fileUpdateSchema = typebox_1.Type.Partial(exports.fileSchema, {
    $id: 'FileUpdate',
});
var fileQueryProperties = typebox_1.Type.Partial(exports.fileSchema, {
    $id: 'FileQuery',
});
exports.fileQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(fileQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
