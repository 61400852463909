"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.registerResponseSchema = exports.userQuerySchema = exports.userUpdateSchema = exports.userWriteSchema = exports.userReadSchema = exports.userSchema = exports.utmDataSchema = exports.mfaSecretSchema = exports.lastRecordedLocationSchema = exports.SIGN_UP_SOURCE = exports.CLIENT_INTENTS = exports.CLIENT_SIGNUP_INTENTS = exports.OPERATIONAL_COUNTRIES = exports.ACTIONS = exports.CLIENT_STATES = exports.UNSUBSCRIBE_REASONS = exports.IUserTypeComponents = exports.SOCIAL_SOURCES = exports.NONE = exports.EMAIL_TOKEN = exports.TWITTER_TOKEN = exports.FACEBOOK_TOKEN = exports.LINKEDIN_TOKEN = exports.INSTAGRAM_TOKEN = void 0;
var typebox_1 = require("@feathersjs/typebox");
var country_codes_1 = require("../constants/country-codes");
var campaign_constants_types_1 = require("./campaign-constants.types");
var currency_types_1 = require("./currency.types");
var general_types_1 = require("./general-types");
exports.INSTAGRAM_TOKEN = 'instagram';
exports.LINKEDIN_TOKEN = 'linkedin';
exports.FACEBOOK_TOKEN = 'facebook';
exports.TWITTER_TOKEN = 'twitter';
exports.EMAIL_TOKEN = 'email';
exports.NONE = 'none';
exports.SOCIAL_SOURCES = [
    exports.INSTAGRAM_TOKEN,
    exports.LINKEDIN_TOKEN,
    exports.FACEBOOK_TOKEN,
    exports.TWITTER_TOKEN,
    exports.EMAIL_TOKEN,
    exports.NONE,
];
var IUserTypeComponents;
(function (IUserTypeComponents) {
    IUserTypeComponents["super"] = "super";
    IUserTypeComponents["client"] = "client";
    IUserTypeComponents["epc"] = "epc";
    IUserTypeComponents["advisor"] = "energy-advisor";
    IUserTypeComponents["investecAdvisor"] = "investec-advisor";
    IUserTypeComponents["cia"] = "cia";
})(IUserTypeComponents || (exports.IUserTypeComponents = IUserTypeComponents = {}));
exports.UNSUBSCRIBE_REASONS = [
    'I already have a solar system installed',
    "I don't want a solar system installed",
    "I don't have access to finance",
    'I am not a home owner',
    'I have selected another solar provider',
];
exports.CLIENT_STATES = [
    'to be assigned',
    'active lead',
    'inactive lead',
    'drop-off',
    'on-hold',
];
exports.ACTIONS = [
    'create-hohm-client',
    'create-epc-user',
    'create-sales-rep',
    'create-ip-client',
    'create-cia-client',
    'create-installer-client',
    'update-assigned-ea',
    'reassign-client-to-advisor',
];
exports.OPERATIONAL_COUNTRIES = ['south africa', 'united states'];
exports.CLIENT_SIGNUP_INTENTS = [
    'I am looking for solar now',
    'I am looking to go solar in 2 - 3 months',
    'I am researching solar options',
];
exports.CLIENT_INTENTS = __spreadArray(__spreadArray([], exports.CLIENT_SIGNUP_INTENTS, true), [
    'I am looking for Solar-as-a-Service',
], false);
exports.SIGN_UP_SOURCE = ['leapfrog', 'ip-shortcut'];
exports.lastRecordedLocationSchema = typebox_1.Type.Object({
    countryCode: (0, typebox_1.StringEnum)(__spreadArray([], country_codes_1.COUNTRY_SUPPORTED_CODES, true)),
    city: typebox_1.Type.String(),
    continent: typebox_1.Type.String(),
    region: typebox_1.Type.String(),
});
exports.mfaSecretSchema = typebox_1.Type.Object({
    ascii: typebox_1.Type.String(),
    base32: typebox_1.Type.String(),
    hex: typebox_1.Type.String(),
    otpauth_url: typebox_1.Type.String(),
});
exports.utmDataSchema = typebox_1.Type.Object({
    utm_source: typebox_1.Type.Optional(typebox_1.Type.String()),
    utm_medium: typebox_1.Type.Optional(typebox_1.Type.String()),
    utm_campaign: typebox_1.Type.Optional(typebox_1.Type.String()),
    utm_term: typebox_1.Type.Optional(typebox_1.Type.String()),
    utm_content: typebox_1.Type.Optional(typebox_1.Type.String()),
});
var MEETING_URLS_TYPE = ['glint-kickoff', 'quick-meeting', 'vsv'];
// type TLoadEager = 'permissions';
var loadEagerSchema = (0, typebox_1.StringEnum)(['permissions', 'projects', 'sparkAdvisor']);
exports.userSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    firstName: typebox_1.Type.String(),
    lastName: typebox_1.Type.String(),
    email: typebox_1.Type.String(),
    kindeId: typebox_1.Type.Union([typebox_1.Type.String(), typebox_1.Type.Null()]),
    password: typebox_1.Type.String(),
    contactNumber: typebox_1.Type.String(),
    picture: typebox_1.Type.String(),
    verified: typebox_1.Type.Boolean(),
    verificationCode: typebox_1.Type.Union([typebox_1.Type.String(), typebox_1.Type.Null()]),
    resetToken: typebox_1.Type.Optional(typebox_1.Type.String()),
    resetCode: typebox_1.Type.Optional(typebox_1.Type.String()),
    campaignToken: (0, typebox_1.StringEnum)(__spreadArray([], campaign_constants_types_1.CAMPAIGNS, true)),
    socialSource: typebox_1.Type.Optional((0, typebox_1.StringEnum)(__spreadArray([], exports.SOCIAL_SOURCES, true))),
    myOrganisationId: typebox_1.Type.String(),
    primaryLocation: typebox_1.Type.Optional(general_types_1.pointSchema),
    address: typebox_1.Type.String(),
    timezone: typebox_1.Type.String(),
    currency: (0, typebox_1.StringEnum)(__spreadArray([], currency_types_1.SUPPORTED_CURRENCY, true)),
    lastRecordedLocation: typebox_1.Type.Optional(exports.lastRecordedLocationSchema),
    operationalCountries: typebox_1.Type.Optional(typebox_1.Type.Array((0, typebox_1.StringEnum)(__spreadArray([], exports.OPERATIONAL_COUNTRIES, true)))),
    hubspotId: typebox_1.Type.Optional(typebox_1.Type.String()),
    signUpSource: typebox_1.Type.Optional((0, typebox_1.StringEnum)(__spreadArray([], exports.SIGN_UP_SOURCE, true))),
    clientState: (0, typebox_1.StringEnum)(__spreadArray([], exports.CLIENT_STATES, true)),
    metaData: typebox_1.Type.Optional(typebox_1.Type.Record(typebox_1.Type.String(), typebox_1.Type.String())),
    lastResponseDate: typebox_1.Type.Date(),
    clientIntent: (0, typebox_1.StringEnum)(__spreadArray([], exports.CLIENT_INTENTS, true)),
    idNumber: typebox_1.Type.String(),
    permissionIds: typebox_1.Type.Array(typebox_1.Type.String()),
    utmData: typebox_1.Type.Optional(exports.utmDataSchema),
    campaignId: typebox_1.Type.Optional(typebox_1.Type.String()),
    sparkAdvisorId: typebox_1.Type.String(),
    teamId: typebox_1.Type.Optional(typebox_1.Type.String()),
    action: typebox_1.Type.Optional((0, typebox_1.StringEnum)(__spreadArray([], exports.ACTIONS, true))),
    selectedKit: typebox_1.Type.String(),
    mfaEnabled: typebox_1.Type.Boolean(),
    mfaSecret: typebox_1.Type.Optional(exports.mfaSecretSchema),
    glint_acceptance: typebox_1.Type.Optional(typebox_1.Type.Boolean()),
    fullName: typebox_1.Type.String(),
    hubspotMeetingUrls: typebox_1.Type.Optional(typebox_1.Type.Object({
        'glint-kickoff': typebox_1.Type.String(),
        'quick-meeting': typebox_1.Type.String(),
        vsv: typebox_1.Type.String(),
    }, { additionalProperties: false })),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
    isReferralSuccessful: typebox_1.Type.Boolean(),
});
exports.userReadSchema = typebox_1.Type.Intersect([
    typebox_1.Type.Omit(exports.userSchema, ['primaryLocation']),
    typebox_1.Type.Object({
        primaryLocation: general_types_1.coordinateSchema,
        advisor: typebox_1.Type.Optional(exports.userSchema),
    }),
]);
exports.userWriteSchema = typebox_1.Type.Omit(exports.userSchema, [
    'id',
    'createdAt',
    'updatedAt',
    'fullName',
]);
exports.userUpdateSchema = typebox_1.Type.Partial(exports.userWriteSchema, {
    $id: 'UserUpdate',
});
var userQueryProperties = typebox_1.Type.Partial(exports.userReadSchema, {
    $id: 'UserQuery',
});
// TODO review this
exports.userQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(userQueryProperties, {
        contactNumber: {
            $ilike: typebox_1.Type.Optional(typebox_1.Type.String()),
        },
    }),
    typebox_1.Type.Object({
        $loadEager: typebox_1.Type.Optional(typebox_1.Type.Array(loadEagerSchema)),
        '$permissions.permission$': typebox_1.Type.Optional(typebox_1.Type.String()),
        '$matchedInstallers.id$': typebox_1.Type.Optional(typebox_1.Type.Number()),
        '$logs.lastProposalGenerationStep$': typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.String(), typebox_1.Type.Boolean()])),
        '$logs.type$': typebox_1.Type.Optional(typebox_1.Type.String()),
        '$comms_subscriptions.subscribed$': typebox_1.Type.Optional(typebox_1.Type.Boolean()),
        '$comms_subscriptions.subscription$': typebox_1.Type.Optional(typebox_1.Type.String()),
        '$campaign.campaignType$': typebox_1.Type.Optional(typebox_1.Type.String()),
    }, { additionalProperties: false }),
], { additionalProperties: false });
exports.registerResponseSchema = typebox_1.Type.Object({
    success: typebox_1.Type.Boolean(),
    message: typebox_1.Type.String(),
    user: typebox_1.Type.Optional(exports.userReadSchema),
    permissions: typebox_1.Type.Array(typebox_1.Type.String()),
});
var VALID_PAGE_ROLES = [
    'admin',
    'advisor',
    'installer',
    'main',
    null,
];
var CLIENT_SECTIONS = [
    'proposals',
    'properties',
    'education',
    'bookings',
    'advisor',
];
