"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const themeOptions = {
    palette: {
        primary: {
            lighter: '#FFBF83',
            light: '#FF9E44',
            dark: '#D66700',
            darker: '#AC5300',
            main: '#FF7A00',
            contrastText: '#FFFFFF',
        },
        secondary: {
            lighter: '#1E3EDB',
            light: '#0D259C',
            dark: '#040718',
            darker: '#000000',
            main: '#080F33',
            contrastText: '#FFFFFF',
        },
    },
};
exports.default = themeOptions;
