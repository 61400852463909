"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.paymentQuerySchema = exports.paymentUpdateSchema = exports.paymentWriteSchema = exports.paymentReadSchema = exports.paymentSchema = void 0;
var typebox_1 = require("@feathersjs/typebox");
exports.paymentSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    type: typebox_1.Type.String(),
    amount: typebox_1.Type.String(),
    date: typebox_1.Type.Date(),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.paymentReadSchema = typebox_1.Type.Omit(exports.paymentSchema, []);
exports.paymentWriteSchema = typebox_1.Type.Omit(exports.paymentReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.paymentUpdateSchema = typebox_1.Type.Partial(exports.paymentSchema, {
    $id: 'PaymentUpdate',
});
var paymentQueryProperties = typebox_1.Type.Partial(exports.paymentSchema, {
    $id: 'PaymentQuery',
});
exports.paymentQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(paymentQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
