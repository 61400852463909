import { forwardRef, ReactElement, Ref } from 'react';
// @mui
import { Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

export const DialogTransition = forwardRef(
	(
		props: TransitionProps & {
			children: ReactElement<unknown, any>;
		},
		ref: Ref<unknown>
	) => <Slide direction="up" ref={ref} {...props} />
);
