"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clientLogoQuerySchema = exports.clientLogoUpdateSchema = exports.clientLogoWriteSchema = exports.clientLogoReadSchema = exports.clientLogoSchema = void 0;
var typebox_1 = require("@feathersjs/typebox");
exports.clientLogoSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    name: typebox_1.Type.String(),
    imageUrl: typebox_1.Type.String(),
    organisationId: typebox_1.Type.String(),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.clientLogoReadSchema = typebox_1.Type.Omit(exports.clientLogoSchema, []);
exports.clientLogoWriteSchema = typebox_1.Type.Omit(exports.clientLogoReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.clientLogoUpdateSchema = typebox_1.Type.Partial(exports.clientLogoSchema, {
    $id: 'ClientLogoUpdate',
});
var clientLogoQueryProperties = typebox_1.Type.Partial(exports.clientLogoSchema, {
    $id: 'ClientLogoQuery',
});
exports.clientLogoQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(clientLogoQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
