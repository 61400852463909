import React from 'react';
import { useDebounce } from 'react-use';
import { Application } from '@feathersjs/feathers/lib';
// queries
import { Api, EntityQueries } from 'hohm-queries';
// @local
import { Autocomplete } from './Autocomplete';

export type TEntitySearchProps<T> = {
	selectEntity: (supplier: T) => void;
	entity: T | null;
	searchFields: string[];
	query: Api.TQuery<T>;
	entityName: Api.TApiEntities;
	label: string;
	getOptionLabel: ((entity: T) => string) | undefined;
	isOptionEqualToValue: ((option: T, value: T) => boolean) | undefined;
	client: Application<any, any>;
};
export const EntitySearch = <T,>({
	selectEntity,
	entity,
	searchFields,
	query,
	entityName,
	label,
	getOptionLabel,
	isOptionEqualToValue,
	client,
}: TEntitySearchProps<T>) => {
	const api = new Api.default(client);
	const queries = new EntityQueries(api);

	const [searchText, setSearchText] = React.useState('');
	const [debouncedText, setDebouncedText] = React.useState('');

	const splitValues = debouncedText.split(' ');

	const searchParams = splitValues.reduce(
		(acc, val) => {
			searchFields.forEach((field) => {
				acc.push({ [field]: { $iLike: `%${val}%` } });
			});

			return acc;
		},
		[] as { [field: string]: { $iLike: string } }[]
	);

	const searchQuery = {
		...query,
		$or: searchParams,
	} as Api.TQuery<T>;

	const { data: suppliers, isLoading } = queries.useGetEntities<T>(
		entityName,
		searchQuery,
		{
			keepPreviousData: true,
		}
	);

	useDebounce(
		() => {
			setDebouncedText(searchText);
		},
		300,
		[searchText]
	);

	const searchForEntity = (
		searchEvent: React.ChangeEvent<HTMLInputElement>
	) => {
		const { value } = searchEvent.target;

		setSearchText(value);
	};

	const changeHandler = (
		e: React.ChangeEvent<unknown>,
		selected: T | null
	) => {
		if (selected != null) {
			selectEntity(selected);
		}
	};

	return (
		<Autocomplete
			label={label}
			options={suppliers?.data}
			loading={isLoading}
			onChange={changeHandler}
			InputProps={{
				onChange: searchForEntity,
			}}
			defaultValue={entity}
			getOptionLabel={getOptionLabel}
			isOptionEqualToValue={isOptionEqualToValue}
			popupIcon
			value={entity}
		/>
	);
};
