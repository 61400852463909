"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Fallback rules are currently not functional due to
// the way the i18n library is being initialised
// The reason its being initalised this way is because
// initialisation happens asynchronously, so for the first
// page load, we need to make sure the library is initialised
// and ready for immediate use
var fallbackRules = {
    translation: [],
    betterbond: ['za', 'translation'],
    bmwfinancialservices: ['za', 'translation'],
    captivepower: ['za', 'translation'],
    clearscore: ['za', 'translation'],
    fnb: ['za', 'translation'],
    glint: ['za', 'translation'],
    investec: ['za', 'translation'],
    magnetoenergy: ['za', 'translation'],
    makro: ['za', 'translation'],
    mspd: ['za', 'translation'],
    mtn: ['za', 'translation'],
    nedbank: ['za', 'translation'],
    nedbankmfc: ['za', 'translation'],
    oldmutual: ['za', 'translation'],
    ooba: ['za', 'translation'],
    pathwayscu: ['us', 'translation'],
    picknpay: ['za', 'translation'],
    za: ['translation'],
    us: ['translation'],
    sasfin: ['za', 'translation'],
    skyworthenergy: ['za', 'translation'],
    solarblackfriday: ['za', 'translation'],
    yoursolar: ['za', 'translation'],
    'cia-onboarding-form': ['za', 'translation'],
};
exports.default = fallbackRules;
