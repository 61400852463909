import React from 'react';
// @form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
// @mui
import {
	Grid,
	Typography,
	Alert,
	Collapse,
	Hidden,
	Stack,
	InputAdornment,
	IconButton,
	Button as MUIButton,
	styled,
} from '@mui/material';
// @icons
import { Visibility, VisibilityOff } from '@mui/icons-material';
// @hohm-types
import { PermissionTypes } from 'hohm-types';
// @components
import { Button, Navlink, InputController } from 'hohm-ui';
import { t } from 'hohm-i18n';
// @local
import useStyles from './styles';

const validationSchema = Yup.object().shape({
	email: Yup.string().email().lowercase().required().label('Email address'),
	password: Yup.string().label('Password').required(),
});

const StyledLogo = styled('img')(({ theme }) => ({
	marginTop: 10,
	opacity: 1,
	[theme.breakpoints.down('sm')]: {
		marginTop: 20,
	},
}));

interface IFormValues {
	email: string;
	password: string;
}

interface IProps {
	login: (payload: { email: string; password: string }) => Promise<{
		success: boolean;
		message: string | null;
		permissions: string[];
	}>;
	redirect: () => void;
	permission?: PermissionTypes.TPermission;
}

const Login = ({ login, redirect, permission }: IProps) => {
	const classes = useStyles();
	const [showPass, setShowPass] = React.useState(false);
	const [error, setError] = React.useState<string | null>(null);

	const pageHeading = t('pages.login.heading');
	const brand = t('brand');

	const { handleSubmit, control, setValue } = useForm<IFormValues>({
		resolver: yupResolver(validationSchema),
	});

	const handleClickShowPassword = () => {
		setShowPass((curr) => !curr);
	};

	const performLogin = async ({ email, password }: IFormValues) => {
		setError(null);

		const result = await login({
			email,
			password,
		});

		const { success } = result;

		if (success === false) {
			setValue('password', '');
			setError(result.message);
		} else {
			redirect();
		}
	};

	const showSignUp = React.useMemo(() => {
		const validPermissions = ['isClient'];

		return validPermissions.includes(
			permission as PermissionTypes.TPermission
		);
	}, [permission]);

	return (
		<Grid
			container
			direction="row"
			alignItems="center"
			justifyContent="center"
			className={classes.background}
		>
			<Hidden mdDown>
				<Grid item xs={12} sm={6} md={6} className={classes.gridImg}>
					<div className={classes.overlay}>
						<div className={classes.centered}>
							<Typography
								gutterBottom
								className={classes.overlayHeading}
							>
								Hassle-free solar and battery solutions.
							</Typography>
							<Typography variant="h4" style={{ color: '#fff' }}>
								We take care of your solar or battery project
								from A to Z, up to 70% faster and 20% cheaper
								than alternative providers
							</Typography>
							<br />
							<Typography className={classes.overlaySubHeading}>
								Your very own Energy Advisor is here to guide
								you through the next steps to keep the lights on
							</Typography>
						</div>
					</div>
				</Grid>
			</Hidden>

			<Grid
				item
				xs={12}
				sm={12}
				md={6}
				lg={6}
				container
				alignItems="center"
				justifyContent="center"
			>
				<Stack spacing={2} className={classes.paper}>
					<Stack alignContent="center" alignItems="center">
						<StyledLogo
							sx={{
								maxHeight: '180px',
								maxWidth: '200px',
							}}
							src={brand.logo}
							alt={`${brand.name} logo`}
						/>
					</Stack>

					<Typography className={classes.welcome}>
						{pageHeading || 'Welcome back!'}
					</Typography>
					<Typography className={classes.subHeader}>
						Log in to manage your solar or battery journey
					</Typography>
					<form onSubmit={handleSubmit(performLogin)}>
						<Stack spacing={1} alignItems="center">
							<InputController
								type="email"
								name="email"
								label="Email"
								{...{ control }}
							/>

							<InputController
								type={showPass ? 'text' : 'password'}
								name="password"
								label="Your Password"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												edge="end"
												onClick={
													handleClickShowPassword
												}
											>
												{showPass ? (
													<Visibility />
												) : (
													<VisibilityOff />
												)}
											</IconButton>
										</InputAdornment>
									),
								}}
								{...{ control }}
							/>

							<Button
								variant="contained"
								color="primary"
								type="submit"
								fullWidth
							>
								Login
							</Button>

							{showSignUp && (
								<MUIButton
									LinkComponent={Navlink}
									href="/signup"
									variant="outlined"
									color="secondary"
									fullWidth
								>
									Sign up
								</MUIButton>
							)}
							{process.env.NEXT_PUBLIC_ENVIRONMENT !==
							'production' ? (
								<>
									<br />
									<MUIButton
										LinkComponent={Navlink}
										href="/select-kit?step=authenticate"
										variant="contained"
										color="secondary"
										fullWidth
									>
										Get my Proposal
									</MUIButton>
								</>
							) : null}

							<Collapse in={error != null} sx={{ width: '100%' }}>
								<Alert sx={{ width: '100%' }} severity="error">
									{error}
								</Alert>
							</Collapse>

							<Navlink
								href="/forgot-password"
								className={classes.navLink}
							>
								<Typography className={classes.forgot}>
									Forgot your password?
								</Typography>
							</Navlink>
						</Stack>
					</form>
				</Stack>
			</Grid>
		</Grid>
	);
};

export default Login;
