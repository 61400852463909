// @mui
import { Stack, Typography } from '@mui/material';
// @local
import { blackFridayTerms, termsAndConditions } from './terms';

export type TTermsAndConditionsProps = Record<string, never>;
export const TermsAndConditions = () => {
	return (
		<Stack
			direction="column"
			spacing={2}
			justifyContent="space-between"
			mb={7}
			px={4}
			py={2}
		>
			{' '}
			<Stack
				direction="column"
				spacing={2}
				justifyContent="space-between"
				sx={{
					height: '100%',
					paddingTop: 2,
				}}
				mb={7}
			>
				<Stack
					spacing={2}
					direction="column"
					px={4}
					justifyContent="center"
					alignContent="center"
					alignItems="center"
				>
					<Typography
						variant="h6"
						textAlign="left"
						my={2}
						color="secondary.main"
					>
						BLACK FRIDAY PROMOTION FOR GLINT SOLAR SUBSCRIPTION
						SERVICES
					</Typography>
				</Stack>
				<Stack spacing={4} direction="column">
					<Stack direction="column" spacing={2}>
						{blackFridayTerms.map((termsAndCondition) => {
							return (
								<>
									<Typography
										key={termsAndCondition.item}
										color="secondary.main"
										sx={{
											py: 0,
											ml: 10,
										}}
									>
										<Stack direction="row">
											<span
												style={{
													fontWeight: 'bold',
													marginRight: 10,
												}}
											>
												{termsAndCondition.id}.{' '}
											</span>
											{termsAndCondition.item}
										</Stack>
									</Typography>
									{termsAndCondition?.subItems &&
										termsAndCondition?.subItems.map(
											(subItems) => (
												<>
													<Stack key={subItems.id}>
														<Typography
															color="secondary.main"
															sx={{
																py: 0,
																ml: 5,
															}}
														>
															<Stack direction="row">
																<span
																	style={{
																		fontWeight:
																			'bold',
																		marginRight: 10,
																	}}
																>
																	{
																		subItems.id
																	}
																	.{' '}
																</span>
																{
																	subItems.subItem
																}
															</Stack>
														</Typography>
													</Stack>
													{subItems.subTexts &&
														subItems.subTexts.map(
															(subTexts) => (
																<Stack
																	key={
																		subItems.subItem
																	}
																>
																	<Typography
																		color="secondary.main"
																		sx={{
																			py: 0,
																			ml: 10,
																		}}
																	>
																		<Stack direction="row">
																			<span
																				style={{
																					fontWeight:
																						'bold',
																					marginRight: 10,
																				}}
																			>
																				{
																					subTexts.id
																				}

																				.{' '}
																			</span>
																			{
																				subTexts.subText
																			}
																		</Stack>
																	</Typography>
																</Stack>
															)
														)}
												</>
											)
										)}
								</>
							);
						})}
					</Stack>
				</Stack>
				<Stack
					spacing={2}
					direction="column"
					px={4}
					justifyContent="center"
					alignContent="center"
					alignItems="center"
				>
					<Typography
						variant="h6"
						textAlign="left"
						my={2}
						color="secondary.main"
					>
						SUBSCRIPTION AGREEMENT SERVICES
					</Typography>
					<Typography
						variant="h6"
						textAlign="left"
						my={2}
						color="secondary.main"
					>
						BETWEEN GLINT ORIGINATION SERVICES AND THE SUBSCRIBER{' '}
					</Typography>
				</Stack>
				<Stack spacing={4} direction="column">
					<Stack direction="column" spacing={2}>
						<Stack spacing={1}>
							<Typography
								color="secondary.main"
								variant="body1"
								fontWeight="bold"
							>
								GENERAL TERMS AND CONDITIONS{' '}
							</Typography>
						</Stack>
					</Stack>
					<Stack direction="column" spacing={2}>
						<Stack spacing={1}>
							<Typography
								color="secondary.main"
								variant="body1"
								fontWeight="bold"
							>
								These are the Terms and Conditions (“Terms”)
								which will govern your application to and
								agreement with the Service Provider in respect
								of subscription for Services.{' '}
							</Typography>
						</Stack>
					</Stack>
					<Stack spacing={2} direction="column">
						<Typography color="secondary.main" variant="body1">
							Before applying for a Subscription, you should
							carefully read and understand these Terms because
							together they govern the Subscription by you from
							us, including your Payment Obligations and what
							happens if you breach this Agreement and they shall
							be binding on your heirs, successors-in -title and
							assignees.
						</Typography>
						<Typography color="secondary.main" variant="body1">
							If you do not agree with these Terms or any step in
							your Application, please click “Cancel” on the
							Interface.
						</Typography>
					</Stack>
					<Stack direction="column" spacing={2}>
						<Stack spacing={1}>
							<Typography
								color="secondary.main"
								variant="body1"
								fontWeight="bold"
							>
								By clicking “I accept”, you confirm that you
								have read, understood, and agree to the Terms,
								that this Agree- ment was complete and that you
								have been able to ask any questions in relation
								thereto.
							</Typography>
							<Typography color="secondary.main" variant="body1">
								<span
									style={{
										fontWeight: 'bold',
									}}
								>
									In terms of the Consumer Protection Act 68
									of 2008 (“CPA”) there are clauses in bold in
									these Terms that re- quire your careful
									attention and consideration, and you should
									ensure that you fully understand what they
									mean. These may limit your rights, make you
									liable, or require you to indemnify a third
									party or confirm facts.
								</span>{' '}
								Nothing in these Terms is intended or must be
								understood to unlawfully restrict, limit, or
								avoid any right or obligation created for either
								you or us in terms of the CPA.
							</Typography>
						</Stack>
					</Stack>
					<Stack spacing={4} direction="column">
						<Stack direction="column" spacing={2}>
							{termsAndConditions.map((termsAndCondition) => {
								return (
									<Stack
										direction="column"
										spacing={2}
										key={termsAndCondition.id}
									>
										<Typography
											color="secondary.main"
											fontWeight="bold"
											sx={{
												py: 0,
												ml: 10,
											}}
										>
											<Stack direction="row">
												<span
													style={{
														fontWeight: 'bold',
														marginRight: 10,
													}}
												>
													{termsAndCondition.id}.{' '}
												</span>
												{termsAndCondition.header}
											</Stack>
										</Typography>
										{termsAndCondition.subHeader && (
											<Typography
												color="secondary.main"
												sx={{
													py: 0,
													ml: 10,
												}}
											>
												<span
													style={{
														fontWeight: 'bold',
														marginRight: 10,
													}}
												/>
												{termsAndCondition.subHeader}
											</Typography>
										)}
										<Stack ml={10}>
											{termsAndCondition.items.map(
												(tcs) => (
													<>
														<Typography
															key={tcs.id}
															color="secondary.main"
															sx={{
																py: 0,
																ml: 5,
																mb: 1,
															}}
														>
															<Stack direction="row">
																<span
																	style={{
																		fontWeight:
																			'bold',
																		marginRight: 10,
																	}}
																>
																	{tcs.id}.{' '}
																</span>{' '}
																<Typography>
																	{tcs.highlight && (
																		<span
																			style={{
																				fontWeight:
																					'bold',
																				marginRight: 10,
																			}}
																		>
																			{
																				tcs?.highlight
																			}
																		</span>
																	)}
																	{tcs.item}
																</Typography>
															</Stack>
														</Typography>
														{tcs.subItems &&
															tcs.subItems.map(
																(subItem) => (
																	<>
																		<Typography
																			key={
																				subItem.id
																			}
																			color="secondary.main"
																			sx={{
																				py: 0,
																				ml: 9,
																				mb: 1,
																			}}
																		>
																			<Stack direction="row">
																				<span
																					style={{
																						fontWeight:
																							'bold',
																						marginRight: 10,
																					}}
																				>
																					{
																						subItem.id
																					}

																					.{' '}
																				</span>{' '}
																				{
																					subItem.subItem
																				}
																			</Stack>
																		</Typography>
																		{subItem.subTexts &&
																			subItem.subTexts?.map(
																				(
																					subText
																				) => (
																					<Typography
																						key={
																							subText.id
																						}
																						color="secondary.main"
																						sx={{
																							py: 0,
																							ml: 15,
																							mb: 1,
																						}}
																					>
																						<Stack direction="row">
																							<span
																								style={{
																									fontWeight:
																										'bold',
																									marginRight: 10,
																								}}
																							>
																								{
																									subText.id
																								}

																								.{' '}
																							</span>{' '}
																							{
																								subText.text
																							}
																						</Stack>
																					</Typography>
																				)
																			)}
																	</>
																)
															)}
													</>
												)
											)}
										</Stack>
									</Stack>
								);
							})}
						</Stack>
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	);
};
