// types
import { PermissionTypes } from 'hohm-types';

export type TRenderPermissionsProps = {
	permissions: Pick<PermissionTypes.IPermissionRead, 'id' | 'name'>[];
};
export const RenderPermissions: React.FC<TRenderPermissionsProps> = ({
	permissions,
}) => (
	<ul
		style={{
			display: 'grid',
			gridTemplateColumns: 'repeat(2, 1fr)',
			gap: '8px',
			margin: '4px 0',
			width: '100%',
		}}
	>
		{permissions.map(({ id, name }) => (
			<li
				key={id}
				style={{
					fontSize: '12px',
					fontWeight: 'bold',
					marginLeft: '2px',
				}}
			>
				{name}
			</li>
		))}
	</ul>
);
