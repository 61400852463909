"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.installationVisitQuerySchema = exports.installationVisitUpdateSchema = exports.installationVisitWriteSchema = exports.installationVisitReadSchema = exports.installationVisitSchema = void 0;
var typebox_1 = require("@feathersjs/typebox");
exports.installationVisitSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Number(),
    // Hardware delivery and storage
    clientName: typebox_1.Type.String(),
    solarInstallerName: typebox_1.Type.String(),
    stockPictures: typebox_1.Type.Array(typebox_1.Type.String()),
    hardwareComponentsRequired: typebox_1.Type.Array(typebox_1.Type.String()),
    hardwareComponentsMissing: typebox_1.Type.Array(typebox_1.Type.String()),
    explanationIfComponentsMissing: typebox_1.Type.String(),
    componentsDamaged: typebox_1.Type.Boolean(),
    explanationIfComponentsDamaged: typebox_1.Type.String(),
    currentStorageLocationSecure: typebox_1.Type.String(),
    // Installation process and pictures
    confirmLocationToInstall: typebox_1.Type.Boolean(),
    projectedTimeForCompletion: typebox_1.Type.String(),
    specialRequirementsForClient: typebox_1.Type.String(),
    smartMeterInstalled: typebox_1.Type.Boolean(),
    accountSetup: typebox_1.Type.Boolean(),
    loginDetailsProvided: typebox_1.Type.Boolean(),
    appDownloadedOnClientDevice: typebox_1.Type.Boolean(),
    picturesDayOne: typebox_1.Type.Array(typebox_1.Type.String()),
    picturesDayTwo: typebox_1.Type.Array(typebox_1.Type.String()),
    picturesDayThree: typebox_1.Type.Array(typebox_1.Type.String()),
    picturesDayFour: typebox_1.Type.Array(typebox_1.Type.String()),
    picturesDayFive: typebox_1.Type.Array(typebox_1.Type.String()),
    // Confirmation
    clientSignature: typebox_1.Type.Optional(typebox_1.Type.String()),
    installerSignature: typebox_1.Type.Optional(typebox_1.Type.String()),
    // Relationships
    propertyId: typebox_1.Type.Number(),
    userId: typebox_1.Type.Number(),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.installationVisitReadSchema = typebox_1.Type.Omit(exports.installationVisitSchema, []);
exports.installationVisitWriteSchema = typebox_1.Type.Omit(exports.installationVisitReadSchema, ['id', 'createdAt', 'updatedAt']);
exports.installationVisitUpdateSchema = typebox_1.Type.Partial(exports.installationVisitSchema, {
    $id: 'InstallationVisitUpdate',
});
var installationVisitQueryProperties = typebox_1.Type.Partial(exports.installationVisitSchema, {
    $id: 'InstallationVisitQuery',
});
exports.installationVisitQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(installationVisitQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
