"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateAzimuth = void 0;
var hohm_types_1 = require("hohm-types");
var angleTolerance = 22.5;
var fullCircleAngle = 360;
var validateAzimuth = function (direction, azimuth) {
    var expectedAzimuth = hohm_types_1.RoofAreaTypes.azimuthLookup[direction];
    var minAzimuth = (expectedAzimuth - angleTolerance + fullCircleAngle) % fullCircleAngle;
    var maxAzimuth = (expectedAzimuth + angleTolerance) % fullCircleAngle;
    if ((azimuth >= minAzimuth && azimuth <= expectedAzimuth) ||
        (azimuth <= maxAzimuth && azimuth >= expectedAzimuth)) {
        return { isValid: true, message: 'Azimuth is valid.' };
    }
    var message = "Entered azimuth ".concat(azimuth, " is not within the acceptable range (").concat(minAzimuth, " - ").concat(maxAzimuth, ") for direction ").concat(direction, ".");
    return { isValid: false, message: message };
};
exports.validateAzimuth = validateAzimuth;
