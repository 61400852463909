"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.customLaborLineItemQuerySchema = exports.customLaborLineItemUpdateSchema = exports.customLaborLineItemWriteSchema = exports.customLaborLineItemReadSchema = exports.customLaborLineItemSchema = void 0;
var typebox_1 = require("@feathersjs/typebox");
exports.customLaborLineItemSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    description: typebox_1.Type.String(),
    price: typebox_1.Type.Number(),
    engineeredProposalPricingId: typebox_1.Type.String(),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.customLaborLineItemReadSchema = typebox_1.Type.Omit(exports.customLaborLineItemSchema, []);
exports.customLaborLineItemWriteSchema = typebox_1.Type.Omit(exports.customLaborLineItemReadSchema, ['id', 'createdAt', 'updatedAt']);
exports.customLaborLineItemUpdateSchema = typebox_1.Type.Partial(exports.customLaborLineItemSchema, {
    $id: 'CustomLaborLineItemUpdate',
});
var customLaborLineItemQueryProperties = typebox_1.Type.Partial(exports.customLaborLineItemSchema, {
    $id: 'CustomLaborLineItemQuery',
});
exports.customLaborLineItemQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(customLaborLineItemQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
