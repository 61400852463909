"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.projectStageMap = exports.steps = exports.EPV_TAGS = exports.EPV_STATES_MAP = exports.EPV_STATES = void 0;
// Isolate these to break cyclic imports leading to undefined errors at runtime
exports.EPV_STATES = [
    'system-draft',
    'draft',
    'sold',
    'qa-approved',
    'final',
];
// TO_DO: Confirm all the required states and their order
exports.EPV_STATES_MAP = {
    'system-draft': 0,
    draft: 1,
    sold: 2,
    'qa-approved': 3,
    final: 4,
};
exports.EPV_TAGS = [
    'system-generated',
    'informed',
    'ea',
    'on-site',
    'change-order',
];
exports.steps = [
    'Sign up to Hohm Energy',
    'Online consultation',
    'Accept initial Engineering Proposal',
    'Physical site inspection with your Hohm-approved installer',
    'Accept final Engineering Proposal',
    'Finance Process',
    'Installation',
    'Go live',
];
exports.projectStageMap = {
    'system-draft': 'Initial Proposal',
    draft: 'Engineering Proposal',
    sold: 'Finance',
    'qa-approved': 'Quality Control',
    final: 'Deployment',
};
