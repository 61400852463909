// @mui
import { Tooltip, Chip, ChipProps, Typography, styled } from '@mui/material';
// types
import { CurrencyTypes } from 'hohm-types';
// utilities
import { HandleCurrency } from 'hohm-utilities';

const StyledChip = styled(Chip)(() => ({
	fontWeight: 'bold',
	backgroundColor: '#fff',
	backgroundImage: 'linear-gradient(to top, #0001, #0000)',
}));

export type TCurrencyChipProps = ChipProps & {
	value: number;
	fromCurrency: CurrencyTypes.TCurrency;
	toCurrency?: CurrencyTypes.TCurrency;
	plain?: boolean;
};

export const CurrencyChip = ({
	value,
	fromCurrency,
	toCurrency,
	plain = true,
	...props
}: TCurrencyChipProps) => {
	const originalFormattedPrice =
		value === -1
			? 'N/A'
			: HandleCurrency.formatCurrency({ value, currency: fromCurrency });

	const targetPrice = HandleCurrency.convertPriceCurrency({
		price: value,
		from: toCurrency || fromCurrency,
		to: toCurrency || fromCurrency,
	});

	const targetFormattedPrice = HandleCurrency.formatCurrency({
		value: targetPrice,
		currency: toCurrency || fromCurrency,
	});

	const toggleText = (isFirstItem = false) =>
		[originalFormattedPrice, targetFormattedPrice][isFirstItem ? 0 : 1];

	return (
		<Tooltip title={toggleText(true)}>
			{plain ? (
				<Typography>{toggleText(false)}</Typography>
			) : (
				<StyledChip
					size="small"
					label={toggleText(false)}
					variant="outlined"
					{...props}
				/>
			)}
		</Tooltip>
	);
};
