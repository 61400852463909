"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTimeEllapsed = void 0;
var getTimeEllapsed = function (createAt, currentTime) {
    var millisecondsEllapsed = currentTime.getTime() - createAt.getTime();
    var minutesEllapsed = Math.floor(millisecondsEllapsed / 60000);
    var hoursEllapsed = Math.floor(millisecondsEllapsed / 3600000);
    var daysEllapsed = Math.floor(millisecondsEllapsed / 86400000);
    var weeksEllapsed = Math.floor(millisecondsEllapsed / 604800000);
    var monthsEllapsed = Math.floor(millisecondsEllapsed / 2678400000);
    if (minutesEllapsed < 60) {
        if (minutesEllapsed === 1) {
            return "about a min ago.";
        }
        return "".concat(minutesEllapsed, " mins ago.");
    }
    if (hoursEllapsed < 24) {
        if (hoursEllapsed === 1) {
            return "about an hour ago.";
        }
        return "".concat(hoursEllapsed, " hours ago.");
    }
    if (daysEllapsed < 7) {
        if (daysEllapsed === 1) {
            return "a day ago.";
        }
        return "".concat(daysEllapsed, " days ago.");
    }
    if (weeksEllapsed < 4) {
        if (weeksEllapsed) {
            return "a week ago.";
        }
        return "".concat(weeksEllapsed, " weeks ago.");
    }
    if (monthsEllapsed < 12) {
        if (monthsEllapsed === 1) {
            return "a month ago.";
        }
        return "".concat(monthsEllapsed, " months ago.");
    }
};
exports.getTimeEllapsed = getTimeEllapsed;
