// @mui
import { SelectChangeEvent, Select, MenuItem } from '@mui/material';
// @local
import { IGridEditSelectCellProps } from './types';

export function GridEditSelectCell({
	props,
	options,
}: IGridEditSelectCellProps) {
	const { id, value, api, field } = props;

	const handleChange = (event: SelectChangeEvent) => {
		const { target, type } = event;

		api.setEditCellValue(
			{ id, field, value: (target as HTMLInputElement).value },
			event
		);

		// Check if the event is not from the keyboard
		// https://github.com/facebook/react/issues/7407
		if (type === 'click') {
			api.commitCellChange({ id, field });
			api.setCellMode(id, field, 'view');
		}
	};

	const handleRef = (element: HTMLElement) => {
		if (element && value) {
			const inputElement = element.querySelector(
				`input[value="${value}"]`
			) as HTMLElement;

			inputElement.focus();
		}
	};

	return (
		<Select
			ref={handleRef}
			value={value}
			variant="filled"
			onChange={handleChange}
			fullWidth
		>
			<MenuItem value="" disabled>
				Select an Option
			</MenuItem>
			{options.map((option) => (
				<MenuItem key={option} value={option}>
					{option}
				</MenuItem>
			))}
		</Select>
	);
}

export default GridEditSelectCell;
