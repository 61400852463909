import * as React from 'react';
// @mui
import { styled } from '@mui/material/styles';
import Grid, {
	GridDirection,
	GridSpacing,
	GridProps,
} from '@mui/material/Grid';

export const StyledDiv = styled('div')(({ theme }) => ({
	position: 'absolute',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	overflowY: 'auto',
	overflowX: 'hidden',
	paddingLeft: theme.spacing(3),
	paddingRight: theme.spacing(3),
	backgroundColor: 'white',
}));

interface IProps extends GridProps {
	containerClass?: string;
	children: React.ReactNode;
	className?: string;
	spacing?: GridSpacing;
	direction?: GridDirection;
	container?: boolean;
}

function Content({ children, ...rest }: IProps) {
	return (
		<StyledDiv>
			<Grid spacing={10} container {...rest}>
				{children}
			</Grid>
		</StyledDiv>
	);
}

export default Content;
