"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const themeOptions = {
    palette: {
        primary: {
            lighter: '#c2b688',
            light: '#c2b688',
            main: '#c2b688',
            dark: '#c2b688',
            darker: '#c2b688',
            contrastText: '#FFFFFF',
        },
        secondary: {
            lighter: '#000000',
            light: '#000000',
            dark: '#000000',
            darker: '#000000',
            main: '#000000',
            contrastText: '#FFFFFF',
        },
    },
};
exports.default = themeOptions;
