import {
	type AccordionProps as TAccordionProps,
	styled,
	Accordion as MuiAccordion,
} from '@mui/material';

export { TAccordionProps };
export const Accordion = styled((props: TAccordionProps) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	width: 400,
	border: `1px solid ${theme.palette.divider}`,
	'&:not(:last-child)': {
		borderBottom: 0,
	},
	'&:before': {
		display: 'none',
	},
}));
