"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.apiConQuerySchema = exports.apiConUpdateSchema = exports.apiConWriteSchema = exports.apiConReadSchema = exports.apiConSchema = exports.API_PERMISSIONS = void 0;
var typebox_1 = require("@feathersjs/typebox");
exports.API_PERMISSIONS = [
    'main',
    'IP_GENERATION',
    'SUPPLIER',
    'GLINT',
    'SOLAR_MAPPING',
];
exports.apiConSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Number(),
    apiKey: typebox_1.Type.String(),
    connectionName: typebox_1.Type.String(),
    isActive: typebox_1.Type.Boolean(),
    requests: typebox_1.Type.Number(),
    permission: (0, typebox_1.StringEnum)(__spreadArray([], exports.API_PERMISSIONS, true)),
    organisationId: typebox_1.Type.Optional(typebox_1.Type.Number()),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.apiConReadSchema = typebox_1.Type.Omit(exports.apiConSchema, []);
exports.apiConWriteSchema = typebox_1.Type.Omit(exports.apiConReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.apiConUpdateSchema = typebox_1.Type.Partial(exports.apiConSchema, {
    $id: 'ApiConUpdate',
});
var apiConQueryProperties = typebox_1.Type.Partial(exports.apiConSchema, {
    $id: 'ApiConQuery',
});
exports.apiConQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(apiConQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
