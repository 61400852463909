'use client';

import { Api, EntityQueries, HohmClient } from 'hohm-queries';
import { create } from './feathersClient';

export const feathersClient = create();

export const auth = new HohmClient(feathersClient);
export const api = new Api.default(feathersClient);
export const queries = new EntityQueries(api);
