"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.logger = void 0;
/* eslint-disable @typescript-eslint/no-explicit-any */
// @local
var environment_1 = require("./environment");
var isAPI = function () { return typeof window === 'undefined'; };
var getColoredTemplate = function (colorIndex) {
    return "\u001B[".concat(colorIndex, "m%s\u001B[0m");
};
var getColoredText = function (colorIndex, text) {
    return getColoredTemplate(colorIndex).replace('%s', text);
};
var emptyFunction = function () {
    //
};
var loggerLoader = function (_a) {
    var _b = _a.prefix, prefix = _b === void 0 ? '' : _b, _c = _a.textColor, textColor = _c === void 0 ? getColoredTemplate(36) : _c, // cyan color,
    isColorized = _a.isColorized, _d = _a.allowedEnvironments, allowedEnvironments = _d === void 0 ? [] : _d;
    var getColoredType = function (type) {
        switch (type) {
            case 'ERROR':
                return getColoredText(31, type); // red color
            case 'INFO':
                return getColoredText(32, type); // green color
            case 'WARN':
                return getColoredText(33, type); //  yellow color
            case 'TRACE':
                return getColoredText(34, type); //  blue color
            default:
                return type;
        }
    };
    var addTextColor = function (_a) {
        var args = _a.args, color = _a.color;
        return isColorized ? [color, args.join(' ')] : args;
    };
    var addColoredPrefix = function (_a) {
        var args = _a.args, type = _a.type;
        return args[0] && typeof args[0] === 'string' && isColorized
            ? __spreadArray([
                "[".concat(getColoredType(type), "] (").concat(prefix, "): ").concat(args[0])
            ], args.slice(1), true) : args;
    };
    var normalizeText = function (props) {
        var args = props.args, type = props.type;
        if (isAPI()) {
            var argsWithColors = addTextColor({
                args: args,
                color: textColor,
            });
            var argsWithPrefix = addColoredPrefix({
                args: argsWithColors,
                type: type,
            });
            return argsWithPrefix;
        }
        return args;
    };
    if (allowedEnvironments === null || allowedEnvironments === void 0 ? void 0 : allowedEnvironments.some(function (environment) { return (0, environment_1.isEnv)(environment); })) {
        var safeConsole_1 = isAPI() ? console : window.console;
        return {
            debug: function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                return safeConsole_1.debug.apply(null, args);
            },
            error: function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                if (isAPI())
                    safeConsole_1.trace.apply(null, normalizeText({ args: args, type: 'TRACE' }));
                return safeConsole_1.error.apply(null, normalizeText({ args: args, type: 'ERROR' }));
            },
            info: function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                return safeConsole_1.info.apply(null, normalizeText({ args: args, type: 'INFO' }));
            },
            trace: function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                return safeConsole_1.trace.apply(null, args);
            },
            warn: function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                return safeConsole_1.warn.apply(null, normalizeText({ args: args, type: 'WARN' }));
            },
            log: function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                safeConsole_1.log.apply(null, args);
            },
        };
    }
    return {
        debug: function () { return emptyFunction; },
        error: function () { return emptyFunction; },
        info: function () { return emptyFunction; },
        trace: function () { return emptyFunction; },
        warn: function () { return emptyFunction; },
        log: function () { return emptyFunction; },
    };
};
exports.logger = loggerLoader({
    prefix: "".concat(process.env.NODE_ENV || 'dev', "-env"),
    textColor: getColoredTemplate(36), // cyan color,
    isColorized: true,
    allowedEnvironments: ['development', 'staging', 'production'],
});
