var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable turbo/no-undeclared-env-vars */
/* eslint-disable class-methods-use-this */
import axios from 'axios';
// utilities
import { logger } from 'hohm-utilities';
var Api = /** @class */ (function () {
    function Api(client) {
        var _this = this;
        this.getJWT = function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2 /*return*/, this.client.authentication.getAccessToken()];
        }); }); };
        this.uploadFile = function (_a) {
            var fileName = _a.fileName, file = _a.file, progressUpdate = _a.progressUpdate;
            return __awaiter(_this, void 0, void 0, function () {
                var token, config, formData, uploadResult, err_1;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 3, , 4]);
                            return [4 /*yield*/, this.getJWT()];
                        case 1:
                            token = _b.sent();
                            config = {
                                headers: {
                                    Authorization: "Bearer ".concat(token),
                                    apiKey: process.env.NEXT_PUBLIC_SPARK_API_KEY,
                                },
                                onUploadProgress: function (progressEvent) {
                                    if (progressUpdate &&
                                        (progressEvent === null || progressEvent === void 0 ? void 0 : progressEvent.loaded) &&
                                        (progressEvent === null || progressEvent === void 0 ? void 0 : progressEvent.total)) {
                                        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                        progressUpdate(percentCompleted);
                                    }
                                },
                            };
                            formData = new FormData();
                            formData.append('uri', file, fileName);
                            return [4 /*yield*/, axios.post("".concat(process.env.NEXT_PUBLIC_API_HOST, "/uploads"), formData, config)];
                        case 2:
                            uploadResult = _b.sent();
                            return [2 /*return*/, { success: true, url: uploadResult.data.url }];
                        case 3:
                            err_1 = _b.sent();
                            logger.error('UPLOAD ERR', "".concat(process.env.NEXT_PUBLIC_API_HOST, "/uploads"), err_1);
                            return [2 /*return*/, { success: false, url: null }];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        };
        this.deleteFile = function (_a) {
            var url = _a.url;
            return __awaiter(_this, void 0, void 0, function () {
                var result, err_2;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, this.client
                                    .service('uploads')
                                    .remove(url.substring(url.lastIndexOf('/') + 1))];
                        case 1:
                            result = _b.sent();
                            return [2 /*return*/, { success: true, id: result }];
                        case 2:
                            err_2 = _b.sent();
                            logger.error('UPLOAD ERR', "".concat(process.env.NEXT_PUBLIC_API_HOST, "/uploads"), err_2);
                            return [2 /*return*/, { success: false, url: null }];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        };
        this.handleError = function (err) {
            logger.error('API Error::', err);
        };
        this.getAll = function (entity, query) { return __awaiter(_this, void 0, void 0, function () {
            var response, err_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.client
                                .service(entity)
                                .find({ query: query })];
                    case 1:
                        response = (_a.sent());
                        return [2 /*return*/, response];
                    case 2:
                        err_3 = _a.sent();
                        this.handleError(err_3);
                        throw err_3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.get = function (entity, id, query) { return __awaiter(_this, void 0, void 0, function () {
            var response, err_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.client
                                .service(entity)
                                .get(id, { query: query })];
                    case 1:
                        response = (_a.sent());
                        return [2 /*return*/, response];
                    case 2:
                        err_4 = _a.sent();
                        this.handleError(err_4);
                        throw err_4;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.create = function (entity, payload) {
            try {
                var response = _this.client
                    .service(entity)
                    .create(payload);
                return response;
            }
            catch (err) {
                _this.handleError(err);
                throw err;
            }
        };
        this.update = function (entity, id, payload) { return __awaiter(_this, void 0, void 0, function () {
            var response, err_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.client
                                .service(entity)
                                .patch(id, payload)];
                    case 1:
                        response = (_a.sent());
                        return [2 /*return*/, response];
                    case 2:
                        err_5 = _a.sent();
                        this.handleError(err_5);
                        throw err_5;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.remove = function (entity, id) { return __awaiter(_this, void 0, void 0, function () {
            var response, err_6;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.client
                                .service(entity)
                                .remove(id)];
                    case 1:
                        response = (_a.sent());
                        return [2 /*return*/, response];
                    case 2:
                        err_6 = _a.sent();
                        this.handleError(err_6);
                        throw err_6;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.client = client;
    }
    return Api;
}());
export default Api;
