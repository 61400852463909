'use client';

import { useState } from 'react';
// @mui
import {
	InputAdornment,
	IconButton,
	SvgIconTypeMap,
	IconButtonProps,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
// @icons
import { Visibility, VisibilityOff } from '@mui/icons-material';

// eslint-disable-next-line @typescript-eslint/ban-types
type TIcon = OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
	muiName: string;
};

type TPosition = 'start' | 'end';

export type TAdornmentProps = Partial<
	{
		edge: TPosition;
		Icons: [TIcon?, TIcon?];
		position: TPosition;
		visibility: boolean;
	} & IconButtonProps
>;
export const Adornment = ({
	edge = 'end',
	Icons = [],
	position = 'end',
	visibility = false,
	...otherProps
}: TAdornmentProps) => {
	const [isVisible, setIsVisible] = useState<boolean>(visibility);
	const VisibleIcon = Icons[0] || Visibility;
	const InvisibleIcon = Icons[Icons.length - 1] || VisibilityOff;

	return (
		<InputAdornment
			onClick={() => setIsVisible((state) => !state)}
			position={position}
		>
			<IconButton {...otherProps} edge={edge}>
				{isVisible ? <VisibleIcon /> : <InvisibleIcon />}
			</IconButton>
		</InputAdornment>
	);
};
