"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getJWTDoc = void 0;
var getJWTDoc = function (token) {
    if (!token || !token.trim()) {
        return null;
    }
    var splittedToken = token.split('.');
    if (splittedToken.length !== 3)
        return null;
    var payload = window.atob(splittedToken[1]);
    try {
        var parsedPayload = JSON.parse(payload);
        return parsedPayload._doc;
    }
    catch (error) {
        return null;
    }
};
exports.getJWTDoc = getJWTDoc;
