"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// @mui
const material_1 = require("@mui/material");
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Drawer = (theme) => {
    const isLight = theme.palette.mode === 'light';
    return {
        MuiDrawer: {
            styleOverrides: {
                modal: {
                    '&[role="presentation"]': {
                        '& .MuiDrawer-paperAnchorLeft': {
                            boxShadow: `8px 24px 24px 12px ${(0, material_1.alpha)(theme.palette.grey[900], isLight ? 0.16 : 0.48)}`,
                        },
                        '& .MuiDrawer-paperAnchorRight': {
                            boxShadow: `-8px 24px 24px 12px ${(0, material_1.alpha)(theme.palette.grey[900], isLight ? 0.16 : 0.48)}`,
                        },
                    },
                },
            },
        },
    };
};
exports.default = Drawer;
