"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const themeOptions = {
    palette: {
        primary: {
            lighter: '#252421',
            light: '#252421',
            dark: '#252421',
            darker: '#252421',
            main: '#252421',
            contrastText: '#FFFFFF',
        },
        secondary: {
            lighter: '#f8c002',
            light: '#f8c002',
            dark: '#f8c002',
            darker: '#f8c002',
            main: '#f8c002',
            contrastText: '#FFFFFF',
        },
    },
    shape: {
        borderRadius: 4,
    },
};
exports.default = themeOptions;
