"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CAMPAIGN_NAMESPACES = exports.DOMAIN_NAMESPACES = exports.DEFAULT_NAMESPACE = exports.SUPPORTED_ZOOM_LEVELS = exports.SUPPORTED_COUNTRY_CURRENCY_SYMBOLS = exports.SUPPORTED_COUNTRY_CURRENCIES = exports.SUPPORTED_COUNTRY_NAMES = exports.SUPPORTED_COUNTRY_CODES = exports.SUPPORTED_NAMESPACES = exports.SUPPORTED_LANGUAGES = void 0;
exports.SUPPORTED_LANGUAGES = ['en'];
exports.SUPPORTED_NAMESPACES = [
    'translation',
    'betterbond',
    'bmwfinancialservices',
    'captivepower',
    'clearscore',
    'fnb',
    'glint',
    'investec',
    'magnetoenergy',
    'makro',
    'mspd',
    'mtn',
    'nedbank',
    'nedbankmfc',
    'oldmutual',
    'ooba',
    'pathwayscu',
    'picknpay',
    'skyworthenergy',
    'solarblackfriday',
    'za',
    'us',
    'sasfin',
    'yoursolar',
    'cia-onboarding-form',
];
exports.SUPPORTED_COUNTRY_CODES = ['us', 'za'];
exports.SUPPORTED_COUNTRY_NAMES = [
    'United States',
    'South Africa',
];
exports.SUPPORTED_COUNTRY_CURRENCIES = ['usd', 'zar'];
exports.SUPPORTED_COUNTRY_CURRENCY_SYMBOLS = ['$', 'R'];
exports.SUPPORTED_ZOOM_LEVELS = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
];
exports.DEFAULT_NAMESPACE = 'za';
exports.DOMAIN_NAMESPACES = {
    'betterbond.hohmenergy.com': 'betterbond',
    'bmwfinancialservices.hohmenergy.com': 'bmwfinancialservices',
    'captivepower.hohmenergy.com': 'captivepower',
    'clearscore.hohmenergy.com': 'clearscore',
    'fnb.hohmenergy.com': 'fnb',
    'investec.hohmenergy.com': 'investec',
    'magnetoenergy.hohmenergy.com': 'magnetoenergy',
    'makro-online.hohmenergy.com': 'makro',
    'makro-staff.hohmenergy.com': 'makro',
    'makro-retail.hohmenergy.com': 'makro',
    'mfc.hohmenergy.com': 'nedbankmfc',
    'mspd.hohmenergy.com': 'mspd',
    'mtn-staff.hohmenergy.com': 'mtn',
    'skyworthenergy.hohmenergy.com': 'skyworthenergy',
    'solarblackfriday.hohmenergy.com': 'solarblackfriday',
    'yoursolar.hohmenergy.com': 'yoursolar',
};
exports.CAMPAIGN_NAMESPACES = {
    betterbond: 'betterbond',
    bmwfinancialservices: 'bmwfinancialservices',
    captivepower: 'captivepower',
    clearscore: 'clearscore',
    fnb: 'fnb',
    glint: 'glint',
    '0f6045b1-bfe2-4282-9b4e-6e939e7e7cd0': 'nedbankmfc',
    'ccdc34bd-eb7f-454e-8590-ac9a3622f851': 'oldmutual',
    magnetoenergy: 'magnetoenergy',
    'makro-alberton': 'makro',
    'makro-online': 'makro',
    'makro-staff': 'makro',
    'makro-retail': 'makro',
    avo: 'translation',
    mspd: 'mspd',
    'mtn-staff': 'mtn',
    nedbank: 'nedbank',
    nedbankmfc: 'nedbankmfc',
    ooba: 'ooba',
    pnp: 'picknpay',
    pnpstaff: 'picknpay',
    investec: 'investec',
    'installer-pilot': 'translation',
    '88c9e3c6-a401-46d6-a35a-4ff89ade4d94': 'translation',
    'fc2fec63-3c0f-4952-a605-5d0d4dadc13d': 'translation',
    '5e9c1d4b-6c0f-475a-a0b7-4f5dd582d9c7': 'translation',
    '08f3cee5-d7c4-41c3-afea-dc30b3364f67': 'translation',
    '712828e1-ff16-4971-a66b-30be8bbcc366': 'translation',
    'aabd6f0a-eed3-4487-959f-215a9cde8d15': 'translation',
    '0f2b52e7-bebd-49b0-9a13-5f3359cea794': 'translation',
    'ece83c77-d69c-4959-8cc2-132390731f78': 'translation',
    '7215ee99-b86c-4fdd-898a-369ebebf4908': 'translation',
    '2aaf7f66-10b4-4bb1-8a31-793ffc5a0d4d': 'translation',
    'd9ab20ed-531e-4a5e-8216-3817c63942a6': 'translation',
    'a8028f26-7511-4264-9bc6-0b294d23f8bd': 'translation',
    '61477113-e468-42b4-b371-32d665a6245c': 'translation',
    'e7e4bb7f-a6d1-4d48-aaa4-623150ff4832': 'translation',
    sasfin: 'sasfin',
    skyworthenergy: 'skyworthenergy',
    solarblackfriday: 'solarblackfriday',
    yoursolar: 'yoursolar',
    'cia-onboarding-form': 'cia-onboarding-form',
    'referral-factory': 'translation',
};
