"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertNumberToSouthAfricaFormat = exports.setTimeInDate = exports.toUtcISOString = exports.getDateInLocalTimeZone = void 0;
var date_fns_tz_1 = require("date-fns-tz");
var date_fns_1 = require("date-fns");
var getDateInLocalTimeZone = function (date, timeZone) {
    return (0, date_fns_tz_1.zonedTimeToUtc)(date, timeZone);
};
exports.getDateInLocalTimeZone = getDateInLocalTimeZone;
// Take in the time that a user has indicated and transform it to UTC+0 time
var toUtcISOString = function (usersTimezone, date) {
    var utcDate = (0, date_fns_tz_1.zonedTimeToUtc)(date, usersTimezone);
    return utcDate.toISOString();
};
exports.toUtcISOString = toUtcISOString;
var setTimeInDate = function (date, time) {
    var _a = time
        .trim()
        .split(':')
        .map(function (x) { return Number(x); }), hours = _a[0], minutes = _a[1];
    return (0, date_fns_1.setMinutes)((0, date_fns_1.setHours)(date, hours), minutes);
};
exports.setTimeInDate = setTimeInDate;
var convertNumberToSouthAfricaFormat = function (inputNumber) {
    // Remove any non-digit characters from the input number
    var cleanedNumber = inputNumber.replace(/\D/g, '');
    // Check if the number is already in the South African format
    if (/^\+[1-9]\d{5,14}$/.test("+".concat(cleanedNumber))) {
        return "+".concat(cleanedNumber);
    }
    // Check if the cleaned number matches the regex pattern
    if (!/^0\d{9,}$/.test(cleanedNumber)) {
        return;
    }
    // Add the South African country code ('+27') to the beginning of the number
    var southAfricanNumber = "+27".concat(cleanedNumber.slice(1));
    return southAfricanNumber;
};
exports.convertNumberToSouthAfricaFormat = convertNumberToSouthAfricaFormat;
