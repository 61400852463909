import React from 'react';
// @mui
import { Grid, Avatar, styled, Typography } from '@mui/material';
// @local
import { type TGooglePlacesProps } from '.';
import { Input } from '../../../inputs/Input';

const StyledDiv = styled('div')(() => ({
	right: '50%',
	position: 'absolute',
	marginTop: '25px',
}));

const StyledAvatar = styled(Avatar)(() => ({
	zIndex: 1000,
	padding: '10px',
	position: 'relative',
	backgroundColor: 'rgba(255,255,255,0.95)',
	'@media (max-width: 600px)': {
		left: '85% !important',
	},
}));

const geocoderAddressComponent =
	(components: google.maps.GeocoderAddressComponent[]) =>
	(targetType: string, targetField: keyof (typeof components)[0]) => {
		const findedComponent = components.find(({ types }) =>
			types.includes(targetType)
		);

		return findedComponent && (findedComponent[targetField] as string);
	};

function Places({
	gridSx,
	onChange,
	showLabel = true,
	showAddress = true,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	countryCode,
	showNorthIcon = false,
	currentAddress,
	textfieldProps,
	getSelectedLocation,
}: TGooglePlacesProps) {
	const [isGooglePlacesLoaded, setIsGooglePlacesLoaded] =
		React.useState<boolean>(false);

	React.useEffect(() => {
		const loadGoogle = () => {
			const autocompleteFormField = document.getElementById(
				'pac-input'
			) as HTMLInputElement;

			const autocomplete = new google.maps.places.Autocomplete(
				autocompleteFormField,
				{
					types: ['geocode', 'establishment'],
					componentRestrictions: { country: 'za' },
				}
			);

			autocomplete.setFields([
				'url',
				'icon',
				'name',
				'vicinity',
				'place_id',
				'geometry',
				'address_component',
				'formatted_address',
			]);

			autocomplete.addListener('place_changed', () => {
				const selectedPlace = autocomplete.getPlace();
				const { address_components: addressComponents } = selectedPlace;

				if (
					addressComponents == null ||
					selectedPlace == null ||
					!selectedPlace.geometry
				) {
					return;
				}

				const geoField = geocoderAddressComponent(addressComponents);
				const city = geoField('locality', 'long_name');
				const region = geoField(
					'administrative_area_level_1',
					'long_name'
				);
				const country = geoField('country', 'long_name');
				const shortName = geoField('country', 'short_name');
				const coordinates: [number, number] = [
					selectedPlace.geometry.location?.lat() || 0,
					selectedPlace.geometry.location?.lng() || 0,
				];

				if (getSelectedLocation && selectedPlace) {
					getSelectedLocation({
						city,
						region,
						country,
						shortName,
						coordinates,
						...selectedPlace,
					});
				}
			});
			setIsGooglePlacesLoaded(true);
		};

		!isGooglePlacesLoaded && loadGoogle();
	});

	return (
		<Grid container>
			<Grid item md={12} xs={12} sx={gridSx}>
				<Input
					id="pac-input"
					label={showLabel ? 'Search for Location' : undefined}
					margin="dense"
					onChange={onChange}
					multiline
					className="controls"
					placeholder="Type to search..."
					InputLabelProps={{
						shrink: true,
					}}
					{...textfieldProps}
				/>

				{currentAddress && showAddress ? (
					<Typography>{currentAddress}</Typography>
				) : null}

				{showNorthIcon === true ? (
					<StyledDiv>
						<StyledAvatar>
							<img
								src="/images/northIconForMap.png"
								alt="North"
								width={40}
								height={40}
							/>
						</StyledAvatar>
					</StyledDiv>
				) : null}
			</Grid>
		</Grid>
	);
}

export default Places;
