"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.subscriptionApplicationResultSchema = exports.subscriptionApplicationReadSchema = void 0;
var typebox_1 = require("@feathersjs/typebox");
exports.subscriptionApplicationReadSchema = typebox_1.Type.Object({
    source: typebox_1.Type.String(),
    sourceRequestorId: typebox_1.Type.String(),
    saIdNumber: typebox_1.Type.String(),
    firstName: typebox_1.Type.String(),
    lastName: typebox_1.Type.String(),
    homeOwner: typebox_1.Type.Boolean(),
    creditProfileId: typebox_1.Type.Optional(typebox_1.Type.String()),
}, { $id: 'SubscriptionApplication', additionalProperties: false });
exports.subscriptionApplicationResultSchema = typebox_1.Type.Object({
    financeApproved: typebox_1.Type.Boolean(),
    creditProfileId: typebox_1.Type.String(),
}, { $id: 'SubscriptionApplicationResult', additionalProperties: false });
