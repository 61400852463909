import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function useDeviceType() {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
	const isTablet = useMediaQuery(theme.breakpoints.down('md'));

	return {
		isMobile,
		isDesktop,
		isTablet,
	};
}
