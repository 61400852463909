"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// @local
// import { InputSelectIcon } from '../../../icons';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Select = (theme) => {
    return {
        MuiSelect: {
            defaultProps: {
            // IconComponent: InputSelectIcon,
            },
            styleOverrides: {
                root: {},
            },
        },
    };
};
exports.default = Select;
