import React from 'react';

const usePopper = () => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	);
	const [open, setOpen] = React.useState(false);

	const handleClick = () => (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
		setOpen((prev) => !prev);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return { anchorEl, open, handleClick, handleClose };
};

export default usePopper;
