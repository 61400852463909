// @form
import { Controller } from 'react-hook-form';
// @local
import { Select } from '../Select';
import { TSelectControllerProps } from '../utils/types';

export type { TSelectControllerProps };
export const SelectController = <T,>({
	name,
	control,
	...rest
}: TSelectControllerProps<T>) => (
	<Controller
		{...{ name, control }}
		render={({ field, formState: { errors } }, { helperText } = rest) => (
			<Select
				{...field}
				{...rest}
				error={!!errors[name]}
				// eslint-disable-next-line react/jsx-no-useless-fragment
				helperText={helperText || <>{errors[name]?.message}</>}
			/>
		)}
	/>
);
