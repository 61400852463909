"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var captivepower = {
    theme: 'captivepower',
    brand: {
        name: 'Captive Power',
        legalName: 'Captive Power',
        logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/captive-power/images.png',
        icon: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/captive-power/images.png',
    },
    pages: {
        signup: {
            sections: {
                titleSection: {
                    title: "From start to solar, we've got your back.",
                },
            },
        },
    },
    poweredBy: {
        name: 'Hohm Energy',
        legalName: 'Hohm Services (Pty) Ltd.',
        logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/general/HohmEnergyLogoOrange.png',
    },
    partner: {
        name: 'Hohm Energy',
        legalName: 'Hohm Services (Pty) Ltd.',
        logo: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/general/HohmEnergyLogoOrange.png',
    },
    packages: {
        hohmPdf: {
            customBookACall: false,
            hideBrandLogo: false,
            hideFinancePages: false,
            hideInvoicePage: false,
        },
    },
    financePartners: [
        {
            name: 'Glint',
            logo: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/glint/glint-logo.png',
        },
    ],
    financeOptions: [
        {
            label: 'Solar or battery subscription',
            value: 'glint',
            default: true,
        },
    ],
    subscriptionOnly: true,
};
exports.default = captivepower;
