"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.monthlyBillQuerySchema = exports.monthlyBillUpdateSchema = exports.monthlyBillWriteSchema = exports.monthlyBillReadSchema = exports.monthlyBillSchema = exports.TARIFF_TYPES = void 0;
var typebox_1 = require("@feathersjs/typebox");
var currency_types_1 = require("./currency.types");
var date_types_1 = require("../utility-types/date.types");
exports.TARIFF_TYPES = ['tou', 'flat'];
var TOURateSchema = typebox_1.Type.Object({
    peakRate: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.Number(), typebox_1.Type.String()])),
    standardRate: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.Number(), typebox_1.Type.String()])),
    offpeakRate: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.Number(), typebox_1.Type.String()])),
});
exports.monthlyBillSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    usage: typebox_1.Type.Number(),
    usageStartDate: typebox_1.Type.Date(),
    usageEndDate: typebox_1.Type.Date(),
    cost: typebox_1.Type.Number(),
    currency: (0, typebox_1.StringEnum)(__spreadArray([], currency_types_1.SUPPORTED_CURRENCY, true)),
    billPicture: typebox_1.Type.String(),
    monthCaptured: (0, typebox_1.StringEnum)(__spreadArray([], date_types_1.MONTHS, true)),
    flatRate: typebox_1.Type.Number(),
    tariffType: (0, typebox_1.StringEnum)(__spreadArray([], exports.TARIFF_TYPES, true)),
    tou: typebox_1.Type.Optional(typebox_1.Type.Object({
        high: typebox_1.Type.Optional(TOURateSchema),
        low: typebox_1.Type.Optional(TOURateSchema),
    })),
    // Relationships
    propertyId: typebox_1.Type.String(),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.monthlyBillReadSchema = typebox_1.Type.Omit(exports.monthlyBillSchema, []);
exports.monthlyBillWriteSchema = typebox_1.Type.Omit(exports.monthlyBillReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.monthlyBillUpdateSchema = typebox_1.Type.Partial(exports.monthlyBillSchema, {
    $id: 'MonthlyBillUpdate',
});
var monthlyBillQueryProperties = typebox_1.Type.Partial(exports.monthlyBillSchema, {
    $id: 'MonthlyBillQuery',
});
exports.monthlyBillQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(monthlyBillQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
