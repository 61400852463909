"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.subscriptionFeeSchema = exports.getSubscriptionFeeData = void 0;
var typebox_1 = require("@feathersjs/typebox");
var __1 = require("..");
var subscription_request_types_1 = require("./subscription-request.types");
exports.getSubscriptionFeeData = typebox_1.Type.Intersect([subscription_request_types_1.ConfigurationSchema, typebox_1.Type.Object({ creditProfileId: typebox_1.Type.String() })]);
exports.subscriptionFeeSchema = typebox_1.Type.Object({
    // Calculated Data
    totalCost: typebox_1.Type.Number(),
    activationFee: typebox_1.Type.Number(),
    monthlyPayments: __1.SubscriptionRequestTypes.PaymentInfoSchema,
    addedCosts: __1.SubscriptionRequestTypes.AddedCostsSchema,
}, { $id: 'SubscriptionFee', additionalProperties: false });
