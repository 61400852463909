"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.creditProfile = exports.creditProfileWriteSchema = exports.creditProfilesReadSchema = void 0;
var typebox_1 = require("@feathersjs/typebox");
exports.creditProfilesReadSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    source: typebox_1.Type.String(),
    sourceRequestorId: typebox_1.Type.String(),
    saIdNumber: typebox_1.Type.String(),
    firstName: typebox_1.Type.String(),
    lastName: typebox_1.Type.String(),
    homeOwner: typebox_1.Type.Boolean(),
    creditScore: typebox_1.Type.Optional(typebox_1.Type.String()),
    debtReview: typebox_1.Type.Optional(typebox_1.Type.Object({
        isUnderDebtReview: typebox_1.Type.Boolean(),
        debtReviewReason: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.String(), typebox_1.Type.Null()])),
        rawValue: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.String(), typebox_1.Type.Null()])),
    })),
    thickOrThin: typebox_1.Type.Optional(typebox_1.Type.Object({
        type: typebox_1.Type.Union([typebox_1.Type.String(), (0, typebox_1.StringEnum)(['thick', 'thin'])]),
        reason: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.String(), typebox_1.Type.Null()])),
        rawValue: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.String(), typebox_1.Type.Null()])),
    })),
    arrearsCheck: typebox_1.Type.Optional(typebox_1.Type.Object({
        monthsInArrears: typebox_1.Type.Number(),
        currentBalance: typebox_1.Type.Number(),
        cummulativeArrearsAmount: typebox_1.Type.Number(),
        currentMonthlyInstallment: typebox_1.Type.Number(),
    })),
    judgements: typebox_1.Type.Optional(typebox_1.Type.Object({
        current: typebox_1.Type.Number(),
        previous: typebox_1.Type.Number(),
        other: typebox_1.Type.Number(),
    })),
    defaults: typebox_1.Type.Optional(typebox_1.Type.Number()),
    creditProfileUpdated: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'date-time' })),
    isValid: typebox_1.Type.Optional(typebox_1.Type.Boolean()),
    createdAt: typebox_1.Type.String({ format: 'date-time' }),
    updatedAt: typebox_1.Type.String({ format: 'date-time' }),
}, { $id: 'CreditProfiles', additionalProperties: false });
exports.creditProfileWriteSchema = typebox_1.Type.Omit(exports.creditProfilesReadSchema, ['id', 'createdAt', 'updatedAt'], {
    $id: 'CreditProfilesData',
});
exports.creditProfile = typebox_1.Type.Omit(exports.creditProfilesReadSchema, ['id', 'createdAt', 'updatedAt', 'firstName', 'lastName', 'homeOwner',
    'creditProfileUpdated', 'isValid', 'createdAt', 'updatedAt', 'source', 'sourceRequestorId']);
