// @mui
import { type InputBaseComponentProps, TextField } from '@mui/material';
// @local
import { numberFormattedInput } from '../NumberInput';
import { type TInputProps } from '../utils/types';
import { inputsDefaultStyle } from '../utils/constants';

export type { TInputProps };
export const Input = ({ isNumeric, decimalScale, ...rest }: TInputProps) => (
	<TextField
		fullWidth
		{...rest}
		InputProps={{
			style: inputsDefaultStyle,
			inputProps: {
				decimalScale,
				...rest.inputProps,
			},
			inputComponent: isNumeric
				? (numberFormattedInput as unknown as React.ElementType<InputBaseComponentProps>)
				: undefined,
			...rest.InputProps,
		}}
	/>
);
