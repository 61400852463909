"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.organisationQuerySchema = exports.organisationUpdateSchema = exports.organisationWriteSchema = exports.organisationReadSchema = exports.organisationSchema = exports.organisationTypesSchema = void 0;
var typebox_1 = require("@feathersjs/typebox");
exports.organisationTypesSchema = (0, typebox_1.StringEnum)([
    'epc',
    'finance',
    'super',
    'supplier',
    'salesRep',
    'commercial',
]);
exports.organisationSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    name: typebox_1.Type.String(),
    about: typebox_1.Type.String(),
    brochure: typebox_1.Type.String(),
    description: typebox_1.Type.String(),
    promotionText: typebox_1.Type.String(),
    paymentTerms: typebox_1.Type.String(),
    logo: typebox_1.Type.String(),
    primaryColor: typebox_1.Type.String(),
    secondaryColor: typebox_1.Type.String(),
    type: exports.organisationTypesSchema,
    email: typebox_1.Type.String(),
    operatingHours: typebox_1.Type.String(),
    contactNumber: typebox_1.Type.Union([typebox_1.Type.Number(), typebox_1.Type.String()]),
    address: typebox_1.Type.String(),
    coordinates: typebox_1.Type.String(),
    vatNumber: typebox_1.Type.String(),
    website: typebox_1.Type.String(),
    isBenchMark: typebox_1.Type.Boolean(),
    externalId: typebox_1.Type.String(), // for external integrations
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.organisationReadSchema = typebox_1.Type.Omit(exports.organisationSchema, []);
exports.organisationWriteSchema = typebox_1.Type.Omit(exports.organisationSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.organisationUpdateSchema = typebox_1.Type.Partial(exports.organisationSchema, {
    $id: 'OrganisationUpdate',
});
var organisationQueryProperties = typebox_1.Type.Partial(exports.organisationSchema, {
    $id: 'OrganisationQuery',
    '$areasOfOperation.operation$': typebox_1.Type.Optional(typebox_1.Type.String()),
    '$areasOfOperation.region$': typebox_1.Type.Optional(typebox_1.Type.String()),
});
exports.organisationQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(organisationQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
