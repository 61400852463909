import { ReactElement, FunctionComponent } from 'react';
// @mui
import { ButtonBase, Avatar, Button, Typography, styled } from '@mui/material';
// icons
import { SvgIconComponent } from '@mui/icons-material';

const iconSize = {
	minWidth: '50px',
	maxWidth: '80%',
	minHeight: '40px',
	maxHeight: '80%',
};

const StyledAvatar = styled(Avatar)(() => ({
	width: 60,
	height: 60,
	backgroundColor: 'white',
}));

const StyledImg = styled('img')(() => iconSize);

const StyledButtonBase = styled(ButtonBase)(() => ({
	width: '100%',
	height: '100%',
	borderRadius: '25px',
}));

const StyledButton = styled(Button)(() => ({
	alignContent: 'flex-start',
	justifyContent: 'flex-start',
}));

export type TItemButtonProps = {
	Icon?: SvgIconComponent | FunctionComponent;
	text?: string;
	label: string;
	onClick: () => void;
	element?: ReactElement;
	iconPath?: string;
	selected: boolean;
	position?: 'startIcon' | 'endIcon';
};
export const ItemButton = ({
	Icon,
	text,
	label,
	onClick,
	element,
	iconPath,
	selected,
	position = 'startIcon',
}: TItemButtonProps) => {
	const content = element || (
		<StyledAvatar variant="rounded">
			{Icon && <Icon sx={iconSize} />}
			{text && text}
			{iconPath && <StyledImg src={iconPath} alt="icon" />}
		</StyledAvatar>
	);

	return (
		<StyledButtonBase onClick={onClick}>
			<StyledButton
				variant={!selected ? 'outlined' : 'contained'}
				fullWidth
				{...(position === 'startIcon'
					? { startIcon: content }
					: { endIcon: content })}
			>
				<Typography variant="body1" align="left" gutterBottom>
					{label}
				</Typography>
			</StyledButton>
		</StyledButtonBase>
	);
};
