import { styled } from '@mui/material';

export type TPointBadgeProps = never;
export const PointBadge = styled('div')(({ theme }) => ({
	padding: '5px',
	borderRadius: '100%',
	backgroundColor: theme.palette.primary.main,
	color: '#fff',
	height: '40px',
	width: '40px',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
}));
