"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Slider = (theme) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const isLight = theme.palette.mode === 'light';
    return {
        MuiSlider: {
        // defaultProps: {
        // 	size: 'small'
        // },
        // styleOverrides: {
        // 	root: {
        // 		'&.Mui-disabled': {
        // 			color: theme.palette.action.disabled
        // 		}
        // 	},
        // 	markLabel: {
        // 		fontSize: 13,
        // 		color: theme.palette.text.disabled
        // 	},
        // 	valueLabel: {
        // 		borderRadius: 8,
        // 		backgroundColor: theme.palette.grey[isLight ? 800 : 700]
        // 	}
        // }
        },
    };
};
exports.default = Slider;
