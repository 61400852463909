import { styled, Grid, Theme } from '@mui/material';

const StyledResponsiveGrid = styled(Grid)(
	({
		theme: {
			breakpoints: { down },
		},
	}: {
		theme: Theme;
	}) => ({
		flex: '1',
		width: 'auto',
		minWidth: 'auto',
		[down('sm')]: {
			flex: 'auto',
			minWidth: '100%',
		},
	})
);

export type TResponsiveGridProps = { children?: JSX.Element };
export const ResponsiveGrid = ({ children }: TResponsiveGridProps) => {
	return <StyledResponsiveGrid item>{children}</StyledResponsiveGrid>;
};
