"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var images_1 = require("../../images");
var investec = {
    financePartners: [],
    brand: {
        name: 'Investec',
        legalName: 'Investec',
        logo: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/general/Investec_Logo.jpg',
        icon: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/general/Investec_Logo.jpg',
    },
    partner: {
        name: 'Hohm Energy',
        legalName: 'Hohm Services (Pty) Ltd.',
        logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/general/HohmEnergyLogoOrange.png',
    },
    poweredBy: {
        name: 'Hohm Energy',
        legalName: 'Hohm Services (Pty) Ltd.',
        logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/general/HohmEnergyLogoOrange.png',
    },
    modules: {
        draftProposal: {
            enableRentalBanner: false,
            showRewardsAlert: true,
            defaultOM: true,
        },
    },
    packages: {
        hohmPdf: {
            hideFinancePages: true,
            hideBrandLogo: true,
        },
    },
    financeOptions: [
        {
            label: 'Cash / Investec Private Home Loan / Investec Sustainability Finance',
            value: 'investec-finance',
            default: true,
        },
    ],
    pages: {
        signup: {
            heading: 'Claim your solar offer now.',
            content: 'Generate your customised proposal in 4 minutes.',
            promoImage: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/signUp/iPhone%20%281%29.png',
            callUsNumber: '010 510 4449',
            callUsText: '',
            offerings: [
                {
                    id: 0,
                    heading: 'Say goodbye to load shedding',
                    content: "Hohm Energy will give you and your family peace of mind that you don't have to restructure your life around the inconvenience of load shedding.",
                },
                {
                    id: 1,
                    heading: 'Get the best price',
                    content: 'Hohm Energy and Investec have negotiated the best prices from product suppliers and solar installers to ensure the best value for you.',
                },
                {
                    id: 2,
                    heading: 'Your own energy advisor',
                    content: 'Hohm Energy is with you every step of the way to answer any questions, manage the process and ensure your solar journey is as seamless as possible.',
                },
            ],
            showOptionsHarmburger: false,
            collaborationText: 'In collaboration with Investec Private Bank',
            showHomeLoanFinePrint: false,
            sections: {
                titleSection: {
                    title: "From start to solar, we've got your back.",
                },
                batterySolutions: {
                    title: 'Our Most Popular Kits',
                },
                showFinanceOptions: false,
                footer: {
                    links: [
                        [
                            {
                                label: 'POPIA Data Policy',
                                link: "".concat(process.env.NEXT_PUBLIC_LANDING_URL, "terms-and-conditions"),
                            },
                            {
                                label: 'Legal Policy',
                                link: "".concat(process.env.NEXT_PUBLIC_LANDING_URL, "terms-and-conditions"),
                            },
                        ],
                    ],
                },
            },
            whyUseData: [
                {
                    icon: images_1.diamondIcon,
                    title: 'You get exceptional quality and the best value',
                    description: 'We only use the highest quality, most durable products, from solar panels to inverters to everything in between.',
                },
                {
                    icon: images_1.engineerIcon,
                    title: 'Only trained and vetted installers work on your home',
                    description: "This means you're guaranteed unmatched workmanship and peace of mind from start to finish.",
                },
                {
                    icon: images_1.handgearIcon,
                    title: 'We hold ourselves accountable',
                    description: 'We stand behind every system installed and are with you throughout your entire solar journey - from installation through to ongoing maintenance.',
                },
                {
                    icon: images_1.quicktimeIcon,
                    title: "We'll install within two weeks",
                    description: "For every day that there is a delay, we'll pay you what you would have saved had your solar system been fully functional.",
                },
            ],
        },
        finance: {
            maintenanceText: 'Including one-year premium operations & maintenance plan',
        },
    },
    kitsTotalPricing: {
        glow: 184727.48,
        spark: 81781.22,
        flash: 61392.34,
        shine: 220005.53,
        flashPlus: 84079.36,
        sparkPlus: 118480.71,
    },
    buyOnly: true,
    hideHomeLoanAddition: true,
    hideSolarFinance: true,
    buyLabel: 'Cash / Investec Private Home Loan / Investec Sustainability Finance',
    hideSubscribeBuyCTA: true,
    financeType: 'investec-finance',
    useInvestecPricing: true,
};
exports.default = investec;
