'use client';

import React from 'react';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
// @mui
import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link';
import { styled } from '@mui/material/styles';

// Add support for the sx prop for consistency with the other branches.
const Anchor = styled('a')({});

interface INextLinkComposedProps
	extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>,
		Omit<NextLinkProps, 'href' | 'as'> {
	to: NextLinkProps['href'];
	linkAs?: NextLinkProps['as'];
	href?: NextLinkProps['href'];
	onMouseEnter?: NextLinkProps['onMouseEnter'];
	onClick?: NextLinkProps['onClick'];
}

const NextLinkComposed = React.forwardRef<
	HTMLAnchorElement,
	INextLinkComposedProps
>(
	// eslint-disable-next-line @typescript-eslint/naming-convention
	function NextLinkComposed(props, ref) {
		const {
			to,
			linkAs,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			href,
			replace,
			scroll,
			shallow,
			prefetch,
			locale,
			...other
		} = props;

		return (
			<NextLink
				href={to}
				prefetch={prefetch}
				as={linkAs}
				replace={replace}
				scroll={scroll}
				shallow={shallow}
				passHref
				locale={locale}
				ref={ref}
				{...other}
			/>
		);
	}
);

export type TNavlinkProps = {
	activeClassName?: string;
	as?: NextLinkProps['as'];
	href: NextLinkProps['href'];
	linkAs?: NextLinkProps['as']; // Useful when the as prop is shallow by styled().
	noLinkStyle?: boolean;
} & Omit<INextLinkComposedProps, 'to' | 'linkAs' | 'href'> &
	Omit<MuiLinkProps, 'href'>;
// A styled version of the Next.js Link component:
// https://nextjs.org/docs/api-reference/next/link
export const Navlink = React.forwardRef<HTMLAnchorElement, TNavlinkProps>(
	// eslint-disable-next-line @typescript-eslint/naming-convention
	function Link(props, ref) {
		const {
			activeClassName = 'active',
			as: linkAs,
			className: classNameProps,
			href,
			noLinkStyle,
			...other
		} = props;

		const router = useRouter();
		const pathname = typeof href === 'string' ? href : href?.pathname;
		const className = clsx(classNameProps, {
			[activeClassName]: router?.pathname === pathname && activeClassName,
		});

		const isExternal =
			typeof href === 'string' &&
			(href.indexOf('http') === 0 || href.indexOf('mailto:') === 0);

		if (isExternal) {
			if (noLinkStyle) {
				return <Anchor className={className} ref={ref} {...other} />;
			}

			return <MuiLink className={className} ref={ref} {...other} />;
		}

		if (noLinkStyle) {
			return (
				<NextLinkComposed
					className={className}
					ref={ref}
					to={href}
					{...other}
				/>
			);
		}

		return (
			<MuiLink
				underline="none"
				component={NextLinkComposed}
				linkAs={linkAs}
				className={className}
				ref={ref}
				to={href}
				{...other}
			/>
		);
	}
);
