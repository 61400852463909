"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.businessQuerySchema = exports.businessUpdateSchema = exports.businessWriteSchema = exports.businessReadSchema = exports.businessSchema = void 0;
var typebox_1 = require("@feathersjs/typebox");
exports.businessSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    name: typebox_1.Type.String(),
    vat: typebox_1.Type.Number(),
    registration: typebox_1.Type.Number(),
    organisationId: typebox_1.Type.Optional(typebox_1.Type.String()),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.businessReadSchema = typebox_1.Type.Omit(exports.businessSchema, []);
exports.businessWriteSchema = typebox_1.Type.Omit(exports.businessReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.businessUpdateSchema = typebox_1.Type.Partial(exports.businessSchema, {
    $id: 'BusinessUpdate',
});
var businessQueryProperties = typebox_1.Type.Partial(exports.businessSchema, {
    $id: 'BusinessQuery',
});
exports.businessQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(businessQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
