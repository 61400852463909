"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.pricingLineItemQuerySchema = exports.pricingLineItemUpdateSchema = exports.pricingLineItemWriteSchema = exports.pricingLineItemReadSchema = exports.pricingLineItemSchema = void 0;
var typebox_1 = require("@feathersjs/typebox");
var project_fixed_cost_types_1 = require("./project-fixed-cost.types");
exports.pricingLineItemSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    quantity: typebox_1.Type.Number(),
    category: (0, typebox_1.StringEnum)(__spreadArray([], project_fixed_cost_types_1.CATEGORIES, true)),
    projectFixedCostId: typebox_1.Type.String(),
    engineeredProposalPricingId: typebox_1.Type.String(),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.pricingLineItemReadSchema = typebox_1.Type.Omit(exports.pricingLineItemSchema, []);
exports.pricingLineItemWriteSchema = typebox_1.Type.Omit(exports.pricingLineItemReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.pricingLineItemUpdateSchema = typebox_1.Type.Partial(exports.pricingLineItemSchema, {
    $id: 'PricingLineItemUpdate',
});
var pricingLineItemQueryProperties = typebox_1.Type.Partial(exports.pricingLineItemSchema, {
    $id: 'PricingLineItemQuery',
});
exports.pricingLineItemQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(pricingLineItemQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
