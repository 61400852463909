"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.normalizeData = void 0;
var normalizr_1 = require("normalizr");
var ramda_1 = require("ramda");
var entitySchema = new normalizr_1.schema.Entity('items');
function normalizeData(data) {
    var transform = Array.isArray(data) ? [entitySchema] : entitySchema;
    var entities = (0, normalizr_1.normalize)(data, transform).entities;
    return (0, ramda_1.pathOr)({}, ['items'], entities);
}
exports.normalizeData = normalizeData;
