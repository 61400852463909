"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const themeOptions = {
    palette: {
        primary: {
            lighter: '#1fa0cc',
            light: '#1fa0cc',
            dark: '#1fa0cc',
            darker: '#1fa0cc',
            main: '#1fa0cc',
            contrastText: '#FFFFFF',
        },
        secondary: {
            lighter: '#3E4F6E',
            light: '#3E4F6E',
            main: '#0E244A',
            dark: '#091933',
            darker: '#091933',
            contrastText: '#FFFFFF',
        },
    },
};
exports.default = themeOptions;
