"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var translation = {
    theme: 'glint',
    brand: {
        name: 'Glint',
        legalName: 'Glint',
        logo: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/glint/glint-logo.png',
        icon: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/glint/glint-logo.png',
    },
    pages: {
        signup: {
            sections: {
                titleSection: {
                    title: "From start to solar, we've got your back.",
                },
            },
        },
    },
    partner: {
        name: 'Hohm Energy',
        legalName: 'Hohm Services (Pty) Ltd.',
        logo: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/general/HohmEnergyLogoOrange.png',
    },
    poweredBy: {
        name: 'Hohm Energy',
        legalName: 'Hohm Services (Pty) Ltd.',
        logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/general/HohmEnergyLogoOrange.png',
    },
    modules: {
        draftProposal: {
            monthlyBill: {
                usageMap: {
                    low: 470,
                    medium: 824,
                    high: 2100,
                },
            },
        },
    },
    financePartners: [],
    financeOptions: [
        {
            label: 'Glint',
            value: 'glint',
            default: true,
        },
    ],
    subscriptionOnly: true,
};
exports.default = translation;
