"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ButtonGroup = (theme) => {
    const styleContained = (color) => ({
        props: { variant: 'contained', color },
        style: {
            boxShadow: 'none',
        },
    });
    return {
        MuiButtonGroup: {
            variants: [
                {
                    props: { variant: 'contained', color: 'inherit' },
                    style: {
                        boxShadow: 'none',
                    },
                },
                styleContained('primary'),
                styleContained('secondary'),
                styleContained('info'),
                styleContained('success'),
                styleContained('warning'),
                styleContained('error'),
                {
                    props: { disabled: true },
                    style: {
                        boxShadow: 'none',
                        '& .MuiButtonGroup-grouped.Mui-disabled': {
                            boxShadow: 'none',
                            color: theme.palette.action.disabled,
                            borderColor: `${theme.palette.action.disabledBackground} !important`,
                            '&.MuiButton-contained': {
                                backgroundColor: theme.palette.action.disabledBackground,
                            },
                        },
                    },
                },
            ],
            styleOverrides: {
                root: {
                    boxShadow: 'none !important',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                },
            },
        },
    };
};
exports.default = ButtonGroup;
