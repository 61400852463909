import { forwardRef, ReactNode } from 'react';
import Head from 'next/head';
// types
import { Branding } from 'hohm-types';
// @mui
import { Box, BoxProps } from '@mui/material';

export type TPageProps = BoxProps & {
	children: ReactNode;
	meta?: ReactNode;
	title: string;
};
export const Page = forwardRef<HTMLDivElement, TPageProps>(
	({ children, meta, title, ...other }, ref) => (
		<>
			<Head>
				<title>{`${title} | ${Branding.brandName}`}</title>
				{meta}
			</Head>

			<Box ref={ref} {...other}>
				{children}
			</Box>
		</>
	)
);
