"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.commsSubscriptionQuerySchema = exports.commsSubscriptionUpdateSchema = exports.commsSubscriptionWriteSchema = exports.commsSubscriptionReadSchema = exports.commsSubscriptionSchema = void 0;
var typebox_1 = require("@feathersjs/typebox");
var COMMS_SUBSCRIPTIONS = ['emails', 'whatsapp'];
exports.commsSubscriptionSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Number(),
    subscription: (0, typebox_1.StringEnum)(__spreadArray([], COMMS_SUBSCRIPTIONS, true)),
    subscribed: typebox_1.Type.Boolean(),
    unsubscribeReason: typebox_1.Type.String(),
    userId: typebox_1.Type.Number(),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.commsSubscriptionReadSchema = typebox_1.Type.Omit(exports.commsSubscriptionSchema, []);
exports.commsSubscriptionWriteSchema = typebox_1.Type.Omit(exports.commsSubscriptionReadSchema, ['id', 'createdAt', 'updatedAt']);
exports.commsSubscriptionUpdateSchema = typebox_1.Type.Partial(exports.commsSubscriptionSchema, {
    $id: 'CommsSubscriptionUpdate',
});
var commsSubscriptionQueryProperties = typebox_1.Type.Partial(exports.commsSubscriptionSchema, {
    $id: 'CommsSubscriptionQuery',
});
exports.commsSubscriptionQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(commsSubscriptionQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
