"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.draftProposalQuerySchema = exports.draftProposalUpdateSchema = exports.draftProposalWriteSchema = exports.draftProposalReadSchema = exports.draftProposalSchema = void 0;
var typebox_1 = require("@feathersjs/typebox");
exports.draftProposalSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    archived: typebox_1.Type.Boolean(),
    config: typebox_1.Type.Object(typebox_1.Type.Any()), // TODO fix config schema
    step: typebox_1.Type.Number(),
    proposalOwnerId: typebox_1.Type.String(),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.draftProposalReadSchema = typebox_1.Type.Omit(exports.draftProposalSchema, []);
exports.draftProposalWriteSchema = typebox_1.Type.Omit(exports.draftProposalReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.draftProposalUpdateSchema = typebox_1.Type.Partial(exports.draftProposalSchema, {
    $id: 'DraftProposalUpdate',
});
var draftProposalQueryProperties = typebox_1.Type.Partial(exports.draftProposalSchema, {
    $id: 'DraftProposalQuery',
});
exports.draftProposalQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(draftProposalQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
