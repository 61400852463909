/* eslint-disable func-names */
import React from 'react';
import { Helmet } from 'react-helmet';
// @mui
import {
	ButtonProps,
	Dialog,
	DialogContent,
	IconButton,
	Slide,
	Box,
	Typography,
	DialogTitle,
	useMediaQuery,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useTheme } from '@mui/material/styles';
// @icons
import { Close } from '@mui/icons-material';
// @hohm-types
import { IndicativeProposalTypes, UserTypes } from 'hohm-types';
// @utils
import { HubSpotUtils, logger } from 'hohm-utilities';
import { Api, EntityQueries } from 'hohm-queries';
import { Application } from '@feathersjs/feathers';

interface IProps extends ButtonProps {
	openhubSpotDialog: boolean;
	setOpenhubSpotDialog: React.Dispatch<React.SetStateAction<boolean>>;
	prefill?: UserTypes.IUserRead;
	extraComponent: React.ReactNode;
	nextStep?: (response?: IndicativeProposalTypes.IConfig | undefined) => void;
	client: Application<any, any>;
}

interface ICustomWindow extends Window {
	hsMeetingEmbed?: any;
}

const Transition = React.forwardRef(
	(
		props: TransitionProps & {
			children: React.ReactElement<
				unknown,
				string | React.JSXElementConstructor<unknown>
			>;
		},
		ref: React.Ref<unknown>
	) => <Slide direction="up" ref={ref} {...props} />
);

interface IHubSpotMeetingIframeProps {
	hubspotMeetingUrl: string;
	prefill?:
		| UserTypes.IUserRead
		| {
				email: string;
				firstName: string;
				lastName: string;
		  };
}

export const HubSpotIFrameInitializer = ({
	hubspotMeetingUrl,
	prefill,
}: IHubSpotMeetingIframeProps) => {
	const theme = useTheme();

	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const height = isMobile ? '800px' : '640px';

	React.useEffect(() => {
		const script = document.createElement('script');

		script.src =
			'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
		script.onload = function () {
			const meetingsEmbedCode = (window as ICustomWindow).hsMeetingEmbed;

			if (meetingsEmbedCode) {
				meetingsEmbedCode.initialize({
					widgetPosition: 'inline',
					targetSelector: '.meetings-iframe-container',
				});
			}
		};
		script.onerror = function (ev) {
			logger.log(`script failed to load.${ev}`);
		};
		document.body.appendChild(script);
	}, [hubspotMeetingUrl]);

	// Construct hubspot url with prefill details
	const preFilledHubSpotMeetingUrl = `${hubspotMeetingUrl}&email=${
		prefill?.email || ''
	}&firstName=${prefill?.firstName || ''}&lastName=${
		prefill?.lastName || ''
	}`;

	return (
		<div
			dangerouslySetInnerHTML={{
				__html: `<iframe src='${preFilledHubSpotMeetingUrl}' height='${height}' width='100%' style='border:0;' />`,
			}}
		/>
	);
};

const HubSpotWidget = ({
	title,
	openhubSpotDialog,
	setOpenhubSpotDialog,
	prefill,
	extraComponent,
	nextStep,
	client,
}: IProps) => {
	const theme = useTheme();
	const PrimaryContrastColorHex = theme?.palette?.primary?.contrastText;
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
	const api = new Api.default(client);
	const queries = new EntityQueries(api);

	const { data: advisor } = queries.useGetEntity<UserTypes.TUserRead>(
		'users',
		prefill?.sparkAdvisorId
	);
	// Initialize hubspotMeetingUrl with the default URL
	const hubspotMeetingUrl = HubSpotUtils.getHubSpotMeetingUrl(
		advisor?.hubspotMeetingUrls,
		'quick-meeting'
	);

	const handleClose = () => {
		setOpenhubSpotDialog(false);
		nextStep?.();
	};

	return (
		<>
			<Dialog
				maxWidth="lg"
				fullWidth
				open={openhubSpotDialog}
				TransitionComponent={Transition}
				fullScreen={fullScreen}
				keepMounted
			>
				<DialogTitle sx={{ backgroundColor: 'secondary.main' }}>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<Typography
							sx={{
								fontWeight: 'bold',

								color: PrimaryContrastColorHex,
							}}
							variant="h5"
						>
							{title ||
								'Book a call with one of our experienced Energy Advisors'}
						</Typography>

						<IconButton onClick={handleClose}>
							<Close htmlColor={PrimaryContrastColorHex} />
						</IconButton>
					</Box>
				</DialogTitle>
				<Helmet>
					<script
						type="text/javascript"
						src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
					/>
				</Helmet>
				<DialogContent sx={{ height: '100%' }}>
					<HubSpotIFrameInitializer
						hubspotMeetingUrl={hubspotMeetingUrl}
						prefill={prefill}
					/>
				</DialogContent>
			</Dialog>
			{extraComponent}
		</>
	);
};

export default HubSpotWidget;
