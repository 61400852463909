"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Paper = (theme) => {
    return {
        MuiPaper: {
            defaultProps: {
                elevation: 0,
            },
            variants: [
                {
                    props: { variant: 'outlined' },
                    style: { borderColor: theme.palette.grey[50012] },
                },
            ],
            styleOverrides: {
                root: {
                    backgroundImage: 'none',
                },
            },
        },
    };
};
exports.default = Paper;
