"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.comLogQuerySchema = exports.comLogUpdateSchema = exports.comLogWriteSchema = exports.comLogReadSchema = exports.comLogSchema = exports.COM_LOG_STATUS = exports.COMMUNICATION_TRIGGERS = exports.COM_LOG_TYPES = void 0;
var typebox_1 = require("@feathersjs/typebox");
exports.COM_LOG_TYPES = [
    'whatsapp-prod',
    'whatsapp-prod-failed',
    'whatsapp-mfc-prod-2',
    'whatsapp-mfc-prod-2-failed',
    'finance link sent',
    'twilio-proposal-generated',
    'email-prod-24-hours',
    'email-prod-36-hours',
    'email-prod-48-hours',
    'email-prod-introduce-epc',
    'email-prod-testimonial-email',
    'email-prod-welcome-email',
    'email-prod-notify-ea-client-accepted-ep',
    'whatsapp-nudge-comms',
    'whatsapp-process-comms',
    'whatsapp-cover-our-ass-comms',
];
exports.COMMUNICATION_TRIGGERS = [
    'ip generated',
    'no ip generated',
    'no site visit booked',
    'proposal not within budget',
    'twilio-proposal-generated',
    'engineering proposal accepted by client',
    'client signs up',
    'verified email but has not yet generated an ip',
    'client has verified email but has not yet generated an IP 24 hours after sign up(N1)',
    'client has verified email but has not yet generated an IP 24 hours after N1 (N2)',
    'client has verified email but has not yet generated an IP 48 hours after N2 (N3)',
    'client has verified email but has not yet generated an IP 24 hours after N3 (N4)',
    'client generates the IP',
    'client has generated an IP but has not accepted it',
    'client accepts IP',
    'deployment, procurement and CX sign off on installer site visit date',
    'Online consultation booking is booked in',
    'Physical site visit booked',
    'Physical site visit confirmed',
    'Installer assigned to project',
    'Day of site visit',
    'morning after the site visit',
    'once email is sent with EP attached',
    'EP not yet accepted  24 hours after submission',
    'EP not yet accepted  48 hours after P9 is sent',
    'CX captures installation date',
    'Client confirms installation date',
    'Morning of installation',
    'Day before QA visit',
    'QA visit day',
    'Day after QA visit',
    'Handover to client',
    'Suggested site visit date',
];
var COM_LOG_STATUS;
(function (COM_LOG_STATUS) {
    COM_LOG_STATUS["FAILED"] = "failed";
    COM_LOG_STATUS["DELAYED"] = "delayed";
    COM_LOG_STATUS["COMPLETED"] = "completed";
    COM_LOG_STATUS["ABANDONED"] = "abandoned";
})(COM_LOG_STATUS || (exports.COM_LOG_STATUS = COM_LOG_STATUS = {}));
exports.comLogSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    type: (0, typebox_1.StringEnum)(__spreadArray([], exports.COM_LOG_TYPES, true)),
    trigger: (0, typebox_1.StringEnum)(__spreadArray([], exports.COMMUNICATION_TRIGGERS, true)),
    message: typebox_1.Type.String(),
    droppedOff: typebox_1.Type.Optional(typebox_1.Type.Boolean()), // Marked as true after 48 hours of no response (Whatsapp)
    userId: typebox_1.Type.String(),
    engineeredProposalId: typebox_1.Type.Optional(typebox_1.Type.String()),
    contactNumber: typebox_1.Type.String(),
    followUpComplete: typebox_1.Type.Boolean(),
    prerequisite: typebox_1.Type.Optional(typebox_1.Type.String()),
    sendingDelayed: typebox_1.Type.Boolean(),
    trials: typebox_1.Type.Number(),
    status: (0, typebox_1.StringEnum)(__spreadArray([], Object.values(COM_LOG_STATUS), true)), // TODO check this
    // Comms that are delayed will be sent after the delayWaitTime has passed
    // Delay time is in minutes
    delayWaitTime: typebox_1.Type.Number(),
    configData: typebox_1.Type.Object({
        taskGid: typebox_1.Type.String(),
    }),
    whatsappMessageId: typebox_1.Type.Optional(typebox_1.Type.String()),
    projectId: typebox_1.Type.Optional(typebox_1.Type.String()),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.comLogReadSchema = typebox_1.Type.Omit(exports.comLogSchema, []);
exports.comLogWriteSchema = typebox_1.Type.Omit(exports.comLogReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.comLogUpdateSchema = typebox_1.Type.Partial(exports.comLogSchema, {
    $id: 'ComLogUpdate',
});
var comLogQueryProperties = typebox_1.Type.Partial(exports.comLogSchema, {
    $id: 'ComLogQuery',
});
exports.comLogQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(comLogQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
