'use client';

import { useState } from 'react';
import {
	type MuiTelInputCountry,
	type MuiTelInputProps,
	MuiTelInput,
} from 'mui-tel-input';
// @local
import { type TPhoneNumberInputProps } from '../utils/types';
import { inputsDefaultStyle } from '../utils/constants';

export type { TPhoneNumberInputProps };
export const PhoneNumberInput = (props: TPhoneNumberInputProps) => {
	const [value, setValue] = useState('');
	const handleChange = (newValue: string) => {
		setValue(newValue);
	};

	return (
		<MuiTelInput
			value={value}
			onChange={handleChange}
			fullWidth
			{...(props as MuiTelInputProps)}
			InputProps={{
				style: inputsDefaultStyle,
				...props?.InputProps,
			}}
			helperText={(({ error, label, helperText } = props) =>
				error && label === helperText
					? 'Please enter a valid contact number'
					: helperText)()}
			defaultCountry={
				(props?.defaultCountry?.toUpperCase() ||
					'ZA') as MuiTelInputCountry
			}
			forceCallingCode
			disableFormatting
		/>
	);
};
