import { Grid, GridProps as TClientLayoutProps, styled } from '@mui/material';

const StyledDiv = styled('div')(({ theme }) => ({
	position: 'relative',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	overflowY: 'auto',
	overflowX: 'hidden',
	paddingLeft: theme.spacing(3),
	paddingRight: theme.spacing(3),
	backgroundColor: 'white',
}));

export type { TClientLayoutProps };
export const ClientLayout = ({
	className,
	children,
	...rest
}: TClientLayoutProps) => (
	<StyledDiv className={className}>
		<Grid spacing={10} container {...rest}>
			{children}
		</Grid>
	</StyledDiv>
);
