// @mui
import { MenuItem } from '@mui/material';
// @local
import { TMenuItemsProps } from '../../utils/types';

const getMenuItemBody = ({ menuItems }: { menuItems: ReadonlyArray<TMenuItemsProps> }) =>
	menuItems?.map((menuItem) =>
		(typeof menuItem === 'object'
			? Object.entries(menuItem)
			: [[menuItem, menuItem]]
		).map(([key, value]) => (
			<MenuItem key={key} value={value}>
				{key}
			</MenuItem>
		))
	) || [];

const MenuItemHead = ({ title = 'Select an Option' }: { title?: string }) => {
	return title ? (
		<MenuItem value="" disabled>
			{title}
		</MenuItem>
	) : null;
};

export const CustomMenuItem = ({
	title,
	menuItems,
}: {
	title?: string;
	menuItems: ReadonlyArray<TMenuItemsProps>
}) => [MenuItemHead({ title }), getMenuItemBody({ menuItems })];
