// @mui
import {
	styled,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentProps,
	Typography,
	Stack,
	IconButton,
} from '@mui/material';

const defaultCloseIcon =
	'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/glint-user-journey/closeIcon.png';

type TVariant =
	| 'h1'
	| 'h2'
	| 'h3'
	| 'h4'
	| 'h5'
	| 'h6'
	| 'subtitle1'
	| 'subtitle2'
	| 'body1'
	| 'body2'
	| 'caption';

const StyledDialog = styled(Dialog)(() => ({
	maxWidth: 'sm',
	fullWidth: true,
	minHeight: '500px',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
	textAlign: 'center',
	color: theme.palette.secondary.main,
}));

const StyledStack = styled(Stack)(() => ({
	mt: 2,
	alignContent: 'center',
	alignItems: 'center',
}));

const StyledIconButton = styled(IconButton)(() => ({
	position: 'absolute',
	right: 8,
	top: 8,
}));

export type TCustomDialogProps = {
	open: boolean;
	onClose: () => void;
	title: string;
	content: DialogContentProps['children'];
	headerIcon?: string;
	closeIcon?: string;
	titleVariant: TVariant;
};

export const CustomDialog = ({
	open,
	onClose,
	title,
	content,
	headerIcon,
	closeIcon,
	titleVariant,
}: TCustomDialogProps) => {
	const close = closeIcon || defaultCloseIcon;

	return (
		<StyledDialog open={open} onClose={onClose}>
			<DialogTitle>
				<StyledStack direction="column">
					{headerIcon && (
						<img
							src={headerIcon}
							alt="check-done"
							style={{ height: '80px', width: '80px' }}
						/>
					)}
					<StyledTypography mt={5} variant={titleVariant}>
						{title}
					</StyledTypography>
					<StyledIconButton aria-label="close" onClick={onClose}>
						<img src={close} alt="close" />
					</StyledIconButton>
				</StyledStack>
			</DialogTitle>
			<DialogContent>{content}</DialogContent>
		</StyledDialog>
	);
};
