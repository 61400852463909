"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.engineeredProposalPricingQuerySchema = exports.engineeredProposalPricingUpdateSchema = exports.engineeredProposalPricingWriteSchema = exports.engineeredProposalPricingReadSchema = exports.engineeredProposalPricingSchema = void 0;
var typebox_1 = require("@feathersjs/typebox");
var currency_types_1 = require("./currency.types");
var indicative_proposal_types_1 = require("./indicative-proposal.types");
exports.engineeredProposalPricingSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    marginPercentage: typebox_1.Type.Number(),
    contingencyPercentage: typebox_1.Type.Number(),
    discountPercentage: typebox_1.Type.Number(),
    depositAmount: typebox_1.Type.Number(),
    finance: indicative_proposal_types_1.proposalFinanceSchema,
    currency: (0, typebox_1.StringEnum)(__spreadArray([], currency_types_1.SUPPORTED_CURRENCY, true)),
    servicePlanId: typebox_1.Type.String(),
    engineeredProposalId: typebox_1.Type.String(),
    productKitId: typebox_1.Type.String(),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.engineeredProposalPricingReadSchema = typebox_1.Type.Omit(exports.engineeredProposalPricingSchema, []);
exports.engineeredProposalPricingWriteSchema = typebox_1.Type.Omit(exports.engineeredProposalPricingReadSchema, ['id', 'createdAt', 'updatedAt']);
exports.engineeredProposalPricingUpdateSchema = typebox_1.Type.Partial(exports.engineeredProposalPricingSchema, {
    $id: 'EngineeredProposalPricingUpdate',
});
var engineeredProposalPricingQueryProperties = typebox_1.Type.Partial(exports.engineeredProposalPricingSchema, {
    $id: 'EngineeredProposalPricingQuery',
});
exports.engineeredProposalPricingQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(engineeredProposalPricingQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
