"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var nedbank = {
    theme: 'nedbankmfc',
    brand: {
        name: 'MFC',
        legalName: 'MFC, a division of Nedbank',
        logo: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/Nedbank.png',
        icon: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/Nedbank.png',
    },
    financePartners: [],
    campaignPartners: [
        {
            name: 'MFC',
            logo: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/Nedbank.png',
        },
    ],
    poweredBy: {
        name: 'Hohm Energy',
        legalName: 'Hohm Services (Pty) Ltd.',
        logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/general/HohmEnergyLogoOrange.png',
    },
    partner: {
        name: 'Hohm Energy',
        legalName: 'Hohm Services (Pty) Ltd.',
        logo: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/general/HohmEnergyLogoOrange.png',
    },
    pages: {
        signup: {
            callUsNumber: 'tel:0105104440',
            callUsText: 'Call us (010) 510 4440',
            sections: {
                titleSection: {
                    title: "From start to solar, we've got your back.",
                },
            },
        },
    },
    supportedLoanBanks: ['Nedbank'],
    financeOptions: [
        {
            label: 'Cash',
            value: 'cash',
        },
        {
            label: 'Solar Finance',
            value: 'nedbank-mfc',
            default: true,
        },
        {
            label: 'Home Loan',
            value: 'home-loan',
        },
    ],
    buyOnly: true,
    hideSubscribeBuyCTA: true,
};
exports.default = nedbank;
