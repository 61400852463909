'use client';

import { useState } from 'react';
// @mui
import { Stack, Badge, Grid, IconButton, Tooltip } from '@mui/material';
// @icons
import { Language as LanguageIcon } from '@mui/icons-material';
// @local
import { LocationDialog } from '../LocationDialog';
import { TLocationsProps } from '../utils/types';

export type { TLocationsProps };
export const Locations = ({
	color = '#7f7f7f',
	myCountryCode,
	confirmLocation,
}: TLocationsProps) => {
	const [viewDialog, setViewDialog] = useState<boolean>(false);

	return (
		<Grid
			item
			md={12}
			xs={12}
			sx={{
				display: 'flex',
				justifyContent: 'flex-start',
			}}
		>
			<Stack direction="row" alignItems="center">
				<Tooltip
					arrow
					disableInteractive
					title="Click here to update your location"
					placement="left"
				>
					<IconButton
						aria-label="locations"
						color="default"
						size="small"
						onClick={() => setViewDialog(true)}
						sx={{
							borderRadius: '20px',
							padding: '10px',
							color,
						}}
					>
						<Badge>
							<LanguageIcon
								fontSize="small"
								sx={{ marginRight: '3px' }}
							/>
							{myCountryCode}
						</Badge>
					</IconButton>
				</Tooltip>
				<LocationDialog
					open={viewDialog}
					handleClose={() => setViewDialog(false)}
					myCountryCode={myCountryCode}
					confirmLocation={confirmLocation}
				/>
			</Stack>
			<LocationDialog
				open={viewDialog}
				handleClose={() => setViewDialog(false)}
				myCountryCode={myCountryCode}
				confirmLocation={confirmLocation}
			/>
		</Grid>
	);
};
