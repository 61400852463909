// @mui
import { Breakpoint } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

type TQuery = 'up' | 'down' | 'between' | 'only';
type TKey = Breakpoint | number;
type TStart = Breakpoint | number;
type TEnd = Breakpoint | number;

const useResponsive = (
	query: TQuery,
	key?: TKey,
	start?: TStart,
	end?: TEnd
) => {
	const theme = useTheme();

	const mediaUp = useMediaQuery(theme.breakpoints.up(key as TKey));

	const mediaDown = useMediaQuery(theme.breakpoints.down(key as TKey));

	const mediaBetween = useMediaQuery(
		theme.breakpoints.between(start as TStart, end as TEnd)
	);

	const mediaOnly = useMediaQuery(theme.breakpoints.only(key as Breakpoint));

	if (query === 'up') {
		return mediaUp;
	}

	if (query === 'down') {
		return mediaDown;
	}

	if (query === 'between') {
		return mediaBetween;
	}

	if (query === 'only') {
		return mediaOnly;
	}
};

export default useResponsive;
