import { StateCreator } from 'zustand/vanilla';
import { Application } from '@feathersjs/feathers/lib';
// types
import {
	CountryCodes,
	CurrencyTypes,
	PermissionTypes,
	UserTypes,
} from 'hohm-types';
// utilities
import { Internationalization } from 'hohm-utilities';
// queries
import { Api } from 'hohm-queries';

export const mergeArrayUnique = (arr1: any[], arr2: any[]) =>
	arr1.concat(arr2.filter((item) => arr1.indexOf(item) === -1));

export interface IAuthData {
	role: string | null;
	userId: string | null;
	countryCode: CountryCodes.TCountryCode | null;
	accessToken: string | null;
	fullName: string | null;
	organisationId: string | number | null;
	permissions?: PermissionTypes.TPermission[] | null;
	sparkAdvisorId?: string | number | null;
	campaignId?: string | number | null;
	currency: CurrencyTypes.TCurrency | null;
	timezone?: string | null;
	email?: string | null;
	utmData?: UserTypes.IUTMData;
}

export interface IAuthActions {
	login: (payload: { email: string; password: string }) => Promise<{
		success: boolean;
		message: string | null;
		permissions: PermissionTypes.TPermission[];
	}>;
	logout: () => Promise<{
		success: boolean;
	}>;
	sendVerificationRequest: (payload: {
		email: string;
	}) => Promise<{ success: boolean }>;
	verifyAccount: (payload: {
		token: string;
	}) => Promise<{ success: boolean }>;
	setState: (payload: Partial<IAuthData>) => void;
	reAuthenticate: () => Promise<{ success: boolean; message: null | string }>;
	getUserLocationFields: () => Promise<{
		success: boolean;
		data: null | Internationalization.ILocationRes;
		message: null | string;
	}>;
	recover: (
		email: string
	) => Promise<{ success: boolean; message: null | string }>;
	updatePassword: (params: {
		userId: string;
		newPassword: string;
		currentPassword: string;
	}) => Promise<{ success: boolean; message: null | string }>;
	resetPassword: (params: {
		email: string;
		password: string;
		resetToken: string;
	}) => Promise<{ success: boolean; message: null | string }>;
	setUserUtmData: (params: UserTypes.IUTMData) => void;
	clearAccessToken: () => void;
}

export type TAuthStore = IAuthData & IAuthActions;

export const createAuthStore =
	<T extends TAuthStore>(
		client: Application<any, any>
	): StateCreator<T, [], [], TAuthStore> =>
	(set, get) => ({
		role: null,
		countryCode: null,
		userId: null,
		accessToken: null,
		fullName: null,
		organisationId: null,
		permissions: null,
		sparkAdvisorId: null,
		campaignId: null,
		currency: null,
		email: null,
		timezone: 'Africa/Johannesburg',
		utmData: {
			utmSource: null,
			utmMedium: null,
			utmCampaign: null,
			utmTerm: null,
			utmContent: null,
		},
		login: async (payload: { email: string; password: string }) => {
			try {
				// @ts-ignore authenticate exists on feathers client
				const result = await client.authenticate({
					strategy: 'local',
					email: payload.email,
					password: payload.password,
				});

				const { permissions = [] } = result.authentication.payload as {
					permissions: PermissionTypes.IPermissionRead[];
				};

				const permissionNames = permissions.map((n) => n.permission);

				const currentPermissions = get().permissions || [];

				const mergedPermissions: PermissionTypes.TPermission[] =
					mergeArrayUnique(currentPermissions, permissionNames);

				set((state) => ({
					...state,
					role: result.authentication.payload.permissions[0].role,
					userId: result.user.id,
					accessToken: result.accessToken,
					fullName: result.user.fullName,
					organisationId: result.user.myOrganisationId,
					permissions: mergedPermissions,
					sparkAdvisorId: result.user.sparkAdvisorId,
					campaignId: result.user.campaignId,
					currency: result.user.currency,
					email: result.user.email,
				}));

				return {
					success: true,
					message: null,
					permissions: mergedPermissions,
				};
			} catch (error: unknown) {
				set((state) => ({
					...state,
					role: null,
					userId: null,
					accessToken: null,
					fullName: null,
					permissions: [] as PermissionTypes.TPermission[],
				}));

				if (error instanceof Error) {
					return {
						success: false,
						message: error.message,
						permissions: [] as PermissionTypes.TPermission[],
					};
				}

				return {
					success: false,
					message: null,
					permissions: [] as PermissionTypes.TPermission[],
				};
			}
		},

		recover: async (email: string) => {
			try {
				const api = new Api.default(client);

				await api.create('auth/request-new-password', {
					email,
				});

				return { success: true, message: null };
			} catch (error: unknown) {
				if (error instanceof Error) {
					return { success: false, message: error.message };
				}

				return { success: false, message: null };
			}
		},

		updatePassword: async (params: {
			userId: string;
			newPassword: string;
			currentPassword: string;
		}) => {
			try {
				const apiActions = new Api.default(client);

				await apiActions.create('auth/update-password', {
					userId: params.userId,
					currentPassword: params.currentPassword,
					newPassword: params.newPassword,
				});

				return { success: true, message: null };
			} catch (error: unknown) {
				if (error instanceof Error) {
					return { success: false, message: error.message };
				}

				return { success: false, message: null };
			}
		},

		resetPassword: async (params: {
			email: string;
			password: string;
			resetToken: string;
		}) => {
			try {
				const api = new Api.default(client);

				await api.create('auth/set-new-password', {
					email: params.email,
					password: params.password,
					resetToken: params.resetToken,
				});

				return { success: true, message: null };
			} catch (error: unknown) {
				if (error instanceof Error) {
					return { success: false, message: error.message };
				}

				return { success: false, message: null };
			}
		},

		getUserLocationFields: async () => {
			try {
				const locationFields =
					await Internationalization.getUserLocationFields();

				return { success: true, data: locationFields, message: null };
			} catch (error: unknown) {
				if (error instanceof Error) {
					return {
						success: false,
						message: error.message,
						data: null,
					};
				}

				return { success: false, message: null, data: null };
			}
		},

		sendVerificationRequest: async ({ email }: { email: string }) => {
			try {
				const api = new Api.default(client);

				await api.create('auth/verify', {
					email,
				});

				return { success: true };
			} catch (error: unknown) {
				if (error instanceof Error) {
					return {
						success: false,
						message: error.message,
						data: null,
					};
				}

				return { success: false, message: null, data: null };
			}
		},

		verifyAccount: async ({ token }: { token: string }) => {
			try {
				const api = new Api.default(client);

				await api.create('auth/verify-email-token', {
					token,
				});

				return { success: true };
			} catch (error: unknown) {
				if (error instanceof Error) {
					return {
						success: false,
						message: error.message,
						data: null,
					};
				}

				return { success: false, message: null, data: null };
			}
		},

		reAuthenticate: async () => {
			try {
				const result =
					// @ts-ignore reAuthenticate exists on feathers client
					(await client.reAuthenticate()) as unknown as {
						accessToken: string;
						user: UserTypes.IUserRead;
						authentication: {
							payload: {
								permissions?: PermissionTypes.IPermissionRead[];
							};
						};
					};

				const { permissions = [] } = result.authentication.payload;

				const permissionNames = (
					permissions as PermissionTypes.IPermissionRead[]
				).map((n) => n.permission);

				const mergedPermissions = mergeArrayUnique(
					permissions,
					permissionNames
				);

				set((state) => ({
					...state,
					role: result.authentication.payload?.permissions?.[0]?.role,
					userId: result.user.id,
					accessToken: result.accessToken,
					fullName: result.user.fullName,
					organisationId: result.user.myOrganisationId,
					permissions: mergedPermissions,
					sparkAdvisorId: result.user.sparkAdvisorId,
					campaignId: result.user.campaignId,
					currency: result.user.currency,
				}));

				return { success: true, message: null };
			} catch (error: unknown) {
				set((state) => ({
					...state,
					role: null,
					userId: null,
					accessToken: null,
					fullName: null,
				}));

				if (error instanceof Error) {
					return { success: false, message: error.message };
				}

				return { success: false, message: null };
			}
		},

		logout: async () => {
			try {
				// @ts-ignore logout exists on feathers client
				await client.logout();

				set((state) => ({
					...state,
					role: null,
					userId: null,
					accessToken: null,
					fullName: null,
				}));

				return { success: true };
			} catch (err) {
				return { success: false };
			}
		},
		setState: (payload: Partial<IAuthData>) =>
			set((state) => ({ ...state, ...payload })),
		setUserUtmData: (params: UserTypes.IUTMData) => {
			set((state) => ({
				...state,
				utmData: params,
			}));
		},
		clearAccessToken: () => {
			set((state) => ({
				...state,
				accessToken: null,
			}));
		},
	});
