import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export default makeStyles(() =>
	createStyles({
		baseIcon: {
			color: '#112f48',
		},
		closeIconContainer: {
			position: 'fixed',
			top: '15px',
			right: '15px',
			zIndex: 1000,
		},
	})
);
