'use client';

import * as R from 'ramda';
import {
	useState,
	ReactElement,
	Dispatch,
	SetStateAction,
	ReactNode,
	useCallback,
	useEffect,
} from 'react';
import { Application } from '@feathersjs/feathers/lib';
import { useDeepCompareEffect } from 'react-use';
// @mui
import { GridPreProcessEditCellProps } from '@mui/x-data-grid';
import {
	Badge,
	Box,
	Button,
	Drawer,
	IconButton,
	InputAdornment,
	Menu,
	MenuItem,
	Stack,
	Tooltip,
	ButtonProps,
	Typography,
	Checkbox,
	FormGroup,
	FormControlLabel,
	Fade,
	Grid,
} from '@mui/material';
import {
	DataGridPro,
	GridCellEditCommitParams,
	GridEnrichedColDef,
	GridEventListener,
	GridRenderCellParams,
	GridRowParams,
	GridSortModel,
} from '@mui/x-data-grid-pro';
import { makeStyles } from '@mui/styles';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
// @icons
import {
	MoreVert,
	Search,
	FilterAlt,
	ArrowBack,
	FilterList,
} from '@mui/icons-material';
// queries
import { Api, EntityQueries } from 'hohm-queries';
// utilities
import { RegEx } from 'hohm-utilities';
// @local
import { Autocomplete } from '../../inputs/Autocomplete';
import { Input } from '../../inputs/Input';
import { Select } from '../../inputs/Select';
import { DatePicker } from '../../inputs/DatePicker';

export { default as GridEditSelectCell } from './EditCell/Select';

const useStyles = makeStyles({
	root: {
		'&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
			outline: 'none',
		},
		'&.MuiDataGrid-root .MuiDataGrid-detailPanel': {
			backgroundColor: '#f3f3f3',
			padding: '40px',
		},
	},
});

export type TDataGridProps = {
	heading?: string;
	entity: Api.TApiEntities;
	query?: Record<string, unknown>;
	columns: GridEnrichedColDef[];
	filters?: TFilters;
	mainMenu?: TMainMenu;
	rowMenu?: TRowMenu;
	pagination?: boolean;
	paginationMode?: 'client' | 'server';
	pageSize?: number;
	sortModel?: GridSortModel;
	rowsPerPageOptions?: number[];
	hideFooterRowCount?: boolean;
	keepPreviousData?: boolean;
	style?: React.CSSProperties;
	autoHeight?: boolean;
	hideSearchAndFilter?: boolean;
	onRowClick?: GridEventListener<'rowClick'>;
	getDetailPanelContent?: (params: GridRowParams) => ReactNode;
	client: Application<any, any>;
	hasEditableAutoSaveCell?: boolean;
	hideSelectionFilters?: boolean;
	onRequestStatus?: (
		status: 'error' | 'success' | 'idle' | 'loading'
	) => void;
	requestTimeout?: number;
	onQuery?: (query: Record<string, unknown>) => void;
};

interface IInternalProps extends TDataGridProps {
	pagination: boolean;
	paginationMode: 'client' | 'server';
	pageSize: number;
	rowsPerPageOptions: number[];
	keepPreviousData: boolean;
	searchText: string;
	searchFields: string[];
	filterModel: TFilterModel;
	disableSelectionOnClick: boolean;
	client: Application<any, any>;
	searchSearchColumns: GridEnrichedColDef[];
	selectedFilters: string[];
}

interface IMainMenuItem {
	label: string;
	tooltip?: string;
	onClick: () => void;
	buttonProps?: ButtonProps;
}

export type TMainMenu = IMainMenuItem[];

interface IRowMenuItem {
	label: string;
	onClick: (params: GridRenderCellParams) => void;
}

export type TRowMenu = IRowMenuItem[];

export enum IFilterType {
	SearchFilter = 'search',
	AutocompleteFilter = 'autocomplete',
	SelectFilter = 'select',
	JSONFilter = 'JSON',
	DateFilter = 'date',
	TextOperatorFilter = 'textOperator',
}

type TFilterValue = number | string;

interface IFilterOption {
	value?: TFilterValue;
	label: string;
	query?: Record<string, unknown>;
}

export enum IFilterOperator {
	$lt = '$lt',
	$lte = '$lte',
	$gt = '$gt',
	$gte = '$gte',
	$ne = '$ne',
	$eq = '$eq',
}

// TODO: fix any type
// Any type is used here because we still need to figure out
// how to pass in the correct type for foreign entities.
// This was a bit of a rabbit hole and has been noted in the
// documentation. This will be fixed in a future version.
interface IFilterForeignEntity {
	entity: Api.TApiEntities;
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	query?: any;
	$select?: string[];
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	getId?: (row: any) => any;
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	getLabel?: (row: any) => any;
}

interface IFilter {
	field: string;
	label: string;
	type?: IFilterType;
	options?: IFilterOption[];
	operator?: IFilterOperator;
	foreignEntity?: IFilterForeignEntity;
}

export type TFilters = IFilter[];

type TFilterOptions = Record<string, IFilterOption[]>;

interface IGetFiltersProps {
	showFilters: boolean;
	setShowFilters: Dispatch<SetStateAction<boolean>>;
	setFilterModel: Dispatch<SetStateAction<TFilterModel>>;
	filters: TFilters;
	filterOptions: TFilterOptions;
}

interface IFilterModelItem extends IFilter {
	value: TFilterValue;
}

type TFilterModel = IFilterModelItem[];

interface IInternalFilterModelItem extends IFilter {
	filterModel: TFilterModel;
	setFilterModel: Dispatch<SetStateAction<TFilterModel>>;
}

type TInternalFilterModel = IInternalFilterModelItem[];

interface IFilterProps {
	filter: IInternalFilterModelItem;
}

interface IFilterWithOptionsProps extends IFilterProps {
	filterOptions: TFilterOptions;
}

interface IRead {
	id: string;
	createdAt: Date;
	updatedAt: Date;
}

type TRead<T> = T & IRead;
type TWrite<T> = Pick<T & IRead, keyof IRead>;

const isJsonFilterField = (field: string) => /^[^\:]+:json$/.test(field);
const isNotJsonFilterField = (field: string) => !isJsonFilterField(field);

const getMainMenu = (mainMenu: TMainMenu) =>
	mainMenu.map((menu) => {
		const buttonProps: ButtonProps = {
			sx: { boxShadow: 'none' },
			color: 'secondary',
			variant: 'contained',
			onClick: menu.onClick,
			...menu.buttonProps,
		};

		return menu.tooltip ? (
			<Tooltip arrow title={menu.tooltip}>
				<Button {...buttonProps}>{menu.label}</Button>
			</Tooltip>
		) : (
			<Button {...buttonProps}>{menu.label}</Button>
		);
	});

const getRowMenu = (rowMenu: TRowMenu, params: GridRenderCellParams) => (
	<PopupState variant="popover" popupId={`${params.id}-row-menu`}>
		{(popupState) => (
			<>
				<IconButton {...bindTrigger(popupState)}>
					<MoreVert />
				</IconButton>
				{rowMenu ? (
					<Menu {...bindMenu(popupState)}>
						{rowMenu?.map((menu) => (
							<MenuItem
								key={`${params.id}-${menu.label}`}
								onClick={() => {
									popupState.close();

									return menu.onClick(params);
								}}
							>
								{menu.label}
							</MenuItem>
						))}
					</Menu>
				) : null}
			</>
		)}
	</PopupState>
);

const getSearchFields = (columns: GridEnrichedColDef[]) =>
	columns
		? columns.reduce((fields, column) => {
				if (column.filterable) fields.push(column.field);

				return fields;
			}, [] as string[])
		: [];

const getSearchColumns = (columns: GridEnrichedColDef[]) =>
	columns
		? columns.reduce((searchColumns, { filterable, field, headerName }) => {
				if (filterable && headerName)
					searchColumns.push({
						field,
						headerName,
					});

				return searchColumns;
			}, [] as GridEnrichedColDef[])
		: [];

const MenuDown = ({
	data,
	selectedFilters,
	setSelectedFilters,
}: {
	data: GridEnrichedColDef[];
	selectedFilters: string[];
	setSelectedFilters: Dispatch<React.SetStateAction<string[]>>;
}) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	useDeepCompareEffect(() => {
		//
	}, [selectedFilters]);

	return (
		<>
			<Tooltip arrow title="Selection filters">
				<IconButton onClick={handleClick}>
					<Badge
						color="primary"
						badgeContent={selectedFilters.length}
					>
						<FilterList color={open ? 'primary' : 'inherit'} />
					</Badge>
				</IconButton>
			</Tooltip>

			<Menu
				id="fade-menu"
				open={open}
				onClose={handleClose}
				anchorEl={anchorEl}
				MenuListProps={{
					'aria-labelledby': 'fade-button',
				}}
				TransitionComponent={Fade}
			>
				<FormGroup sx={{ p: '0 13px' }}>
					{data.map(({ field, headerName }) => (
						<FormControlLabel
							key={`${field + Date.now()}`}
							label={headerName}
							control={
								<Checkbox
									size="small"
									defaultChecked={selectedFilters.includes(
										field
									)}
								/>
							}
							onChange={(e) => {
								const { target } = e;

								if ((target as HTMLInputElement).checked) {
									if (isJsonFilterField(field)) {
										setSelectedFilters(
											(oldSelectedFilters) => [
												...oldSelectedFilters.filter(
													isJsonFilterField
												),
												field,
											]
										);
									} else {
										setSelectedFilters(
											(oldSelectedFilters) => [
												...oldSelectedFilters.filter(
													isNotJsonFilterField
												),
												field,
											]
										);
									}
								} else {
									setSelectedFilters((oldSelectedFilters) =>
										oldSelectedFilters.filter(
											(filter) => filter !== field
										)
									);
								}
							}}
						/>
					))}
				</FormGroup>
			</Menu>
		</>
	);
};

const getSearchField = (
	setSearchText: (searchText: string) => void,
	filteredColumns: GridEnrichedColDef[],
	selectedFilters: string[],
	setSelectedFilters: Dispatch<React.SetStateAction<string[]>>,
	hideSelectionFilters?: boolean
) => (
	<Input
		size="small"
		placeholder="Search"
		onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
			setSearchText(event.target.value);
		}}
		InputProps={{
			startAdornment: (
				<InputAdornment
					position="start"
					sx={{
						marginLeft: '-5px',
					}}
				>
					<Search />
				</InputAdornment>
			),
			endAdornment: (
				<InputAdornment
					position="end"
					sx={{
						marginRight: '-13px',
					}}
				>
					{filteredColumns?.length > 1 && !hideSelectionFilters ? (
						<MenuDown
							data={filteredColumns}
							selectedFilters={selectedFilters}
							setSelectedFilters={setSelectedFilters}
						/>
					) : null}
				</InputAdornment>
			),
		}}
	/>
);

const setFilterModelFieldValue = (
	filterValue: IFilterModelItem,
	filterModel: TFilterModel
) => {
	const filters = filterModel.filter(
		(filter) =>
			!(
				filter.field === filterValue.field &&
				filter.label === filterValue.label
			)
	);

	if (filterValue.value != null && filterValue.value !== '') {
		filters.push(filterValue);
	}

	return filters;
};

const getFilterFieldValue = (filter: IInternalFilterModelItem) =>
	filter.filterModel.reduce((value, curFilter) => {
		if (
			curFilter.field === filter.field &&
			curFilter.label === filter.label
		)
			return curFilter.value;

		return value;
	}, '' as TFilterValue);

function FilterSearch({ filter }: IFilterProps) {
	const filterValue = getFilterFieldValue(filter);

	return (
		<Input
			key={filter.label}
			type={
				filter.type === IFilterType.TextOperatorFilter
					? 'number'
					: 'text'
			}
			size="small"
			label={filter.label}
			value={filterValue}
			onChange={(event) => {
				filter.setFilterModel(
					setFilterModelFieldValue(
						{
							...filter,
							value: event.target.value,
						},
						filter.filterModel
					)
				);
			}}
		/>
	);
}

function FilterDate({ filter }: IFilterProps) {
	const filterValue = getFilterFieldValue(filter);

	return (
		<DatePicker
			key={filter.label}
			value={filterValue || null}
			label={filter.label}
			renderInput={(params) => <Input {...params} size="small" />}
			onChange={(newValue) => {
				if (newValue) {
					filter.setFilterModel(
						setFilterModelFieldValue(
							{
								...filter,
								value: newValue,
							},
							filter.filterModel
						)
					);
				}
			}}
		/>
	);
}

const optionsIncludesValue = (rows: IFilterOption[], option: IFilterOption) =>
	rows.reduce((hasValue, row) => {
		if (row.value === option.value) return true;

		return hasValue;
	}, false);

function FilterAutocomplete({
	filter,
	filterOptions,
}: IFilterWithOptionsProps) {
	const filterValue = getFilterFieldValue(filter);
	let filterValueOption: IFilterOption = {
		value: 0,
		label: '',
	};

	let tmpOptions: IFilterOption[] = [];

	if (filter?.options) tmpOptions = filter?.options;
	else if (filterOptions[filter.field])
		tmpOptions = filterOptions?.[filter.field];

	const options = tmpOptions.reduce(
		(returnOptions: IFilterOption[], option: IFilterOption) => {
			if (option.value === filterValue) filterValueOption = option;
			if (!option.value) option.value = option.label;
			if (!optionsIncludesValue(returnOptions, option))
				returnOptions.push(option);

			return returnOptions;
		},
		[] as IFilterOption[]
	);

	return (
		<Autocomplete<IFilterOption>
			key={filter.label}
			size="small"
			value={filterValueOption}
			placeholder={filter.label}
			options={options}
			isOptionEqualToValue={(option, value) =>
				option.value === value.value
			}
			onChange={(event, value) => {
				const newValue = value;

				filter.setFilterModel(
					setFilterModelFieldValue(
						{
							...filter,
							value: newValue?.value || '',
						},
						filter.filterModel
					)
				);
			}}
		/>
	);
}

function FilterSelect({ filter, filterOptions }: IFilterWithOptionsProps) {
	const filterValue = getFilterFieldValue(filter);
	let options: IFilterOption[] = [];

	if (filter.options) options = filter.options;
	else if (filterOptions[filter.field]) options = filterOptions[filter.field];

	const IDList: TFilterValue[] = [];

	options = options.filter((option: IFilterOption) => {
		if (!option.value) option.value = option.label;
		if (!IDList.includes(option.value)) {
			IDList.push(option.value);

			return true;
		}

		return false;
	});

	return (
		<Select
			key={filter.label}
			size="small"
			label={filter.label}
			value={filterValue}
			onChange={(event) => {
				filter.setFilterModel(
					setFilterModelFieldValue(
						{
							...filter,
							value: event.target.value,
						},
						filter.filterModel
					)
				);
			}}
			menuItems={[...options].map(({ label, value }) => ({
				[label]: String(value || ''),
			}))}
			menuItemTitle=""
		/>
	);
}

const getFilter = (
	filter: IInternalFilterModelItem,
	filterOptions: TFilterOptions
) => {
	const filterType = filter.type || IFilterType.SearchFilter;

	switch (filterType) {
		case IFilterType.DateFilter:
			return <FilterDate filter={filter} />;
		case IFilterType.AutocompleteFilter:
			return (
				<FilterAutocomplete
					filter={filter}
					filterOptions={filterOptions}
				/>
			);
		case IFilterType.SelectFilter:
		case IFilterType.JSONFilter:
			return (
				<FilterSelect filter={filter} filterOptions={filterOptions} />
			);
		default:
			return <FilterSearch filter={filter} />;
	}
};

function GetFilters({
	showFilters,
	setShowFilters,
	setFilterModel,
	filters,
	filterOptions,
}: IGetFiltersProps) {
	const [tmpFilterModel, setTmpFilterModel] = useState<TFilterModel>([]);

	const internalFilters: TInternalFilterModel = filters.map((filter) => ({
		...filter,
		filterModel: tmpFilterModel,
		setFilterModel: setTmpFilterModel,
	}));

	const handleApplyFilters = () => {
		setFilterModel(tmpFilterModel);
		setShowFilters(false);
	};

	const handleResetFilters = () => {
		setTmpFilterModel([]);
		setFilterModel([]);
		setShowFilters(false);
	};

	return (
		<Drawer
			anchor="left"
			open={showFilters}
			onClose={() => {
				setShowFilters(false);
			}}
		>
			<Box sx={{ width: '270px', padding: '30px' }} role="presentation">
				<Box sx={{ mb: 1 }}>
					<Tooltip
						arrow
						placement="right"
						title="Close advanced filters"
					>
						<IconButton
							onClick={() => {
								setShowFilters(false);
							}}
						>
							<ArrowBack />
						</IconButton>
					</Tooltip>
				</Box>

				<Stack direction="column" spacing={2}>
					{internalFilters.map((filter) =>
						getFilter(filter, filterOptions)
					)}
					<Button variant="contained" onClick={handleApplyFilters}>
						Apply filters
					</Button>
					<Button
						onClick={handleResetFilters}
						disabled={internalFilters.every(
							(e) => !e.filterModel[0]?.value
						)}
					>
						Reset filters
					</Button>
				</Stack>
			</Box>
		</Drawer>
	);
}

const filterToJson = (field: string, value: string | number) => {
	const jsonFilter: Object = {};
	let currentLevel: any = jsonFilter;

	const levels = field.replace(/\$/g, '').split('.');

	levels.map((level: string, index: number) => {
		const tmp: Object = (currentLevel[level] =
			levels.length - 1 === index ? value : {});

		currentLevel = tmp;
	});

	return jsonFilter;
};

const getSearchQuery = (
	searchText: string,
	fields: string[],
	selectedFilters: string[]
) => {
	let customFilters = {};

	const searchQuery: any = {};

	if (searchText) {
		const searchParams = [searchText].reduce(
			(acc: any, val) => {
				if (val)
					fields.forEach((field) => {
						if (isJsonFilterField(field)) {
							const normalizedField = field.replace(':json', '');

							if (!customFilters) {
								customFilters = filterToJson(
									normalizedField,
									`%${searchText}%`
								);
							} else {
								customFilters = R.mergeDeepRight(
									customFilters,
									filterToJson(
										normalizedField,
										`%${searchText}%`
									)
								);
							}
						} else if (selectedFilters.includes(field))
							acc.push({ [field]: { $iLike: `%${val}%` } });
					});

				return acc;
			},
			[] as { [field: string]: { $iLike: string } }[]
		);

		if (selectedFilters.some(isJsonFilterField)) {
			searchQuery.$customFilters = customFilters;
		} else {
			searchQuery.$or = searchParams;
		}

		return searchQuery;
	}
};

const getFilterQuery = (filterModel: TFilterModel) => {
	if (!filterModel?.length) return;

	const query = filterModel.reduce(
		(qry, filter) => {
			if (filter.operator) {
				qry[filter.field] = {
					[filter.operator]: filter.value,
				};
			} else if (filter.type === IFilterType.JSONFilter) {
				if (!qry.$customFilters) {
					qry.$customFilters = filterToJson(
						filter.field,
						filter.value
					);
				} else {
					qry.$customFilters = R.mergeDeepRight(
						qry.$customFilters,
						filterToJson(filter.field, filter.value)
					);
				}
			} else if (
				filter.type === IFilterType.AutocompleteFilter ||
				filter.type === IFilterType.SelectFilter
			) {
				let hasQuery = false;

				if (filter.options) {
					filter.options.forEach((option) => {
						if (
							option.query &&
							((option.value && option.value === filter.value) ||
								(option.label && option.value === filter.value))
						) {
							hasQuery = true;
							qry = R.mergeDeepRight(qry, option.query);
						}
					});
				}

				if (!hasQuery) qry[filter.field] = filter.value;
			} else if (filter.type === IFilterType.DateFilter) {
				qry[filter.field] = filter.value;
			} else qry[filter.field] = { $iLike: `%${filter.value}%` };

			return qry;
		},
		{} as Record<string, unknown>
	);

	return query;
};

const getSortQuery = (sortModel: GridSortModel) => {
	if (!sortModel.length) return;

	return {
		$sort: {
			...sortModel.reduce(
				(sortQuery, sortModelItem) => {
					sortQuery[sortModelItem.field] =
						sortModelItem.sort === 'asc' ? 1 : -1;

					return sortQuery;
				},
				{} as Record<string, number>
			),
		},
	};
};

function ServerDataGrid<TEntityRead>({
	client: propsClient,
	pageSize: propsPageSize,
	sortModel: propsSortModel,
	query: propsQuery,
	searchText,
	searchFields,
	selectedFilters,
	filterModel,
	onQuery,
	entity,
	keepPreviousData,
	onRequestStatus,
	requestTimeout,
	columns,
	rowMenu,
	pagination,
	paginationMode,
	rowsPerPageOptions,
	hideFooterRowCount,
	autoHeight,
	onRowClick,
	disableSelectionOnClick,
	getDetailPanelContent,
	hasEditableAutoSaveCell,
}: IInternalProps): ReactElement {
	const api = new Api.default(propsClient);
	const queries = new EntityQueries(api);

	const [page, setPage] = useState<number>(0);
	const [pageSize, setPageSize] = useState<number>(propsPageSize);
	const [sortModel, setSortModel] = useState<GridSortModel>(
		propsSortModel || []
	);
	const [query, setQuery] = useState<Api.TQuery<TEntityRead>>({
		...propsQuery,
		$limit: pageSize,
		$skip: page * pageSize,
	} as Api.TQuery<TEntityRead>);

	const [updatedEntityId, setUpdatedEntityId] = useState<string>('');
	const [updatedEntityData, setUpdatedEntityData] = useState<
		Partial<TWrite<TEntityRead>>
	>({});

	const fetchData = () => {
		const searchQuery = getSearchQuery(
			searchText,
			searchFields,
			selectedFilters
		);
		const filterQuery = getFilterQuery(filterModel);
		const sortQuery = getSortQuery(sortModel);

		const newQuery = {
			...propsQuery,
			...searchQuery,
			...filterQuery,
			...sortQuery,
			$limit: pageSize,
			$skip: page * pageSize,
		} as Api.TQuery<TEntityRead>;

		setQuery(newQuery);
		if (onQuery) onQuery(newQuery);
	};

	const { data, isLoading, status } = queries.useGetEntities<TEntityRead>(
		entity,
		query,
		{
			keepPreviousData,
			onSuccess() {
				if (onRequestStatus) onRequestStatus('success');
			},
		}
	);

	// This block of code will send the status updates to `props.onRequestStatus` callback function
	// if after `props.requestTimeout` (defaultValue: 5 seconds) the status value is not equal to 'success'
	// else `props.onRequestStatus` callback function will receive a status equal to 'success'
	const [fetchingTimeout, setFetchingTimeout] = useState<number>(-1);

	if (status === 'success') {
		clearTimeout(fetchingTimeout);
	}

	useEffect(() => {
		setFetchingTimeout(
			window.setTimeout(() => {
				if (onRequestStatus) onRequestStatus(status);
			}, requestTimeout || 5000)
		);
	}, [status]);

	const { handleUpdateEntity: updateEntity } = queries.useUpdateEntity<
		TRead<TEntityRead>,
		Partial<TWrite<TEntityRead>>
	>(entity, updatedEntityId || null);

	const handleCellEditCommit = useCallback(
		({ id, field, value }: GridCellEditCommitParams) => {
			setUpdatedEntityId(String(id));
			setUpdatedEntityData({
				[field]: value,
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	useDeepCompareEffect(() => {
		updateEntity(updatedEntityData);
	}, [updatedEntityData]);

	const rows = data?.data || [];
	const rowCount = data?.total || 0;

	if (rowMenu) {
		columns.push({
			field: 'actions',
			type: 'actions',
			width: 50,
			renderCell: (params: GridRenderCellParams) =>
				getRowMenu(rowMenu as TRowMenu, params),
		});
	}

	const handlePageChange = (newPage: number) => {
		setPage(newPage);
	};

	const handleSortModelChange = (newSortModel: GridSortModel) => {
		setSortModel(newSortModel);
	};

	const handlePageSizeChange = (newPageSize: number) => {
		setPageSize(newPageSize);
	};

	useDeepCompareEffect(() => {
		fetchData();
	}, [
		page,
		pageSize,
		sortModel,
		propsQuery,
		searchText,
		filterModel,
		selectedFilters,
	]);

	return (
		<DataGridPro
			rows={rows}
			columns={columns}
			loading={isLoading}
			pagination={pagination}
			paginationMode={paginationMode}
			pageSize={pageSize}
			rowCount={rowCount}
			rowsPerPageOptions={rowsPerPageOptions}
			hideFooterRowCount={hideFooterRowCount}
			sortingMode="server"
			className={useStyles().root}
			autoHeight={autoHeight}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			onRowClick={onRowClick}
			disableSelectionOnClick={disableSelectionOnClick}
			onSortModelChange={handleSortModelChange}
			getDetailPanelContent={getDetailPanelContent}
			getDetailPanelHeight={() => 'auto'}
			disableColumnMenu
			onCellEditCommit={
				hasEditableAutoSaveCell
					? handleCellEditCommit
					: () => {
							//
						}
			}
		/>
	);
}

// TODO: add support to more operators
// Advanced Filters : text search, DateFilter operators support are : $gte, $lte
// TextField Filters : keywords according to the Selection Filters checked fields
// Selection Filters : not json filters are supported
function ClientDataGrid<TEntityRead>({
	client,
	pageSize: propsPageSize,
	query: propsQuery,
	entity,
	keepPreviousData,
	onQuery,
	searchText,
	filterModel,
	selectedFilters,
	searchFields,
	columns,
	rowMenu,
	pagination,
	paginationMode,
	rowsPerPageOptions,
	hideFooterRowCount,
	autoHeight,
	onRowClick,
	disableSelectionOnClick,
	getDetailPanelContent,
}: IInternalProps): ReactElement {
	const api = new Api.default(client);
	const queries = new EntityQueries(api);

	const [pageSize, setPageSize] = useState<number>(propsPageSize);
	const [query, setQuery] = useState<Api.TQuery<TEntityRead>>({
		...propsQuery,
		$limit: 400,
		$skip: 0,
	} as Api.TQuery<TEntityRead>);

	const { data, isLoading } = queries.useGetEntities<TEntityRead>(
		entity,
		query,
		{ keepPreviousData }
	);

	const fetchData = () => {
		const qry = {
			...propsQuery,
			$limit: 400,
			$skip: 0,
		} as Api.TQuery<TEntityRead>;

		setQuery(qry);
		if (onQuery) onQuery(qry);
	};

	useDeepCompareEffect(() => {
		fetchData();
	}, [propsQuery, searchText, filterModel]);

	const selectionClientModeFilters = (selectedField: string) =>
		selectedFilters.includes(selectedField);

	const textFieldClientModeFilters =
		(row: any, searchTextArray: string[]) => (searchField: string) => {
			return searchTextArray.some((searchTextValue) =>
				String(row[searchField]).match(
					new RegExp(RegEx.quote(searchTextValue), 'i')
				)
			);
		};

	const rows = (data?.data || []).filter((row: any) => {
		const searchTextArray = searchText.includes(' ')
			? searchText?.split(' ')
			: [searchText];

		const advancedDateFilters = filterModel?.every((queryFilter) => {
			const { type, field, value: selectedDate, operator } = queryFilter;

			if (type)
				if (type === IFilterType.DateFilter) {
					const rowDate = row[field];

					if (operator === IFilterOperator.$gte)
						return new Date(rowDate) >= new Date(selectedDate);

					if (operator === IFilterOperator.$lte)
						return new Date(rowDate) <= new Date(selectedDate);
				}

			return String(row[field]).match(
				new RegExp(RegEx.quote(String(selectedDate)), 'i')
			);
		});

		const selectionAndTextFieldFilters = searchFields
			.filter(selectionClientModeFilters)
			.some(
				textFieldClientModeFilters(
					row,
					searchText === '' ? [''] : searchTextArray.filter(Boolean)
				)
			);

		return advancedDateFilters && selectionAndTextFieldFilters;
	});

	const rowCount = rows?.length || 0;

	if (rowMenu) {
		columns.push({
			field: 'actions',
			type: 'actions',
			width: 50,
			renderCell: (params: GridRenderCellParams) =>
				getRowMenu(rowMenu as TRowMenu, params),
		});
	}

	const handlePageSizeChange = (newPageSize: number) => {
		setPageSize(newPageSize);
	};

	return (
		<DataGridPro
			rows={rows}
			columns={columns}
			loading={isLoading}
			pagination={pagination}
			paginationMode={paginationMode}
			pageSize={pageSize}
			rowCount={rowCount}
			rowsPerPageOptions={rowsPerPageOptions}
			hideFooterRowCount={hideFooterRowCount}
			className={useStyles().root}
			autoHeight={autoHeight}
			onPageSizeChange={handlePageSizeChange}
			onRowClick={onRowClick}
			disableSelectionOnClick={disableSelectionOnClick}
			getDetailPanelContent={getDetailPanelContent}
			getDetailPanelHeight={() => 'auto'}
			disableColumnMenu
		/>
	);
}

const useGetFilterOptions = <TEntityRead,>(
	props: TDataGridProps
): TFilterOptions => {
	const api = new Api.default(props.client);
	const queries = new EntityQueries(api);

	const filterFields = (props?.filters
		?.filter((filter) => {
			if (
				(filter.type === IFilterType.AutocompleteFilter ||
					filter.type === IFilterType.SelectFilter) &&
				!filter.options &&
				!filter.foreignEntity
			)
				return true;

			return false;
		})
		.map((filter) => filter.field) || []) as Array<keyof TEntityRead>;

	let select = {};
	let limit = 0;

	if (filterFields.length) {
		select = { $select: ['id', ...filterFields] };
		limit = 400;
	}

	const qry = {
		...props.query,
		...select,
		$limit: limit,
		$skip: 0,
	} as Api.TQuery<TEntityRead>;

	const { data } = queries.useGetEntities<TEntityRead>(props.entity, qry, {
		keepPreviousData: props.keepPreviousData,
	});

	const filterOptions: TFilterOptions = {};

	filterFields.forEach((key) => {
		if (typeof key === 'string' && !filterOptions[key]) {
			filterOptions[key] = [];
		}
	});

	// TODO: replace with TEntityRead union type when ready
	data?.data?.forEach((row) => {
		filterFields.forEach((key) => {
			if (typeof key === 'string' && row[key]) {
				filterOptions[key].push({
					value: row[key] as unknown as string,
					label: row[key] as unknown as string,
				});
			}
		});
	});

	const filterForeignFields =
		props?.filters?.filter((filter) => {
			if (
				(filter.type === IFilterType.SelectFilter ||
					filter.type === IFilterType.AutocompleteFilter) &&
				!filter.options &&
				filter.foreignEntity
			)
				return true;

			return false;
		}) || [];

	const foreignFilterQueries = queries.useGetManyEntities(
		filterForeignFields.map((filter) => {
			let $select = ['id', 'name'];

			if (filter?.foreignEntity?.$select)
				$select = filter?.foreignEntity?.$select;

			const query = {
				...filter?.foreignEntity?.query,
				$select,
				$limit: 400,
				$skip: 0,
			};

			return {
				entity: filter.foreignEntity?.entity as Api.TApiEntities,
				query,
				options: { keepPreviousData: props.keepPreviousData },
			};
		})
	);

	filterForeignFields.forEach(({ field, foreignEntity }, index) => {
		filterOptions[field] = [];
		const foreignData = foreignFilterQueries[index]?.data?.data;

		if (foreignData?.length) {
			// TODO: fix any type
			// Any type is used here because we still need to figure out
			// how to pass in the correct type for foreign entities.
			// This was a bit of a rabbit hole and has been noted in the
			// documentation. This will be fixed in a future version.
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			foreignData.forEach((foreignRow: any) => {
				let value;
				let label;

				if (foreignEntity?.getId)
					value = foreignEntity.getId(foreignRow);
				else value = foreignRow.id;

				if (foreignEntity?.getLabel)
					label = foreignEntity.getLabel(foreignRow);
				else label = foreignRow.name;

				if (!value && label) value = label;

				if (value && label) {
					filterOptions[field].push({
						value,
						label,
					});
				}
			});
		}
	});

	return filterOptions;
};

export const DataGrid = <TEntityRead,>(props: TDataGridProps): ReactElement => {
	const {
		heading,
		style,
		mainMenu,
		filters,
		columns,
		pagination,
		keepPreviousData,
		autoHeight,
		onRowClick,
		hideSearchAndFilter,
		hideSelectionFilters,
		...rest
	} = props;

	const [searchText, setSearchText] = useState<string>('');
	const [showFilters, setShowFilters] = useState<boolean>(false);
	const [filterModel, setFilterModel] = useState<TFilterModel>([]);

	const [defaultFilters, jsonFilters] = getSearchColumns(columns).reduce(
		(acc, { field }) => {
			acc[isJsonFilterField(field) ? 1 : 0].push(field);

			return acc;
		},
		[[], []] as [string[], string[]]
	);

	const [selectedFilters, setSelectedFilters] = useState<string[]>(
		defaultFilters.length ? defaultFilters : jsonFilters
	);

	const filterOptions = useGetFilterOptions<TEntityRead>(props);

	const DataGridParams: IInternalProps = {
		...rest,
		columns,
		searchText,
		searchFields: getSearchFields(columns),
		searchSearchColumns: getSearchColumns(columns),
		filterModel,
		pagination: pagination != null ? pagination : true,
		paginationMode: props?.pagination
			? props?.paginationMode || 'server'
			: props?.pagination === false
				? 'client'
				: props?.paginationMode || 'server',
		pageSize: props?.pageSize || 10,
		rowsPerPageOptions: props?.rowsPerPageOptions || [10, 25, 50],
		hideFooterRowCount: props?.hideFooterRowCount || false,
		keepPreviousData: keepPreviousData != null ? keepPreviousData : false,
		autoHeight: autoHeight != null ? autoHeight : true,
		disableSelectionOnClick: !onRowClick,
		hideSearchAndFilter: hideSearchAndFilter || false,
		selectedFilters,
	};

	return (
		<div style={{ ...style }}>
			<Grid
				container
				gap={{ xs: 0, sm: 1 }}
				padding={1}
				direction={{ xs: 'column', sm: 'row' }}
			>
				{heading ? (
					<Grid textAlign={{ xs: 'center', sm: 'right' }}>
						<Typography variant="h3">{heading}</Typography>
					</Grid>
				) : null}
				{DataGridParams.hideSearchAndFilter === false ? (
					<Grid
						container
						xs
						gap={2}
						alignItems="center"
						justifyContent="right"
					>
						{mainMenu ? (
							<Grid
								sx={{
									flex: 'auto',
									textAlign: { xs: 'center', sm: 'right' },
								}}
							>
								{getMainMenu(mainMenu)}
							</Grid>
						) : null}

						<Grid
							container
							sx={{
								flex: { xs: 'auto', sm: 'none' },
								width: 'auto',
								flexFlow: 'nowrap',
								justifyContent: { xs: 'end', sm: 'end' },
							}}
						>
							{DataGridParams.searchFields.length ? (
								<Grid flex="auto">
									{getSearchField(
										setSearchText,
										DataGridParams.searchSearchColumns,
										selectedFilters,
										setSelectedFilters,
										hideSelectionFilters
									)}
								</Grid>
							) : null}
							{props?.filters?.length ? (
								<Grid>
									<Tooltip arrow title="Advanced filters">
										<IconButton
											color="default"
											onClick={() => {
												setShowFilters(true);
											}}
										>
											<Badge
												badgeContent={
													filterModel.length
												}
												color="primary"
											>
												<FilterAlt />
											</Badge>
										</IconButton>
									</Tooltip>
								</Grid>
							) : null}
						</Grid>
					</Grid>
				) : null}
			</Grid>
			{DataGridParams.paginationMode === 'server' ? (
				<ServerDataGrid<TEntityRead> {...DataGridParams} />
			) : (
				<ClientDataGrid<TEntityRead> {...DataGridParams} />
			)}
			{filters?.length
				? GetFilters({
						showFilters,
						setShowFilters,
						setFilterModel,
						filters,
						filterOptions,
					})
				: null}
		</div>
	);
};

export const isNumberAndNotEmptyPreProcess = (
	params: GridPreProcessEditCellProps
) => {
	const { props } = params;

	const { value: newValue } = props;
	let hasError = true;

	if (newValue?.length > 0 || (newValue >= 0 && newValue !== null)) {
		const stringToNumber = Number(newValue);

		hasError = isNaN(stringToNumber);
	}

	return { ...props, error: hasError };
};
