// @mui
import { Grid, IconButton, List, Drawer } from '@mui/material';
// @icons
import { Close as CloseIcon } from '@mui/icons-material';
// @local
import AuthItem from './AuthItem';
import useStyles from './styles';

interface IProps {
	handleDrawerClose: () => void;
	open: boolean;
}

const AuthDrawer = ({ handleDrawerClose, open }: IProps) => {
	const classes = useStyles();

	const navList = [
		{
			id: 0,
			label: 'Home',
			link: '/',
			inApp: false,
		},
		{
			id: 1,
			label: 'How it works',
			link: '/homeowner-solutions',
			inApp: false,
		},
		{
			id: 2,
			label: 'About us',
			link: '/company',
			inApp: false,
		},
		{
			id: 3,
			label: 'Saving tips',
			link: '/learn',
			inApp: false,
		},
		{
			id: 4,
			label: 'Solar education',
			link: '/what-is-solar-pv',
			inApp: false,
		},
		{
			id: 5,
			label: 'Login',
			link: '/login',
			inApp: true,
		},
		{
			id: 6,
			label: 'Contact',
			link: '/contact-hohm',
			inApp: false,
		},
	];

	return (
		<Drawer
			variant="persistent"
			anchor="right"
			open={open}
			classes={{
				paper: classes.drawerPaper,
			}}
		>
			<Grid container>
				<Grid md={12} xs={12} item container justifyContent="flex-end">
					<div className={classes.closeContainer}>
						<IconButton
							color="inherit"
							onClick={handleDrawerClose}
							size="large"
						>
							<CloseIcon className={classes.closeIcon} />
						</IconButton>
					</div>
				</Grid>
				<Grid md={12} xs={12} item className={classes.drawerItems}>
					<List>
						{navList.map(({ id, link, inApp, label }) => (
							<AuthItem
								key={id}
								link={link}
								inApp={inApp}
								label={label}
								handleDrawerClose={handleDrawerClose}
							/>
						))}
					</List>
				</Grid>
			</Grid>
		</Drawer>
	);
};

export default AuthDrawer;
