import Link from 'next/link';
// @mui
import { Grid, styled } from '@mui/material';
// types
import { Branding } from 'hohm-types';
// @local
import { Button } from '../inputs/Button';

export type TNotFoundProps = {
	link: string;
};

const StyledGrid = styled(Grid)(() => ({
	height: '100%',
	overflow: 'auto',
	position: 'fixed',
	backgroundColor:'#000',
	backgroundImage: "linear-gradient(237deg, #082131 49.01%, rgba(20, 68, 98, 0.46) 70.4%, rgba(30, 97, 140, 0.00) 88.63%)",
	alignItems: 'center',
	justifyContent: 'center',
}));

export const NotFound = ({ link }: TNotFoundProps) => (
	<StyledGrid container>
		<Link href={link} passHref>
			<Grid container position="relative" maxWidth="600px">
				<Grid maxHeight="80%" p={2}>
					<img
						src="https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/notFoundBanner.webp"
						alt="Not Found page"
						style={{ maxWidth: '100%', maxHeight: '100%' }}
					/>
				</Grid>
				<Grid xs={12} textAlign={{ md: 'right', xs: 'center' }}>
					<Button variant="contained">
						Let’s go back {Branding.brandName}
					</Button>
				</Grid>
			</Grid>
		</Link>
	</StyledGrid>
);
