"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.kitPanelSchema = exports.kitPanelQuerySchema = exports.kitPanelUpdateSchema = exports.kitPanelWriteSchema = exports.kitPanelReadSchema = exports.kitPanelBaseSchema = void 0;
var typebox_1 = require("@feathersjs/typebox");
var panel_types_1 = require("./panel.types");
exports.kitPanelBaseSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    panelId: typebox_1.Type.String(),
    productKitId: typebox_1.Type.String(),
    orderNo: typebox_1.Type.Number(),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.kitPanelReadSchema = typebox_1.Type.Omit(exports.kitPanelBaseSchema, []);
exports.kitPanelWriteSchema = typebox_1.Type.Omit(exports.kitPanelReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.kitPanelUpdateSchema = typebox_1.Type.Partial(exports.kitPanelBaseSchema, {
    $id: 'KitPanelUpdate',
});
var kitPanelQueryProperties = typebox_1.Type.Partial(exports.kitPanelBaseSchema, {
    $id: 'KitPanelQuery',
});
exports.kitPanelQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(kitPanelQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
exports.kitPanelSchema = typebox_1.Type.Object({
    orderNo: typebox_1.Type.Number(),
    panel: panel_types_1.panelSchema,
});
