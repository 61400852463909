import React from 'react';
// @mui
import { IconButton } from '@mui/material';
// i8n
import { t } from 'hohm-i18n';
// @icons
import { Menu as MenuIcon } from '@mui/icons-material';
// @local
import AuthDrawer from './AuthDrawer';
import useStyles from './styles';

const AuthLayout = ({ children }: { children: React.ReactNode }) => {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);

	const showOptionsHarmburger = t('pages.signup.showOptionsHarmburger');

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	return (
		<div>
			{showOptionsHarmburger && (
				<IconButton
					color="inherit"
					onClick={handleDrawerOpen}
					className={classes.closeIconContainer}
					size="large"
					id="tester"
				>
					<MenuIcon fontSize="large" className={classes.baseIcon} />
				</IconButton>
			)}

			<AuthDrawer open={open} handleDrawerClose={handleDrawerClose} />

			{children}
		</div>
	);
};

export default AuthLayout;
