// @mui
import { Stack, Typography } from '@mui/material';

const GlintBigLogo =
	'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/glint-user-journey/Glint-Big-logo.png';

const ItemWithBullets = ({
	heading,
	content,
	notBulleted,
}: {
	heading: string;
	content: {
		title: string;
		description: {
			title: string;
			descriptionItems?: string[];
		}[];
	}[];
	notBulleted?: boolean;
}) => {
	const styles = notBulleted
		? {
				py: 0.5,
		  }
		: {
				display: 'list-item',
				py: 0,
				ml: 5,
		  };

	return (
		<Stack>
			<Typography
				color="secondary.main"
				variant="body1"
				fontWeight="bold"
				sx={{
					pb: 1,
				}}
			>
				{heading}
			</Typography>
			{content.map((item) => (
				<Stack key={item.title}>
					<Typography component="p" color="secondary.main">
						{item.title}
					</Typography>
					{item.description.map((desc) => (
						<Stack key={desc.title}>
							<Typography color="secondary.main" sx={styles}>
								{desc.title}
							</Typography>
							{desc.descriptionItems &&
								desc.descriptionItems.map((descItem) => (
									<Typography
										key={descItem}
										color="secondary.main"
										sx={{
											py: 0,
											ml: 10,
										}}
									>
										- {descItem}
									</Typography>
								))}
						</Stack>
					))}
				</Stack>
			))}
		</Stack>
	);
};

export type TGlintPrivacyPolicyProps = never;
export const GlintPrivacyPolicy = () => {
	return (
		<Stack overflow="scroll">
			<Stack
				direction="column"
				spacing={2}
				justifyContent="space-between"
				sx={{
					height: '100%',
					paddingTop: 2,
				}}
				mb={7}
			>
				<Stack
					spacing={2}
					direction="column"
					px={4}
					justifyContent="center"
					alignContent="center"
					alignItems="center"
				>
					<Typography
						variant="h6"
						textAlign="left"
						my={2}
						color="secondary.main"
					>
						Glint Finance / Glint Origination Services (Pty) Ltd:
						Privacy Policy{' '}
					</Typography>
					<Stack>
						<img
							src={GlintBigLogo}
							alt="glint logo"
							style={{
								maxWidth: '200px',
							}}
						/>
					</Stack>
					<Typography
						variant="body1"
						textAlign="left"
						my={2}
						color="secondary.main"
					>
						August 2023 | Version 1.0
					</Typography>
					<Typography color="secondary.main" variant="body1">
						We respect your privacy. Callouts like this are a
						summary of our privacy policy and contain the most
						important and relevant points for you. Please read the
						full privacy policy because it applies to you.
					</Typography>
				</Stack>
				<Stack
					direction="column"
					spacing={2}
					justifyContent="space-between"
					mb={7}
					px={4}
					py={2}
				>
					<Stack spacing={4} direction="column">
						<Stack direction="column" spacing={2}>
							<Stack spacing={1}>
								<Typography
									color="secondary.main"
									variant="body1"
									fontWeight="bold"
								>
									Introduction
								</Typography>
								<Typography
									color="secondary.main"
									variant="body1"
								>
									Welcome to our Privacy Policy and this is
									our plan of action when it comes to
									protecting your privacy. We respect your
									privacy and take the protection of personal
									information very seriously. The purpose of
									this policy is to describe the way that we
									collect, store, use, and protect information
									that can be associated with you or another
									specific natural or juristic person and can
									be used to identify you or that person
									(personal information).
								</Typography>{' '}
								<Typography
									color="secondary.main"
									variant="body1"
								>
									The purpose of this policy is to describe
									the way that we handle your personal
									information.
								</Typography>
							</Stack>
							<ItemWithBullets
								heading="Audience"
								content={[
									{
										title: 'This policy applies to you if you are:',
										description: [
											{
												title: 'a visitor to our website; or',
											},
											{
												title: '	a customer who has ordered or requested the goods or services that we provide.',
											},
										],
									},
								]}
							/>
							<ItemWithBullets
								heading="What is personal information"
								content={[
									{
										title: 'Personal information includes:',
										description: [
											{
												title: '	certain information that we collect automatically when you visit our website;',
											},
											{
												title: ' 	certain information collected on registration (see below);',
											},
											{
												title: 'geolocation information (see below);',
											},

											{
												title: 'certain information collected on submission; and',
											},
											{
												title: 'optional information that you provide to us voluntarily (see below);',
											},
										],
									},
									{
										title: 'but excludes:',
										description: [
											{
												title: 'information that has been made anonymous so that it does not identify a specific person;',
											},
											{
												title: 'permanently de-identified information that does not relate or cannot be traced back to you specifically;',
											},
											{
												title: 'non-personal statistical information collected and compiled by us.',
											},
										],
									},
								]}
							/>
							<Typography color="secondary.main" variant="body1">
								Personal information includes information we
								collect (i) automatically when you visit our
								website, (ii) on registration, (iii) on
								submission, and (iv) from you voluntarily. It
								excludes (i) anonymous, (ii) de-identified,
								(iii) non-personal statistical, and (iv) public
								information.
							</Typography>
							<ItemWithBullets
								heading="Common examples"
								content={[
									{
										title: 'Common examples of the types of personal information which we may collect and process include your:',
										description: [
											{
												title: 'identifying information – such as your name, date of birth, or identification number of any kind;',
											},
											{
												title: 'contact information – such as your phone number or email address;',
											},
											{
												title: 'address information – such as your physical or postal address; or',
											},
											{
												title: 'demographic information – such as your gender or marital status.',
											},
										],
									},
								]}
							/>
							<ItemWithBullets
								heading="Sensitive personal information"
								content={[
									{
										title: 'Depending on the goods or services that you require, we may also collect sensitive personal information including your:',
										description: [
											{
												title: 'financial information – such as your bank account details;',
											},
											{
												title: 'sensitive demographic information – such as your race or ethnicity;',
											},
											{
												title: 'medical information – such as information about your physical or mental health;',
											},
											{
												title: 'sexual information – such as information about your sex life or sexual orientation;',
											},
											{
												title: 'criminal information – such as information about your commission or alleged commission of any offence or about any related legal proceedings;',
											},
											{
												title: 'employment information – including your membership of a trade union;',
											},
											{
												title: 'beliefs – including your political or religious beliefs.',
											},
										],
									},
								]}
							/>
							<ItemWithBullets
								heading="Acceptance"
								content={[
									{
										title: 'Acceptance required',
										description: [
											{
												title: 'You must accept all the terms of this policy when you order our goods or request our services. If you do not agree with anything in this policy, then you may not order our goods or request our services.',
											},
										],
									},
								]}
							/>
							<Typography color="secondary.main" variant="body1">
								You may not order our goods or request our
								services if you do not accept this policy.
							</Typography>
							<ItemWithBullets
								heading="Legal capacity"
								content={[
									{
										title: 'You may not access our website or order our goods or request our services if you are younger than 18 years old or do not have legal capacity to conclude legally binding contracts.',
										description: [],
									},
								]}
							/>
							<ItemWithBullets
								heading="Deemed acceptance"
								content={[
									{
										title: 'By accepting this policy, you are deemed to have read, understood, accepted, and agreed to be bound by all of its terms.',
										description: [],
									},
								]}
							/>
							<ItemWithBullets
								heading="Your obligations"
								content={[
									{
										title: 'You may only send us your own personal information or the information of another data subject where you have their permission to do so.',
										description: [],
									},
								]}
							/>
							<ItemWithBullets
								heading="How do we collect personal information?"
								content={[
									{
										title: 'On submission of an enquiry or registration',
										description: [
											{
												title: 'When you submit an enquiry or if you register on our website, you will no longer be anonymous to us.',
											},
											{
												title: 'This personal information may include:',
											},
											{ title: 'your name and surname;' },
											{ title: 'your email address;' },
											{ title: 'your telephone number;' },
											{
												title: 'your company name, company registration number, and VAT number;',
											},
											{
												title: 'your postal address or street address; and',
											},
											{
												title: 'your username and password.',
											},
										],
									},
									{
										title: '',
										description: [
											{
												title: 'We will use this personal information to fulfil your account, provide additional services and information to you as we reasonably think appropriate, and for any other purposes set out in this policy.',
											},
										],
									},
								]}
							/>
							<Typography color="secondary.main" variant="body1">
								We collect certain information when you enquire
								or register on our website.
							</Typography>
							<ItemWithBullets
								heading="On order or request"
								content={[
									{
										title: 'When you order our goods or request our services from us, you will be asked to provide us with additional information on a voluntary basis (goods or services information).',
										description: [],
									},
								]}
							/>
							<ItemWithBullets
								heading="Events"
								content={[
									{
										title: 'You will provide us with personal information when you attend an event that we host.',
										description: [],
									},
								]}
							/>
							<ItemWithBullets
								heading="From browser"
								content={[
									{
										title: 'We automatically receive and record Internet usage information on our server logs from your browser, such as your Internet Protocol address (IP address), browsing habits, click patterns, version of software installed, system type, screen resolutions, colour capabilities, plug-ins, language settings, cookie preferences, search engine keywords, JavaScript enablement, the content and pages that you access on the website, and the dates and times that you visit the website, paths taken, and time spent on sites and pages within the website (usage information). Please note that other websites visited before entering our website might place personal information within your URL during a visit to it, and we have no control over such websites. Accordingly, a subsequent website that collects URL information may log some personal information',
										description: [],
									},
								]}
							/>
							<Typography color="secondary.main" variant="body1">
								We collect certain information from your web
								browser, including your Internet usage
								information when you visit our website.
							</Typography>
							<ItemWithBullets
								heading="Cookies"
								content={[
									{
										title: 'For information on how we deal with cookies, please refer to our Cookies Policy.',
										description: [],
									},
								]}
							/>
							<ItemWithBullets
								heading="Cookies"
								content={[
									{
										title: 'Our website may contain electronic image requests (called a single-pixel gif or web beacon request) that allow us to count page views and to access cookies. Any electronic image viewed as part of a web page (including an ad banner) can act as a web beacon. Our web beacons do not collect, gather, monitor or share any of your personal information. We merely use them to compile anonymous information about our website.',
										description: [],
									},
								]}
							/>
							<Typography color="secondary.main" variant="body1">
								We collect certain information from web beacons
								on our website to compile anonymous information
								about our website.
							</Typography>
							<ItemWithBullets
								heading="Optional details"
								content={[
									{
										title: 'You may also provide additional information to us on a voluntary basis (optional information). This includes content or products that you decide to upload or download from our website or when you enter competitions, take advantage of promotions, respond to surveys, order certain additional goods or services, or otherwise use the optional features and functionality of our website.',
										description: [],
									},
								]}
							/>
							<ItemWithBullets
								heading="Recording calls"
								content={[
									{
										title: 'We may monitor and record any telephone calls that you make to us, unless you specifically request us not to.',
										description: [],
									},
								]}
							/>
							<ItemWithBullets
								heading="Who are our data subjects?"
								content={[
									{
										title: 'We process the personal information of the following categories of people:',
										description: [
											{
												title: 'customers or organisations,',
											},
											{ title: 'prospects or leads,' },
											{ title: 'employees,' },
											{
												title: 'recruiters and medical practitioners providing services related to employees,',
											},
											{
												title: 'contractors, vendors, or suppliers,',
											},
											{
												title: 'children and their guardians,',
											},
											{ title: 'debtors and creditors,' },
											{ title: 'dealers, and' },
											{
												title: 'directors and shareholders.',
											},
										],
									},
								]}
							/>
							<ItemWithBullets
								heading="Purpose for processing"
								content={[
									{
										title: 'We may use or process any goods or services information, or optional information that you provide to us for the purposes that you indicated when you agreed to provide it to us. Processing includes gathering your personal information, disclosing it, and combining it with other personal information. We generally collect and process your personal information for various purposes, including:',
										description: [
											{
												title: 'goods or services purposes – such as:',
												descriptionItems: [
													'collecting orders or requests for and providing our goods or services',
													'managing our contracts with various data subjects',
													'managing customer credit in general',
													'processing customer requests or complaints',
													'keeping our data subject records and information up to date',
													'better understanding our data subject’s needs',
													'providing support to our customers',
												],
											},
											{
												title: 'marketing purposes – such as:',
												descriptionItems: [
													'marketing to customers',
													'marketing to prospects',
													'running promotional competitions for business',
												],
											},
											{
												title: 'business purposes – such as:',
												descriptionItems: [
													'managing employees in general',
													'internal audit',
													'accounting',
													'business planning and due diligence, and joint ventures, disposals of business, or other proposed and actual transactions',
												],
											},
											{
												title: 'legal purposes – such as:',
												descriptionItems: [
													'handling claims and enforcing debts, and',
													'complying with regulations, or pursuing good governance.',
												],
											},
										],
									},
									{
										title: 'We may use your usage information for the purposes described above and to:',
										description: [
											{
												title: 'remember your information so that you will not have to re-enter it during your visit or the next time you access the website;',
											},
											{
												title: 'monitor website usage metrics such as total number of visitors and pages accessed; and',
											},
											{
												title: 'track your entries, submissions, and status in any promotions or other activities in connection with your usage of the website.',
											},
										],
									},
								]}
							/>
							<Typography color="secondary.main" variant="body1">
								We may use any of your personal information that
								you provide to us for the purposes that you
								indicated when you agreed to provide it to us.
							</Typography>
							<ItemWithBullets
								heading="Consent to collection"
								content={[
									{
										title: 'We will obtain your consent to collect personal information:',
										description: [
											{
												title: 'in accordance with applicable law;',
											},
											{
												title: 'when you provide us with any registration information or optional information.',
											},
										],
									},
								]}
							/>
							<ItemWithBullets
								heading="Our obligations"
								content={[
									{
										title: 'We may use your personal information to fulfil our obligations to you.',
										description: [],
									},
								]}
							/>
							<ItemWithBullets
								heading="Messages and updates"
								content={[
									{
										title: 'We may use your personal information to fulfil our obligations to you.',
										description: [
											{
												title: 'We may send administrative messages and email updates to you about the website. In some cases, we may also send you primarily promotional messages. You can choose to opt-out of promotional messages.',
											},
										],
									},
								]}
							/>
							<Typography color="secondary.main" variant="body1">
								We may use your information to send you
								administrative messages and email updates to you
								regarding the website and for marketing purposes
								where lawful.
							</Typography>
							<ItemWithBullets
								heading="Targeted content"
								content={[
									{
										title: 'While you are logged into the website, we may display targeted adverts and other relevant information based on your personal information. In a completely automated process, computers process the personal information and match it to adverts or related information. We never share personal information with any advertiser, unless you specifically provide us with your consent to do so. Advertisers receive a record of the total number of impressions and clicks for each advert. They do not receive any personal information. If you click on an advert, we may send a referring URL to the advertiser’s website identifying that a customer is visiting from the website. We do not send personal information to advertisers with the referring URL. Once you are on the advertiser’s website however, the advertiser is able to collect your personal information.',
										description: [],
									},
								]}
							/>
							<ItemWithBullets
								heading="Reasons we share personal information"
								content={[
									{
										title: 'We may share your personal information with:',
										description: [
											{
												title: 'other divisions or companies within the group of companies to which we belong so as to provide joint content and services like registration, for transactions and customer support, to help detect and prevent potentially illegal acts and violations of our policies, and to guide decisions about our products, services, and communications (they will only use this information to send you marketing communications if you have requested their goods or services);',
											},
											{
												title: 'an affiliate, in which case we will seek to require the affiliates to honour this privacy policy;',
											},
											{
												title: 'our goods or services providers under contract who help provide certain goods or services or help with parts of our business operations, including fraud prevention, bill collection, marketing, technology services (our contracts dictate that these goods or services providers only use your information in connection with the goods or services they supply or services they perform for us and not for their own benefit);',
											},
											{
												title: 'credit bureaus to report account information, as permitted by law; and',
											},
											{
												title: 'other third parties who provide us with relevant services where appropriate.',
											},
										],
									},
								]}
							/>
							<ItemWithBullets
								heading="Targeted content"
								content={[
									{
										title: 'While you are logged into the website, we may display targeted adverts and other relevant information based on your personal information. In a completely automated process, computers process the personal information and match it to adverts or related information. We never share personal information with any advertiser, unless you specifically provide us with your consent to do so. Advertisers receive a record of the total number of impressions and clicks for each advert. They do not receive any personal information. If you click on an advert, we may send a referring URL to the advertiser’s website identifying that a customer is visiting from the website. We do not send personal information to advertisers with the referring URL. Once you are on the advertiser’s website however, the advertiser is able to collect your personal information.',
										description: [],
									},
								]}
							/>
							<Typography color="secondary.main" variant="body1">
								We may share your personal information with
								third parties for the purposes of fulfilling our
								obligations to you among other purposes.
							</Typography>
							<ItemWithBullets
								heading="Regulators"
								content={[
									{
										title: 'We may disclose your personal information as required by law or governmental audit.',
										description: [],
									},
								]}
							/>
							<ItemWithBullets
								heading="Law enforcement"
								content={[
									{
										title: 'We may disclose personal information if required:',
										description: [
											{
												title: 'by a subpoena or court order',
											},
											{
												title: 'to comply with any law;',
											},
											{
												title: 'to protect the safety of any individual or the general public; and',
											},
											{
												title: 'to prevent violation of our customer relationship terms.',
											},
										],
									},
								]}
							/>
							<Typography color="secondary.main" variant="body1">
								We may disclose personal information to third
								parties if required for legal reasons.
							</Typography>
							<ItemWithBullets
								heading="No selling"
								content={[
									{
										title: 'We will not sell personal information. No personal information will be disclosed to anyone except as provided in this privacy policy.',
										description: [],
									},
								]}
							/>
							<ItemWithBullets
								heading="Marketing purposes"
								content={[
									{
										title: 'We may disclose aggregate statistics (information about the customer population in general terms) about the personal information to advertisers or business partners.',
										description: [],
									},
								]}
							/>
							<ItemWithBullets
								heading="Employees"
								content={[
									{
										title: 'We may need to disclose personal information to our employees that require the personal information to do their jobs. These include our responsible management, human resources, accounting, audit, compliance, information technology, or other personnel.',
										description: [],
									},
								]}
							/>
							<ItemWithBullets
								heading="Change of ownership"
								content={[
									{
										title: 'If we undergo a change in ownership, or a merger with, acquisition by, or sale of assets to, another entity, we may assign our rights to the personal information we process to a successor, purchaser, or separate entity. We will disclose the transfer on the website. If you are concerned about your personal information migrating to a new owner, you may request us to delete your personal information.',
										description: [],
									},
								]}
							/>
							<ItemWithBullets
								heading="Security"
								content={[
									{
										title: `There is no such thing as 'perfect security'. We have to compromise between increased levels of security and the convenience to you in transacting with us.`,
										description: [],
									},
								]}
							/>
							<ItemWithBullets
								heading="Our security responsibilities"
								notBulleted
								content={[
									{
										title: '',
										description: [
											{
												title: 'We take the security of personal information very seriously and always do our best to comply with applicable data protection laws by maintaining reasonable measures to protect personal information from loss, misuse, and unauthorised access, disclosure, alteration, and destruction.',
											},
											{
												title: 'Our hosting company will host our website in a secure server environment that uses a firewall and other advanced security measures to prevent interference or access from outside intruders. We authorise access to personal information only for those employees who require it to fulfil their job responsibilities. We implement disaster recover procedures where appropriate.',
											},
										],
									},
								]}
							/>
							<Typography
								color="secondary.main"
								variant="body1"
								fontWeight="bold"
								sx={{
									p: 0,
								}}
							>
								Our security disclaimers
							</Typography>
							<ItemWithBullets
								notBulleted
								heading="Please note the following:"
								content={[
									{
										title: '',
										description: [
											{
												title: 'The third parties whose systems we link to are responsible for the security of information while it is collected by, stored on, or passing through the systems under their control.',
											},
											{
												title: 'We will use all reasonable endeavours to ensure that our website and your information is not compromised. However, we cannot guarantee that no harmful code will enter our website (for example viruses, bugs, trojan horses, spyware or adware). You should be aware of the risks associated with using websites (addressed below).',
											},
											{
												title: 'If you experience a problem or loss that is caused by information you provided to us, your computer being compromised in some way or by something beyond our control, we cannot take responsibility for causing the problem. We will, however, do our best to help you if we can.',
											},
										],
									},
								]}
							/>
							<ItemWithBullets
								heading="Phishing"
								content={[
									{
										title: 'You must only log in to your account from a page that begins with https://www.{insertweb_address}',
										description: [],
									},
								]}
							/>
							<Typography color="secondary.main" variant="body1">
								Our website is hosted on a secure server and
								uses security measures to prevent interference
								by intruders.
							</Typography>
							<ItemWithBullets
								heading="Accurate and current"
								content={[
									{
										title: 'We will try to keep the personal information we collect as accurate, current, complete, confidential and reliable for the purposes defined in this policy. From time to time we may request you to update your personal information on the website. You are able to review or update any personal information that we hold on you by accessing your account online, emailing us, or phoning us. Please note that in order to better protect you and safeguard your personal information, we take steps to verify your identity before granting you access to your account or making any corrections to your personal information.',
										description: [],
									},
								]}
							/>
							<Typography color="secondary.main" variant="body1">
								Please keep your personal information accurate
								and up to date by accessing your account online,
								emailing us, or by phoning us.
							</Typography>
							<ItemWithBullets
								heading="Retention"
								content={[
									{
										title: 'We will only retain your personal information for as long as it is necessary to fulfil the purposes explicitly set out in this policy, unless:',
										description: [
											{
												title: 'retention of the record is required or authorised by law; or',
											},
											{
												title: 'you have consented to the retention of the record.',
											},
										],
									},
									{
										title: 'During the period of retention, we will continue to abide by our non-disclosure obligations and will not share or sell your personal information. We may retain your personal information in physical or electronic records at our discretion.',
										description: [],
									},
								]}
							/>
							<Typography color="secondary.main" variant="body1">
								We will only retain your personal information
								for as long as is necessary.
							</Typography>
							<ItemWithBullets
								heading="Transfer to another country"
								content={[
									{
										title: 'We send personal information outside of South Africa to various countries. We will only transfer data to other countries who have similar privacy laws to South Africa’s that provide an adequate level of protection, or to recipients who can guarantee the protection of personal information to the same standard we must protect it.',
										description: [],
									},
								]}
							/>
							<Stack spacing={1}>
								<Typography
									color="secondary.main"
									variant="body1"
									fontWeight="bold"
									sx={{
										pb: 1,
									}}
								>
									Your rights
								</Typography>
								<Stack direction="column">
									<Typography
										component="p"
										color="secondary.main"
										fontWeight="bold"
									>
										Request what information we hold on you
									</Typography>
									<Typography color="secondary.main">
										You may request access to your personal
										information to receive a copy of the
										personal information that we hold on
										you.
									</Typography>
								</Stack>
								<Stack direction="column">
									<Typography
										component="p"
										color="secondary.main"
										fontWeight="bold"
									>
										Updating or removing
									</Typography>
									<Typography color="secondary.main">
										You may choose to correct or update the
										personal information you have submitted
										to us, by clicking the relevant menu in
										any of the pages on our website or
										contacting us by phone or email.
									</Typography>
								</Stack>
								<Stack direction="column">
									<Typography
										component="p"
										color="secondary.main"
										fontWeight="bold"
									>
										Withdrawal of consent or objection to
										processing
									</Typography>
									<Typography color="secondary.main">
										You may withdraw your consent where we
										are relying on consent as a lawful
										justification to process. You may also
										object to our processing where we are
										relying on another lawful justification
										for processing. Please note that if you
										do so, we might not be able to provide
										services to you.
									</Typography>
									<Typography color="secondary.main" pt={1}>
										We may need to request additional
										information from you to verify your
										identity for you to access these rights.
										This is to ensure that your personal
										information is not disclosed to an
										unauthorized person.
									</Typography>
								</Stack>
							</Stack>
							<Typography color="secondary.main" variant="body1">
								You may choose to update or remove the personal
								information you have submitted to us.
							</Typography>
							<ItemWithBullets
								heading="Changes"
								content={[
									{
										title: 'We may change the terms of this policy at any time by updating this web page. We will notify you of any changes by placing a notice in a prominent place on the website or by sending you an email detailing the changes that we have made and indicating the date that they were last updated. If you do not agree with the changes, then you must stop using the website and our goods or services. If you continue to use the website or our goods or services following notification of a change to the terms, the changed terms will apply to you, and you will be deemed to have accepted those updated terms.',
										description: [],
									},
								]}
							/>
							<ItemWithBullets
								heading="Limitation
								"
								content={[
									{
										title: 'We are not responsible for, give no warranties, nor make any representations in respect of the privacy policies or practices of linked or any third-party websites.',
										description: [],
									},
								]}
							/>
							<ItemWithBullets
								heading="Enquiries
								"
								content={[
									{
										title: 'If you have any questions or concerns arising from this privacy policy or the way in which we handle personal information, please contact us.',
										description: [],
									},
								]}
							/>
						</Stack>
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	);
};
