"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const themeOptions = {
    palette: {
        primary: {
            lighter: '#006241',
            light: '#006241',
            dark: '#006241',
            darker: '#006241',
            main: '#006241',
            contrastText: '#FFFFFF',
        },
        secondary: {
            lighter: '#39833E',
            light: '#39833E',
            dark: '#39833E',
            darker: '#39833E',
            main: '#39833E',
            contrastText: '#FFFFFF',
        },
    },
    typography: {
        fontFamily: '"Libre Franklin", Arial, Helvetica, sans-serif',
    },
    shape: {
        borderRadius: 6,
    },
};
exports.default = themeOptions;
