// @mui
import {
	Tooltip,
	IconButton,
	TooltipProps,
	SvgIconTypeMap,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
// @icons
import { Help } from '@mui/icons-material';

export type TIconTooltipProps = Omit<TooltipProps, 'children'> & {
	Icon?: OverridableComponent<SvgIconTypeMap<object, 'svg'>> & {
		muiName: string;
	};
};

export const IconTooltip = ({ Icon = Help, ...rest }: TIconTooltipProps) => (
	<Tooltip {...rest}>
		<IconButton>
			<Icon />
		</IconButton>
	</Tooltip>
);
