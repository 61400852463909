"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const themeOptions = {
    palette: {
        primary: {
            lighter: '#ffbe00',
            light: '#ffbe00',
            main: '#ffbe00',
            dark: '#ffbe00',
            darker: '#ffbe00',
            contrastText: '#FFFFFF',
        },
        secondary: {
            lighter: '#282828',
            light: '#282828',
            dark: '#282828',
            darker: '#282828',
            main: '#282828',
            contrastText: '#FFFFFF',
        },
    },
};
exports.default = themeOptions;
