// @form
import { Controller } from 'react-hook-form';
// @local
import { DatePicker } from '../DatePicker';
import { Input } from '../Input';
import { TDatePickerControllerProps } from '../utils/types';

export type { TDatePickerControllerProps };
export const DatePickerController = <T,>({
	name,
	control,
	...rest
}: TDatePickerControllerProps<T>) => (
	<Controller
		{...{ name, control }}
		render={({ field, formState: { errors } }) => (
			<DatePicker
				{...field}
				{...rest}
				renderInput={(params) => (
					<Input
						{...params}
						// eslint-disable-next-line react/jsx-no-useless-fragment
						helperText={<>{errors[name]?.message}</>}
					/>
				)}
			/>
		)}
	/>
);
