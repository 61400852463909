"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.projectCreditItemQuerySchema = exports.projectCreditItemUpdateSchema = exports.projectCreditItemWriteSchema = exports.projectCreditItemReadSchema = exports.projectCreditItemSchema = void 0;
var typebox_1 = require("@feathersjs/typebox");
exports.projectCreditItemSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    name: typebox_1.Type.String(),
    price: typebox_1.Type.Number(),
    engineeredProposalId: typebox_1.Type.String(),
    organisationId: typebox_1.Type.Optional(typebox_1.Type.String()),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.projectCreditItemReadSchema = typebox_1.Type.Omit(exports.projectCreditItemSchema, []);
exports.projectCreditItemWriteSchema = typebox_1.Type.Omit(exports.projectCreditItemReadSchema, ['id', 'createdAt', 'updatedAt']);
exports.projectCreditItemUpdateSchema = typebox_1.Type.Partial(exports.projectCreditItemSchema, {
    $id: 'ProjectCreditItemUpdate',
});
var projectCreditItemQueryProperties = typebox_1.Type.Partial(exports.projectCreditItemSchema, {
    $id: 'ProjectCreditItemQuery',
});
exports.projectCreditItemQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(projectCreditItemQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
