// @mui
import { IconButton, IconButtonProps, styled } from '@mui/material';
// @icons
import { Close } from '@mui/icons-material';

const StyledIconButton = styled(IconButton)(() => ({
	top: '10px',
	right: '10px',
	position: 'absolute',
}));

export const DialogCloseButton = ({
	color,
	...otherProps
}: IconButtonProps) => (
	<StyledIconButton
		size="large"
		aria-label="close"
		{...otherProps}
		color={color}
	>
		<Close htmlColor={color} />
	</StyledIconButton>
);
