"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const themeOptions = {
    palette: {
        primary: {
            lighter: '#8bc6ae',
            light: '#4b9e7b',
            dark: '#307150',
            darker: '#1d452d',
            main: '#3c8d69',
        },
        secondary: {
            lighter: '#f5f5f5',
            light: '#e0e0e0',
            dark: '#616161',
            darker: '#212121',
            main: '#9e9e9e',
        },
    },
    typography: {
        fontFamily: '"Libre Franklin", Arial, Helvetica, sans-serif',
    },
    shape: {
        borderRadius: 0,
    },
};
exports.default = themeOptions;
