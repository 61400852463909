import styled from '@emotion/styled';
// @mui
import {
	Stack,
	Grid,
	SxProps,
	Tooltip,
	Typography,
	TooltipProps,
	SvgIconTypeMap,
} from '@mui/material';
// @icons
import { OverridableComponent } from '@mui/material/OverridableComponent';

export interface IDefaultTooltipProps {
	arrow?: boolean;
	placement?: TooltipProps['placement'];
}

export interface IUserGuideItem {
	tooltip: {
		title: string;
	} & IDefaultTooltipProps;
	text: string;
	textSx?: SxProps;
	icons: {
		// eslint-disable-next-line @typescript-eslint/ban-types
		Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
			muiName: string;
		};
		sx?: SxProps;
	}[];
}

export type TIconListProps = { iconList: IUserGuideItem[] };

export const IconList = ({ iconList }: TIconListProps) => {
	const StyledStack = styled(Stack)(() => ({
		gap: '8px',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
	}));

	const StyledGrid = styled(Grid)(() => ({
		display: 'flex',
		alignItems: 'flex-start',
		flexDirection: 'column',
	}));

	const defaultColor = {
		color: 'info.main',
	};

	const defaultTooltipProps: IDefaultTooltipProps = {
		arrow: true,
		placement: 'left',
	};

	const getTooltipProp = ({ ...tooltipProps }: IUserGuideItem['tooltip']) =>
		({
			...defaultTooltipProps,
			...tooltipProps,
		}) as IUserGuideItem['tooltip'];

	const getIconStyle = ({ sx }: { sx?: SxProps }) =>
		sx
			? {
					...defaultColor,
					...sx,
			  }
			: defaultColor;

	const getIconList = ({ items }: { items: IUserGuideItem[] }) =>
		items.map(({ text, icons, textSx, tooltip }, itemIndex) => (
			<StyledStack key={itemIndex}>
				<Tooltip {...getTooltipProp(tooltip)}>
					<StyledGrid>
						{icons.map(({ sx, Icon }, iconIndex) => (
							<Icon sx={getIconStyle({ sx })} key={iconIndex} />
						))}
					</StyledGrid>
				</Tooltip>
				<Typography variant="body2" sx={textSx}>
					{text}
				</Typography>
			</StyledStack>
		));

	return getIconList({ items: iconList });
};
