"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.financeRequestsWriteSchema = exports.financeRequestReadSchema = void 0;
var typebox_1 = require("@feathersjs/typebox");
exports.financeRequestReadSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    financeApproved: typebox_1.Type.Optional(typebox_1.Type.Boolean()),
    // Relationship
    creditProfileId: typebox_1.Type.String(),
    // Ad-hoc data
    createdAt: typebox_1.Type.String({ format: 'date-time' }),
    updatedAt: typebox_1.Type.String({ format: 'date-time' }),
}, { $id: 'FinanceRequests', additionalProperties: false });
exports.financeRequestsWriteSchema = typebox_1.Type.Omit(exports.financeRequestReadSchema, ['id', 'createdAt', 'updatedAt'], {
    $id: 'FinanceRequestsData',
});
