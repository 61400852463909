"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var makro = {
    theme: 'makro',
    brand: {
        name: 'Makro',
        legalName: 'Makro',
        logo: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/makro/Makro-Logo.png',
        icon: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/makro/Makro-Logo.png',
    },
    financePartners: [
        {
            name: 'Makro',
            logo: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/makro/Makro-Logo.png',
        },
    ],
    campaignPartners: [
        {
            name: 'FNB',
            logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/finance-partners/logo-1-removebg-preview.png',
        },
        {
            name: 'Nedbank',
            logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/Nedbank.png',
        },
        {
            name: 'Ooba Homeloans',
            logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/finance-partners/ooba.png',
        },
    ],
    partner: {
        name: 'Hohm Energy',
        legalName: 'Hohm Services (Pty) Ltd.',
        logo: 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/general/HohmEnergyLogoOrange.png',
    },
    poweredBy: {
        name: 'Hohm Energy',
        legalName: 'Hohm Services (Pty) Ltd.',
        logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/general/HohmEnergyLogoOrange.png',
    },
    pages: {
        signup: {
            heading: 'We make solar simple',
            content: "Makro and Hohm Energy have partnered to offer an industry-leading client solution, giving you an indicative proposal in minutes for a range of suitable solar options that meet your needs.<br /><br />Sign up to Hohm Energy and get access to the following benefits:",
            offerings: [
                {
                    id: 0,
                    heading: 'Say goodbye to loadshedding',
                    content: "Hohm Energy will give you and your family peace of mind that you don't have to structure your life around the inconvenience of load shedding.",
                },
                {
                    id: 1,
                    heading: 'Save money',
                    content: 'Solar generates power from the sun, saving you and your family money on your rising electricity bill.',
                },
                {
                    id: 2,
                    heading: 'Affordable finance with no upfront cost',
                    content: 'Solar finance has never been easier. Apply for the most affordable solar loan.',
                },
                {
                    id: 3,
                    heading: 'Get the best price',
                    content: 'Hohm Energy has negotiated the best prices from product suppliers and solar installers to ensure best value for you.',
                },
                {
                    id: 4,
                    heading: 'Your own Energy Advisor',
                    content: 'Hohm Energy is with you every step of the way to answer any questions, manage the process and ensure your solar journey is as seamless as possible.',
                },
            ],
            sections: {
                titleSection: {
                    title: "From start to solar, we've got your back.",
                },
            },
        },
    },
    modules: {
        draftProposal: {
            enableAdditionalFields: true,
        },
    },
    financeOptions: [
        {
            label: 'Cash',
            value: 'cash',
        },
        {
            label: 'Solar Finance',
            value: 'nedbank-mfc',
            default: true,
        },
        {
            label: 'Home Loan',
            value: 'home-loan',
        },
    ],
};
exports.default = makro;
