"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStatusInfo = void 0;
var getStatusInfo = function (status) {
    var statusInfo = {
        label: 'System draft',
        color: 'secondary',
    };
    switch (status) {
        case 'draft':
            statusInfo = {
                label: 'Draft',
                color: 'warning',
            };
            break;
        case 'sold':
            statusInfo = {
                label: 'Sold',
                color: 'success',
            };
            break;
        case 'qa-approved':
            statusInfo = {
                label: 'QA Approved',
                color: 'info',
            };
            break;
        case 'final':
            statusInfo = {
                label: 'Final',
                color: 'primary',
            };
            break;
        default:
            break;
    }
    return statusInfo;
};
exports.getStatusInfo = getStatusInfo;
