import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Images } from 'hohm-types';

export default makeStyles((theme: Theme) =>
	createStyles({
		background: {
			minHeight: '100vh',
			overflow: 'hidden',
		},
		gridImg: {
			backgroundImage: `url(${Images.authBackground})`,
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			opacity: 1,
		},
		overlay: {
			background: 'rgba(14, 36, 74, 0.85)',
			width: '100%',
			height: '100vh',
			justifyContent: 'center',
			textAlign: 'center',
			zIndex: 1,
			filter: 'drop-shadow(0 0 3px rgba(0, 0, 0, 0.3))',
		},
		centered: {
			position: 'relative',
			top: '45%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			padding: '25px',
		},
		overlayHeading: {
			color: '#FFFFFF',
			fontSize: 60,
			paddingLeft: theme.spacing(5),
			paddingRight: theme.spacing(5),
			opacity: 1,
			fontWeight: 900,
			boxShadow: 'none',
		},
		overlaySubHeading: {
			color: '#FFFFFF',
			boxShadow: 'none',
			fontSize: 20,
			paddingTop: theme.spacing(2),
			paddingLeft: theme.spacing(10),
			paddingRight: theme.spacing(10),
			justifyContent: 'center',
			textAlign: 'center',
			opacity: 1,
			[theme.breakpoints.down('lg')]: {
				paddingLeft: theme.spacing(2),
				paddingRight: theme.spacing(2),
			},
		},
		paper: {
			width: '100%',
			maxWidth: '400px',
			padding: theme.spacing(2),
			textAlign: 'center',
			color: theme.palette.text.secondary,
			[theme.breakpoints.down('md')]: {
				maxWidth: '480px',
			},
		},
		welcome: {
			fontSize: 50,
			fontWeight: 800,
			letterSpacing: 0,
			color: '#112f48',
			opacity: 1,
			[theme.breakpoints.down('md')]: {
				fontSize: 35,
			},
		},
		subHeader: {
			fontSize: 17,
			letterSpacing: 0,
			color: '#112f48',
			opacity: 1,
			[theme.breakpoints.down('md')]: {
				fontSize: 14,
			},
		},
		navLink: { textDecoration: 'none' },
		forgot: {
			fontSize: 17,
			letterSpacing: 0,
			color: '#112f48',
			opacity: 1,
			marginTop: 10,
			[theme.breakpoints.down('md')]: {
				fontSize: 14,
			},
		},
		footer: {
			fontSize: 13,
			letterSpacing: 0,
			color: '#112f48',
			opacity: 1,
			[theme.breakpoints.down('md')]: {
				fontSize: 12,
			},
		},
	})
);
