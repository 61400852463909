import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material/styles';

const styles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			marginTop: '40px',
			height: '25px',
			'& .MuiDataGrid-overlayContent': {
				flexDirection: 'column',
				alignItems: 'center',
			},
			'& .ant-empty-img-1': {
				fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
			},
			'& .ant-empty-img-2': {
				fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
			},
			'& .ant-empty-img-3': {
				fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
			},
			'& .ant-empty-img-4': {
				fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
			},
			'& .ant-empty-img-5': {
				fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
				fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
			},
		},
		label: {
			marginTop: theme.spacing(1),
		},
	})
);

export default styles;
