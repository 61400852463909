/* eslint-disable no-useless-catch */
import { feathers as feathersIni, HookContext } from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import auth, { AuthenticationClient } from '@feathersjs/authentication-client';
import io from 'socket.io-client';
import { v4 } from 'uuid';
import { logger } from 'hohm-utilities';

const IS_BROWSER = typeof window !== 'undefined';
const IS_LOCAL_STORAGE_AVAILABLE = typeof localStorage !== 'undefined';
const BASE_URL = String(process.env.NEXT_PUBLIC_UI_URL);
const SOCKET_PATH = String(process.env.NEXT_PUBLIC_SOCKET_PATH);

let token =
	IS_BROWSER && IS_LOCAL_STORAGE_AVAILABLE
		? localStorage.getItem('connectionToken')
		: null;

if (!token && IS_BROWSER && IS_LOCAL_STORAGE_AVAILABLE) {
	token = v4();
	localStorage.setItem('connectionToken', token);
}

export const connectionToken = token;

function handleUnauthenticated() {
	return (context: HookContext) => {
		const { error } = context;

		const validRoutes = [
			'/get-my-proposal',
			'select-kit',
			'glint-preapproval',
			'engineered-proposal-actions',
		];

		// The server is responding with 401s, that means the token is expired,
		// or invalid, etc.
		// The other case is when the socket is not connected, then we
		// do not want to reload the page, we just want to wait for the socket
		// to reconnect and reauthenticate, this is typically due to temporary
		// loss of server connection
		logger.log(error.name);

		if (error.name === 'NotAuthenticated') {
			if (
				validRoutes.some((route) =>
					window.location.pathname.includes(route)
				)
			) {
				return;
			}

			if (
				['/', '/login/', '/logout/'].indexOf(
					window.location.pathname
				) === -1
			) {
				window.location.href = '/login/';
			}
		}
	};
}

class AuthenticationClientAdapated extends AuthenticationClient {
	handleError(error: any, type: string) {
		if (error.code === 401 || error.code === 404) {
			const promise = this.removeAccessToken().then(() => this.reset());

			return type === 'logout'
				? promise
				: promise.then(() => Promise.reject(error));
		}

		return Promise.reject(error);
	}
}

export function create() {
	const socket = io(BASE_URL, {
		transports: ['websocket'],
		forceNew: true,
		secure: true,
		path: SOCKET_PATH,
		query: {
			connectionToken,
			apiKey: process.env.NEXT_PUBLIC_SPARK_API_KEY,
		},
	});

	const feathers = feathersIni();

	feathers.configure(
		socketio(socket, {
			timeout: 16000,
		})
	);

	feathers.configure(
		auth({
			storage:
				IS_BROWSER && IS_LOCAL_STORAGE_AVAILABLE
					? localStorage
					: undefined,
			storageKey: 'access-token',
			Authentication: AuthenticationClientAdapated,
		})
	);

	socket.on('connect_error', () => {
		logger.info('Not connected to the server');
	});

	socket.on('connect', () => {
		logger.info(`CONNECTED: ${socket.connected}`);
	});

	feathers.hooks({
		before: {
			all: [],
		},
		error: {
			all: [handleUnauthenticated()],
		},
	});

	return feathers;
}
