'use client';

import React, { useEffect, useState } from 'react';
import { useDebounce, useDeepCompareEffect } from 'react-use';
import { useRouter } from 'next/router';
import { Application } from '@feathersjs/feathers/lib';
// @mui
import {
	Collapse,
	Grid,
	Stack,
	Button,
	Typography,
	Paper,
	Box,
	styled,
} from '@mui/material';
// @api
import { EntityQueries, Api } from 'hohm-queries';
// @stores
import { UserTypes } from 'hohm-types';
// @utilities
import { Format } from 'hohm-utilities';
// @local
import Content from './Content';
import { Autocomplete } from '../../inputs/Autocomplete';

const StyledContent = styled(Content)(() => ({
	backgroundImage: `url(https://res.cloudinary.com/eldo-energy/image/upload/v1579773450/base_back_re1mw6.png)`,
	height: '100%',
	backgroundPosition: 'center',
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
}));

const StyledPaper = styled(Paper)(() => ({
	padding: '50px',
	marginTop: '100px',
}));

const StyledTypography = styled(Typography)(() => ({
	color: '#9e9e9e',
	marginTop: '20px',
	marginBottom: '20px',
}));

export type TSelectClientProps = {
	client: Application<any, any>;
	rootPath: 'admin' | 'advisor' | 'installer' | 'main' | '/';
	installerId?: string;
	onRequestStatus?: (
		status: 'error' | 'success' | 'idle' | 'loading'
	) => void;
	requestTimeout?: number;
	onQuery?: (query: Record<string, unknown>) => void;
	dataMode?: 'client' | 'server';
	query?: Record<string, unknown>;
};
export const SelectClient = ({
	client,
	rootPath,
	installerId,
	onRequestStatus,
	requestTimeout,
	onQuery,
}: TSelectClientProps) => {
	const apiActions = new Api.default(client);
	const queryEntities = new EntityQueries(apiActions);

	const router = useRouter();

	const [searchText, setSearchText] = React.useState<string>('');
	const [debouncedText, setDebouncedText] = React.useState<string>('');

	const splitValues = debouncedText.split(' ');

	const searchParams = splitValues.reduce(
		(acc, val) => {
			['firstName', 'lastName'].forEach((field) => {
				acc.push({ [field]: { $iLike: `%${val}%` } });
			});

			return acc;
		},
		[] as { [field: string]: { $iLike: string } }[]
	);

	const advisorQuery = {
		'$permissions.permission$': 'isClient',
		$or: searchParams,
	} as Api.TQuery<UserTypes.IUserRead>;

	const installerQuery = {
		...advisorQuery,
		myOrganisationId: installerId,
	};

	const internalQuery: Api.TQuery<UserTypes.IUserRead> =
		rootPath === 'installer' ? installerQuery : advisorQuery;

	useDeepCompareEffect(() => {
		if (onQuery) onQuery(internalQuery);
	}, [internalQuery]);

	const { data, isLoading, status } =
		queryEntities.useGetEntities<UserTypes.IUserRead>(
			'users',
			internalQuery,
			{
				keepPreviousData: false,
				onSuccess() {
					if (onRequestStatus) onRequestStatus('success');
				},
			}
		);

	const [fetchingTimeout, setFetchingTimeout] = useState<number>(-1);

	if (status === 'success') {
		clearTimeout(fetchingTimeout);
	}

	useEffect(() => {
		setFetchingTimeout(
			window.setTimeout(() => {
				if (onRequestStatus) onRequestStatus(status);
			}, requestTimeout || 5000)
		);
	}, [status]);

	const [clientSelection, setClientSelection] =
		React.useState<UserTypes.IUserRead | null>(null);
	const isClientSelected = Boolean(clientSelection);

	const selectExistingClientAndGenerateIP = async () => {
		if (clientSelection) {
			if (rootPath === 'installer') {
				router.push(
					`/generate-ip?role=epcAdmin&clientId=${clientSelection.id}&step=select-usage&subStep=default`
				);
			} else {
				router.push(
					`/generate-ip?role=advisor&clientId=${clientSelection.id}&step=select-usage&subStep=default`
				);
			}
		}
	};

	useDebounce(
		() => {
			setDebouncedText(searchText);
		},
		300,
		[searchText]
	);

	const searchForClients = (
		searchEvent: React.ChangeEvent<HTMLInputElement>
	) => {
		const { value } = searchEvent.target;

		setSearchText(value);
	};

	return (
		<StyledContent justifyContent="center">
			<Grid
				item
				xs={12}
				sm={12}
				md={6}
				lg={6}
				alignItems="center"
				justifyContent="center"
			>
				<StyledPaper>
					<Stack spacing={2}>
						<StyledTypography>
							Kindly select or search an existing client to
							proceed
						</StyledTypography>
						<>
							<Autocomplete
								label="Search for a client"
								InputProps={{
									sx: {
										borderRadius: '30px',
									},
									onChange: searchForClients,
								}}
								options={data?.data || []}
								onChange={(e, selectedClient) => {
									setClientSelection(selectedClient);
								}}
								loading={isLoading}
								noOptionsText="No client was found!"
								getOptionLabel={({ firstName, lastName }) =>
									`${firstName} ${lastName}`
								}
							/>
							<Collapse in={isClientSelected} unmountOnExit>
								<Box mt="20px">
									<Button
										fullWidth
										variant="contained"
										color="secondary"
										disabled={clientSelection === null}
										onClick={
											selectExistingClientAndGenerateIP
										}
										style={{
											borderRadius: '30px',
											padding: '15px',
										}}
									>
										{clientSelection ? (
											<b>
												Generate IP for{' '}
												{Format.getFullname(
													clientSelection
												)}
											</b>
										) : null}
									</Button>
								</Box>
							</Collapse>
						</>
					</Stack>
				</StyledPaper>
			</Grid>
		</StyledContent>
	);
};
