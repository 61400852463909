'use client';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { typings } from 'hohm-themes';

// The groupings in this file and in this package are grouped according
// to the groupings found under the components section on the MUI
// documentation site: https://mui.com/material-ui/getting-started/

// Please try keep this file alphabetically sorted and tidy.

// Data display components

export { Avatar, type TAvatarProps } from './data-display/Avatar';
export {
	ThumbnailGrid,
	type TFileTypes,
	type TFile,
} from './data-display/Thumbnails';
export {
	CurrencyChip,
	type TCurrencyChipProps,
} from './data-display/CurrencyChip';
export { DataItem, type TDataItemProps } from './data-display/DataItem';
export * as DataGrid from './data-display/DataGrid';
export {
	DataGrid as DataGridTable,
	type TDataGridProps,
} from './data-display/DataGrid';
export { FieldValue, type TFieldValueProps } from './data-display/FieldValue';
export * as FieldValueProps from './data-display/FieldValue';
export * as Google from './data-display/Google';
export {
	GoogleMarkerMap,
	type TGoogleMarkerMapProps,
} from './data-display/Google/MarkerMap';
export {
	GooglePlaces,
	type TGooglePlacesProps,
} from './data-display/Google/Places';
export {
	GoogleLoader,
	type TGoogleLoaderProps,
} from './data-display/Google/Loader';
export { Heading, type THeadingProps } from './data-display/Heading';
export { IconList, type TIconListProps } from './data-display/IconList';
export {
	IconTooltip,
	type TIconTooltipProps,
} from './data-display/IconTooltip';
export { Image, type TImageProps } from './data-display/Image';
export { PointBadge, type TPointBadgeProps } from './data-display/PointBadge';
export {
	StakeholderAvatar,
	type TStakeholderAvatarProps,
} from './data-display/StakeholderAvatar';
export * as Table from './data-display/Table';
export { ViewFiles, type TViewFilesProps } from './data-display/ViewFiles';
export * as ViewFilesProps from './data-display/ViewFiles';

// Feedback components

export {
	CircularProgress,
	type TCircularProgressProps,
} from './feedback/CircularProgress';
export { CustomDialog, type TCustomDialogProps } from './feedback/CustomDialog';
export * from './feedback/PdfNotify';

// Hooks

export * as hooks from './hooks';

// Input components

export { Input, type TInputProps } from './inputs/Input';
export {
	InputController,
	type TInputControllerProps,
} from './inputs/InputController';
export { DatePicker, type TDatePickerProps } from './inputs/DatePicker';
export {
	DatePickerController,
	type TDatePickerControllerProps,
} from './inputs/DatePickerController';
export {
	PhoneNumberInput,
	type TPhoneNumberInputProps,
} from './inputs/PhoneNumberInput';
export {
	PhoneNumberInputController,
	type TPhoneNumberInputControllerProps,
} from './inputs/PhoneNumberInputController';
export { Select, type TSelectProps } from './inputs/Select';
export {
	SelectController,
	type TSelectControllerProps,
} from './inputs/SelectController';
export { Autocomplete, type TAutocompleteProps } from './inputs/Autocomplete';
export { Button, type TButtonProps } from './inputs/Button';
export { TileButton, type TTileButtonProps } from './inputs/TileButton';
export { ItemButton, type TItemButtonProps } from './inputs/ItemButton';
export {
	CurrencyInput,
	type TCurrencyInputProps,
} from './inputs/CurrencyInput';

export {
	CurrencyInputController,
	type TCurrencyInputControllerProps,
} from './inputs/CurrencyInputController';

export { EntitySearch, type TEntitySearchProps } from './inputs/EntitySearch';
export { FileUpload, type TFileUploadProps } from './inputs/FileUpload';
export { Dropzone, type IDropzoneProps } from './inputs/FileUpload/Dropzone';
export { Locations, type TLocationsProps } from './inputs/Locations';
export {
	LoadingButton,
	type TLoadingButtonProps,
} from './inputs/LoadingButton';
export {
	LocationDialog,
	type TLocationDialogProps,
} from './inputs/LocationDialog';
export { NumberInput, numberFormattedInput } from './inputs/NumberInput';
export {
	NumberStepper,
	type TNumberStepperProps,
} from './inputs/NumberStepper';
export { SelectDialog, type TSelectDialogProps } from './inputs/SelectDialog';
export { UploadAvatar, type TUploadAvatarProps } from './inputs/UploadAvatar';

// Layout components

export { ClientLayout, type TClientLayoutProps } from './layout/ClientLayout';
export { Page, type TPageProps } from './layout/Page';
export {
	ResponsiveGrid,
	type TResponsiveGridProps,
} from './layout/ResponsiveGrid';
export {
	TrackingConsent,
	type TTrackingConsentProps,
} from './layout/TrackingConsent';

// Navigation components

export { Navlink, type TNavlinkProps } from './navigation/Navlink';
export {
	VerticalSidebar,
	type TVerticalSidebarProps,
} from './navigation/VerticalSidebar';
export { Sidebar, type TSidebarProps } from './navigation/Sidebar';

// Single use components are either too specific to be used in multiple places
// or they contain business logic specific to the application that cannot be reused

export * as HubSpotWidget from './single-use/HubSpotWidget';

export {
	InstallerUserDetails,
	type TInstallerUserDetailsProps,
} from './single-use/InstallerUserDetails';
export {
	PrivacyPolicy,
	type TPrivacyPolicyProps,
} from './single-use/PrivacyPolicy';
export {
	RenderPermissions,
	type TRenderPermissionsProps,
} from './single-use/RenderPermissions';
export {
	SelectClient,
	type TSelectClientProps,
} from './single-use/SelectClient';
export {
	GlintPrivacyPolicy,
	type TGlintPrivacyPolicyProps,
} from './single-use/terms/GlintPrivacyPolicy';
export {
	TermsAndConditions,
	type TTermsAndConditionsProps,
} from './single-use/terms/TermsAndConditions';
export {
	CopyrightFooter,
	type TCopyrightFooterProps,
} from './single-use/CopyrightFooter';
export { OutlyingAreaChargeAlert } from './single-use/OutlyingAreaAlert';

// Surface components

export { Accordion, type TAccordionProps } from './surfaces/Accordion';
export {
	AccordionSummary,
	type TAccordionSummaryProps,
} from './surfaces/AccordionSummary';
export {
	AccordionDetails,
	type TAccordionDetailsProps,
} from './surfaces/AccordionDetails';

export { Carousel, type ICarouselProps } from './surfaces/Carousel';

export {
	InformationalBlock,
	type IInformationalBlockProps,
} from './surfaces/InformationalBlocks';

// Utility components

export { Adornment, type TAdornmentProps } from './utils/Adornment';
export { DialogTransition } from './utils/DialogTransition';
export { DialogCloseButton } from './utils/DialogCloseButton';
export { MenuPopover, type TMenuPopoverProps } from './utils/MenuPopover';
export { Masonry, type TMasonryProps } from './utils/Masonry';
export {
	LocalizationProvider,
	type TLocalizationProviderProps,
} from './utils/LocalizationProvider';
export { TrackNextAppViews } from './utils/TrackNextAppViews';
export { TrackNextPageViews } from './utils/TrackNextPageViews';

// Pages

export { NotFound, type TNotFoundProps } from './pages/NotFound';
