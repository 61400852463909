"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Container = (theme) => {
    return {
        MuiContainer: {
            styleOverrides: {
                root: {},
            },
        },
    };
};
exports.default = Container;
