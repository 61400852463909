"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.brandingLogo = exports.brandingColorLogo = exports.brandWordLogo = exports.brandIcon = exports.brandLogoLayout = exports.brandLogoHohm = exports.fullBrandNameCapitalized = exports.brandNameCapitalized = exports.brandName = void 0;
exports.brandName = 'Hohm';
exports.brandNameCapitalized = 'HOHM';
exports.fullBrandNameCapitalized = "HOHM ENERGY SERVICES PTY LTD";
// images
exports.brandLogoHohm = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/general/HohmEnergyLogoOrange.png';
exports.brandLogoLayout = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/general/HohmEnergyLogoOrange.png';
exports.brandIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/layout/SocialMediaAppIconOrange.png';
exports.brandWordLogo = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/layout/SocialMediaAppIconOrange.png';
// branding
exports.brandingColorLogo = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/general/HohmEnergyLogoOrange.png';
exports.brandingLogo = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/general/HohmEnergyLogoOrange.png';
