"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.notificationQuerySchema = exports.notificationUpdateSchema = exports.notificationWriteSchema = exports.notificationReadSchema = exports.notificationSchema = exports.NOTIFICATION_ACTIONS = exports.NOTIFICATION_TYPES = void 0;
var typebox_1 = require("@feathersjs/typebox");
exports.NOTIFICATION_TYPES = [
    'proposal',
    'engineering-quote',
    'site visit',
    'booking',
    'quote',
];
exports.NOTIFICATION_ACTIONS = [
    'no-action',
    'view-initial-proposal',
    'view-engineered-proposal',
    'confirm-site-visit',
    'view-calendar',
];
exports.notificationSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    read: typebox_1.Type.Boolean(),
    message: typebox_1.Type.String(),
    type: (0, typebox_1.StringEnum)(__spreadArray([], exports.NOTIFICATION_TYPES, true)),
    action: (0, typebox_1.StringEnum)(__spreadArray([], exports.NOTIFICATION_ACTIONS, true)),
    meta: typebox_1.Type.Object({
        engineeringQuoteId: typebox_1.Type.Optional(typebox_1.Type.String()),
    }),
    projectId: typebox_1.Type.Optional(typebox_1.Type.String()),
    organisationId: typebox_1.Type.Optional(typebox_1.Type.String()),
    userId: typebox_1.Type.String(),
    bookingId: typebox_1.Type.String(),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.notificationReadSchema = typebox_1.Type.Omit(exports.notificationSchema, []);
exports.notificationWriteSchema = typebox_1.Type.Omit(exports.notificationReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.notificationUpdateSchema = typebox_1.Type.Partial(exports.notificationSchema, {
    $id: 'NotificationUpdate',
});
var notificationQueryProperties = typebox_1.Type.Partial(exports.notificationSchema, {
    $id: 'NotificationQuery',
});
exports.notificationQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(notificationQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
