"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.siteVisitQuerySchema = exports.siteVisitUpdateSchema = exports.siteVisitWriteSchema = exports.siteVisitReadSchema = exports.siteVisitSchema = exports.INSTALLATION_TYPES = exports.PRIORITY_LIST = exports.METER_TYPES = void 0;
var typebox_1 = require("@feathersjs/typebox");
var property_types_1 = require("./property.types");
exports.METER_TYPES = ['prepaid', 'standard', 'none'];
exports.PRIORITY_LIST = ['go-green', 'savings', 'backup-power'];
exports.INSTALLATION_TYPES = [
    'battery backup',
    'grid-tied',
    'hybrid-solar',
    'off-grid',
];
exports.siteVisitSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    saOntime: typebox_1.Type.Boolean(),
    installerOnTime: typebox_1.Type.Boolean(),
    installerArrivaltime: typebox_1.Type.Date(),
    installerRep: typebox_1.Type.String(),
    // Distribution
    dbPictures: typebox_1.Type.Array(typebox_1.Type.String()),
    dbLocationPictures: typebox_1.Type.Array(typebox_1.Type.String()),
    numberOfDbs: typebox_1.Type.Number(),
    loadNeededBatteryBackupInKwh: typebox_1.Type.Number(),
    clientPriority: (0, typebox_1.StringEnum)(__spreadArray([], exports.PRIORITY_LIST, true)),
    customerBudget: typebox_1.Type.Number(),
    numberOfSubDbs: typebox_1.Type.Number(),
    dbType: (0, typebox_1.StringEnum)(__spreadArray([], exports.METER_TYPES, true)),
    dbRewiring: typebox_1.Type.Boolean(),
    ampRating: typebox_1.Type.Number(),
    essentialLoads: typebox_1.Type.Array(typebox_1.Type.String()),
    meterType: (0, typebox_1.StringEnum)(__spreadArray([], exports.METER_TYPES, true)),
    statementPictures: typebox_1.Type.Array(typebox_1.Type.String()),
    billPictures: typebox_1.Type.Array(typebox_1.Type.String()),
    monthlyKwh: typebox_1.Type.Number(),
    // Installation
    meterDistanceFromDbToInverter: typebox_1.Type.String(),
    installationLocationDescription: typebox_1.Type.String(),
    installationType: (0, typebox_1.StringEnum)(__spreadArray([], exports.INSTALLATION_TYPES, true)),
    installationLocationApproved: typebox_1.Type.Boolean(),
    installationLocationPictures: typebox_1.Type.Array(typebox_1.Type.String()),
    wifiSignalAcceptable: typebox_1.Type.Boolean(),
    availableLanConnection: typebox_1.Type.Boolean(),
    preperationIsRequired: typebox_1.Type.Boolean(),
    preperationDetails: typebox_1.Type.String(),
    // Roof
    roofAreas: typebox_1.Type.Array(typebox_1.Type.Object({
        solarInstallationAreaPictures: typebox_1.Type.Array(typebox_1.Type.String()),
        mountingType: typebox_1.Type.String(),
        direction: typebox_1.Type.String(),
        sameDirection: typebox_1.Type.Boolean(),
        kWp: typebox_1.Type.Number(),
        proposedLayout: typebox_1.Type.String(),
        obstructionDetails: typebox_1.Type.String(),
        obstructionPictures: typebox_1.Type.Array(typebox_1.Type.String()),
        waterproofingWarranty: typebox_1.Type.Boolean(),
        currentRoofDamange: typebox_1.Type.String(),
    })),
    // Hardware
    numberOfGeysers: typebox_1.Type.Number(),
    geysersAreGridConnected: typebox_1.Type.Boolean(),
    ledLightsInstalled: typebox_1.Type.Boolean(),
    requestLightsUpgrade: typebox_1.Type.Boolean(),
    solarPowerDetails: typebox_1.Type.String(),
    geysersPositionDetails: typebox_1.Type.String(),
    // Additional Info
    specialInstallationRequests: typebox_1.Type.String(),
    typeOfHome: (0, typebox_1.StringEnum)(__spreadArray([], property_types_1.PROPERTY_TYPES, true)),
    siteRestrictions: typebox_1.Type.String(),
    requiredDocumentation: typebox_1.Type.String(),
    additionalInstallationInstructions: typebox_1.Type.String(),
    numberOfEQs: typebox_1.Type.Number(),
    daysToCreateEQ: typebox_1.Type.Number(),
    // Confirmation
    clientSignature: typebox_1.Type.String(),
    installerSignature: typebox_1.Type.String(),
    // Relationships
    propertyId: typebox_1.Type.Number(),
    userId: typebox_1.Type.Number(),
    '$user.myOrganisationId$': typebox_1.Type.Optional(typebox_1.Type.String()),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.siteVisitReadSchema = typebox_1.Type.Omit(exports.siteVisitSchema, []);
exports.siteVisitWriteSchema = typebox_1.Type.Omit(exports.siteVisitReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.siteVisitUpdateSchema = typebox_1.Type.Partial(exports.siteVisitSchema, {
    $id: 'SiteVisitUpdate',
});
var siteVisitQueryProperties = typebox_1.Type.Partial(exports.siteVisitSchema, {
    $id: 'SiteVisitQuery',
});
exports.siteVisitQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(siteVisitQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
