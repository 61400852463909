"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MuiCssBaseline = (theme) => {
    return {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                    font-family: 'Quicksand';
                    font-style: normal;
                    font-weight: 300;
                    font-display: swap;
                    src: url('/fonts/Quicksand.woff2') format('woff2');
                    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
                }

                @font-face {
                    font-family: 'Quicksand';
                    font-style: normal;
                    font-weight: 400;
                    font-display: swap;
                    src: url('/fonts/Quicksand.woff2') format('woff2');
                    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
                }

                @font-face {
                    font-family: 'Quicksand';
                    font-style: normal;
                    font-weight: 500;
                    font-display: swap;
                    src: url('/fonts/Quicksand.woff2') format('woff2');
                    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
                }
            `,
        },
    };
};
exports.default = MuiCssBaseline;
