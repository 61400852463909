"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.systemDesignQuerySchema = exports.systemDesignUpdateSchema = exports.systemDesignWriteSchema = exports.systemDesignReadSchema = exports.systemDesignSchema = exports.panelMappingSchema = exports.panelRowSchema = void 0;
var typebox_1 = require("@feathersjs/typebox");
var engineered_proposal_types_1 = require("./engineered-proposal.types");
var property_types_1 = require("./property.types");
var general_types_1 = require("./general-types");
exports.panelRowSchema = typebox_1.Type.Object({
    rowId: typebox_1.Type.String(),
    panelIds: typebox_1.Type.Array(typebox_1.Type.String()),
    arrayOrientation: (0, typebox_1.StringEnum)(['north', 'east', 'west', 'south']),
    panelOrientation: (0, typebox_1.StringEnum)(['portrait', 'landscape']),
    color: typebox_1.Type.String(),
});
exports.panelMappingSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    mapping: typebox_1.Type.Array(general_types_1.panelMappingSchema),
    type: typebox_1.Type.Optional(typebox_1.Type.String()),
    quantity: typebox_1.Type.Number(),
});
// TODO cleanup this schema
exports.systemDesignSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    backupLoads: typebox_1.Type.Array(typebox_1.Type.String()),
    activeLoads: typebox_1.Type.Array(typebox_1.Type.String()),
    panelRows: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.Array(exports.panelRowSchema), typebox_1.Type.Null()])),
    panelMapping: typebox_1.Type.Optional(typebox_1.Type.Union([exports.panelMappingSchema, typebox_1.Type.Null()])),
    cabling: typebox_1.Type.Optional(typebox_1.Type.Union([engineered_proposal_types_1.cablingSchema, typebox_1.Type.Null()])),
    cableRun: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.Array(engineered_proposal_types_1.cableRunSchema), typebox_1.Type.Null()])),
    essentialLoads: typebox_1.Type.Optional(typebox_1.Type.Record(typebox_1.Type.Union(property_types_1.ESSENTIAL_LOADS.map(function (n) { return typebox_1.Type.Literal(n); })), typebox_1.Type.Boolean())),
    dbLocation: typebox_1.Type.Optional(typebox_1.Type.Union([general_types_1.pointSchema, typebox_1.Type.Null()])),
    inverterLocation: typebox_1.Type.Optional(typebox_1.Type.Union([general_types_1.pointSchema, typebox_1.Type.Null()])),
    systemLossesPercentages: typebox_1.Type.Optional(engineered_proposal_types_1.systemLossesPercentagesSchema),
    engineeredProposalId: typebox_1.Type.Optional(typebox_1.Type.String()),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.systemDesignReadSchema = typebox_1.Type.Omit(exports.systemDesignSchema, []);
exports.systemDesignWriteSchema = typebox_1.Type.Omit(exports.systemDesignReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.systemDesignUpdateSchema = typebox_1.Type.Partial(exports.systemDesignSchema, {
    $id: 'SystemDesignUpdate',
});
var systemDesignQueryProperties = typebox_1.Type.Partial(exports.systemDesignSchema, {
    $id: 'SystemDesignQuery',
});
exports.systemDesignQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(systemDesignQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
