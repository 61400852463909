import React from 'react';
import {
	Box,
	Grid,
	Card,
	CardMedia,
	CardActionArea,
	Tooltip,
	IconButton,
} from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Delete, InsertDriveFile } from '@mui/icons-material';

export type TFileTypes =
	| 'pdf'
	| 'image'
	| 'video'
	| 'audio'
	| 'text'
	| 'application'
	| 'other';

export type TFile = {
	url: string | null;
	type?: TFileTypes;
};

export const ThumbnailGrid = ({
	files,
	remove,
}: {
	files: TFile[];
	remove?: (url: string) => void;
}) => {
	const handleRemoveClick = (url: string, event: React.MouseEvent) => {
		event.preventDefault(); // Prevent the default anchor link behavior
		event.stopPropagation(); // Stop the event from propagating to the CardActionArea
		if (remove) {
			remove(url);
		}
	};

	const height = remove ? 'auto' : 100;
	const padding = remove ? 2 : 0;

	return (
		<Box sx={{ margin: '20px 0' }}>
			<Grid container spacing={2}>
				{files.map((file, index) => (
					<Grid item xs={6} sm={4} md={3} key={index}>
						{file.url != null ? (
							<Card>
								{remove && (
									<Box
										sx={{
											margin: '4px',
											textAlign: 'right',
										}}
										onClick={(event) =>
											handleRemoveClick(file.url!, event)
										}
									>
										<IconButton aria-label="Remove">
											<Delete />
										</IconButton>
									</Box>
								)}
								<Tooltip title="Click to view file">
									<CardActionArea
										component="a"
										href={file.url}
										target="_blank"
									>
										{file.type === 'pdf' ? (
											<Box
												sx={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													height,
												}}
											>
												<PictureAsPdfIcon
													sx={{
														fontSize: 60,
														color: 'red',
													}}
												/>
											</Box>
										) : file.type === 'image' ? (
											<CardMedia
												component="img"
												image={file.url}
												alt={`File ${index + 1}`}
												sx={{ maxHeight: height }}
											/>
										) : (
											<Box
												sx={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													height,
													padding,
												}}
											>
												<InsertDriveFile
													sx={{
														fontSize: 60,
													}}
												/>
											</Box>
										)}
									</CardActionArea>
								</Tooltip>
							</Card>
						) : null}
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

export default ThumbnailGrid;
