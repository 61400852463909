"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IEventType = void 0;
var IEventType;
(function (IEventType) {
    IEventType["SupplierApproved"] = "SUPPLIER_APPROVED";
    IEventType["DepotApproved"] = "DEPOT_APPROVED";
    IEventType["StockLevelUpdated"] = "STOCK_LEVEL_UPDATED";
    IEventType["StockAllocated"] = "STOCK_ALLOCATED";
    IEventType["SerialNumberAllocated"] = "SERIAL_NUMBER_ALLOCATED";
    IEventType["DealUpdatedInAirtable"] = "DEAL_UPDATED_IN_AIRTABLE";
})(IEventType || (exports.IEventType = IEventType = {}));
