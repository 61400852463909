"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.permissionQuerySchema = exports.permissionUpdateSchema = exports.permissionWriteSchema = exports.permissionReadSchema = exports.permissionSchema = exports.PERMISSIONS = exports.installerPermissions = exports.adminPermissions = exports.generalPermissions = exports.ADVISOR_STAGE_PERMISSIONS = void 0;
var typebox_1 = require("@feathersjs/typebox");
exports.ADVISOR_STAGE_PERMISSIONS = [
    'stage1EA',
    'stage2EA',
    'stage3EA',
    'stage4EA',
    'isPremiumEa',
    'isHSDealOwner',
    'isHSContactOwner',
];
exports.generalPermissions = [
    'isAdvisor',
    'financeEa',
    'isClient',
    'isFinance',
    'isSupplier',
    'isSalesRep',
    'administerAllInstallers',
    'isCIAdmin',
    'isDesigner', // for Design-System-App users
    'isChannelPartner', // for Channel-Partner-App users
];
exports.adminPermissions = [
    'isSuper', // general admin - read only
    'isSuperUserAdmin', // user admin - user management [users, keys, installers - all, clients]
    'isSuperPricingAdmin', // pricing admin - pricing management [service plans, stock - all, pricing - all,configurations - [payment options, tax, tariff, campaigns] ]
    'isSuperKitAdmin', // kit admin - kit management [components - all, configurations - [product kits, project areas]]
];
exports.installerPermissions = [
    'isEPCMember', // installer team member
    'isEPCAdmin', // installer admin
    'isEPCTeamLead', // installer team lead
    'isEPCDirector', // installer director
];
exports.PERMISSIONS = __spreadArray(__spreadArray(__spreadArray([], exports.generalPermissions, true), exports.adminPermissions, true), exports.installerPermissions, true);
exports.permissionSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    name: typebox_1.Type.String(),
    permission: (0, typebox_1.StringEnum)(__spreadArray([], exports.PERMISSIONS, true)),
    risk: typebox_1.Type.Number(),
    role: typebox_1.Type.String(),
    organisationId: typebox_1.Type.String(),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.permissionReadSchema = typebox_1.Type.Omit(exports.permissionSchema, []);
exports.permissionWriteSchema = typebox_1.Type.Omit(exports.permissionReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.permissionUpdateSchema = typebox_1.Type.Partial(exports.permissionSchema, {
    $id: 'PermissionUpdate',
});
var permissionQueryProperties = typebox_1.Type.Partial(exports.permissionSchema, {
    $id: 'PermissionQuery',
});
exports.permissionQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(permissionQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
