"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function downloadOrViewFile(url) {
    var isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
    var isSafari = navigator.userAgent.toLowerCase().indexOf('safari') > -1;
    // If in Chrome or Safari - download via virtual link click
    if (isChrome || isSafari) {
        // Creating new link node.
        var link = document.createElement('a');
        link.href = url;
        if (link.download !== undefined) {
            // Set HTML5 download attribute. This will prevent file from opening if supported.
            var fileName = url.substring(url.lastIndexOf('/') + 1, url.length);
            link.download = fileName;
        }
        // Dispatching click event.
        if (document.createEvent) {
            var e = document.createEvent('MouseEvents');
            e.initEvent('click', true, true);
            link.dispatchEvent(e);
            return true;
        }
    }
    // Force file download (whether supported by server).
    if (url.indexOf('?') === -1) {
        url += '?download';
    }
    window.open(url, '_blank');
    return true;
}
exports.default = downloadOrViewFile;
