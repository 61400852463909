"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var betterbond = {
    theme: 'betterbond',
    brand: {
        name: 'BetterBond',
        legalName: 'BetterBond',
        logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/betterbond/betterbond_logo.png',
        icon: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/betterbond/betterbond_logo.png',
    },
    financePartners: [
        {
            name: 'BetterBond',
            logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/betterbond/betterbond_logo.png',
        },
    ],
    campaignPartners: [
        {
            name: 'BetterBond',
            logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/betterbond/betterbond_logo.png',
        },
    ],
    partner: {
        name: 'Hohm Energy',
        legalName: 'Hohm Services (Pty) Ltd.',
        logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/general/HohmEnergyLogoOrange.png',
    },
    poweredBy: {
        name: 'Hohm Energy',
        legalName: 'Hohm Services (Pty) Ltd.',
        logo: 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/general/HohmEnergyLogoOrange.png',
    },
    pages: {
        signup: {
            content: 'BetterBond and Hohm Energy have partnered to offer an industry-leading client solution, giving you an indicative proposal in minutes for a range of suitable solar options that meet your needs',
            sections: {
                titleSection: {
                    title: "From start to solar, we've got your back.",
                },
            },
        },
    },
    supportedLoanBanks: ['BetterBond'],
    financeOptions: [
        {
            label: 'Home Loan',
            value: 'home-loan',
            default: true,
        },
    ],
};
exports.default = betterbond;
