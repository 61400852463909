"use strict";
// General
Object.defineProperty(exports, "__esModule", { value: true });
exports.GraphIcon = exports.FinanceIcon = exports.AwardIcon = exports.ToolsIcon = exports.DraftIcon = exports.QuestionIcon = exports.FactoryIcon = exports.SolarIcon = exports.PhoneIcon = exports.MenuImg = exports.TariffImg = exports.CalendarImg = exports.LocationImg = exports.EmailImg = exports.ContactImg = exports.ProfileImg = exports.whatIsSolar = exports.storage = exports.offGrid = exports.hybrid = exports.gridTied = exports.homeImg = exports.apartmentIcon = exports.clusterIcon = exports.freeStandingIcon = exports.complexIcon = exports.sixthStep = exports.fifthStep = exports.fourthStep = exports.thirdStep = exports.secStep = exports.firstStep = exports.notificationIcon = exports.oobaLogo = exports.pathwaysLogo = exports.NedbankMFC = exports.NedbankLogo = exports.partnershipWithInvestec = exports.authBackground = exports.InvestecMobileLogo = exports.InvestecLogo = exports.TickIcon = exports.usdSparkPlatform = exports.SparkPlatform = exports.desktopBookACall = exports.pdfLogo = exports.Direction = exports.Location = exports.Area = exports.PoweredByHohm = void 0;
exports.clientDetailsIcon = exports.Compliance = exports.Battery = exports.Inverter = exports.SubContractors = exports.Cables = exports.SolarPanels = exports.InverterLogo = exports.TeslaLogo = exports.PowerwallIcon = exports.JasolarLogo = exports.SolarPanelIcon = exports.EnphaseLogo = exports.StepTwo = exports.StepThree = exports.StepOne = exports.stepFourSecond = exports.StepFourFirst = exports.Compass = exports.siteVisitIcon = exports.callIcon = exports.PowerwallImg = exports.SteepIcon = exports.AverageIcon = exports.GentleIcon = exports.FlatIcon = exports.AccountIcon = exports.PropertiesIcon = exports.ScheduleIcon = exports.GenerateProposalIcon = exports.ProposalIcon = exports.BaseQuoteIcon = exports.BaseSpeakerIcon = exports.BaseGraphIcon = exports.BaseFinanceIcon = exports.DesignIcon = exports.SpeakerIcon = exports.BaseToolIcon = exports.QuoteIcon = exports.BaseMonitor = exports.HandshakeIcon = exports.MonitorIcon = void 0;
exports.PoweredByHohm = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/general/HohmEnergyLogoOrange.png';
exports.Area = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/general/Area.svg';
exports.Location = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/general/Location.svg';
exports.Direction = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/general/Direction.svg';
exports.pdfLogo = 'https://res.cloudinary.com/getspark/image/upload/v1625830443/Spark/logo_the4cy.png';
exports.desktopBookACall = 'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/ip-assets/desktop/01_Call.svg';
// auth
exports.SparkPlatform = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/signUp/iPhone%20%281%29.png';
exports.usdSparkPlatform = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/signUp/iPhone.png';
exports.TickIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/signUp/TickIcon.png';
exports.InvestecLogo = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/signUp/InvestecLogo.png';
exports.InvestecMobileLogo = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/spark-proposal/investecMobile.png';
exports.authBackground = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/shutterstock_1403947616-min.jpg';
exports.partnershipWithInvestec = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/signUp/partnership-with-investec.png';
exports.NedbankLogo = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/nedbank-logo-web-1024x1024.jpeg';
exports.NedbankMFC = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/NEDBANK_MFC_Primary%20endorsed%20identity_RGB_Eng.png';
exports.pathwaysLogo = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/signUp/image3.png';
exports.oobaLogo = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/signUp/image5.png';
// client site visit
exports.notificationIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/site-visit/notification.svg';
// spark process
exports.firstStep = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/spark-process/one.svg';
exports.secStep = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/spark-process/two.svg';
exports.thirdStep = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/spark-process/three.svg';
exports.fourthStep = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/spark-process/four.svg';
exports.fifthStep = ' https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/spark-process/five.svg';
exports.sixthStep = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/spark-process/six.svg';
// property types
exports.complexIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/property-types/complex.svg';
exports.freeStandingIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/property-types/freestanding.svg';
exports.clusterIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/property-types/cluster.svg';
exports.apartmentIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/property-types/apartment.svg';
// solar education
exports.homeImg = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/solar-education/home.png';
exports.gridTied = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/solar-education/gridTied.png';
exports.hybrid = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/solar-education/hybrid.png';
exports.offGrid = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/solar-education/offGrid.png';
exports.storage = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/solar-education/storage.png';
exports.whatIsSolar = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/solar-education/whatIsSolar.png';
// client
exports.ProfileImg = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/client/clientName.svg';
exports.ContactImg = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/client/clientContact.svg';
exports.EmailImg = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/client/clientEmail.svg';
exports.LocationImg = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/property/location.svg';
exports.CalendarImg = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/property/main/calendar.svg';
exports.TariffImg = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/property/tariff.svg';
exports.MenuImg = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/property/menu.svg';
// help button
exports.PhoneIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/help/Phone.svg';
// landing
exports.SolarIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/landing/the-problem-1.svg';
exports.FactoryIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/landing/the-problem-2.svg';
exports.QuestionIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/landing/the-problem-5.svg';
exports.DraftIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/landing/proposal-icon.svg';
exports.ToolsIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/landing/tools-icon-grey.svg';
exports.AwardIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/landing/the-solution-2.svg';
exports.FinanceIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/landing/the-solution-4.svg';
exports.GraphIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/landing/graph.svg';
exports.MonitorIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/landing/monitor-icon-white.svg';
exports.HandshakeIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/landing/handshake-icon.svg';
exports.BaseMonitor = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/landing/monitor-icon.svg';
exports.QuoteIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/landing/quote-icon-yellow.svg';
exports.BaseToolIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/landing/tools-icon.svg';
exports.SpeakerIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/landing/speaker-icon-yellow.svg';
exports.DesignIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/landing/funnel-icon.svg';
exports.BaseFinanceIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/landing/hand-icon.svg';
exports.BaseGraphIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/landing/graph-icon.svg';
exports.BaseSpeakerIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/landing/speaker-icon-white.svg';
exports.BaseQuoteIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/landing/quote-icon-white.svg';
// Layout
exports.ProposalIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/layout/proposals.svg';
exports.GenerateProposalIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/layout/genproposal.svg';
exports.ScheduleIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/layout/schedule.svg';
exports.PropertiesIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/layout/properties.svg';
exports.AccountIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/layout/AccountIcon.svg';
// Proposal
exports.FlatIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/spark-proposal/Flat.svg';
exports.GentleIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/spark-proposal/Gentle.svg';
exports.AverageIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/spark-proposal/Average.svg';
exports.SteepIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/spark-proposal/Steep.svg';
exports.PowerwallImg = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/powerwall/powerwall.jpg';
// proposal generation
exports.callIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/proposal/forum.png';
exports.siteVisitIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/proposal/people.png';
// roof direction
exports.Compass = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/direction/compass.svg';
exports.StepFourFirst = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/direction/step4First.png';
exports.stepFourSecond = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/direction/step4Second.png';
exports.StepOne = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/direction/stepOne.png';
exports.StepThree = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/direction/stepThree.png';
exports.StepTwo = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/direction/stepTwo.png';
// EQ create
exports.EnphaseLogo = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/EQ-Create/enphaseLogo.png';
exports.SolarPanelIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/EQ-Create/solarPanel.jpg';
exports.JasolarLogo = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/EQ-Create/jasolar.jpg';
exports.PowerwallIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/EQ-Create/powerwall.png';
exports.TeslaLogo = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/EQ-Create/tesla.png';
exports.InverterLogo = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/EQ-Create/enphaseInverter.png';
// Proposals sideview
exports.SolarPanels = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/proposal-sideview/Solar%20Panel.png';
exports.Cables = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/proposal-sideview/Cable.png';
exports.SubContractors = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/proposal-sideview/Sub%20contractors.png';
exports.Inverter = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/proposal-sideview/Group%20464.png';
exports.Battery = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/proposal-sideview/Battery.png';
exports.Compliance = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/proposal-sideview/Accreditation%20bodies.png';
// advisor
exports.clientDetailsIcon = 'https://hohm-space.ams3.cdn.digitaloceanspaces.com/static-images/advisor/group.png';
