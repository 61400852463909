// @mui
import { Button, ButtonProps, CircularProgress, styled } from '@mui/material';
// @icons
import { ChevronRight, SvgIconComponent } from '@mui/icons-material';

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
	color: theme.palette.grey[400],
}));

export type TLoadingButtonProps = ButtonProps & {
	loading?: boolean;
	SubmitIcon?: SvgIconComponent | null;
	submitText?: string;
	loadingText?: string;
};
export const LoadingButton = ({
	loading = false,
	children,
	SubmitIcon,
	submitText = 'Submit',
	loadingText = 'In progress',
	...other
}: TLoadingButtonProps) => {
	const text = loading ? loadingText : submitText;
	const Submit = SubmitIcon || ChevronRight;
	let endIcon = null;

	if (loading) {
		endIcon = <StyledCircularProgress size={16} />;
	} else if (SubmitIcon != null) {
		endIcon = <Submit />;
	}

	return (
		<Button
			variant="contained"
			size="large"
			color="primary"
			fullWidth
			{...other}
			disabled={loading || other.disabled}
			endIcon={endIcon}
		>
			{children || text}
		</Button>
	);
};
