// @mui
import { InputAdornment } from '@mui/material';
// @form
import { Controller } from 'react-hook-form';
// types
import { CurrencyTypes } from 'hohm-types';
// utilities
import { HandleCurrency } from 'hohm-utilities';
// @local
import { Input } from './Input';
import { TInputControllerProps } from './utils/types';

export type TCurrencyInputControllerProps = {
	currency: CurrencyTypes.TCurrency;
};

export const CurrencyInputController = <T,>({
	currency,
	name,
	control,
	InputProps,
	...rest
}: TInputControllerProps<T> & TCurrencyInputControllerProps) => {
	const currencySymbol = HandleCurrency.getCurrencySymbol(
		currency as CurrencyTypes.TCurrency
	);

	return (
		<Controller
			name={name}
			control={control}
			render={(
				{ field, formState: { errors } },
				{ helperText } = rest
			) => (
				<Input
					{...field}
					isNumeric
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								{currencySymbol}
							</InputAdornment>
						),
						...InputProps,
					}}
					error={!!errors[name]}
					helperText={helperText || <>{errors[name]?.message}</>}
					{...rest}
				/>
			)}
		/>
	);
};

export default CurrencyInputController;
