'use client';

import { useState } from 'react';
// @mui
import {
	Box,
	Stack,
	Skeleton,
	Tooltip,
	Typography,
	Modal,
	styled,
} from '@mui/material';
// types
import { DocumentTypes } from 'hohm-types';
// @local
import { DialogCloseButton } from '../utils/DialogCloseButton';

export type TViewFilesProps = {
	file: DocumentTypes.IDocumentRead;
	index: number | string;
	tooltipTitle?: string;
};

const StyledBox = styled(Box)(() => ({
	right: '0',
	width: '100%',
	height: '100%',
	display: 'flex',
	position: 'relative',
	alignItems: 'center',
	flexDirection: 'column',
	justifyContent: 'center',
}));

export const getPDF = async (url: string) => {
	const openPDF = async () => {
		if (url) {
			const myWindow = window.open(url, '_blank');

			if (myWindow) {
				setTimeout(() => {
					myWindow.document.body.style.margin = '0';
					myWindow.document.title = `pdf`;
				}, 300);
			}

			myWindow?.focus();
		}
	};

	openPDF();
};

export const ViewFiles = ({
	file,
	index,
	tooltipTitle = 'Click to view image',
}: TViewFilesProps) => {
	const [viewFile, setViewFile] = useState(false);
	const fileNameViewTypes = ['application/pdf', 'application/vnd.ms-excel'];

	const handleHoverOver = () => {
		setViewFile(true);
	};

	const handleHoverOut = () => {
		setViewFile(false);
	};

	return (
		<Box key={`file_${index}`}>
			{fileNameViewTypes.includes(file?.fileType as string) ? (
				<Tooltip title="Click to view pdf" arrow>
					<Stack
						direction="column"
						justifyContent="flex-start"
						sx={{ mr: 2 }}
					>
						<Skeleton
							variant="rectangular"
							width={250}
							height={250}
							onClick={() => getPDF(file.documentPath)}
						/>
						<Typography
							variant="overline"
							align="left"
							sx={{ mt: 1, mb: 1 }}
						>
							{`${file.name} (PDF)`}
						</Typography>
					</Stack>
				</Tooltip>
			) : (
				<Box>
					<Modal open={viewFile} onClose={handleHoverOut}>
						<StyledBox>
							<DialogCloseButton onClick={handleHoverOut} />
							<img
								src={`${file.documentPath}`}
								alt={`document - ${index}`}
								style={{
									padding: '12px',
									maxWidth: 'calc(100% - 66px)',
									maxHeight: 'calc(100% - 66px)',
									boxShadow: '0 0 13px #000a',
									transition: '.2s',
									background: 'white',
									borderRadius: '20px',
								}}
								loading="lazy"
							/>
						</StyledBox>
					</Modal>
					<Tooltip title={tooltipTitle} arrow>
						<Stack
							direction="column"
							justifyContent="flex-start"
							sx={{ mr: 2 }}
							onClick={handleHoverOver}
						>
							<img
								src={`${file.documentPath}?w=164&h=164&fit=crop&auto=format`}
								alt={`document - ${index}`}
								style={{
									maxWidth: '250px',
									maxHeight: '250px',
								}}
								loading="lazy"
							/>
							<Typography
								variant="overline"
								align="left"
								sx={{ mt: 1, mb: 1 }}
							>
								{`${file.name} (image)`}
							</Typography>
						</Stack>
					</Tooltip>
				</Box>
			)}
		</Box>
	);
};
