export const blackFridayTerms = [
	{
		id: 1,
		item: "It is important that you understand and agree that when you apply to participate in the Black Friday Promotion for Glint Solar Subscription Services (the 'Promotion'), these terms and conditions ('Promotion Terms and Conditions') will apply and be binding on you.",
	},
	{
		id: 2,
		item: "The Promotion is organized, offered, and run by Hohm Energy Services (Pty) Ltd ('Hohm') as duly authorized, on behalf of Glint Origination Services (Pty) Ltd ('Glint').",
	},
	{
		id: 3,
		item: 'This Promotion is only valid in South Africa to selected applicants who qualify based on the specific application criteria of Glint for solar subscription services.',
	},
	{
		id: 4,
		item: 'If you wish to participate in the Promotion and are eligible, you will contract directly with Glint as a subscriber for solar services provided by it.',
	},
	{
		id: 5,
		item: 'In addition to these Promotion Terms and Conditions, all standard terms and conditions of Glint and Hohm apply to this Promotion.',
	},
	{
		id: 6,
		item: "This Promotion will run from 25 October 2023 to 30 November 2023 (the 'Promotion Period'). You must sign up during the Promotion Period to be eligible to apply to participate.",
	},
	{
		id: 7,
		item: 'You must be at least 18 years old and the owner of the property in which the solar plant will be installed.',
	},
	{
		id: 8,
		item: 'Glint reserves the right to amend or withdraw the Promotion at any time before the expiry of the Promotion Period in its sole and absolute discretion.',
	},
	{
		id: 9,
		item: 'The Promotion applies to new subscriptions only. Customers that have signed up for Glint solar services prior to the commencement of the Promotion Period through Hohm or otherwise do not qualify for the Promotion.',
	},
	{
		id: 10,
		item: 'To apply to participate in the Promotion:',
		subItems: [
			{
				id: 10.1,
				subItem:
					'You must follow the prompts in the relevant communications or may apply through the Hohm website or directly to Glint. You may also contact a Hohm energy advisor telephonically as advised in the Promotion material.',
			},
			{
				id: 10.2,
				subItem:
					'You will sign up and register for the Promotion on the Hohm website, select a participating solar subscription package, conclude the relevant subscription documentation and give the necessary consents for assessment of your application.',
			},
			{
				id: 10.3,
				subItem:
					'Your application will be subject to the application and verification processes of Hohm and Glint',
			},
		],
	},
	{
		id: 11,
		item: 'Only Promotion applicants who are eligible in terms of Glints subscription criteria will be entitled to par- ticipate in the Promotion. Qualification is dependent on the credit and verification processes and require- 2 ments of Glint and in Glints sole and absolute discretion. Neither Glint not Hohm will be liable to you, in any manner whatsoever, if you are not eligible to participate in the Promotion.',
	},
	{
		id: 12,
		item: 'You will only be able to participate in the Promotion once Glint agrees to enter into a subscription ser- vices agreement ( “Subscription Agreement”) with you and you agree thereto.',
	},
	{
		id: 13,
		item: 'The Promotion will be subject to the terms and conditions of the Subscription Agreement and these Pro- motion Terms and Conditions .Should you breach these Promotion Terms and Conditions and/ or those set out in the Subscription Agreement, Glint reserves the right to revoke the Promotion benefits from you. ',
	},
	{
		id: 14,
		item: 'The following important details apply to the Promotion:',
		subItems: [
			{
				id: 14.1,
				subItem:
					'You will be bound by an initial solar services subscription period of 60 months ( “Initial Period”).',
			},
			{
				id: 14.2,
				subItem:
					'Upon completion of the Initial Period, you will be entitled to renew the subscription on a month-to-month basis.',
			},
			{
				id: 14.3,
				subItem:
					'You will not have to pay the initiation fee, normally required. ',
			},
			{
				id: 14.4,
				subItem:
					'Your monthly subscription fee for December 2023 will be zero.',
				subTexts: [
					{
						id: String('14.4.1'),
						subText:
							'You will benefit from a  “payment holiday” each December during the Initial Period  (where you want it ). A “payment holiday” is not a discount or saving but means that your payment obli- gation to Glint is deferred to the end of the relevant Subscription Period to which it relates. The payment holiday doesn’t increase the monthly fee but does extend the length of the contract. This means that the term of your Subscription Agreement will be extended by a factor of 1.2X for example if you choose to take 4 x  December payment holidays, you will be committed to extend the 60-month contract by 5 months to 65 months',
					},
					{
						id: String('14.4.2'),
						subText:
							'You are not obliged to take advantage of the payment holidays by participating in the Promotion. You will still be entitled to zero initiation fee and a free month for December 2023.',
					},
					{
						id: String('14.4.3'),
						subText:
							'Subject to any default by you of your Subscription Agreement, no cancellation fee will be payable if you choose to cancel after the first 6 months . You will however always be liable for arrear amounts owing, such default fees and / or collection costs and such other amounts as are stipulated in the Subscription Agreement.',
					},
					{
						id: String('14.4.4'),
						subText:
							'Please note that if you made use of the December payment holidays available in this Promo- tion, but then decide to cancel the Subscription Agreement before the Initial Period ( as duly ex- tended),you will be obliged to fully settle all December payments which shall be deemed to be immediately due , owing and payable on your cancellation.',
					},
					{
						id: String('14.4.5'),
						subText:
							'You may be entitled to continued December payment holidays on the basis described herein during each full year that you extend your Subscription Agreement after the Initial Period.',
					},
					{
						id: String('14.4.6'),
						subText:
							'You will always be responsible to pay the Removal Fee stipulated in the Subscription Agree- ment where you cancel the Subscription Agreement prior to the end of the Initial Period ( as duly extended by any payment holiday)',
					},
				],
			},
		],
	},
];

export interface ITermAndConditionItem {
	id: number | string;
	header: string;
	subHeader?: string;
	items: {
		id: number | string;
		highlight?: string;
		item: string;
		subItems?: {
			id: number | string;
			subItem: string;
			subTexts?: {
				id: number | string;
				text: string;
			}[];
		}[];
	}[];
}

export const termsAndConditions: ITermAndConditionItem[] = [
	{
		id: 1,
		header: 'Agreement',
		items: [
			{
				id: 1.1,
				item: 'This Subscription Agreement (“Agreement”) is made up, incorporates, and must be read together with the  Subscription  Details,  these  Terms  and  any  Schedules and/ or Annexures hereto, which may be amended from time to time in accordance with the provisions hereof, each of the provisions, undertak- ings, agreements and consents contained in the Application, our Privacy Policy and Website User Terms.',
			},
			{
				id: 1.2,
				item: 'The aforesaid documents and steps together will form the agreement between you and us and none of them in isolation may be relied upon as the basis of our relationship with you or the basis of the rights and obligations created in your subscription for Services.',
			},
		],
	},
	{
		id: 2,
		header: 'Definitions',
		items: [
			{
				id: 2.1,
				highlight: '"Applicable Law"',
				item: 'means all applicable laws of a Government Authority, including, legislation, regulations, judgments, injunctions, writs and orders or like actions, rules and regulations of any regional, provincial, municipal or other similar authority and the common law; ',
			},
			{
				id: 2.2,
				item: '“Application” means the application you have made for the provision of a Solar Solution by subscription for Services and includes each step taken as part of the Application process;',
			},
			{
				id: 2.3,
				highlight: '"Battery"',
				item: 'means the battery and components that we own described in the Component Details which forms part of the Plant; ',
			},
			{
				id: 2.4,
				highlight: '"Change in Law"',
				item: 'means after the Signature Date the adoption, promulgation, amendment, change in interpretation, modification or repeal by any Regulatory Authority of any law; or the imposition by a Regulatory Authority of any condition in connection with the issuance, renewal or modification of any consents, permits, clearances, authorisations, approvals, rulings, exemptions, registrations, filings, decisions and/or licences, required to be issued by or made with any Regulatory Authority in connection with Solar Solutions and/ or the performance of any of the Services;',
			},
			{
				id: 2.5,
				highlight: '"Commitment Fee"',
				item: 'means the fee payable by you to us within 7 (seven) days of the Signature Date into the Glint Payment Account as described in the Subscription Details;',
			},
			{
				id: 2.6,
				highlight: '"Component Details"',
				item: 'means component details of the Plant as described in clause 7.3 and as updated from time to time in terms of this Agreement;',
			},
			{
				id: 2.7,
				highlight: '"Connection Point"',
				item: 'means one or more energy connection point(s) where the Primary Meter connects to the Private Distribution Network on the Premises (DB) and the connection point between the DB and the Utilities electrical meter;',
			},
			{
				id: 2.8,
				highlight: '"Consumed Energy"',
				item: 'means the Energy consumed at the Premises during each calendar month of this Agreement pursuant to the provision of the Services under this Agreement measured in kWh at the Metering Point;',
			},
			{
				id: 2.9,
				highlight: '"Contractor"',
				item: 'means a skilled third-party service provider or contractor validly appointed by us to instal and commission the Plant, perform any of the Services or other obligations of the Service Provider in terms of this Agreement; ',
			},
			{
				id: String('2.10'),
				highlight: '"CPI"',
				item: 'means the Rand (ZAR) annual average ‘all items’ consumer price index, as published by South African Reserve Bank and Statistics South Africa;',
			},
			{
				id: String('2.11'),
				highlight: '“Debit Order”',
				item: 'means the Debit Order Mandate concluded by you which forms part of this Agreement;',
			},
			{
				id: String('2.12'),
				highlight: '“Dispose”',
				item: 'means assign, transfer or otherwise dispose of or grant or permit or suffer the grant of any legal or equitable interest (either in whole or in part) whether by sale, lease, declaration or creation of a trust or otherwise and any references herein to "Disposal" shall be construed accordingly; ',
			},
			{
				id: String('2.13'),
				highlight: '“Emergency”',
				item: 'means a serious, unexpected, and potentially dangerous situation requiring immediate action;',
			},
			{
				id: String('2.14'),
				highlight: '“Encumbrance”',
				item: 'means any mortgage, pledge, lien, deed of cession, assignment, hypothecation or security interest or any other agreement or arrangement having the effect of conferring security whether by contract or operation of law, and "Encumber" shall have the corresponding meaning;',
			},
			{
				id: String('2.15'),
				highlight: '“Energy/Solar Outputs” ',
				item: 'means the solar energy generated via the Plant, expressed in kWh and measured by the Inverter, or such other means as the Service Provider may determine from time to time, and available for consumption at the Premises in terms of this Agreement; ',
			},
			{
				id: String('2.16'),
				highlight: '“Fair Market Value”',
				item: 'means an amount equal to the value of something in a free market transaction between an informed willing seller and an informed willing buyer;',
			},
			{
				id: String('2.17'),
				highlight: '“Force Majeure Event” ',
				item: 'means an event of force majeure as more fully described in clause 36;',
			},
			{
				id: String('2.18'),
				highlight: '“Glint Payment Account” ',
				item: 'means the Glint Payment Account described in the Subscription Details into which your Payment Obligations are to be made, or such other account as may be advised to you by the Service Provider in writing from time to time;',
			},
			{
				id: String('2.19'),
				highlight: '“Grid Interruption” ',
				item: 'means any event including any interruption, failure, outage, voltage surge or other event of the national electricity grid that results in the curtailment or interruption of the Plant’s ability to generate Energy; ',
			},
			{
				id: String('2.20'),
				highlight: '“Homeowner”',
				item: 'means the ultimate owner of the Premises where the Subscriber is not the owner thereof;',
			},
			{
				id: String('2.21'),
				highlight: '“Interface”',
				item: 'means the Website, mobile application or any other prescribed interface through which you may apply for a Subscription from us;',
			},
			{
				id: String('2.22'),
				highlight: '“Inverter”',
				item: ' means the inverter/s we own described in the Component Details which forms part of the Plant; ',
			},
			{
				id: String('2.23'),
				highlight: '"kWh"',
				item: 'means kilowatt hour alternating current (AC);',
			},
			{
				id: String('2.24'),
				highlight: '"Metering Point"',
				item: 'shall be the point(s) at which the Plant production and consumption at the Premises of the Energy produced by the Plant is metered; 				',
			},
			{
				id: String('2.25'),
				highlight: '“Panels”',
				item: 'means the solar panels we own described in the Component Details which form part of the Plant; ',
			},
			{
				id: String('2.26'),
				highlight: '“Parties” ',
				item: 'means the parties to this Agreement as set out in the Subscription Details and "Party" means either of them, as the case may be; ',
			},
			{
				id: String('2.27'),
				highlight: '“Payment Account”',
				item: 'means the bank account for purposes of Debit Order collections as described in the Subscription Details and the Debit Order and verified by you;',
			},
			{
				id: String('2.28'),
				highlight: '“Placement Area"',
				item: 'means the area(s) where the Plant (or part thereof) may be placed by the Contractor;',
			},
			{
				id: String('2.29'),
				highlight: '"Plant"',
				item: 'means a solar power generation Plant as described in the Subscription Details and Component Details and includes, without limitation, all equipment, the Primary Meter, Batteries, Panels, protection, Inverters, the Software, controls, meters, switches, connections, conduit, wires and all related equipment and accessories, installed as a removable, temporary fixture. The Plant includes all support structures and panelling erected for the purpose of securing Batteries, the Primary Meter, Panels and Inverters;',
			},
			{
				id: String('2.30'),
				highlight: '“Premises”',
				item: ' means the premises in respect of which you require the Services to be rendered and at which the Plant is to be installed and operated from as detailed in the Subscription Details; ',
			},
			{
				id: String('2.31'),
				highlight: '"Primary Meter" ',
				item: 'means the metering equipment installed by us which includes the metering Point;',
			},
			{
				id: String('2.32'),
				highlight: '“Prime rate”',
				item: ' means the publicly quoted basic rate of interest per annum at which the Service Provider’s bankers lend on overdraft, as certified by any manager of that bank whose appointment it shall not be necessary to prove; ',
			},
			{
				id: String('2.33'),
				highlight: '"Private Distribution Network"',
				item: ' means the Homeowner or, as the case may be, Subscriber-owned electric lines used internally for distribution of electricity at the Premises;',
			},
			{
				id: String('2.34'),
				highlight: '"Regulatory Authority"',
				item: ' means the national, local, provincial Government of the Republic of South Africa, any ministry or department thereof and any government owned or controlled agency;',
			},
			{
				id: String('2.35'),
				highlight: '“Removal Fee”',
				item: ' means the amount you must pay us to deinstall the Plant (as escalated) as detailed in the Subscription Details; ',
			},
			{
				id: String('2.36'),
				highlight: '"Required Documents"',
				item: 'means verified copies of the applicable documents listed in the Subscription Details and such other documents as we may reasonably request, which you must deliver to us as soon as possible after the Signature Date according to the prompts and instructions in the process of your Application to conclude this Agreement with us;',
			},
			{
				id: String('2.37'),
				highlight: '“Services”',
				item: ' means the generation of Solar Outputs for consumption and use at the Premises through the Plant. As part of the Services, the Service Provider will install and maintain the Plant so that it may be operated optimally for this purpose; ',
			},
			{
				id: String('2.38'),
				highlight: '“Service Provider, Glint, we, us, our” ',
				item: 'means Glint Origination Services (Pty) Ltd, its authorised agents (including but not limited to its approved Contractors and their sub-contractors) and its nominee and/ or successor/s in title and and/ or assignees in the event of a cession, assignment, delegation, transfer, sale, pledge or hypothecation of any its rights and/or obligations in terms of this Agreement; ',
			},
			{
				id: String('2.39'),
				highlight: '“Sign”, “Signed” or “Signature”',
				item: `means the Subscriber’s express acknowledgement of the acceptance of the Agreement and the Subscription Details hereto, which is appended by an electronic communication in the form of the Subscriber's ticking the corresponding field at the end of the Agreement and/or the Subscription Details. “Electronic communication” has the meaning assigned to it in the Electronic Communications and Transactions Act 25 of 2002 ("ECTA");`,
			},
			{
				id: String('2.40'),
				highlight: '“Signature Date"',
				item: 'means the date of Signature of this Agreement by the Subscriber,being the date on which it becomes binding on the Subscriber;',
			},
			{
				id: String('2.41'),
				highlight: '“Software”',
				item: 'means the proprietary software the rights in or to which are owned by us or a third party who allows and/ or gives licence to us to use the Software in order to monitor and manage the Plant and the provision and use of the Services which is embedded in the Plant; ',
			},
			{
				id: String('2.42'),
				highlight: '“Subscriber / you / your”',
				item: 'means the person who made the Application and whose details are listed in the Subscription Details; ',
			},
			{
				id: String('2.43'),
				highlight: '"Subscriber Account"',
				item: 'means your account on the Glint Interface which details your Subscription, use of and Payment Obligations for the Services as well as all other related information; ',
			},
			{
				id: String('2.44'),
				highlight: '“Subscription Details”',
				item: 'means the subscription details which you have agreed to, subject to and incorporating these Terms and which can be found on your Subscriber Account;',
			},
			{
				id: String('2.45'),
				highlight: '“Subscription Fee”',
				item: ' means the monthly subscription fee payable by you to us for the Services as set out in the Subscription Details;',
			},
			{
				id: String('2.46'),
				highlight: '“Subscription Period”',
				item: 'means the period commencing on the Subscription Start Date for a minimum period of 7 years [“Scheduled Subscription Period”] and continuing indefinitely thereafter, until terminated in accordance with this Agreement;',
			},
			{
				id: String('2.47'),
				highlight: '“Subscription Start Date”',
				item: 'means the first day following the date on which the Plant has been installed and commissioned as required under this Agreement and is being used to generate Solar Output;',
			},
			{
				id: String('2.48'),
				highlight: '“Termination Fee” ',
				item: 'means the termination fee as described in the Subscription Details;',
			},
			{
				id: String('2.49'),
				highlight: '“Utility” ',
				item: 'means a licensed third party supplying electrical energy to the Premises in terms of a separate energy supply agreement between the Subscriber or the Homeowner, as the case may be, and such third party; and',
			},
			{
				id: String('2.50'),
				highlight: '“VAT”',
				item: 'means Value Added Tax (as defined in the Value-Added Tax Act of 89 of 1991).',
			},
		],
	},
	{
		id: 3,
		header: 'Interpretation',
		items: [
			{
				id: 3.1,
				item: 'In this Agreement:',
				subItems: [
					{
						id: String('3.1.1'),
						subItem:
							'referring to the singular includes the plural and vice versa;',
					},
					{
						id: String('3.1.2'),
						subItem:
							'the number of days refers to calendar days, unless specifically stated otherwise;',
					},
					{
						id: String('3.1.3'),
						subItem:
							'“person” includes a firm, body, body corporate, unincorporated association or any other body or entity; a reference to either party includes that a party’s successors and permitted assigns, substitutes in title, cessionary/ies, delegates, authorised representatives, liquidators, judicial managers and the like;',
					},
					{
						id: String('3.1.4'),
						subItem:
							'a reference to a clause, schedule or annexure is a reference to a clause, schedule or annexure of this Agreement; if there is a conflict between the provisions of this Agreement and any Schedule or Annexure hereto, the provisions of this Agreement shall prevail; and',
					},
					{
						id: String('3.1.5'),
						subItem:
							'headings are inserted for convenience only and not affect the interpretation hereof.',
					},
				],
			},
			{
				id: 3.2,
				item: 'Provisions, terms, explanations, and annotations contained in your Application shall be incorporated by reference in this Agreement and be read together with it.',
			},
			{
				id: 3.3,
				item: 'Provisions, agreements, consents and terms and conditions which apply as part of the process of your interactions with us or a third party acting on our behalf on an Interface, in person, or otherwise, shall be binding on you and shall be incorporated by reference into these Terms. You acknowledge and agree that you have read and understand such provisions, agreements, consents and terms and conditions accordingly.',
			},
			{
				id: 3.4,
				item: `A reference to any person shall be a reference to such person's permitted successor, transferee, assignee, cessionary and/or delegate.`,
			},
			{
				id: 3.5,
				item: 'If any provision of this Agreement or any specific application becomes invalid or unenforceable, the remainder of this Agreement, or the application of the provision in other circumstances, shall not be affected, and each provision of this Agreement shall be valid and enforceable to the fullest extent permitted by Applicable Law.',
			},
		],
	},
	{
		id: 4,
		header: 'Application',
		items: [
			{
				id: 4.1,
				item: 'You have applied for a Subscription for the Services through our Interface. ',
			},
			{
				id: 4.2,
				item: 'You must agree to these Terms before we continue to process your Application.',
			},
			{
				id: 4.3,
				item: 'Based on the information that you’ve shared in your request for a Solar Solution, you were presented with Subscription options that may be appropriate for your Solar Output needs and the relevant key terms for each option.  These key terms of the option you have selected are contained in the Subscription Details and summarise and describe the Plant, monthly Subscription Payments, the duration of the Subscription and the estimated Solar Output for your Premises.',
			},
			{
				id: 4.4,
				item: 'Based on the details and information presented, you choose the Subscription option you wish to apply for as prompted by the instructions on the Interface.',
			},
			{
				id: 4.5,
				item: 'We will utilise the information you share us or shared with us on your behalf, credit bureau information and any other information we may deem relevant and necessary, which will include personal information (“Personal Information”) as defined in the Protection of Personal Information Act (“POPIA”), to assess your creditworthiness and to make a decision on your Application. We may also require verification and confirmation of Personal Information and such further information as we deem necessary and/ or the law requires to make a decision on your Application. Failure to provide such information within the time frame we require may result in a decline or delay in your Application.',
			},
			{
				id: 4.6,
				item: 'The assessment of your creditworthiness and verification of the information you give us will require and include the exchange and analysis of information about you (including Personal Information) with third parties, including Hohm Energy Service (Pty) Limited (“Hohm”), which exchange, and analysis will be in terms of our Privacy Policy which you have agreed to in the Application.',
			},
			{
				id: 4.7,
				item: 'We will not process your Application unless and until you submit all Required Documentation as listed in the Application process.',
			},
			{
				id: 4.8,
				item: 'We reserve the right at our sole discretion and, without assigning any reason, to approve or decline such Application. We are never obliged to accept your Application and you will have no claim against us if we decline it.',
			},
			{
				id: 4.9,
				item: 'Our acceptance or decline of your Application will be communicated to you.  You acknowledge and accept that our acceptance of your Application for a Subscription creates a separate and distinct contractual relationship between you and us outside the terms and conditions that apply to your relationship with any third party, including, without limitation, Hohm.',
			},
		],
	},
	{
		id: 5,
		header: 'Duration',
		items: [
			{
				id: 5.1,
				item: 'This Agreement will become binding on the Signature Date in respect of and to the extent that the provisions thereof relate to rights and obligations between the Parties which arise, or by necessary implication, are governed, prior to the Subscription Start Date.',
			},
			{
				id: 5.2,
				item: 'The Services shall commence on the Subscription Start Date and shall continue for the Subscription Period as described in the Subscription Details, whereafter it shall continue indefinitely (together, the “Term”) until terminated by either Party on the terms and conditions as provided for in this Agreement.',
			},
			{
				id: 5.3,
				item: 'The Subscriber agrees that it has elected to enter into this Agreement for a longer fixed term period than 24 months, as it is of a financial benefit to the Subscriber. The Subscriber understands that if the Term was a shorter period, then the subscription fee would be higher than the Subscription Fee amount listed on the Subscription Details since the cost of providing the Services over a short amount of time is higher for the Service Provider.',
			},
		],
	},
	{
		id: 6,
		header: 'Subscription and Services',
		items: [
			{
				id: 6.1,
				item: 'Once we approve your Application you will be deemed to have subscribed for the Services you requested and selected in your Application and recorded in Subscription Details on the terms and conditions contained in this Agreement.',
			},
			{
				id: 6.2,
				item: 'Correspondingly and subject to continued compliance by you with all your obligations contained in this Agreement, we will grant to you, the right to use and enjoyment of the Services, in accordance with this Agreement.',
			},
			{
				id: 6.3,
				item: 'You acknowledge and understand that:',
				subItems: [
					{
						id: String('6.3.1'),
						subItem:
							'to provide the Services, we need to install, maintain, and operate the Plant on the Premises and all such required access to the Premises to do so;',
					},
					{
						id: String('6.3.2'),
						subItem:
							'by signing this Agreement, you hereby authorise us (including our Contractors) access to the Premises to install, maintain, and operate the Plant;',
					},
					{
						id: String('6.3.3'),
						subItem:
							'the provision of the Services involves facilitating the ability to access and store Solar Outputs via the Plant and is not a warranty or guarantee of continuous, uninterrupted power supply and you will not have any claim against us of any nature whatsoever in this regard and hereby indemnify us accordingly.',
					},
				],
			},
		],
	},
	{
		id: 7,
		header: 'The Plant, Delivery and Ownership',
		items: [
			{
				id: 7.1,
				item: 'Once we accept your Application we will conduct, in our sole discretion, a virtual and/or physical site inspection of the Premises in order to:',
				subItems: [
					{
						id: String('7.1.1'),
						subItem:
							'determine the application suitability of the Premises for installation and operation of the Plant in accordance with the Applicable Laws and safety standards , to our satisfaction; and',
					},
					{
						id: String('7.1.2'),
						subItem:
							'determine, to our satisfaction that the Premises and any subsequent installation and operation of the Plant will be reasonably secured and protected against theft, vandalism and / or any other threats to its integrity and our ownership thereof.',
					},
				],
			},
			{
				id: 7.2,
				item: 'To the extent that, we are satisfied, in our sole and absolute discretion, with the inspection referred to in clause 7.1, we will purchase the necessary components required to install and operate the Plant to provide the Services.',
			},
			{
				id: 7.3,
				item: 'Each component of the Plant which we purchase for performance of our obligations in terms of this Agreement shall be determined and documented and described in the Component Details (as may be updated from time to time).The Component Details, which may be found under the relevant notation on your Subscriber Account will include, without being limited to, the following:',
				subItems: [
					{
						id: String('7.3.1'),
						subItem:
							'The serial numbers of all major components. The serial numbers serve as unique identifiers and will be used for tracking, maintenance, and verification purposes.',
					},
					{
						id: String('7.3.2'),
						subItem:
							'A detailed list of specifications for each component of the Plant, including but not limited to, make, model, dimensions, capacity, and date of manufacture.',
					},
					{
						id: String('7.3.3'),
						subItem:
							'We will retain all documentation related to the Plant which include copies of purchase receipts, warranty information, and any other relevant documentation that can validate the provenance and authenticity of the Plant components .',
					},
					{
						id: String('7.3.4'),
						subItem:
							'Any subsequent additions, replacements, or modifications to the Plant components that result in changes to serial numbers or specifications will be documented in the Component Details which will be updated and apply accordingly.',
					},
				],
			},
			{
				id: 7.4,
				item: 'You agree that we (including our Contractors shall be entitled to access the Premises to take photos and conduct any visual inspection as deemed necessary by us of the Plant to confirm and verify the serial numbers and other unique identifiers.',
			},
			{
				id: 7.5,
				item: 'The Plant will be delivered to us at the Premises for installation. You understand and agree that you do not own the Plant or any part thereof by virtue of the fact that it is delivered to or retained at the Premises. You understand and agree that delivery of the Plant to the Premises, (including each component separately, and/ or the Plant as a whole, and whether delivery takes place in whole or in part, or at different times), is made to us at the Premises, as purchaser thereof and you shall accept such delivery for us and on our behalf, such that ownership therein vests in the Service Provider. ',
			},
			{
				id: 7.6,
				item: 'Should the Plant, or any part thereof, be sold or otherwise transferred by the Service Provider to a successor or successors in title, as the case may be, (the “Transferee”) you understand and agree that immediately upon each such sale or transfer to such successor in title becoming effective, your intention will change so as to hold the Plant at the Premises for and on behalf of such Transferee as owner, such that ownership in the Plant transfers from the then owner of the Plant (being tor a successor or successors in title, as the case may be,) to such Transferee by constructive delivery in the form of constitutum possessorium.',
			},
			{
				id: 7.7,
				item: 'We or the Transferee, as the case may be, are and shall remain the owner of the Plant and the owner or licensee (as the case may be) of the Software.',
			},
			{
				id: 7.8,
				item: 'If you, make any additions or improvements to the Plant, which shall only be done with our prior written consent, you agree that the addition or improvement made shall accede to the Plant and shall become the sole property of the Service Provider or our Transferee, where applicable.',
			},
			{
				id: 7.9,
				item: 'The Plant will be installed in such a manner that it is easily removable by using brackets, other appropriate accessories, and the Primary Meter to ensure that it is kept separate from and does not accede to the Premises or any part thereof. In this regard, Parties agree that the Service Provider (its successors-in-title and/or assigns) is the legal owner of the Plant, including, the Primary Meter and all other movable assets relating to the Plant. The Plant is considered personal property of the Service Provider (removable from the Premises) and does not attach or accede to or be deemed a part of, or fixture to, the Placement Area or any other immovable property of the Subscriber.',
			},
			{
				id: 7.1,
				item: 'In accordance with this clause 7 the Subscriber acknowledges that the Plant (and any other components referred to in clauses 7.9) may not:',
				subItems: [
					{
						id: String('7.10.1'),
						subItem:
							'be subject to any lien or to the landlord’s hypothec for rent owing;',
					},
					{
						id: String('7.10.2'),
						subItem: 'be Disposed of by the Subscriber;',
					},
					{
						id: String('7.10.3'),
						subItem:
							'be attached or be taken in execution of any legal proceedings instituted against the Subscriber or the owner of the Premises, the Subscriber’s landlord (if applicable) or any other person;',
					},
					{
						id: String('7.10.4'),
						subItem:
							'be lawfully validly Disposed of or Encumbered.',
					},
				],
			},
		],
	},
	{
		id: 8,
		header: 'Commitment Fee',
		items: [
			{
				id: 8.1,
				item: 'You must pay us or procure the payment to us into the Glint Payment Account of the Commitment Fee as listed in the Subscription Details within 7 (seven) days of the Signature Date to initiate the Services.',
			},
			{
				id: 8.2,
				item: 'If the Commitment Fee is not paid or is not successfully completed, the Subscription Application will be deemed to have failed and no agreement between you and us for the Services will exist. You will have no claim against us if the Agreement fails because we did not receive the Commitment Fee.',
			},
			{
				id: 8.3,
				item: 'If you have paid the Commitment Fee in accordance with this Agreement and we choose to terminate this Agreement prior to the Subscription Start Date, we will refund the Commitment Fee to you.',
			},
			{
				id: 8.4,
				item: 'These Terms will still remain applicable to your Application.',
			},
		],
	},
	{
		id: 9,
		header: 'Subscription Fees and Payment',
		items: [
			{
				id: 9.1,
				item: 'Subscription Fee',
				subItems: [
					{
						id: String('9.1.1'),
						subItem:
							'You will pay us Subscription Fee monthly in advance for the duration of the Subscription Period, by way of Debit Order.',
					},
					{
						id: String('9.1.2'),
						subItem:
							'Subject to agreement in terms of the provisions of clauses 9.1.4 and 9.1.5, the Subscription Fee will be based on the monthly amount detailed in the Subscription Details as escalated in accordance with the percentage listed in the Subscription Details.',
					},
					{
						id: String('9.1.3'),
						subItem:
							'Should the Debit Order Date (as detailed in the Subscription Details) and the Subscription Start Date fall on different days, you will be liable to make payment of a pro rata Subscription Fee for the period between the Subscription Start Date and the first Debit Order Date (“Pro-Rated Period”). The pro-rated Subscription Fee shall be due and payable and collected by way of Debit Order on the first Debit Order Date and shall be equivalent to one thirtieth of the Subscription Fees multiplied by the number of days of the Pro-Rated Period.',
					},
					{
						id: String('9.1.4'),
						subItem:
							'The mechanism used to calculate the Subscription Fees payable by you may, by consent between the Parties recorded in writing, be varied or replaced with a fixed rate multiplied by the Solar Output consumed or deemed to have been consumed at the Premises (“Consumed Solar Output”). If the Parties do not agree to the change in the mechanism, as provided for in this clause 9.1.4 then, the agreed Subscription Fee as provided in the Subscription Details shall continue to apply.',
					},
					{
						id: String('9.1.5'),
						subItem:
							'If at any stage during the subsistence of this Agreement there are changes in: the Prime Rate; and/or any law or regulation or the interpretation thereof or the then current prevailing weighted average cost of funding to the Service Provider, resulting in any increase to the Service Provider in the cost of providing or maintaining this Agreement, then without derogating from anything aforementioned, the Service Provider may in its discretion, at any time on or after the date of change, recalculate the Subscription Fee which falls due for payment on or after the said date so that the Service Provider maintains the internal rate of return it enjoyed immediately prior to the said change and may substitute the recalculated Subscription Fee for the previously calculated Subscription Fee. Written notice of this change will be delivered via the Interface or email to you.',
					},
					{
						id: String('9.1.6'),
						subItem:
							'Any adjustment to the Subscription Fee shall take effect upon the next Debit Order Date following the date of such change and notice thereof. For the purposes of these clauses 9.1.5 and 9.1.6 the “internal rate of return” shall mean the rate of return enjoyed by the Service Provider during the currency of this Agreement on the fees and amounts payable by you under this Agreement and taking into consideration the costs, including funding costs from time to time incurred by the Service Provider in providing the Services under this Agreement, and as recorded in a computer programme prepared by the Service Provider in respect of this Agreement. A certificate signed by the Service Provider’s auditors shall be conclusive proof of the changed Subscription Fee required to cover the costs referred to above and/ or maintain its internal rate of return.',
					},
					{
						id: String('9.1.7'),
						subItem:
							'You shall not be entitled to withhold any Subscription Fees or any other amount payable in terms of this Agreement for any reason whatsoever. You shall not set-off or deduct against any Subscription Fee or any other amount payable in terms of the Agreement, any present or future claim which you may have against the Service Provider from any cause arising. All payments made by you to us in terms of this Agreement must be made without counterclaim and shall be free of any exchange, bank costs and other charges applicable to you.',
					},
					{
						id: String('9.1.8'),
						subItem:
							'All amounts due and payments made will be reflected in your Subscription Account on the Interface. If you require a statement you may request one from us.',
					},
				],
			},
			{
				id: 9.2,
				item: 'Debit Order',
				subItems: [
					{
						id: String('9.2.1'),
						subItem:
							'You or, where applicable, a third party approved by us, in our sole and absolute discretion, (“Authorised Third Party Payer”) must complete and sign the Debit Order   and agree that the terms of the Debit Order  shall be read together with and be incorporated as part of this Agreement and shall apply to the payment of any amounts which may become payable by you to us pursuant to this Agreement, howsoever arising (“Payment Obligations”).',
					},
					{
						id: String('9.2.2'),
						subItem:
							'You or such Authorised Third Party Payer, where applicable, hereby authorise the Service Provider, or any third party appointed by it and on its behalf, to initiate debits in terms of the Debit Order for the relevant Payment Obligations on each Debit Order Date until the Subscription Fee and all other Payment Obligations that may arise, have been remitted to the Service Provider in full.',
					},
					{
						id: String('9.2.3'),
						subItem:
							'In the event that you default on any of your obligations under the Agreement, including, without limitation, if you or an Authorised Third Party Payer change the Payment Account, block our withdrawals, or otherwise hinder or impede the exercise by the Service Provider of its rights hereunder, you hereby authorise the Service Provider to debit any of your other bank accounts for any portion of the Payment Obligations that were not remitted, in breach of this Agreement. Accordingly, you hereby authorise all your banking institutions to accept and to charge any debit entries initiated by the Service Provider to any of your (or the Authorised Third Party Payer’s) bank accounts.',
					},
					{
						id: String('9.2.4'),
						subItem:
							'You understand that the Debit Order is a fundamental condition inducing the Service Provider to enter into the Agreement, and consequently, if you or the Authorised Third Party Payer terminate the Debit Order, such termination shall be deemed to be a Default Event. Termination of the Debit Order by you or the Authorised Third Party does not release you from any of your Payment Obligations under this Agreement.',
					},
					{
						id: String('9.2.5'),
						subItem:
							'In the event of a Debit Order Date falling on a Sunday or public holiday the Payment Obligations due on that Debit Order Date will be deducted from the Payment Account on the day following the Sunday or public holiday concerned.',
					},
					{
						id: String('9.2.6'),
						subItem:
							'If a Debit Order withdrawal and/ or payment, or any part thereof is returned unpaid or is unprocessed then:',
						subTexts: [
							{
								id: String('9.2.6.1'),
								text: 'you will be in breach of this Agreement and a Default Event will have occurred; and',
							},
							{
								id: String('9.2.6.2'),
								text: 'the Service Provider may charge an unpaid debit order fee on your account should any payments be dishonoured by your bankers. You shall also be liable for all costs/fees incurred as a result of any dishonoured payments, or multiple debit orders loaded against the Payment Account;',
							},
							{
								id: String('9.2.6.3'),
								text: 'we may re-submit any unpaid or unprocessed Debit Order, or part thereof, at any time after initial failed submission until such time as it is paid to us. ',
							},
						],
					},
					{
						id: String('9.2.7'),
						subItem:
							'In the event of all or any of the Payment Obligations becoming immediately due and payable by the you to the Service Provider, following a breach of this Agreement, the happening of a Default Event and/or termination of this Agreement or pursuant to any other provision of this Agreement which specifically provides therefore, the Service Provider shall be entitled without further instruction, to deduct the full amount of any Payment Obligation as at that date plus any penalty, interest, damages and/ or any cost incurred and owing by you to us, via the Debit Order as one lump sum payment.',
					},
					{
						id: String('9.2.8'),
						subItem:
							'Any change in the manner of payment requested by us from you shall not be construed as being a novation and we reserve our rights to change the manner of payment of any Payment Obligations payable by you in terms of this Agreement.',
					},
					{
						id: String('9.2.9'),
						subItem:
							'We will not be liable for the costs of multiple debit order collection attempts from you. If any costs or fees are incurred due to any Debit Order being rejected or otherwise failing for reasons including having insufficient funds and/ or your bank details changing from those provided to us, you shall be liable and responsible for paying all such costs and fees and you hereby indemnify us for such costs and fees which shall immediately become due and payable by you to us.',
					},
				],
			},
			{
				id: 9.3,
				item: 'Failure to make payment',
				subItems: [
					{
						id: String('9.3.1'),
						subItem:
							'If you think that you may not be able to meet any Payment Obligations, including your Subscription Fee please contact our Customer Care Centre.',
					},
					{
						id: String('9.3.2'),
						subItem:
							'If you fail to make payment to us of any Payment Obligation timeously then we shall be entitled, in addition to any other rights we may have in this Agreement or in law, to suspend performance of our obligations in terms of this Agreement and to disconnect/ lock or shutdown the Plant using the Software until such time that your account is no longer in arrears. You will not have any claim for loss or damage arising out of a suspension pursuant to this clause 9.3.2.',
					},
					{
						id: String('9.3.3'),
						subItem:
							'If your account goes more than 30 (thirty) days into arrears [if any invoice has been outstanding for more than 30 (thirty) days] then we shall be entitled, in addition to any other rights we may have, to de-install and remove the Plant and charge you the Removal Fee and any costs of enforcement of this Agreement (including attorney / own client costs) which you will be liable to pay.',
					},
				],
			},
			{
				id: 9.4,
				item: 'Default interest on arrears',
				subItems: [
					{
						id: String('9.4.1'),
						subItem:
							'If you do not make payment of your Payment Obligations on the due date thereof then, without prejudice to any of the other rights or remedies as may accrue to us, such overdue amounts will bear interest at 2% (two percent) per month, calculated from the due date for payment to the date of receipt of actual payment (both dates inclusive) and such interest will be compounded monthly in arrears.',
					},
					{
						id: String('9.4.2'),
						subItem:
							'You will, in the absence of any manifest error, be bound by our calculation in the determination of any interest calculation.',
					},
				],
			},
		],
	},
	{
		id: 10,
		header: 'Consumer Protection Act (“CPA”)',
		items: [
			{
				id: 10.1,
				item: 'You acknowledge that your attention has been drawn to all of the clauses printed in bold and that you have been provided adequate opportunity to consider the clauses and have any of your concerns addressed prior to signing this Agreement.',
			},
			{
				id: 10.2,
				item: 'If the provisions of the CPA apply to any of the Services rendered to you by us in terms of this Agreement, then the provisions of the CPA shall prevail in the event of any conflict between the CPA and the Agreement. The Parties agree that this clause 10 shall be limited in application only to the Services supplied in terms of this Agreement to which the CPA applies.',
			},
		],
	},
	{
		id: 11,
		header: 'Installation of the Plant',
		items: [
			{
				id: 11.1,
				item: 'Commencement',
				subItems: [
					{
						id: String('11.1.1'),
						subItem:
							'Installation and construction of the Plant will only commence if:',
						subTexts: [
							{
								id: String('11.1.1.1'),
								text: 'we have received payment of the Commitment Fee;',
							},
							{
								id: String('11.1.1.2'),
								text: 'we have conducted an inspection and are satisfied that the Premises and Placement Area is suitable for installation of the Plant and provision of the Services;',
							},
							{
								id: String('11.1.1.3'),
								text: 'we are satisfied, in our sole discretion that the Premises are safe and secure to minimise the risk any likely damage or theft to the Plant;',
							},
							{
								id: String('11.1.1.4'),
								text: 'you provide your existing Certificate of Compliance (“COC”) upon which the supplementary Solar COC can be added;',
							},
							{
								id: String('11.1.1.5'),
								text: 'where applicable any Homeowner, Body Corporate or similar consents and any permissions particularly related to the ownership or occupation of the Premises, estate or area and the installation of the Plant at the Premises have been received by us;',
							},
							{
								id: String('11.1.1.6'),
								text: 'any other requirements/conditions necessary for installation of the Plant at the Premises which arise out of your ownership and/ or occupation of the Premises have been met; and',
							},
							{
								id: String('11.1.1.7'),
								text: 'we have procured the relevant regulatory/ legislative permissions pertaining to the Plant, its installation and commissioning, including those from the relevant Utility or other relevant body/ies;',
							},
						],
					},
					{
						id: String('11.1.2'),
						subItem:
							'In the event that the requirements as set out in clauses 11.1.1.1 to 11.1.1.6 above have not been satisfied or remedied at your cost and or we are unable (using our reasonable commercial endeavours) to meet the requirements contained in clause 11.1.1.7 within 60 (sixty) days of the Signature Date then, we may terminate this Agreement on notice to you, but without prejudice to any claims that accrued to us prior to the date of termination.',
					},
					{
						id: String('11.1.3'),
						subItem:
							'You will have no claims against us, in the event of termination of the Agreement pursuant to clause 11.1.2.',
					},
					{
						id: String('11.1.4'),
						subItem:
							'You will have no claims against us for reimbursement of the Commitment Fee in the event of your failure to comply with the provisions of clause 11.1.1.4 and, to the extent applicable clauses 11.1.1.5 and 11.1.1.6 or where you frustrate the compliance by you or us of any of the provisions of clauses 11.1.1.2,11.1.1.3 and / or 11.1.1.7.',
					},
				],
			},
			{
				id: 11.2,
				item: 'Plant design',
				subItems: [
					{
						id: String('11.2.1'),
						subItem:
							'We will design the Plant in accordance with industry standards and considering any technical limitations of the Premises or the Placement Area.',
					},
					{
						id: String('11.2.2'),
						subItem:
							'We are responsible for integration of the Plant into the Premises, including but not limited to the procurement of the components that make up the Plant, installation, commissioning, Plant testing, obtaining the supplementary electrical certificate of compliance and all other work required to connect the Plant to the Connection Points.',
					},
					{
						id: String('11.2.3'),
						subItem:
							'As at the Signature Date hereof, we anticipate that the Plant will consist of the list of components as detailed in the Subscription Details, but shall be entitled, in our sole and absolute discretion to modify or change the Plant or its components to facilitate provision of the Services and in view of the placement Area. We will notify you of such modifications or changes and the Component Details will be completed and updated accordingly.',
					},
				],
			},
			{
				id: 11.3,
				item: 'Subscription Start Date',
				subItems: [
					{
						id: String('11.3.1'),
						subItem:
							'Subject to clause 11.1, we will endeavour to commence with the construction and installation of the Plant as soon as reasonably possible after the 7th day following the Signature date.',
					},
					{
						id: String('11.3.2'),
						subItem:
							'If we anticipate an unforeseen delay, then we shall notify you of the updated anticipated Subscription Start Date.',
					},
					{
						id: String('11.3.3'),
						subItem:
							'We will not be liable to you (including any penalties or breach hereof) if, for whatever reason, the Subscription Start Date is later than anticipated.',
					},
					{
						id: String('11.3.4'),
						subItem:
							'You will be entitled to terminate the Agreement if, due to the fault of the Service Provider, the Subscription Start Date is not within 90 (ninety) days of the date you signed the Agreement.',
					},
					{
						id: String('11.3.4'),
						subItem:
							'We will notify you when installation is completed, the COC received and shall confirm the Subscription Start Date.',
					},
				],
			},
			{
				id: 11.4,
				item: 'Snag period',
				subItems: [
					{
						id: String('11.4.1'),
						subItem:
							'You will have a period of 7 (seven) calendar days from the Subscription Start Date to bring any technical issues relating to the Plant or Services to our attention, provided that you give us notice in writing of any such issues within this period. If there are no issues reported within this period then we shall be entitled to assume that you are totally satisfied with the Plant, its installation, and the Solar Output provided.',
					},
				],
			},
		],
	},
	{
		id: 12,
		header: 'Access to and Storage of the Plant',
		items: [
			{
				id: 12.1,
				item: 'You hereby grant us (including our representatives, Contractors/sub-contractors and/or any of their representatives) unrestricted rights of access (“Access Rights”) to the Premises (which includes the rooftop of the Premises):',
				subItems: [
					{
						id: String('12.1.1'),
						subItem:
							'for proper performance of the Services, including all aspects of installation of the Plant;',
					},
					{
						id: String('12.1.2'),
						subItem:
							'to enforce our rights and your obligations in terms of this Agreement and/ or the termination or enforcement thereof;',
					},
					{
						id: String('12.1.3'),
						subItem: 'to enforce our ownership rights; and/ or',
					},
					{
						id: String('12.1.4'),
						subItem: 'any other purpose set out in this Agreement.',
					},
				],
			},
			{
				id: 12.2,
				item: 'You must ensure that we (including our representatives, contractors/sub-contractors and their representatives) are granted and permitted access to the Premises for purposes of dismantling and removing the Plant from the Premises in accordance with our rights to do so under this Agreement and/ or in law. If you fail to do so, we shall be entitled to pursue any rights and/or remedy available to us under this Agreement or otherwise in law.',
			},
			{
				id: 12.3,
				item: 'We will endeavour to give you reasonable notice before we need access to the Premises unless the circumstances reasonably permit short or no notice, and we shall be granted access to the Premises 7 (seven) days a week in the event of an Emergency.',
			},
			{
				id: 12.4,
				item: `If you are not the owner of the Premises, you shall obtain the Homeowners' consent to enter into this Agreement and grant the Access Rights ("Landlords Consent") in such form and substance satisfactory to us in our sole and absolute discretion.`,
			},
			{
				id: 12.5,
				item: `if you require the consent of a body corporate, homeowners association and/ or any body which makes rules in respect of the Premises, you shall obtain such consent to enter into this Agreement and grant the Access Rights ("Body Corporate's Consent"). The Body Corporate's Consent shall be in form and substance satisfactory to us in our sole and absolute discretion`,
			},
			{
				id: 12.6,
				item: 'You hereby indemnify us in the event of any loss or damage because of your failure to get and deliver the requisite consent and any Commitment Fee paid shall be non refundable and constitute liquidated damages in this event.',
			},
		],
	},
	{
		id: 13,
		header: 'Energy Production',
		items: [
			{
				id: 13.1,
				item: 'You acknowledge and agree that there are several factors that may negatively impact the production of Energy by the Plant, these include without being limited to:',
				subItems: [
					{
						id: String('13.1.1'),
						subItem: 'In respect of the Panels:',
						subTexts: [
							{
								id: String('13.1.1.1'),
								text: 'weather conditions;',
							},
							{
								id: String('13.1.1.2'),
								text: 'the positioning of the panels;',
							},
							{
								id: String('13.1.1.3'),
								text: 'dirt or dust on the panels;',
							},
							{
								id: String('13.1.1.4'),
								text: 'shade on the panels from trees and other surrounding structures;',
							},
							{
								id: String('13.1.1.5'),
								text: 'damage to or degradation of the panels;',
							},
							{
								id: String('13.1.1.6'),
								text: 'location of the Premises;',
							},
							{
								id: String('13.1.1.7'),
								text: 'lack of maintenance of the Premises;',
							},
							{
								id: String('13.1.1.8'),
								text: 'the period between sunset and sunrise; and',
							},
							{
								id: String('13.1.1.9'),
								text: 'use of the Plant in a manner that is not intended by us or the intended design and/or is not in accordance with the operational guidelines given to you.',
							},
						],
					},
					{
						id: String('13.1.2'),
						subItem: 'In respect of the Battery/ies:',
						subTexts: [
							{
								id: String('13.1.2.1'),
								text: 'damage to or degradation of the Battery;',
							},
							{
								id: String('13.1.2.2'),
								text: 'positioning of the Battery/ies;',
							},
							{
								id: String('13.1.2.3'),
								text: 'overuse of the Battery as advised by us; and',
							},
							{
								id: String('13.1.2.4'),
								text: 'use of the Battery for purposes other than intended by the manufacturer, us or the intended design and/or is not in accordance with the operating guidelines.',
							},
						],
					},
				],
			},
			{
				id: 13.2,
				item: 'While we agree to use commercially reasonable endeavours to ensure that the Plant performs optimally given the unique circumstances of the Premises, you knowledge that the various factors mentioned above in clause 13.1 may adversely affect the performance of the Plant and as a result, we do not give any guarantees or make any warranties in respect of the Plant’s performance or the Energy which may be made available to at the Premises for consumption. You shall not have any claim of any nature whatsoever against us in respect of the Plants performance and/ or quantity of Solar Output produced. You hereby indemnify us accordingly.',
			},
		],
	},
	{
		id: 14,
		header: 'Energy Delivery and Measurement',
		items: [
			{
				id: 14.1,
				item: `Subject to the terms and conditions of this Agreement, and in return for due payment of the Subscription Fee, we agree to make Energy generated by the Plant available to you for your consumption at the Premises.`,
			},
			{
				id: 14.2,
				item: 'You will take title to the Solar Energy , and risk of loss therein will pass from the Service Provider to the Subscriber, at the Connection point between the Primary Meter and the Private Distribution Network Connection Point.',
			},
			{
				id: 14.3,
				item: 'You acknowledge and agree that you shall remain liable and continue to fulfil your obligations in terms of this Agreement, including, without limitation, payment of the Subscription Fee, should the Plant or part thereof be prevented from generating Energy, wholly or in part, as a result of the occurrence of any of the following events, and you hereby indemnify us accordingly:',
				subItems: [
					{
						id: String('14.3.1'),
						subItem:
							'in the event of a relocation of the Plant, the period commencing from the date on which the Plant is shutdown to facilitate the relocation and ending on the date on which the relocated Plant is reinstalled and commissioned at a new location pursuant to clause 20.1;',
					},
					{
						id: String('14.3.2'),
						subItem:
							'any interference (including maintenance, cleaning, repairs etc) with the Plant by any person who was not authorised to perform such interference;',
					},
					{
						id: String('14.3.3'),
						subItem: `destruction or damage to the Plant or the Plant's 's ability to safely produce Energy which was not directly caused by us or any person who was authorised by us to work on or perform any services on the Plant;`,
					},
					{
						id: String('14.3.4'),
						subItem:
							'someone other than us or our approved subcontractors installed, removed, re-installed or repaired the Plant;',
					},
					{
						id: String('14.3.5'),
						subItem: 'shutdown of the Plant;',
					},
					{
						id: String('14.3.6'),
						subItem:
							'a failure by you to comply with your obligations in clause 12;',
					},
					{
						id: String('14.3.7'),
						subItem: 'weather conditions;',
					},
					{
						id: String('14.3.8'),
						subItem:
							'any modifications, improvements, revisions, or additions to the Plant by a party other than us;',
					},
					{
						id: String('14.3.9'),
						subItem: 'a Grid Interruption;',
					},
					{
						id: String('14.3.10'),
						subItem:
							'your negligent act or wilful act or omission or that of your employees, agents, contractors, subcontractors, service providers, guests, visitors and/ or tenants;',
					},
					{
						id: String('14.3.11'),
						subItem: 'shading from foliage;',
					},
					{
						id: String('14.3.12'),
						subItem:
							'the occurrence of any factors or scenarios as detailed in in clause 13;',
					},
					{
						id: String('14.3.13'),
						subItem:
							'if you have not fulfilled your obligations under clause 16.1.1 and there is a theft of the Plant; and/or',
					},
					{
						id: String('14.3.14'),
						subItem:
							'for any other reason that is not attributable to our wilful conduct or negligence.',
					},
				],
			},
			{
				id: 14.4,
				item: 'Consumed Energy generated by the Plant will be measured at the Metering Point.',
			},
		],
	},
	{
		id: 15,
		header: 'Subscriber Warranties',
		subHeader: 'You warrant to and in favour of us that:',
		items: [
			{
				id: 15.1,
				item: 'there is no lien, pledge, cession, servitude or other encumbrance over the Premises that may prevent us from performing our obligations or exercising our rights (including our rights of ownership of the Plant) in this Agreement;',
			},
			{
				id: 15.2,
				item: 'you will be responsible for the acts and/ or omissions of any person who enters, uses and/ or is present on the Premises (other than Glint);',
			},
			{
				id: 15.3,
				item: 'you are not aware of any current or future right granted to a third-party to occupy the Premises;',
			},
			{
				id: 15.4,
				item: 'you have procured and delivered to us all and any permissions and/or consents that you are required to procure and deliver for installation and operation of the Plant and provision of the Services on the Premises have been given and you hereby indemnify us accordingly;',
			},
			{
				id: 15.5,
				item: 'you are permitted, by law and with full power and authority to grant the Service Provider the Access Rights in terms of clause 12;',
			},
			{
				id: 15.6,
				item: 'all representations made, information given, and documentation presented to us prior to and after the Signature Date and during the Subscription Period are true and correct in all respects and we are entitled to rely on the truth and correctness thereof;',
			},
			{
				id: 15.7,
				item: 'you have made full disclosure to us of the physical condition and structure of the Premises; and',
			},
			{
				id: 15.8,
				item: 'you have made full disclosure to us in respect of the ownership of the Premises and your rights of occupation of the Premises.',
			},
		],
	},
	{
		id: 16,
		header: 'Subscriber’s Obligations',
		items: [
			{
				id: 16.1,
				item: 'In addition to any of your other obligations in this Agreement, you shall for the duration of Subscription Period:',
				subItems: [
					{
						id: String('16.1.1'),
						subItem:
							'secure the Premises at your own cost and undertake to provide or procure the provision of such measures and personnel which may be required for this purpose, which are in our discretion satisfactory. We may ask to check the security in place from time to time;',
					},
					{
						id: String('16.1.2'),
						subItem:
							'be liable to restore and make good any damage to the Plant as a result of the failure on your part to carry out your obligation in clause 16.1.1 or as a result of theft, fire and structural collapse of the Premises which is not covered by insurance in terms of clause 39;',
					},
					{
						id: String('16.1.3'),
						subItem:
							'keep the Plant unobstructed by buildings, structures, trees or vegetation;',
					},
					{
						id: String('16.1.4'),
						subItem:
							'ensure that the Premises are not modified in any way that diminishes efficiency of the Plant;',
					},
					{
						id: String('16.1.5'),
						subItem:
							'maintain the Premises (including its rooftop);',
					},

					{
						id: String('16.1.6'),
						subItem:
							'not be entitled and refrain from making or authorising any third party from making any modifications, improvements, revisions or additions to the Plant or taking any other action that could invalidate or diminish any warranty in respect of the Plant or any part thereof;',
					},

					{
						id: String('16.1.7'),
						subItem:
							'not in any way tamper with the Plant or authorise any third party to do so;',
					},
					{
						id: String('16.1.8'),
						subItem:
							'notify us in writing and obtain our prior written consent should any electrical work to the main wiring on the Premises be required with details thereof and allow us access to check such wiring should we so elect to do so. To the extent that any changes to wiring are required which affect or are affected by our Plant and/ or the provision of the Services, we may (but shall not be obliged) to carry out such wiring, in which case our standard Contractor rates will apply;',
					},
					{
						id: String('16.1.9'),
						subItem:
							'only use us for maintenance or repairs of the Plant or any part thereof;',
					},
					{
						id: String('16.1.10'),
						subItem:
							'be liable for any loss or damage caused to the Plant as a result of you or third parties appointed by you effecting any repairs or undertaking general maintenance on the Premises which affects the Plant or on the Plant itself;',
					},
					{
						id: String('16.1.11'),
						subItem:
							'notify us in writing as soon as reasonably practical of any damage to the Plant resulting from any maintenance or repairs carried out on the Premises by you or any other reason;',
					},
					{
						id: String('16.1.12'),
						subItem:
							'be and remain responsible for the payment of all municipal and other charges in respect of all electric current supplied by any Utility and/ or third-party supplier for usage of services provided by those persons and consumed in or on the Premises, including any deposit and connecting fees which may be payable.',
					},
				],
			},
			{
				id: 16.2,
				item: 'Neither you nor anyone on your behalf may make or permit any alterations or modifications, including improvements or additions, to be made to the Plant (and the Premises, to the extent that it affects the Plant) unless you have obtained our consent in writing.',
			},
			{
				id: 16.3,
				item: 'You are aware that any alterations or modifications as referred to in clause 16.2 above may have the effect of impacting or voiding the warranties given to us by the manufacturers of the Plant components.',
			},
			{
				id: 16.4,
				item: 'You agree that you shall be liable to us for the damages or loss (including the cost of restoration of the Plant) suffered by us in the event that you fail to perform any of you obligations in terms of this clause 16. Your liability shall be no less than the full cost of the Plant as invoiced to us and in the event that the damage/loss is covered in part by a claim under an insurance policy for the Premises which covers the Plant, then you shall be liable for any shortfall not reimbursed to us by the insurance company.',
			},
		],
	},
	{
		id: 17,
		header: 'Maintenance and Repairs',
		items: [
			{
				id: 17.1,
				item: 'We are responsible for required maintenance, repairs and, where applicable, replacement to the Plant (or any part thereof) during the Subscription Period.',
			},
			{
				id: 17.2,
				item: 'Accordingly, we shall, at our cost, and in our discretion, be entitled to carry out, replace or make good and/ or repair the Plant (or any part thereof) which may become damaged, faulty or defective from time to time during the Subscription Period and you shall have no claim of any nature whatsoever against us in respect of any losses which it may suffer by reason of any such alteration, repair or replacement.',
			},
			{
				id: 17.3,
				item: 'While we will endeavour to give you reasonable notice of the maintenance, repairs or replacements that we will make, we shall be entitled, without prior notice, to perform emergency maintenance, repairs and/ or replacements to the Plant at any time.',
			},
			{
				id: 17.4,
				item: 'You acknowledge that any maintenance, repair or replacement work may require the total shutdown of the Plant, whether it is an emergency or not.',
			},
			{
				id: 17.5,
				item: 'We shall not be liable for any loss suffered by you or to reimburse you for costs you incur for purchasing electrical energy from a Utility or otherwise during the Plant shutdown period.',
			},
			{
				id: 17.6,
				item: 'You shall immediately notify us in writing of any fault, defect or damage to the Plant and we will attend to the maintenance, repair, or replacement thereof in a timely manner, based on the nature of the fault, defect or damage concerned with the cost thereof being at our cost, save where this Agreement provides that you will be liable for such costs.',
			},
			{
				id: 17.7,
				item: 'You shall not be entitled to withhold, set off or deduct any Subscription Fees or other Payment Obligation owing to us in the event of a fault, defect or damage requiring maintenance, repair or replacement of the Plant or part thereof, unless the fault, defect or damage that is notified but not rectified within 60 (sixty) business days. If you purposefully delay the maintenance or repair work or the giving of notice pursuant to clause 17.6 or fail to pay for the costs thereof where you are liable to do so in terms of this Agreement, then the time period in this clause 17.7 shall not apply.',
			},
			{
				id: 17.8,
				item: 'If the Plant requires maintenance or repairs due to your negligence or for which you are otherwise responsible, you shall be liable to compensate us for diagnosing and correcting the problem and the work carried out to restore the Plant back to its condition as at the Subscription Start Date, fair wear and tear excluded (“Fault Costs”). The Fault Costs shall be determined by reference to our or our Contractors’ then current standard rates and include any lost income to us by virtue of our rights in this Agreement or of our Contractors that otherwise would have been generated if no such damage had existed. The Fault Costs shall be due and payable immediately by you to us by way of Debit Order or as otherwise determined by us and shall be included in your next invoice.',
			},
			{
				id: 17.9,
				item: 'We shall be entitled, in our sole discretion, to charge you a Call-Out Fee if a fault, defect or damage is reported by you and we attend at the Premises to investigate, and it transpires that the Plant is performing as reasonably expected.',
			},
		],
	},
	{
		id: 18,
		header: 'Service Provider’s Obligations',
		subHeader:
			'In addition to any other obligations, we have in terms of this Agreement:',
		items: [
			{
				id: 18.1,
				item: 'we shall ensure that the Plant remains compliant with regulatory requirements for Subscription Period unless the Plant fails to be or remain compliant due to your interference or fault;',
			},
			{
				id: 18.2,
				item: 'we shall endeavour to ensure that the quality of the Plant and its components are from official and, where applicable, accredited suppliers with market-related warranty periods in place.',
			},
		],
	},
	{
		id: 19,
		header: 'Shutdown',
		items: [
			{
				id: 19.1,
				item: 'In addition to our right to shut down the Plant for general maintenance as provided in clause 17, we are entitled, at our discretion, to shut down the Plant if we believe the Premises conditions or activities of persons on the Premises, which are not under our control, may interfere with the safe operation of the Plant.',
			},
			{
				id: 19.2,
				item: 'You shall cooperate with us so we can coordinate our respective efforts to restore the Premises conditions so as to not interfere with the safe operation of the Plant and to reduce, to the extent practicable, the duration of the shutdown.',
			},
			{
				id: 19.3,
				item: 'If a shutdown pursuant to clause 19.1 continues for 60 (Sixty) business days or longer, we may elect to terminate this Agreement.',
			},
		],
	},
	{
		id: 20,
		header: 'Relocation of the Plant or Disposal of the Premises',
		items: [
			{
				id: 20.1,
				item: 'Relocation',
				subItems: [
					{
						id: String('20.1.1'),
						subItem:
							'You may be entitled to request us to move the Plant (the “relocation request”) to another location on the Premises or to an entirely different premises, if that new premises is owned or leased by you (the “new Location”).',
					},
					{
						id: String('20.1.2'),
						subItem:
							'We may, in our sole discretion, approve or deny the relocation request.',
					},
					{
						id: String('20.1.3'),
						subItem:
							'If we approve the relocation request to the new Location, we (or our Contractor/s) will remove the Plant from the Premises and re-install the Plant at the new Location.',
					},
					{
						id: String('20.1.4'),
						subItem:
							'You will be liable for all the costs related to the removal and reinstallation of the Plant in terms of this clause 20.',
					},
					{
						id: String('20.1.5'),
						subItem:
							'Once the Plant has been reinstalled at the new Location, the Parties agree that this Agreement shall be amended so that all references to “Premises” in this Agreement shall refer to the new Location.',
					},
					{
						id: String('20.1.6'),
						subItem:
							'If we deny the relocation request to the new Location, this Agreement shall terminate as if you give notice to terminate in terms of clause 23.',
					},
				],
			},
			{
				id: 20.2,
				item: 'Sale of the Premises',
				subItems: [
					{
						id: String('20.2.1'),
						subItem:
							'If you intend to sell the Premises, you must notify us of such intention in writing.',
					},
					{
						id: String('20.2.2'),
						subItem:
							'You shall notify any potential purchaser (“Purchaser”) of the Premises:',
						subTexts: [
							{
								id: String('20.2.2.1'),
								text: 'that the Plant belongs to us and in no way accedes to the Premises;',
							},
							{
								id: String('20.2.2.2'),
								text: 'as a result of clause 20.2.2.1 the Plant does not form part of the purchase price of the Premises; and',
							},
							{
								id: String('20.2.2.3'),
								text: 'when the Purchaser takes possession of the Premises, you will deliver the Plant to the Purchaser on our behalf and the Purchaser will accept delivery with the intention to hold the Plant on our behalf, in our capacity as owner thereof. We will require and you must deliver to us, a copy of the notification which must be satisfactory to us, and which must be made a condition of the sale of the Premises.',
							},
						],
					},
					{
						id: String('20.2.3'),
						subItem:
							'If the Purchaser of the Premises intends on taking cession of this Agreement, then such cession shall be subject to the Service Provider’s approval and the Purchaser shall be required to agree to the terms and conditions of this Agreement.',
					},
					{
						id: String('20.2.4'),
						subItem:
							'A sale of the Premises pursuant to 20.2.3 shall be subject to the condition that this Agreement is ceded from you to the Purchaser, as consented to by us in writing in our sole and absolute discretion and subject to any credit checks, verifications and/ or other requirements we may have.',
					},
					{
						id: String('20.2.5'),
						subItem:
							'If we reject the application by the Purchaser for the cession of this Agreement, then the Plant shall be removed and the Agreement shall terminate as if you had given notice to terminate in terms of clause 23 below and the relevant fees and costs for early termination shall apply.',
					},
				],
			},
			{
				id: 20.3,
				item: 'Termination of the lease of the Premises',
				subItems: [
					{
						id: String('20.3.1'),
						subItem:
							'If you or the Homeowner intends to terminate any rights of occupation you have in respect of the Premises, you shall notify us of such intention in writing and the Plant shall be removed, and this Agreement shall terminate as if it was terminated in terms of clause 23 below.',
					},
					{
						id: String('20.3.2'),
						subItem:
							'The above notwithstanding, you may make a relocation request to the Service Provider in terms of clause 20.1.',
					},
					{
						id: String('20.3.3'),
						subItem:
							'You may provide the Homeowner with our contact details in order to explore other alternatives which may, in our sole discretion, be available on the termination of your lease.',
					},
				],
			},
		],
	},
	{
		id: 21,
		header: 'Net Metering',
		items: [
			{
				id: 21.1,
				item: 'For purposes of this clause "Net Metering Arrangement" means an arrangement with the Utility (or other such body or company that has rights to purchase the excess power) whereby Solar Output in excess of the Subscribers consumption requirements, if any, may be fed back to the relevant Utility (or alternative body) distribution Plant and set off by the Utility or alternative body against the electricity supplied by the Utility for a relevant billing cycle.',
			},
			{
				id: 21.2,
				item: 'You agree that we shall be entitled to sell any Energy generated by the Plant in excess of your Consumed Energy (“Excess Energy”) to a third party, to the extent permitted by law. This includes, without being limited to, via Net Metering Arrangement.',
			},
			{
				id: 21.3,
				item: 'You agree to use all reasonable efforts to assist with the execution of any necessary agreements with third parties as may be required, including inter alia assigning your right against the Utility for receiving any credits for your benefit (for example via a Net Metering Arrangement), and use all efforts to enable a direct payment for our benefit.',
			},
			{
				id: 21.4,
				item: 'You warrant that you will not do anything (including switching off electrical breakers) that will interfere with the uninterrupted feed into the electrical grid of the total amount of electrical energy generated, or potentially generated, by the Plant.',
			},
			{
				id: 21.5,
				item: 'We shall be entitled to enhance the Plant to receive the benefits of a Net Metering Arrangement. This includes, inter alia, the installation of necessary cables and equipment on the Premises, and the execution of any necessary agreements with third parties (including with a Utility).',
			},
			{
				id: 21.6,
				item: 'We will be liable for any costs incurred in selling any Excess Energy to a third-party.',
			},
			{
				id: 21.7,
				item: 'You hereby agree that where your consumption for any monthly period (as measured against the same monthly period in the previous year) is, in our reasonable opinion, excessive, you agree and we reserve the right to charge you for the excess consumption on the basis of our excessive consumption tariffs.',
			},
		],
	},
	{
		id: 22,
		header: 'Environmental Benefits',
		subHeader:
			'All renewable energy credits, carbon off-set credits, utility rebates or credit, incentive or any other similar benefit received as a result of the Plant (whether existing now of in the future) shall be for our benefit as owner of the Plant. You hereby agree that you shall fully co-operate with us to enable this right, which may include the completion and lodging of various forms, so that we may claim any renewable energy credits, rebates, carbon offset credits or any other benefits from the Plant.',
		items: [],
	},
	{
		id: 23,
		header: 'Termination by the Subscriber',
		items: [
			{
				id: 23.1,
				item: 'Termination on notice:',
				subItems: [
					{
						id: '',
						subItem:
							'You may terminate this Agreement on 20 (twenty) days written notice to us in terms of the CPA; provided that the terms and conditions attaching to such termination shall be subject to clause 24 below.',
					},
				],
			},
			{
				id: 23.2,
				item: 'Purchase of the Plant:',
				subItems: [
					{
						id: '',
						subItem:
							'You are entitled, at any time, to make an offer to purchase the Plant from us. To the extent that your purchase of the Plant is concluded and the purchase price therefor received by us, this Agreement shall terminate subject to your payment of any amounts owing and accrued as at the date of termination. The terms of the purchase shall be subject to clause 25 below.',
					},
				],
			},
			{
				id: 23.3,
				item: 'Termination due to breach by the Service Provider:',
				subItems: [
					{
						id: '',
						subItem:
							'You may terminate this Agreement on written notice to us in the event that we are found to have materially breached of this Agreement and failed to rectify such breach, provided that the terms and conditions attaching to such termination shall be subject to clause 28.2 below.',
					},
				],
			},
		],
	},
	{
		id: 24,
		header: 'Termination in terms of the CPA',
		items: [
			{
				id: '',
				item: 'Upon termination of this Agreement as contemplated in clause 23.1 above, you agree that you shall be liable to us for:',
			},
			{
				id: 24.1,
				item: 'any arrears in respect of unpaid Payment Obligations ;',
			},
			{
				id: 24.2,
				item: 'the Termination Fee as provided for in the Subscription Details, being a cancellation fee based on the remaining term of the Subscription Period and  having been calculated with due regard to reasonableness requirements of the CPA; and',
			},
			{
				id: 24.3,
				item: 'the Removal Fee as provided for in the Subscription Details,',
			},
			{
				id: '',
				item: 'which shall be paid into the Glint Payment Account within 5 (five) working days of the termination date and which we may deduct via the Debit Order.',
			},
		],
	},
	{
		id: 25,
		header: 'Election to Purchase',
		items: [
			{
				id: 25.1,
				item: 'You are entitled to purchase the Plant at any time after the Scheduled Subscription Period as detailed in the Subscription Details, subject to the following conditions:',
				subItems: [
					{
						id: String('25.1.1'),
						subItem:
							'you must provide us with one calendar months written notice requesting to purchase the Plant;',
					},
					{
						id: String('25.1.2'),
						subItem:
							'we will quote you the purchase price (“Purchase Price”) for the Plant based on the Fair Market Value thereof plus the amount of any Payment Obligations which you owe us at the time the requesting notice is received (“Purchase Invoice”);',
					},
					{
						id: String('25.1.3'),
						subItem:
							'the Purchase Invoice shall be valid for a period of 5 (five) days from the date that you receive it;',
					},
					{
						id: String('25.1.4'),
						subItem:
							'you acknowledge that purchase of the Plant in terms of clause 25 specifically excludes the purchase (and transfer of ownership) of the Software or hardware related to the Software, which shall remain our property (if owned by us) or if used under licence, remain under licence to us;',
					},
					{
						id: String('25.1.5'),
						subItem:
							'transfer of ownership of the Plant (subject to clause 25.1.4) pursuant to this clause 25 will be on a voetstoots (“as is”) basis and shall exclude all warranties, express or implied, regarding the state or condition of or any matter relating to the Plant;',
					},
					{
						id: String('25.1.6'),
						subItem:
							'there is no impediment in law which would debar us from transferring ownership of the Plant to you;',
					},
					{
						id: String('25.1.7'),
						subItem:
							'payment of the Purchase Price shall be made in South African Rands, without set-off or deduction into our bank account within 5 (five) working days of receipt of the Purchase Invoice from us in respect of the purchase of the Plant;',
					},
					{
						id: String('25.1.8'),
						subItem:
							'delivery of the Plant shall be deemed to have taken place upon receipt by us of payment of the Purchase Price at which time you agree that your intention will change so as to hold the Plant at the Premises for your own account and as owner thereof, such that ownership in the Plant transfers from the then owner of the Plant to you by constructive delivery in the form of constitutum possessorium.',
					},
					{
						id: String('25.1.9'),
						subItem:
							'Ownership of and all risk in and all benefit of the Plant shall transfer to you upon delivery pursuant to clause 25.1.8;',
					},
					{
						id: String('25.1.10'),
						subItem:
							'to the extent possible, we will transfer any remaining manufacturer warranties on the Plant or part thereof to you;',
					},
					{
						id: String('25.1.11'),
						subItem:
							'you shall hold us harmless and indemnify us against any taxes leviable or other costs incurred by us in respect of the transfer of ownership of the Plant.',
					},
				],
			},
			{
				id: 25.2,
				item: 'You may request us to maintain and service the Plant beyond termination of this Agreement, in terms of a separate maintenance agreement offered by us.',
			},
		],
	},
	{
		id: 26,
		header: 'Termination by the Service Provider',
		items: [
			{
				id: '',
				item: 'We may terminate this Agreement in the event of a breach by you pursuant to the provisions of clause 28.',
			},
		],
	},
	{
		id: 27,
		header: 'Consensual Termination',
		items: [
			{
				id: 27.1,
				item: 'The Parties may agree to terminate this Agreement in the event that the national electrical Utility is deactivated or interrupted for a period of 180 (one hundred and eighty) consecutive days or longer.',
			},
			{
				id: 27.2,
				item: 'If either Party terminates this Agreement in terms of clause 27.1 then the termination conditions of clause 24 shall apply.',
			},
		],
	},
	{
		id: 28,
		header: 'Breach',
		items: [
			{
				id: 28.1,
				item: 'Default by the Subscriber',
				subItems: [
					{
						id: String('28.1.1'),
						subItem:
							'You will be in default of this Agreement if you:',
						subTexts: [
							{
								id: String('28.1.1.1'),
								text: 'fail to satisfy any Payment Obligation due in terms of this Agreement on due date thereof;',
							},
							{
								id: String('28.1.1.2'),
								text: 'omit to do or do any act or thing which may prejudice our rights to the Plant and in terms of this Agreement;',
							},
							{
								id: String('28.1.1.3'),
								text: 'cause us to suffer any loss or damage in relation to the Plant;',
							},
							{
								id: String('28.1.1.4'),
								text: 'make or have made misrepresentations to us or any representation or warranty in connection with this Agreement or any matter relevant thereto that is found to be incorrect, untrue, or misleading;',
							},
							{
								id: String('28.1.1.5'),
								text: 'commit any other breach of your obligations in terms of this Agreement which breach shall be deemed to be a material breach;',
							},
							{
								id: String('28.1.1.6'),
								text: 'enter into or attempt to enter into a compromise/or debt arrangement with all of your creditors or any of them;',
							},
							{
								id: String('28.1.1.7'),
								text: 'allow any judgment entered against you to remain unsatisfied for a period of 10 (ten) days from the date of such judgment being granted or failing to obtain a rescission of such judgment within a period of 14 (fourteen) days from the date of such judgment being granted;',
							},
							{
								id: String('28.1.1.8'),
								text: 'are provisionally or finally sequestrated;',
							},
							{
								id: String('28.1.1.9'),
								text: 'commit any act referred to as an act of insolvency in the Insolvency Act;',
							},
							{
								id: String('28.1.1.10'),
								text: 'have any attachments levied in respect of your property or assets.',
							},
						],
					},
					{
						id: String('28.1.2'),
						subItem:
							'We will notify you of your breach in writing and in such notice grant you 14 (fourteen) days (or such longer period as may be required in law) within which you may remedy the default, if such default is capable of remedy (the “Remedy Period”).',
					},
					{
						id: String('28.1.2'),
						subItem:
							'If you do not remedy the default within the Remedy Period, or such default is incapable of remedy, then we shall be entitled to immediately terminate the Agreement, and without prejudice to any other rights we may have, including, without limitation, the right to claim damages, we shall be entitled to:',
						subTexts: [
							{
								id: String('28.1.2.1'),
								text: 'payment of all Payment Obligations in arrears or otherwise owing by you to us as at the date of termination; and',
							},
							{
								id: String('28.1.2.2'),
								text: 'payment of the Removal Fee; and',
							},
							{
								id: String('28.1.2.3'),
								text: 'payment of the Termination Fee as liquidated damages for your breach; and',
							},
							{
								id: String('28.1.2.4'),
								text: 'access the Premises immediately to remove and re-take possession of the Plant.',
							},
						],
					},
					{
						id: String('28.1.4'),
						subItem:
							'The provisions of clause 28.1 shall not be construed as precluding us from enforcing specific performance by you under this Agreement or any other legal action or remedy available to us.',
					},
					{
						id: String('28.1.5'),
						subItem:
							'All legal expenses incurred by us pursuant to a default in terms of this clause 28.1, including attorney and own client cost, collection commission, tracing fees, the cost of any valuation, dismantling, removal and storage of the Plant and all other expenses incurred in taking possession of the Plant, shall be borne by you.',
					},
				],
			},
			{
				id: 28.2,
				item: 'Default by the Service Provider',
				subItems: [
					{
						id: String('28.2.1'),
						subItem:
							'We will be deemed to be in default of this Agreement if we commit a material breach of this Agreement.',
					},
					{
						id: String('28.2.2'),
						subItem:
							'You will notify in writing of the breach in writing and in such notice grant us the Remedy Period within which it we may remedy the default.',
					},
					{
						id: String('28.2.3'),
						subItem:
							'If we do not remedy the default within the Remedy Period, then you shall be entitled to terminate the Agreement and shall only remain liable to us for any Payment Obligations due and payable as at the date of termination. You shall not be liable to make payment of the Removal Fee to us.',
					},
				],
			},
		],
	},
	{
		id: 29,
		header: 'Exclusions',
		subHeader:
			'We will not be in default or deemed to be in default of this Agreement, and shall accordingly not be liable to you for any damage or loss you may suffer as a result of any of the following:',
		items: [
			{
				id: 29.1,
				item: 'a power or voltage surge unless caused by us;',
			},
			{
				id: 29.2,
				item: 'the installation, removal, repair, modifications, improvements, revisions, additions etc of the Plant or any of its components by a person not authorised by us to perform such services;',
			},
			{
				id: 29.3,
				item: 'destruction or damage to the Plant or its ability to produce Energy safely, which is not directly caused by us; or',
			},
			{
				id: 29.4,
				item: 'any Grid Interruption, which is not directly caused by us.',
			},
		],
	},
	{
		id: 30,
		header: 'Certificate of Balance',
		items: [
			{
				id: '',
				item: 'A certificate signed by any of our directors, managers or the accountants (whose appointment will not as such need not be proven by us) as to any Payment Obligation and/ or amount owing by you to us in terms of this Agreement and/or any other matter relevant to this Agreement, shall be proof, on the face of it, of the matters therein and sufficient for the purpose for which the Certificate was issued and for all legal proceedings including summary judgment and/or provisional sentence and for the purpose of pleadings or any other trial action against you for the obligations and/ or amounts reflected in the Certificate.',
			},
		],
	},
	{
		id: 31,
		header: 'Restoration of Premises upon Removal of the Plant',
		items: [
			{
				id: 31.1,
				item: 'You are aware and accept that there may be various effects on the Premises as a result of the installation and removal of the Plant. These may include, but are not limited to:',
				subItems: [
					{
						id: String('31.1.1'),
						subItem:
							'holes in the roof or on the structure on which the Plant was installed;',
					},
					{
						id: String('31.1.2'),
						subItem:
							'discolouration of the roof or on the structure on which the Plant was installed, as well as the walls below the roof/structure on which the Plant was installed; and',
					},
					{
						id: String('31.1.3'),
						subItem:
							'various other minor resultant changes from the installation or de-installation.',
					},
				],
			},
			{
				id: 31.2,
				item: 'If it is reasonably required, and subject to required access being granted, we will restore the roof or the structure on which the Plant was installed to a level that is reasonably similar to its condition prior to installation, limited to:',
				subItems: [
					{
						id: String('31.2.1'),
						subItem: 'the filling of drilled holes;',
					},
					{
						id: String('31.2.2'),
						subItem:
							'clearing of removal debris occasioned by the removal.',
					},
				],
			},
			{
				id: 31.3,
				item: 'You agree that we shall not be liable to repaint your roof or structure or perform any unreasonable repairs on removal.',
			},
			{
				id: 31.4,
				item: 'If there is a dispute between the Parties as to the reasonableness of the restoration, the dispute shall be determined in terms of clause 42.',
			},
			{
				id: 31.5,
				item: 'You understand and agree that affects to the Premises as a result of removal of the Plant does not impute or confer any rights of accession to the Premises.',
			},
		],
	},
	{
		id: 32,
		header: 'Indemnity by the Subscriber',
		items: [
			{
				id: 32.1,
				item: `The Subscriber indemnifies, defends and holds harmless the Service Provider, its shareholders, directors, officers, employees and representatives and its affiliates, agents, contractors or licensees and their respective directors, officers and employees (herein referred to as the "Service Provider Parties"), from and against all claims made against or suffered by any of the Service Provider Parties for any loss of or damage to property or death or injury to persons (except for workers' compensation claims), resulting from any negligent act or omission of the Subscriber or any person for whom the Subscriber is responsible (and where the Subscriber is a corporate entity, any of the Subscribers' shareholders, directors, officers, employees and representatives and its affiliates, agents, contractors or licensees and their respective directors, officers and employees) that arises out of or is in any manner connected with the performance of this Agreement.`,
			},
			{
				id: 32.2,
				item: 'The Subscriber indemnifies, defends and holds harmless the Service Provider Parties from and against all claims, damages, penalties, fines, costs, liabilities, losses, expenses and fees made against or suffered by any of the Service Provider Parties resulting from, arising out of, or relating to:',
				subItems: [
					{
						id: String('32.2.1'),
						subItem:
							'any breach of or non-compliance by the Subscriber with any of its obligations contained in this Agreement;',
					},
					{
						id: String('32.2.2'),
						subItem:
							'any breach of or non-compliance by the Subscriber with any laws, including, without limitation, non-compliance by the Subscriber with the tariff/meter requirements imposed by any Utility or other Regulatory Authority, or any election by the Subscriber not to change its tariff/meter in line with any such requirements;',
					},
					{
						id: String('32.2.3'),
						subItem:
							'any acts or omissions of any persons having access to, using, visiting or otherwise on or at the Premises (other than Glint, including its Contractors);',
					},
					{
						id: String('32.2.4'),
						subItem:
							'any loss or liability which we may incur as a result of any consent to disclosure of information on your behalf being found to be invalid for any reason whatsoever.',
					},
				],
			},
			{
				id: 32.3,
				item: 'All acts and/or omissions of any person having access to, using, visiting or otherwise on or at the Premises, other than the Service Provider (including its Contractors), shall for all purposes under this Agreement be attributed to the Subscriber as if such acts and/or omissions were acts and/or omissions of the Subscriber.',
			},
			{
				id: 32.3,
				item: 'The indemnities in this clause 32 are separate and independent obligations and continue after termination of this Agreement.',
			},
		],
	},
	{
		id: 33,
		header: 'Indemnity by the Service Provider',
		items: [
			{
				id: '',
				item: 'We indemnify you from and against all claims made against or suffered by you for liability for any death, injury or damage to any person or property proven in a court of law to have resulted from our wilful or grossly negligent act or omission.',
			},
		],
	},
	{
		id: 34,
		header: 'Reciprocal Warranties',
		items: [
			{
				id: 34.1,
				item: 'Each Party warrants in favour of the other, that:',
				subItems: [
					{
						id: String('34.1.1'),
						subItem:
							'it has the necessary capacity and authority to enter into this Agreement;',
					},
					{
						id: String('34.1.2'),
						subItem:
							'this Agreement shall be valid, binding and enforceable once it has been signed by both Parties;',
					},
					{
						id: String('34.1.3'),
						subItem:
							'the executing of this Agreement and its performance in terms hereof shall not, to the knowledge of the Party:',
						subTexts: [
							{
								id: String('34.1.3.1'),
								text: 'contravene any governing documents of the Party, if the Party is a juristic entity; and',
							},
							{
								id: String('34.1.3.2'),
								text: 'contravene any other agreement, obligation or undertaking to which it is already bound;',
							},
						],
					},
					{
						id: String('34.1.4'),
						subItem:
							'it is not aware of any fact or circumstance that may impair its ability to perform its obligations in terms of this Agreement; and',
					},
					{
						id: String('34.1.5'),
						subItem:
							'other than those warranties and representations contained in this Agreement, it is not relying on any warranties or representations made by the other Party or any other person.',
					},
				],
			},
			{
				id: 34.2,
				item: 'The Parties agree that all of the warranties contained in clause 34.1 above are separate and distinct and shall in no way be limited or restricted by any other warranties or clauses in this Agreement.',
			},
			{
				id: 34.3,
				item: 'The Parties agree that all of the warranties contained in clause 34.1 above are material and have induced the other into entering into this Agreement.',
			},
		],
	},
	{
		id: 35,
		header: 'Limitation of Liability',
		items: [
			{
				id: 35.1,
				item: 'Our liability to you in connection with this Agreement, however arising (whether by breach of contract or otherwise) shall be limited to the aggregate of the Subscription Fees paid by you to us in the immediately preceding 12 (twelve) months.',
			},
			{
				id: 35.2,
				item: 'You acknowledge that you shall have no claim against us whatsoever, if we breach any warranties or obligations in terms of this Agreement as a result and/ or arising out of any Change of Law that comes into force after the Parties have signed this Agreement, even if such law be applied retrospectively.',
			},
		],
	},
	{
		id: 36,
		header: 'Force Majeure',
		items: [
			{
				id: 36.1,
				item: 'A force majeure event shall be any unforeseeable circumstance that prevents either Party from fulfilling their obligations in terms of this Agreement and shall include but shall not be limited to acts of God, war, terrorism, riots, earthquakes, hurricanes, lightning, flood, fire, cyclones (or other natural disasters), epidemics or pandemics, national or industrial strikes, a change in law, governmental intervention and power surges that are not the fault of either Party.',
			},
			{
				id: 36.2,
				item: 'The Parties shall:',
				subItems: [
					{
						id: String('36.2.1'),
						subItem:
							'to the extent possible, comply with and perform all their obligations in terms of this Agreement;',
					},
					{
						id: String('36.2.2'),
						subItem:
							'use all commercially reasonable endeavours to prevent, minimise and mitigate the effect of the delay caused by any force majeure event; and',
					},
					{
						id: String('36.2.3'),
						subItem:
							'to the extent possible, ensure that the normal performance of this Agreement shall resume after the force majeure event has come to an end.',
					},
				],
			},
			{
				id: 36.3,
				item: 'Clause 36.2 notwithstanding, a Party who cannot perform its obligations in terms of this Agreement due to a force majeure event (the “affected Party”) shall be temporarily excused from performing in terms hereof to the extent that the impossibility is caused by the force majeure event.',
			},
			{
				id: 36.4,
				item: 'The affected Party shall not be excused from any liability for payment that accrued prior to the force majeure event, including, without limitation, any arrears on Payment Obligations.',
			},
			{
				id: 36.5,
				item: 'If a force majeure event extends beyond a period of period of more than 90 (ninety) consecutive days then either Party may terminate this Agreement on notice to the other Party, and you may be held liable for:',
				subItems: [
					{
						id: String('36.5.1'),
						subItem:
							'all outstanding Payment Obligations up to the date on which the force majeure event began; and',
					},
					{
						id: String('36.5.2'),
						subItem: '50% (half) of the Removal Fee.',
					},
				],
			},
		],
	},
	{
		id: 37,
		header: 'Change in Law',
		items: [
			{
				id: 37.1,
				item: 'This Agreement is based on the Applicable Law as at the Signature Date; however, the Parties understand and acknowledge that during the Subscription Period there may be a change in Applicable Law by way of:',
				subItems: [
					{
						id: String('37.1.1'),
						subItem:
							'the adoption, promulgation, amendment, change in interpretation, modification, or repeal by a Responsible Authority of any law; and/or',
					},
					{
						id: String('37.1.2'),
						subItem:
							'the imposition by a Responsible Authority of any condition in connection with the issuance, renewal or modification of any consents, permits, clearances, authorisations, approvals, rulings, exemptions, registrations filings, decisions and/or licences, required to be issued by or made with any Responsible Authority in connection with the performance of any of the Services and/or the operation and maintenance of the Plant,',
					},
				],
			},
			{
				id: 37.2,
				item: 'If either Party claims a Change in Law has occurred, it must advise the other Party in writing of the: (i) date of such Change in Law; (ii) nature and expected effect of such Change in Law; and (iii) actions to be taken in order to comply with requirements of this clause 37.',
			},
			{
				id: 37.3,
				item: 'The Parties must use all reasonable endeavours to: (i) prevent and reduce to a minimum and mitigate the effect of any delay occasioned by any Change in Law; and (ii) to ensure normal performance of this Agreement despite the Change in Law and must otherwise perform their obligations as herein agreed to the maximum of their ability.',
			},
			{
				id: 37.4,
				item: 'The Subscriber agrees to an adjustment in the then applicable Subscription Fee as shall place the Service Provider in the same overall economic position it would have been but for such Change in Law.',
			},
			{
				id: 37.5,
				item: 'Neither Party shall be liable for any inability to perform any of its obligations under this Agreement and will not be in default as a result of such non-performance in the event of a Change in Law affecting it accordingly.',
			},
			{
				id: 37.6,
				item: 'Where there is a Change in Law that prevents either Party from performing any of its obligations in terms of this Agreement, we may, on written notice to you, amend the contractual terms to reasonably allow for the consequences of the Change in Law.',
			},
		],
	},
	{
		id: 38,
		header: 'Insurance',
		items: [
			{
				id: 38.1,
				item: 'From the Subscription Start Date and for the duration of the Subscription Period each of the Parties must maintain the required insurances to protect their particular interests in the Plant and/ or the Services and/ or the Premises, as the case may be.',
			},
			{
				id: 38.2,
				item: 'Where you are the insuring party as Homeowner and /or occupier of the Premises on which our Plant is installed and operated, you must (or procure that the Homeowner and/ or occupier, as the case may be, must):',
				subItems: [
					{
						id: String('38.2.1'),
						subItem:
							'ensure and procure that our ownership interest in the Plant is noted on the relevant insurance policy at all relevant times;',
					},
					{
						id: String('38.2.2'),
						subItem:
							'immediately inform us in writing of any changes to, or cancellation of, the insurance policy; and',
					},
					{
						id: String('38.2.3'),
						subItem:
							'procure that your insurer or broker signs confirmation as required by us.',
					},
				],
			},
			{
				id: 38.3,
				item: 'The Subscriber shall advise the Service Provider immediately of any loss of or damage to the Plant or any of its components and the Subscriber shall be liable for any excess or shortfall (in the insurance payment) accrued by the Service Provider to the extent that the loss of or damage to the Plant arises as a result of any act or omission of the Subscriber or any other person having access to, using, visiting or otherwise on or at the Premises, other than the Service Provider (including its Contractors), for whose acts and/ or omissions the Subscriber shall be liable.',
			},
		],
	},
	{
		id: 39,
		header: 'Credit Reference Consent',
		items: [
			{
				id: '',
				item: 'The Subscriber consents to the Service Provider: ',
			},
			{
				id: 39.1,
				item: `making enquiries and searches, exchanging information, including personal information about the Subscriber's credit record, creditworthiness, the operation, implementation, performance and/ or enforcement of this Agreement with any credit bureau or credit reference agency and any other party when assessing the Subscriber's application for the use of the Plant and in respect of the operation, implementation, performance and enforcement of this Agreement during the Term;`,
			},
			{
				id: 39.2,
				item: `providing credit reference agencies with regular updates regarding how the Subscriber manages its account, including the Subscriber's failure to meet agreed terms and conditions and any information concerning the Subscriber’s creditworthiness;`,
			},
			{
				id: 39.3,
				item: `sharing required information to any person who, in its opinion, needs it to carry out any of the Service Provider's rights or duties in terms of this Agreement or any law pertaining to the Services provided to the Subscriber by the Service Provider; and`,
			},
			{
				id: 39.4,
				item: `monitoring payment by researching the Subscriber’s record at any credit reference agency/ies and using new information and data obtained from such credit reference agency/ies for the duration of this Agreement.`,
			},
		],
	},
	{
		id: 40,
		header: 'Cession and Assignment',
		items: [
			{
				id: 40.1,
				item: 'We may without notice to you, cede delegate, transfer, sell, pledge and/or assign all or any of our rights and/or all or any of our obligations under this Agreement, including our right of ownership in the Plant and/ or any part thereof either absolutely or as security to any other person or persons (notwithstanding that a cession and/or delegation to more than one person may result in a splitting of claims against you, which splitting of claims you hereby agree to).',
			},
			{
				id: 40.2,
				item: 'If such cession, delegation, transfer, sale, pledge or hypothecation takes place, you hereby agree to hold the Plant on behalf of the relevant Transferee as owner thereof and you shall if required make any or all payments under this Agreement directly to such Transferee.',
			},
			{
				id: 40.3,
				item: 'Unless the context otherwise indicates, any reference to the Service Provider shall be deemed to include reference to its Transferee.',
			},
			{
				id: 40.4,
				item: 'You shall not, without our prior written permission:',
				subItems: [
					{
						id: String('40.4.1'),
						subItem:
							'cede or assign any of its rights and/or delegate any of your obligations in and to and arising from this Agreement;',
					},
					{
						id: String('40.4.2'),
						subItem:
							'encumber the Plant or any part thereof assets by way of mortgage, pledge, lien, charge, hypothecation or security interest or any other agreement or arrangement having the effect of conferring security on the assets; and',
					},
					{
						id: String('40.4.3'),
						subItem:
							'whether voluntary or involuntarily, sell, transfer or otherwise dispose of the Premises and/ or the Plant.',
					},
				],
			},
		],
	},
	{
		id: 41,
		header: 'The Protection of Personal Information',
		items: [
			{
				id: 41.1,
				item: 'The Service Provider respects the privacy of the Subscribers personal information.',
			},
			{
				id: 41.2,
				item: 'The Service Provider will process personal information of the Subscriber only for purposes related to this Agreement and the provision of the Services as more fully explained in the Service Providers Privacy Policy which can be found at www.glint.co.za and which must be read together with this Agreement.',
			},
			{
				id: 41.3,
				item: 'The Subscriber hereby expressly consents to the Service Provider:',
				subItems: [
					{
						id: String('41.3.1'),
						subItem:
							'sharing its Personal Information to any person, subsidiary, holding company or associated company or other company who is engaged in the Service Providers business or who is acting on the Service Providers behalf for the above purposes;',
					},
					{
						id: String('41.3.2'),
						subItem:
							'sharing the Subscribers Personal Information with any person who provides services to the Service Provider or acts as the Service Provider’s agent for the above purposes or to whom the Service Provider has transferred or proposes to transfer any of its rights and duties in respect of the Subscribers relationship with it. The Service Provider requires persons who provide services to it to agree to the Service Provider’s privacy policies and comply with POPIA if they need access to any Personal Information to carry out their services.',
					},
					{
						id: String('41.3.3'),
						subItem: '',
					},
				],
			},
			{
				id: 41.4,
				item: 'The Subscriber specifically agrees that the Service Provider may, until the Subscriber otherwise directs Share the Subscribers Personal Information with registered credit bureau and other verification agencies pursuant to clause 39.',
			},
			{
				id: 41.5,
				item: 'The Service Provider may and the Subscriber expressly consents to the collecting and processing of the Subscriber’s personal information by the Service Provider, to: open, administer and operate the Subscriber’s account; provide any combination of services, analysis, advice or intermediary service linked to the Subscriber’s account, to the Subscriber; monitor and analyse the conduct on the Subscriber’s account for credit, fraud, compliance and other risk- related purposes; carry out statistical and other analyses to identify potential markets and trends; and develop new products and services.',
			},
			{
				id: 41.6,
				item: 'The Subscriber hereby expressly consents that the Service Provider may: process and further process the Subscriber’s personal information within the Service Provider’s corporate group for the above purposes; disclose the Subscriber’s personal information to any person who provides services to the Service Provider or acts as the Service Provider’s agent or to whom the Service Provider has transferred or proposes to transfer any of its rights and duties in respect of the Subscriber’s account (some of these persons may be located in countries outside of the Republic of South Africa), this Agreement, the Plant or any part thereof; and share the Subscriber’s personal information with the Subscriber’s service providers and Contractors, as necessary. The Service Provider agrees to request all such persons who provide services to the Service Provider to agree to its privacy policies if such persons need access to any personal information to carry out their services.',
			},
			{
				id: 41.7,
				item: 'The Subscriber further agrees that the Service Provider may disclose your personal information:',
				subItems: [
					{
						id: String('41.7.1'),
						subItem:
							'if we implement the provisions of clause 40 , in which case we may disclose your personal information to such Transferee;',
					},
					{
						id: String('41.7.2'),
						subItem:
							'if we sell or buy any business or assets, in which case we may disclose your personal information to the prospective seller or buyer of such business or assets;',
					},
					{
						id: String('41.7.3'),
						subItem:
							'if all or substantially all of its assets are acquired by a third party, in which case personal information held by it about its Subscribers will be one of the transferred assets;',
					},
					{
						id: String('41.7.4'),
						subItem:
							'if we are under a duty to disclose or share your personal information in order to comply with any legal or regulatory obligation or request; and/or',
					},
					{
						id: String('41.7.5'),
						subItem:
							'to enforce this Agreement and other agreements we have with you or to investigate potential breaches; report defaulters to any credit bureau; or',
					},
					{
						id: String('41.7.6'),
						subItem:
							'for the purpose of publishing statistics relating to the use of the Services or our website application, in which case all information will be aggregated and made anonymous.',
					},
				],
			},
			{
				id: 41.8,
				item: 'The Subscriber acknowledges that:',
				subItems: [
					{
						id: String('41.8.1'),
						subItem:
							'the Service Provider will at all times remain responsible for determining the purpose of and means for processing the Subscriber’s personal information;',
					},
					{
						id: String('41.8.2'),
						subItem:
							'the Service Provider may be required by various laws to collect some of the Subscriber’s personal information;',
					},
					{
						id: String('41.8.3'),
						subItem:
							'without the Subscriber’s personal information, the Service Provider may be unable to open or continue to offer services to the Subscriber;',
					},
					{
						id: String('41.8.4'),
						subItem:
							'the Service Provider shall be entitled to rely on the authorisations given by you in this clause for purposes of compliance with all applicable data privacy laws. and that you shall have no claim against the Service Provider or its Transferees for the relevant, collection, use, processing and/ or exchange of the information or the outcomes produced therefrom; and',
					},
					{
						id: String('41.8.5'),
						subItem:
							'the Subscriber is providing the Service Provider with the Subscriber’s personal information voluntarily.',
					},
				],
			},
		],
	},
	{
		id: 42,
		header: 'Dispute Resolution',
		items: [
			{
				id: 42.1,
				item: 'If a dispute arises between the Parties in respect of this Agreement (the “dispute”), either Party may give the other written notice of the dispute (the “dispute notice”), which dispute notice must contain the full particulars of the dispute.',
			},
			{
				id: 42.2,
				item: 'The Parties agree to try and resolve the dispute initially by way of negotiation. The Parties agree that they shall, in an attempt to resolve the dispute, hold a meeting within 7 (seven) working days of the issuing of the dispute notice at a place and at a time suitable to both Parties.',
			},
			{
				id: 42.3,
				item: 'If the dispute cannot be resolved in terms of clause 42.2 above, either Party may refer the matter for expert determination by an independent expert as per clauses 42.4 and 42.5 .',
			},
			{
				id: 42.4,
				item: 'Either Party may, within 5 (five) working days of the meeting held in terms of clause 42.2 give the other written notice that they wish to refer the matter for determination by an independent expert (the “Expert”).',
			},
			{
				id: 42.5,
				item: 'The Expert shall be independent, and:',
				subItems: [
					{
						id: String('42.5.1'),
						subItem:
							'if the dispute is of a legal/contractual nature, a practising attorney or an Advocate of the Johannesburg Bar Associations, with more than 5 (five) years of experience;',
					},
					{
						id: String('42.5.2'),
						subItem:
							'if the dispute is of a financial nature a Chartered or Professional Accountant, with more than 5 (five) years of experience;',
					},
					{
						id: String('42.5.3'),
						subItem:
							'if the dispute is of a technical nature an engineer, with more than 5 (five) years of experience in the solar energy field appointed by both Parties by consent,',
					},
					{
						id: String('42.5.4'),
						subItem:
							'failing which the Service Provider’s auditors shall independently appoint the Expert to determine the dispute.',
					},
				],
			},
			{
				id: 42.6,
				item: 'The Expert shall provide the Parties with the:',
				subItems: [
					{
						id: String('42.6.1'),
						subItem: 'date on which the proceedings will commence;',
					},
					{
						id: String('42.6.2'),
						subItem:
							'the venue where the proceedings shall take place; and',
					},
					{
						id: String('42.6.3'),
						subItem:
							'the way in which the matter shall be presented by the Parties (whether in person or in writing).',
					},
				],
			},
			{
				id: 42.7,
				item: 'The Expert shall not be an arbitrator but shall determine the matter and decide the matter as an expert in the field.',
			},
			{
				id: 42.8,
				item: 'The losing Party shall be liable to make payment of the Expert’s fees and any costs incurred by the proceedings, which the Expert shall include in his/her determination.',
			},
			{
				id: 42.9,
				item: 'The Parties agree that the determination made by the Expert (the “Finding”) shall be final and binding on them.',
			},
			{
				id: String('42.10'),
				item: 'The Parties agree that nothing herein contained shall prevent them from approaching a Court with jurisdiction for urgent relief or in respect of a liquidated claim.',
			},
			{
				id: String('42.11'),
				item: 'This clause 42 shall survive the Term of this Agreement and shall operate beyond the termination of this Agreement.',
			},
		],
	},
	{
		id: 43,
		header: 'Contact Details',
		items: [
			{
				id: 43.1,
				item: 'The Customer chooses, as the address for the serving of legal and any other notices in terms of this Agreement, its physical address set out on the Subscription Details (notice address).',
			},
			{
				id: 43.2,
				item: 'Any other notice or communication required or permitted to be given in respect of the provisions of this Agreement will be valid and effective if sent to the Customers notice address, or the email address or postal address provided in the Subscription Details, or any address advised in terms of clause 43.3 below.',
			},
			{
				id: 43.3,
				item: 'The Customer must give the Service Provider written notice to change its notice address, postal address, telephone number or email address. The change will be effective on the 10th (tenth) Business Day after receipt of the notice.',
			},
			{
				id: 43.4,
				item: 'Notices shall be delivered by hand or by email. Any notice delivered by hand will be deemed to have been received on the day of delivery; or by email will be deemed to have been received on the 1st (first) Business Day after the date it was sent.',
			},
			{
				id: 43.5,
				item: 'A written notice or communication which is received by the Customer will be an adequate written notice or communication to the Customer.',
			},
			{
				id: 43.6,
				item: 'The Service Provider chooses the physical address set out in the Subscription Details as the address at which all processes and notices arising out of or in connection with this Agreement, its breach or termination may validly be served upon or delivered to the Service Provider.',
			},
		],
	},
	{
		id: 44,
		header: 'Governing Law and Jurisdiction',
		items: [
			{
				id: 44.1,
				item: 'This Agreement shall in all matters be governed by and construed under the laws of the Republic of South Africa.',
			},
			{
				id: 44.2,
				item: 'Should any provision of this Agreement be or be retrospectively rendered unlawful, then that unlawful provision only shall be deemed to be modified to the extent and in the manner necessary to render it consistent with the enactment rendering it unlawful, or if such modification is impossible, be deemed to be severable from the remaining provisions hereof as if it was not included herein. The remaining provisions shall continue unaffected. In either such event, notwithstanding anything to the contrary contained in this Agreement, the Service Provider and the Customer shall have all the rights conferred upon them by the law rendering such provision unlawful.',
			},
			{
				id: 44.3,
				item: 'The Customer consents to the jurisdiction of any competent High Court in respect of any action to be instituted against the Customer by the Service Provider in terms of this Agreement, provided however, that nothing herein contained shall preclude the Service Provider from proceeding against the Customer in terms of this Agreement in any Court of competent jurisdiction.',
			},
		],
	},
	{
		id: 45,
		header: 'Waiver of Rights',
		items: [
			{
				id: '',
				item: `Any latitude or extension of time which may be allowed by the Service Provider to the Subscriber in respect of any payment or strict and punctual compliance and performance in terms of the provisions of this Agreement, or any other indulgence which may be extended by the Service Provider to the Subscriber, shall not prejudice any of the Service Provider's rights under this Agreement or operate as a waiver or novation of such right.`,
			},
		],
	},
	{
		id: 46,
		header: 'General',
		items: [
			{
				id: 46.1,
				item: 'This Agreement constitutes the entire and complete agreement between the Parties. No Agreement differing from the terms and conditions of this Agreement, shall be of any force or effect or create any estoppels, unless it is in writing and signed by both Parties to this Agreement.',
			},
			{
				id: 46.2,
				item: 'No alteration, addition, amendment, variation or suspension of any of the terms hereof, or any consensual cancellation of this Agreement shall be of any force or effect unless reduced to writing and signed by the Parties or their duly authorised representatives.',
			},
			{
				id: 46.3,
				item: 'This Agreement may be signed in counterparts, each of which shall be deemed an original.',
			},
			{
				id: 46.4,
				item: 'The Parties agree that, in order for this Agreement to be valid and enforceable, it does not need to be initialled or signed on every page.',
			},
			{
				id: 46.5,
				item: 'The rule of construction that an agreement shall be interpreted against the party responsible for the drafting or preparation of the agreement, shall not apply.',
			},
		],
	},
];
