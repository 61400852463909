import React from 'react';

// @mui
import {
	Grid,
	Typography,
	Button,
	Stack,
	IconButton,
	Snackbar,
	Alert,
} from '@mui/material';
// @icons
import { Close } from '@mui/icons-material';
// @logic
import { getPDF } from '../../../data-display/ViewFiles';

interface IPdfNotifyProps {
	setPdfSuccessOpen: React.Dispatch<React.SetStateAction<boolean>>;
	pdfSuccessOpen: boolean;
	pdfURL: string;
	pdfType?: string;
}

function PdfSuccessNotify({
	pdfSuccessOpen,
	setPdfSuccessOpen,
	pdfURL,
	pdfType,
}: IPdfNotifyProps) {
	const handleClose = (
		_event: React.SyntheticEvent | Event,
		reason?: string
	) => {
		if (reason === 'clickaway') {
			return;
		}
		setPdfSuccessOpen(false);
	};

	return (
		<Snackbar
			open={pdfSuccessOpen}
			onClose={handleClose}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
		>
			<Alert severity="success" variant="outlined" sx={{ width: '100%' }}>
				<Grid container spacing={1}>
					<Grid item xs={12} md={12}>
						<Typography>
							{pdfType === 'invoice'
								? 'Generated Invoice PDF, click below to view'
								: pdfType === 'ip'
								? 'Generated IP PDF, click below to view'
								: 'Generated EP PDF, click below to view'}
						</Typography>
					</Grid>
					<Grid item xs={12} md={12}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
						>
							<Button
								disabled={pdfURL === null}
								onClick={() => getPDF(pdfURL)}
								color="primary"
								variant="contained"
							>
								View PDF
							</Button>
							<IconButton
								size="small"
								aria-label="close"
								color="inherit"
								onClick={handleClose}
							>
								<Close fontSize="small" />
							</IconButton>
						</Stack>
					</Grid>
				</Grid>
			</Alert>
		</Snackbar>
	);
}

export default PdfSuccessNotify;
