"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CAMPAIGN_TYPE = exports.MAKRO_CAMPAIGNS = exports.campaignLogos = exports.CAMPAIGNS_UUIDS = exports.CAMPAIGNS = exports.campaignWebsites = exports.campaignColors = exports.campaignCodes = void 0;
// Isolate these to break cyclic imports leading to undefined errors at runtime
var branding_1 = require("../constants/branding");
var images_1 = require("../constants/images");
exports.campaignCodes = {
    avo: 'avo',
    betterbond: 'betterbond',
    bmwfinancialservices: 'bmwfinancialservices',
    captivepower: 'captivepower',
    blackfriday: 'solarblackfriday',
    'digital-kung-fu': 'ece83c77-d69c-4959-8cc2-132390731f78',
    flow: '7215ee99-b86c-4fdd-898a-369ebebf4908',
    clearscore: 'clearscore',
    fnb: 'fnb',
    glint: 'glint',
    investec: 'investec',
    'installer-pilot': 'installer-pilot',
    magnetoenergy: 'magnetoenergy',
    'makro-alberton': 'makro-alberton',
    'makro-online': 'makro-online',
    'makro-staff': 'makro-staff',
    'makro-retail': 'makro-retail',
    'mtn-staff': 'mtn-staff',
    mspd: 'mspd',
    'ktob-agent-1': '88c9e3c6-a401-46d6-a35a-4ff89ade4d94',
    'ktob-agent-2': 'fc2fec63-3c0f-4952-a605-5d0d4dadc13d',
    'ktob-agent-3': '5e9c1d4b-6c0f-475a-a0b7-4f5dd582d9c7',
    'ktob-agent-4': '08f3cee5-d7c4-41c3-afea-dc30b3364f67',
    'ktob-agent-5': '712828e1-ff16-4971-a66b-30be8bbcc366',
    'ktob-agent-6': 'aabd6f0a-eed3-4487-959f-215a9cde8d15',
    'ktob-agent-7': '0f2b52e7-bebd-49b0-9a13-5f3359cea794',
    nedbank: 'nedbank',
    'nedbank-campaign': '2aaf7f66-10b4-4bb1-8a31-793ffc5a0d4d',
    'nedbank-public': 'd9ab20ed-531e-4a5e-8216-3817c63942a6',
    'nedbank-mfc': '0f6045b1-bfe2-4282-9b4e-6e939e7e7cd0',
    nedbankmfc: 'nedbankmfc',
    ooba: 'ooba',
    oldmutual: 'ccdc34bd-eb7f-454e-8590-ac9a3622f851',
    'pathways-credit-union': 'a8028f26-7511-4264-9bc6-0b294d23f8bd',
    pnp: 'pnp',
    pnpstaff: 'pnpstaff',
    'plus-digital': '61477113-e468-42b4-b371-32d665a6245c',
    public: 'e7e4bb7f-a6d1-4d48-aaa4-623150ff4832',
    sasfin: 'sasfin',
    skyworthenergy: 'skyworthenergy',
    solarblackfriday: 'solarblackfriday',
    yoursolar: 'yoursolar',
    'cia-onboarding-form': 'cia-onboarding-form',
    'referral-factory': 'referral-factory',
};
exports.campaignColors = {
    public: '#ff585b',
    'nedbank-mfc': '#6ecd44',
    'nedbank-campaign': '#007500',
    'nedbank-public': '#5CFF5C',
    ooba: '#00b88a',
    'pathways-credit-union': '#0f325c',
    fnb: '#009999',
};
exports.campaignWebsites = {
    glint: 'https://www.glint.co.za/',
};
exports.CAMPAIGNS = Object.keys(exports.campaignCodes);
exports.CAMPAIGNS_UUIDS = Object.values(exports.campaignCodes);
exports.campaignLogos = {
    public: branding_1.brandingLogo,
    'nedbank-mfc': images_1.NedbankMFC,
    'nedbank-campaign': images_1.NedbankLogo,
    'nedbank-public': images_1.NedbankLogo,
    ooba: images_1.oobaLogo,
    'pathways-credit-union': images_1.pathwaysLogo,
};
exports.MAKRO_CAMPAIGNS = [
    'makro-retail',
    'makro-alberton',
    'makro-staff',
    'makro-online',
];
exports.CAMPAIGN_TYPE = ['regular', 'channel-partner'];
