var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/* eslint-disable class-methods-use-this */
import { useQuery, useMutation, useQueryClient, useQueries, } from 'react-query';
// @local
import { logger } from 'hohm-utilities';
import { validate as isValidUUID } from 'uuid';
var EntityQueries = /** @class */ (function () {
    function EntityQueries(api) {
        var _this = this;
        this.entityQueryKey = function (entity, id, query) { return [entity, id, JSON.stringify(query)]; };
        this.entitiesQueryKey = function (entity, query) { return [entity, JSON.stringify(query)]; };
        this.entityGetAll = function (entity, query) {
            return function () {
                return _this.api.getAll(entity, query);
            };
        };
        this.entityGet = function (entity, id, query) {
            return function () {
                if (id == null) {
                    return Promise.reject(new Error('Invalid id'));
                }
                return _this.api.get(entity, id, query);
            };
        };
        this.useGetEntity = function (entity, id, query) {
            return useQuery(_this.entityQueryKey(entity, id, query), _this.entityGet(entity, id, query), {
                enabled: !!id && _this.hasValidId(id),
            });
        };
        this.useGetEntityFunction = function (entity, options) {
            var _a = useMutation(function (queryParams) {
                return _this.api.get(entity, queryParams.id, queryParams.query);
            }, __assign({}, options)), getEntity = _a.mutate, rest = __rest(_a, ["mutate"]);
            return __assign({ getEntity: getEntity }, rest);
        };
        this.useGetEntities = function (entity, query, options) {
            return useQuery(_this.entitiesQueryKey(entity, query), _this.entityGetAll(entity, query), options);
        };
        this.useGetManyEntities = function (entityQueries) {
            return useQueries(entityQueries.map(function (entityQuery, index) {
                var _a;
                return ({
                    queryKey: [entityQuery.entity, index],
                    queryFn: _this.entityGetAll(entityQuery.entity, entityQuery.query),
                    keepPreviousData: (_a = entityQuery.options) === null || _a === void 0 ? void 0 : _a.keepPreviousData,
                });
            }));
        };
        this.useGetEntitiesFunction = function (entity, options) {
            var _a = useMutation(function (query) {
                return _this.api.getAll(entity, query);
            }, __assign({}, options)), getEntity = _a.mutate, rest = __rest(_a, ["mutate"]);
            return __assign({ getEntity: getEntity }, rest);
        };
        this.useUpdateEntity = function (entity, id, options, invalidate) {
            if (invalidate === void 0) { invalidate = []; }
            var queryClient = useQueryClient();
            var _a = useMutation(function (data) {
                var updateId = data.id || id;
                if (!updateId)
                    throw new Error("Invalid id '".concat(updateId, "' in useUpdateEntity for service ").concat(entity));
                return _this.api.update(entity, updateId, data);
            }, __assign({ onSuccess: function () {
                    queryClient.invalidateQueries({
                        queryKey: __spreadArray(__spreadArray([], invalidate, true), [entity], false),
                    });
                } }, options)), handleUpdateEntity = _a.mutate, rest = __rest(_a, ["mutate"]);
            return __assign({ handleUpdateEntity: handleUpdateEntity }, rest);
        };
        this.manualUpdate = function (_a) {
            var entity = _a.entity, data = _a.data, id = _a.id;
            return _this.api.update(entity, id, data);
        };
        this.useDeleteEntity = function (entity, options) {
            var queryClient = useQueryClient();
            var _a = useMutation(function (id) { return _this.api.remove(entity, id); }, __assign({ onSuccess: function () {
                    queryClient.invalidateQueries(entity);
                } }, options)), handleDeleteEntity = _a.mutate, rest = __rest(_a, ["mutate"]);
            return __assign({ handleDeleteEntity: handleDeleteEntity }, rest);
        };
        this.useCreateEntity = function (entity, options) {
            var queryClient = useQueryClient();
            var _a = useMutation(function (data) {
                return _this.api.create(entity, data);
            }, __assign({ onSuccess: function () {
                    queryClient.invalidateQueries(entity);
                }, onError: function (error) {
                    logger.log("".concat(entity, " Error"), { error: error });
                } }, options)), handleCreateEntity = _a.mutate, rest = __rest(_a, ["mutate"]);
            return __assign({ handleCreateEntity: handleCreateEntity }, rest);
        };
        this.api = api;
    }
    EntityQueries.prototype.hasValidId = function (id) {
        return /^\d+$/.test(id) || isValidUUID(id);
    };
    return EntityQueries;
}());
export default EntityQueries;
