"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.caseStudyQuerySchema = exports.caseStudyUpdateSchema = exports.caseStudyWriteSchema = exports.caseStudyReadSchema = exports.caseStudySchema = void 0;
var typebox_1 = require("@feathersjs/typebox");
var general_types_1 = require("./general-types");
var roof_area_types_1 = require("./roof-area.types");
exports.caseStudySchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    name: typebox_1.Type.String(),
    date: typebox_1.Type.Date(),
    address: typebox_1.Type.String(),
    kwpSize: typebox_1.Type.Number(),
    description: typebox_1.Type.String(),
    imageUrl: typebox_1.Type.String(),
    systemType: (0, typebox_1.StringEnum)(__spreadArray([], general_types_1.SYSTEM_TYPES, true)),
    mountType: (0, typebox_1.StringEnum)(__spreadArray([], roof_area_types_1.MOUNTING_TYPES, true)),
    inverter: typebox_1.Type.String(),
    battery: typebox_1.Type.String(),
    annualSavings: typebox_1.Type.Number(),
    // Relationships
    organisationId: typebox_1.Type.String(),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.caseStudyReadSchema = typebox_1.Type.Omit(exports.caseStudySchema, []);
exports.caseStudyWriteSchema = typebox_1.Type.Omit(exports.caseStudyReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.caseStudyUpdateSchema = typebox_1.Type.Partial(exports.caseStudySchema, {
    $id: 'CaseStudyUpdate',
});
var caseStudyQueryProperties = typebox_1.Type.Partial(exports.caseStudySchema, {
    $id: 'CaseStudyQuery',
});
exports.caseStudyQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(caseStudyQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
