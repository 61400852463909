"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.paymentOptionQuerySchema = exports.paymentOptionUpdateSchema = exports.paymentOptionWriteSchema = exports.paymentOptionReadSchema = exports.paymentOptionSchema = exports.INVOICE_PAYMENT_TERMS_DESCRIPTIONS = exports.PAYMENT_TERMS_IN_MONTHS = exports.USER_BANKS = exports.INVOICE_NUMBER_DEFAULT = exports.INVOICE_PAYMENT_TERM_DEFAULT = exports.INVOICE_PAYMENT_TERMS = exports.PAYMEMT_TYPES = void 0;
var typebox_1 = require("@feathersjs/typebox");
exports.PAYMEMT_TYPES = [
    'cash',
    'finance',
    'home-loan',
    'subscription',
];
exports.INVOICE_PAYMENT_TERMS = ['100', '70/30', '70/20/10', '60/40'];
exports.INVOICE_PAYMENT_TERM_DEFAULT = '100';
exports.INVOICE_NUMBER_DEFAULT = '000';
// TODO: can we store these banks on the database?
exports.USER_BANKS = [
    'Nedbank',
    'Standard Bank',
    'FNB',
    'Absa',
    'Investec',
    'Old Mutual',
    'BetterBond',
    'Other',
];
exports.PAYMENT_TERMS_IN_MONTHS = [
    '12',
    '24',
    '36',
    '48',
    '60',
    '72',
    '84',
    '96',
];
exports.INVOICE_PAYMENT_TERMS_DESCRIPTIONS = {
    '100': ['100% of the payment due on order'],
    '70/20/10': [
        '10% deposit for physical site inspection',
        '70% of the payment upon acceptance of final engineering proposal prior to installation commencing',
        '20% of the payment (less SSEG value of R3500) prior to commissioning the system',
    ],
    '70/30': [
        '70% of the payment due upon acceptance of engineering proposal prior to installation commencing',
        '30% of the payment (less SSEG value of R3500) before commissioning of the system',
    ],
    '60/40': [
        '60% of the payment due on order',
        '40% of the payment upon delivery',
    ],
};
exports.paymentOptionSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    type: (0, typebox_1.StringEnum)(__spreadArray([], exports.PAYMEMT_TYPES, true)),
    name: typebox_1.Type.String(),
    term: typebox_1.Type.Optional(typebox_1.Type.Number()),
    interestRate: typebox_1.Type.Optional(typebox_1.Type.Number()),
    deposit: typebox_1.Type.Optional(typebox_1.Type.Number()),
    isFixed: typebox_1.Type.Optional(typebox_1.Type.Boolean()),
    isDefaultOption: typebox_1.Type.Optional(typebox_1.Type.Boolean()),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.paymentOptionReadSchema = typebox_1.Type.Omit(exports.paymentOptionSchema, []);
exports.paymentOptionWriteSchema = typebox_1.Type.Omit(exports.paymentOptionReadSchema, [
    'id',
    'createdAt',
    'updatedAt',
]);
exports.paymentOptionUpdateSchema = typebox_1.Type.Partial(exports.paymentOptionSchema, {
    $id: 'PaymentOptionUpdate',
});
var paymentOptionQueryProperties = typebox_1.Type.Partial(exports.paymentOptionSchema, {
    $id: 'PaymentOptionQuery',
});
exports.paymentOptionQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(paymentOptionQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
