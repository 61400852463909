"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getContactNumberRegex = exports.contactNumberRegexZA = exports.idNumberValidator = void 0;
var idNumberValidator = function (_a) {
    var saIdNumber = _a.saIdNumber, gender = _a.gender, foreigner = _a.foreigner;
    if (saIdNumber === '1234567890123')
        return {
            isValid: true,
            errorMessage: '',
        };
    var validated = false;
    var errors = true;
    if (Number.isInteger(Number(saIdNumber)) && saIdNumber.length === 13) {
        errors = false;
        var num_array = saIdNumber.split('').map(Number);
        var id_month = num_array[2] * 10 + num_array[3];
        var id_day = num_array[4] * 10 + num_array[5];
        if (id_month < 1 || id_month > 12) {
            errors = true;
        }
        if (id_day < 1 || id_day > 31) {
            errors = true;
        }
        var id_gender = num_array[6] >= 5 ? 'male' : 'female';
        if (gender && gender.toLowerCase() !== id_gender) {
            errors = true;
        }
        var id_foreigner = num_array[10];
        if (foreigner &&
            id_foreigner &&
            Number(foreigner) !== Number(id_foreigner)) {
            errors = true;
        }
        var even_digits_1 = [];
        var odd_digits_1 = [];
        num_array.forEach(function (digit, index) {
            if (index === 0 || index % 2 === 0) {
                odd_digits_1.push(digit);
            }
            else {
                even_digits_1.push(digit);
            }
        });
        var check_digit = odd_digits_1.pop();
        var added_odds = odd_digits_1.reduce(function (a, b) { return a + b; }, 0);
        var concatenated_evens = Number(even_digits_1.join(''));
        var evensx2 = concatenated_evens * 2;
        var added_evens = String(evensx2)
            .split('')
            .reduce(function (a, b) { return a + Number(b); }, 0);
        var sum = added_odds + added_evens;
        var last_digit = String(sum).slice(-1);
        var verify_check_digit = (10 - Number(last_digit)) % 10;
        if (Number(verify_check_digit) !== Number(check_digit)) {
            errors = true;
        }
        if (!errors) {
            validated = true;
        }
    }
    return {
        isValid: validated,
        errorMessage: errors ? 'Please enter a valid SA ID number' : '',
    };
};
exports.idNumberValidator = idNumberValidator;
exports.contactNumberRegexZA = /^\+[1-9]\d{5,14}$/;
var getContactNumberRegex = function (code) {
    if (code === void 0) { code = 'za'; }
    switch (code) {
        case 'za':
        default:
            return exports.contactNumberRegexZA;
    }
};
exports.getContactNumberRegex = getContactNumberRegex;
