"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const themeOptions = {
    palette: {
        primary: {
            lighter: '#0067B2',
            light: '#0067B2',
            dark: '#0067B2',
            darker: '#0067B2',
            main: '#0067B2',
            contrastText: '#FFFFFF',
        },
        secondary: {
            lighter: '#303030',
            light: '#303030',
            dark: '#303030',
            darker: '#303030',
            main: '#303030',
            contrastText: '#FFFFFF',
        },
    },
    shape: {
        borderRadius: 4,
    },
};
exports.default = themeOptions;
