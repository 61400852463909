"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.panelQuerySchema = exports.panelUpdateSchema = exports.panelWriteSchema = exports.panelReadSchema = exports.panelSchema = void 0;
var typebox_1 = require("@feathersjs/typebox");
var currency_types_1 = require("./currency.types");
var general_types_1 = require("./general-types");
exports.panelSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    brand: typebox_1.Type.String(),
    model: typebox_1.Type.String(),
    lengthMM: typebox_1.Type.Number(),
    breadthMM: typebox_1.Type.Number(),
    moduleEfficiency: typebox_1.Type.Number(),
    wattYield: typebox_1.Type.Number(),
    degradation: typebox_1.Type.Number(),
    default: typebox_1.Type.Boolean(),
    cost: typebox_1.Type.Number(),
    markupPercentage: typebox_1.Type.Number(),
    price: typebox_1.Type.Number(), // calculated field
    productPicture: typebox_1.Type.Optional(typebox_1.Type.String()),
    oemLogo: typebox_1.Type.Optional(typebox_1.Type.String()),
    benefits: typebox_1.Type.Optional(typebox_1.Type.Array(typebox_1.Type.String())),
    description: typebox_1.Type.Optional(typebox_1.Type.String()),
    currency: (0, typebox_1.StringEnum)(__spreadArray([], currency_types_1.SUPPORTED_CURRENCY, true)),
    isArchived: typebox_1.Type.Optional(typebox_1.Type.Boolean()),
    isActive: typebox_1.Type.Optional(typebox_1.Type.Boolean()),
    voc: typebox_1.Type.Number(),
    isc: typebox_1.Type.Number(),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.panelReadSchema = typebox_1.Type.Omit(exports.panelSchema, []);
exports.panelWriteSchema = typebox_1.Type.Omit(exports.panelReadSchema, [
    'id',
    'price',
    'createdAt',
    'updatedAt',
]);
exports.panelUpdateSchema = typebox_1.Type.Partial(exports.panelSchema, {
    $id: 'PanelUpdate',
});
var panelQueryProperties = typebox_1.Type.Partial(exports.panelSchema, {
    $id: 'PanelQuery',
});
exports.panelQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(panelQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
var panelPlottingSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Optional(typebox_1.Type.Number()),
    quantity: typebox_1.Type.Number(),
    mapping: typebox_1.Type.Array(general_types_1.panelMappingSchema),
    panel: typebox_1.Type.Optional(exports.panelReadSchema),
    rotationAngle: typebox_1.Type.Optional(typebox_1.Type.Number()),
});
