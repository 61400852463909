import { PropsWithChildren } from 'react';
// @mui
import { Typography } from '@mui/material';

export type THeadingProps = PropsWithChildren & {
	variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
};

export const Heading = ({ variant, children }: THeadingProps) => (
	<Typography variant={variant} gutterBottom>
		{children}
	</Typography>
);
