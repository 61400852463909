"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.glintKits = void 0;
exports.glintKits = {
    'Spark Plus Services': 1699,
    'Glow Services': 2850,
    'Shine Services': 4300,
    'Flash Services': 1350,
    'Flash Plus Services': 999,
    'Spark Services': 1350,
};
