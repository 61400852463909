"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MONTHS = void 0;
exports.MONTHS = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
];
