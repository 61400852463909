import { useEffect } from 'react';
import { usePathname } from 'next/navigation';
import { Analytics } from 'hohm-utilities';

export const TrackNextAppViews = () => {
	const pathname = usePathname();

	useEffect(() => {
		Analytics.trackPageView();
	}, [pathname]);

	return null;
};
