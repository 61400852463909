import { useEffect } from 'react';
import { logger } from 'hohm-utilities';
import { getExpirationTime } from '../utilities/tokenExpiration';

// Converts seconds to milliseconds
const SECONDS_TO_MILLISECONDS = 1000;

export const useAutomaticDisconnection = (
	accessToken: string | null,
	callback: () => void
) => {
	useEffect(() => {
		if (!accessToken) return;

		let timeoutId: NodeJS.Timeout | null = null;

		try {
			const expirationTime = getExpirationTime(accessToken);

			const timeRemainingToExpireAccessToken =
				expirationTime * SECONDS_TO_MILLISECONDS - Date.now();

			// Set accessToken to null after the delay, if it's positive
			if (timeRemainingToExpireAccessToken > 0) {
				timeoutId = setTimeout(
					callback,
					timeRemainingToExpireAccessToken
				);
			} else callback();
		} catch (error) {
			logger.error('Failed to set automatic disconnection:', error);
			// Handle the error appropriately (e.g., log it, notify the user, etc.)
		}

		// Cleanup function to clear the timeout if the component unmounts
		// eslint-disable-next-line consistent-return
		return () => {
			if (timeoutId) clearTimeout(timeoutId);
		};
	}, [accessToken, callback]); // Dependencies array ensures effect runs when accessToken or setState changes
};
