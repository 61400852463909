'use client';

import { useEffect, useState } from 'react';
// @mui
import {
	IconButton,
	Input,
	styled,
	Divider,
	Typography,
	Grid,
} from '@mui/material';
import { Add, Remove } from '@mui/icons-material';

interface IOptionalProps {
	minValue?: number;
	maxValue?: number;
}

interface IProps extends IOptionalProps {
	innerText: string;
	outerText: string;
	value: number;
}

interface IEventProps {
	onChange?: (value: number) => void;
}

const StyledInput = styled(Input)(() => ({
	width: '64px',
	fontSize: '36px',
	fontWeight: 'bold',
	padding: '0',
	margin: '0',
}));

const StyledInnerText = styled(Typography)(({ theme }) => ({
	color: theme.palette.text.primary,
}));

const StyledOuterText = styled(Typography)(({ theme }) => ({
	width: '100%',
	color: theme.palette.text.primary,
}));

export type TNumberStepperProps = IOptionalProps & IProps & IEventProps;
export const NumberStepper = ({
	innerText,
	outerText,
	value,
	minValue = 0,
	maxValue = Infinity,
	onChange,
	...rest
}: TNumberStepperProps) => {
	const [numberStepperValue, setNumberStepperValue] = useState(
		Number(value || minValue)
	);

	useEffect(() => {
		if (onChange) onChange(numberStepperValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [numberStepperValue]);

	useEffect(() => {
		setNumberStepperValue(value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	return (
		<Grid container direction="row" spacing={2} alignItems="center">
			<Grid
				item
				flex="1"
				container
				alignItems="center"
				justifyContent="center"
				minWidth="160px"
			>
				<Grid item>
					<IconButton
						size="small"
						color="primary"
						onClick={() => {
							setNumberStepperValue(
								(oldNumberStepperValue) =>
									oldNumberStepperValue - 1
							);
						}}
						disabled={Number(minValue) >= numberStepperValue}
					>
						<Remove />
					</IconButton>
				</Grid>
				<Grid item>
					<StyledInput
						{...rest}
						value={numberStepperValue}
						disableUnderline
						inputProps={{
							style: {
								textAlign: 'center',
								fontSize: '30px',
							},
						}}
					/>
				</Grid>
				<Grid item>
					<IconButton
						size="small"
						color="primary"
						onClick={() =>
							setNumberStepperValue(
								(oldNumberStepperValue) =>
									oldNumberStepperValue + 1
							)
						}
						disabled={Number(maxValue) <= numberStepperValue}
					>
						<Add />
					</IconButton>
				</Grid>
			</Grid>
			<Grid
				item
				flex="auto"
				sx={{ maxWidth: '10px' }}
				display={{
					xs: 'none',
				}}
			>
				<Divider
					variant="middle"
					orientation="vertical"
					flexItem
					style={{ height: '46px', marginTop: '6px' }}
				/>
			</Grid>
			<Grid
				item
				width={{
					xs: '100%',
					sm: 'auto',
				}}
			>
				<StyledInnerText variant="body2">{innerText}</StyledInnerText>
			</Grid>
			<Grid
				item
				flex="auto"
				sx={{ maxWidth: '10px' }}
				display={{
					xs: 'none',
				}}
			>
				<Divider
					variant="middle"
					orientation="vertical"
					flexItem
					style={{ height: '46px', marginTop: '6px' }}
				/>
			</Grid>
			<Grid
				item
				flex="2"
				width={{
					xs: '100%',
					sm: 'auto',
				}}
			>
				<StyledOuterText variant="body2">{outerText}</StyledOuterText>
			</Grid>
		</Grid>
	);
};
