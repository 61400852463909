"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.inverterQuerySchema = exports.inverterUpdateSchema = exports.inverterWriteSchema = exports.inverterReadSchema = exports.inverterSchema = exports.INVERTER_TYPES = exports.PHASES = void 0;
var typebox_1 = require("@feathersjs/typebox");
var currency_types_1 = require("./currency.types");
exports.PHASES = ['single', '3-phase'];
exports.INVERTER_TYPES = ['micro', 'string'];
var requiredItemsSchema = typebox_1.Type.Array(typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    name: typebox_1.Type.String(),
    value: typebox_1.Type.Number(),
    currency: (0, typebox_1.StringEnum)(__spreadArray([], currency_types_1.SUPPORTED_CURRENCY, true)),
    description: typebox_1.Type.Optional(typebox_1.Type.String()),
    quantityPerInverter: typebox_1.Type.Number(),
    quantityPerProject: typebox_1.Type.Number(),
}));
exports.inverterSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    brand: typebox_1.Type.String(),
    model: typebox_1.Type.String(),
    kwSize: typebox_1.Type.Number(),
    phase: (0, typebox_1.StringEnum)(__spreadArray([], exports.PHASES, true)),
    variation: (0, typebox_1.StringEnum)(__spreadArray([], exports.INVERTER_TYPES, true)),
    mpptMin: typebox_1.Type.Number(),
    mpptMax: typebox_1.Type.Number(),
    mppts: typebox_1.Type.Number(),
    maxAmpInput: typebox_1.Type.Number(),
    cost: typebox_1.Type.Number(),
    markupPercentage: typebox_1.Type.Number(),
    price: typebox_1.Type.Number(), // calculated field
    default: typebox_1.Type.Boolean(),
    productPicture: typebox_1.Type.Optional(typebox_1.Type.String()),
    oemLogo: typebox_1.Type.Optional(typebox_1.Type.String()),
    benefits: typebox_1.Type.Optional(typebox_1.Type.Array(typebox_1.Type.String())),
    description: typebox_1.Type.Optional(typebox_1.Type.String()),
    currency: (0, typebox_1.StringEnum)(__spreadArray([], currency_types_1.SUPPORTED_CURRENCY, true)),
    maxStackCount: typebox_1.Type.Number(),
    batteryIncluded: typebox_1.Type.Boolean(),
    requiredItems: requiredItemsSchema,
    isArchived: typebox_1.Type.Optional(typebox_1.Type.Boolean()),
    isActive: typebox_1.Type.Optional(typebox_1.Type.Boolean()),
    createdAt: typebox_1.Type.Date(),
    updatedAt: typebox_1.Type.Date(),
});
exports.inverterReadSchema = typebox_1.Type.Omit(exports.inverterSchema, []);
exports.inverterWriteSchema = typebox_1.Type.Omit(exports.inverterReadSchema, [
    'id',
    'price',
    'createdAt',
    'updatedAt',
]);
exports.inverterUpdateSchema = typebox_1.Type.Partial(exports.inverterSchema, {
    $id: 'InverterUpdate',
});
var inverterQueryProperties = typebox_1.Type.Partial(exports.inverterSchema, {
    $id: 'InverterQuery',
});
exports.inverterQuerySchema = typebox_1.Type.Intersect([
    (0, typebox_1.querySyntax)(inverterQueryProperties),
    // Add additional query properties here
    typebox_1.Type.Object({}, { additionalProperties: false }),
], { additionalProperties: false });
