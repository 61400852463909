import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export default makeStyles(() =>
	createStyles({
		drawerItemsText: {
			fontSize: 30,
			fontWeight: 800,
			marginTop: 10,
			marginBottom: 10,
			color: 'white',
		},
	})
);
