import React from 'react';
import { Wrapper, Status } from '@googlemaps/react-wrapper';

export type TGoogleLoaderProps = {
	children?: React.ReactNode;
};

export const GoogleLoader = ({ children }: TGoogleLoaderProps) => {
	const render = (status: Status): React.ReactElement => {
		if (status === Status.LOADING) return <h3>{status} ..</h3>;
		if (status === Status.FAILURE) return <h3>{status} ...</h3>;

		return <div />;
	};

	return (
		<Wrapper
			apiKey={process.env.NEXT_PUBLIC_GOOGLE_API_KEY as string}
			render={render}
			libraries={['drawing', 'geometry', 'places']}
		>
			{children}
		</Wrapper>
	);
};
