'use client';

import * as React from 'react';
import { useMount } from 'react-use';
// @mui
import { styled } from '@mui/material/styles';
// ui
import { CircularProgress } from 'hohm-ui';
import { TReAuthenticateClientReturn } from 'hohm-queries';

interface ITemplateProps {
	children: JSX.Element;
	reAuthenticateClient: () => TReAuthenticateClientReturn;
}

const Authenticate = ({ children, reAuthenticateClient }: ITemplateProps) => {
	const [state, setState] = React.useState('');

	useMount(() => {
		try {
			const authenticateUser = async () => {
				setState('loading');
				await reAuthenticateClient();
				// TO*DO - Reimplement fetch currencies
				// await store.currencies.fetchCurrencies({});
				setState('done');
			};

			authenticateUser();
		} catch (err) {
			// console.log('Error in Authenticate', err);
		}
	});

	const Loader = styled('div')(() => ({
		zIndex: 1000,
		position: 'fixed',
		backgroundColor: 'white',
		height: '100vh',
		width: '100vw',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	}));

	if (state === 'loading') {
		return (
			<Loader>
				<CircularProgress color="secondary" />
			</Loader>
		);
	}

	if (state === 'done') {
		return children;
	}

	return <div />;
};

export default Authenticate;
