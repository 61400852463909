"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getHubSpotMeetingUrl = void 0;
var hohm_types_1 = require("hohm-types");
/**
 * @description Get the HubSpot meeting URL. Return default if none is provided.
 * @param {string} [hubspotMeetingUrls] - The assigned energy advisor's custom meeting URL
 * @param {string} [meetingAgenda] - The HubSpot meeting type
 * @returns {string} - The HubSpot meeting URL
 */
var getHubSpotMeetingUrl = function (hubspotMeetingUrls, meetingAgenda) {
    var embed = '?embed=true';
    var defaultUrl = process.env.NODE_ENV === 'production'
        ? hohm_types_1.Variables.prodHubSpotMeetingUrl
        : hohm_types_1.Variables.devHubSpotMeetingUrl;
    if (meetingAgenda && (hubspotMeetingUrls === null || hubspotMeetingUrls === void 0 ? void 0 : hubspotMeetingUrls[meetingAgenda])) {
        return "".concat(hubspotMeetingUrls[meetingAgenda]).concat(embed);
    }
    return "".concat(defaultUrl).concat(embed);
};
exports.getHubSpotMeetingUrl = getHubSpotMeetingUrl;
